import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
  Alert
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import clsx from "clsx";
import useStyles from "./styles";
import RestaurantContext from "../../../context/Restaurant";

function AddCart({ isVisible, toggleModal, data, supplier }) {
  const theme = useTheme();
  const classes = useStyles();
  const extraSmall = useMediaQuery(theme.breakpoints.down('md'));

  const [quantity, setQuantity] = useState(1);
  const [error, setError] = useState(null);
  const {
    addCartItem,
    checkItemCart,
    addQuantity
  } = useContext(RestaurantContext);
  const handleAddToCart = async () => {
    const result = checkItemCart(data.id);
    if (result.exist) addQuantity(result.key);
    else
      addCartItem(
        supplier,
        data?.id,
        data?.name,
        quantity,
        data?.discountedPrice ? data.discountedPrice : data.price,
        data?.unit,
      );
    setQuantity(1)
    toggleModal();
  }

  return (
    <Dialog
      onClose={toggleModal}
      open={isVisible}
      maxWidth="xs"
      className={classes.root}
    >
      <Box display="flex" justifyContent="flex-end">
        <IconButton size={extraSmall ? "medium" : "small"} onClick={toggleModal} className={classes.closeContainer}>
          <CloseIcon color="#FF6B5E" />
        </IconButton>
      </Box>
      <DialogTitle>
        <Box component="div">
          <Typography variant="h5" color="textSecondary" className={clsx(classes.boldText, classes.MB2)}>
            Sepete Kaç {data?.unit} {data?.name} Eklemek İstiyorsunuz?
          </Typography>
          <Typography variant="subtitle2" className={`${classes.disabledText} ${classes.lightText} ${classes.MB2}`}>
            {data?.description ? data.description : ""}
          </Typography>
          <Typography variant="subtitle2" className={`${classes.disabledText} ${classes.lightText}`}>
            Mevcut miktar: {data?.availableAmount} {data?.unit}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          fullWidth
          type="number"
          value={quantity}
          onChange={e => setQuantity(Math.max(1, parseInt(e.target.value)))}
        />
        {error && <Alert severity="error" style={{ marginTop: '10px' }}>{error}</Alert>}
        <DialogActions>
          <Button
            variant="contained"
            fullWidth
            className={classes.btnBase}
            onClick={handleAddToCart}
            style={{ backgroundColor: "#FF6B5E" }}
          >
            <Typography variant="subtitle2" className={classes.boldText}>
              Ekle
            </Typography>
          </Button>
          <Button variant="outlined" fullWidth className={classes.btnBase} onClick={toggleModal}>
            <Typography variant="subtitle2" color="#FF6B5E" className={classes.boldText}>
              İptal
            </Typography>
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default React.memo(AddCart);
