import React from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
  Box,
  Button,
} from "@mui/material";
import {
  Facebook,
  Twitter,
  Instagram,
  LinkedIn,
  Groups,
  ShoppingCart,
  Storefront,
  ListAlt,
  StarOutline,
  Assessment,
  Email,
  Feedback,
  Search
} from "@mui/icons-material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import useStyle from "./styles";
import supplierImage from "../../../assets/images/supplier.png";
import logoBand from "../../../assets/images/logo_band.png";

const FeatureBox = ({ IconType, title, description, mobile, ipad }) => (
  <Grid
    container
    flexDirection="column"
    padding={2}
    paddingLeft={mobile ? 0 : 2}
    paddingRight={mobile ? 0 : 2}
    alignItems="center"
    justifyContent="center"
    xs={12}
    sm={4}
  >
    <Grid item xs={12} sm={4}>
      <IconType style={{ fontSize: "70px", color: "#FF6B5E" }} />
    </Grid>
    <Grid item>
      <Typography
        color="#555"
        fontSize={mobile ? "14px" : "21px"}
        fontWeight={700}
        marginTop={1}
        align="center"
      >
        {title}
      </Typography>
    </Grid>
    <Grid item>
      <Typography
        color="#777"
        paddingLeft={mobile || ipad ? 0 : 8}
        paddingRight={mobile || ipad ? 0 : 8}
        marginTop={0}
        align="center"
        fontSize={mobile ? "12px" : "14px"}
      >
        {description}
      </Typography>
    </Grid>
  </Grid>
);

function StoreContainer() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const ipad = useMediaQuery('(min-width:600px) and (max-width:1024px)');
  const classes = useStyle({ mobile });

  const featuresList = [
    {
      title: "Merkezi Tedarikçi ve Sipariş Yönetimi",
      description:
        "Tüm tedarikçilerinizle sorunsuz iletişim kurun, yanlış anlaşılmaları azaltın ve sipariş doğruluğunu artırın",
      IconType: Groups,
    },
    {
      title: "Otomatik Sipariş Tekrarı",
      description: "Tekrarlanan siparişlerde geçirilen zamanı azaltın",
      IconType: ShoppingCart,
    },
    {
      title: "Online Pazar Yeri",
      description: "Yeni ve güvenilir tedarikçileri kolayca keşfedin",
      IconType: Storefront,
    },
    {
      title: "Dijital Alışveriş Listeleri",
      description:
        "Kâğıt karmaşasını unutun, siparişlerinizi dijital düzende tutun",
      IconType: ListAlt,
    },
    {
      title: "Tedarikçi ve Sipariş Değerlendirme Sistemi",
      description:
        "Tedarikçilerinizi etkin bir şekilde değerlendirin, siparişlerin kalitesini sürekli izleyin",
      IconType: StarOutline,
    },
    {
      title: "Raporlarla İş Analitiği",
      description:
        "İşletmenizin performansını analiz etmek ve geliştirmek için size özel hazırlanan raporlara ulaşın",
      IconType: Assessment,
    },
  ];

  const supplierSection = (
    <Box className={classes.supplierSection} padding={mobile ? 3 : 6}>
      <img src={supplierImage} alt="Supplier" className={classes.supplierImage} />

      <Box className={classes.supplierContent}>
        <Typography fontSize={mobile ? '18px' : '21px'} fontWeight={700}>
          Tedarikçiler! İşletmenizin Tedarikçileriyle Kolay İletişim Kurun
        </Typography>
        <Typography paragraph>
          Merkezi Stok, Sipariş ve Teslimat Yönetim Platformu
        </Typography>
        <Typography className={`${classes.bulletItem} ${classes.bulletItemSpacing}`} component="div">Kolay Sipariş Yönetimi</Typography>
        <Typography className={`${classes.bulletItem} ${classes.bulletItemSpacing}`} component="div">Günlük Optimize Edilmiş Teslimat Planları</Typography>
        <Typography className={`${classes.bulletItem} ${classes.bulletItemSpacing}`} component="div">Çevrimiçi Pazaryerinde Daha Fazla Müşteriye Erişim Sağlama</Typography>
        <Typography className={`${classes.bulletItem} ${classes.bulletItemSpacing}`} component="div">Müşteri Geri Bildirimleri, Analizler, Raporlar!</Typography>
        <a href="https://tedarikci.tedabir.com" target="_blank" style={{ textDecoration: 'none' }}>
          <Button
            disableElevation
            style={{
              marginTop: 20,
              borderRadius: 30,
              backgroundColor: "#FF6B5E",
              padding: "10px 30px",
              fontSize: "15px"
            }}
          >
            <Typography variant="body2" color="secondary" className={classes.textBold}>
              Daha fazlası İçİn tıklayın!
            </Typography>
          </Button>
        </a>
      </Box>
    </Box>
  );

  const howItWorksSection = (
    <Box className={classes.howItWorks}>
      <Typography variant="h4" align="center" className={classes.sectionTitle} gutterBottom>
        Nasıl Çalışır?
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        {[{ Icon: Groups, text: "Tedarikçilerinizi sistemimize ekleyebilir ve onlara siparişler ve notlar gönderebilirsiniz." },
        { Icon: Email, text: "Tedarikçilerin platformu kullanma zorunlulukları yoktur! Biz, verdiğiniz siparişleri düzgün bir formata dönüştürerek SMS ve e-posta yoluyla onlara iletiyoruz." },
        { Icon: Feedback, text: "Tedarikçiler, aynı link üzerinden cevap verebilir veya bu link aracılığıyla platforma katılarak sipariş yönetimini kolaylaştırabilirler." },
        { Icon: Search, text: "Ayrıca, pazar yeri özelliğimiz aracılığıyla yeni tedarikçileri ve ürünleri keşfedebilir, fiyatlarını görebilir, değerlendirmelerini inceleyebilir ve hemen sipariş verebilirsiniz." }
        ].map((item, index) => (
          <Grid item xs={6} sm={3} key={index} className={classes.featureItem}>
            <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
              <item.Icon className={classes.iconStyle} />
              <Typography className={classes.howItWorksText}>{item.text}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Grid container className={classes.mainContainer}>
      <Grid item xs={12}>
        <Box className={classes.firstSection}>
          <Grid item xs={5} className={classes.pageTitle}>
            <Typography style={{
              fontSize: mobile ? "14px" : ipad ? "18px" : "38px",
            }}
              align="left">
              TedaBir: Restoranlar, Oteller, Cafeler, Marketler İçin Etkin
              Tedarikçi Yönetimi
            </Typography>
          </Grid>
          <a href="https://tedabir.com/register" target="_blank" style={{ textDecoration: 'none', position: 'absolute', right: 50, bottom: 50 }}>
            <Button
              className={classes.registerButton}
            >
              Hemen Kaydol
            </Button>
          </a>
        </Box>
      </Grid>

      <Grid item xs={12} className={classes.secondSectionContainer}>
        <Grid item xs={12}>
          <Typography
            variant={mobile ? "h5" : "h4"}
            align="center"
            marginTop={6}
            fontWeight={600}
            color={"#555"}
          >
            Tek Noktadan Tedarik Yönetimi ve Tedarikçi Keşif Platformu
          </Typography>
        </Grid>
        <Grid container xs={12} justifyContent={"center"}>
          <Grid item xs={6}>
            <Typography
              align="center"
              marginTop={2}
              fontWeight={400}
              color={"#777"}
              fontSize={mobile ? "12px" : "14px"}
            >
              TedaBir, restoranlar, oteller, kafeler ve benzeri işletmeler için
              tedarikçileriyle hızlı ve kolay iletişim kurma platformudur. Tüm
              tedarikçilerinizi tek bir yerde yönetin ve siparişlerinizi kolayca
              düzenleyin.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          xs={12}
          flexDirection={"row"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          flexWrap={"wrap"}
          paddingTop={mobile ? 3 : 6}
          paddingLeft={ipad ? 6 : 12}
          paddingRight={ipad ? 6 : 12}
          paddingBottom={mobile ? 3 : 6}
        >
          {featuresList.map(({ title, description, IconType }) => (
            <FeatureBox
              title={title}
              description={description}
              IconType={IconType}
              mobile={mobile}
              ipad={ipad}
            />
          ))}
        </Grid>
      </Grid>

      {howItWorksSection}

      {supplierSection}

      <Box className={mobile || ipad ? classes.videoNewMobile : classes.videoNew}>
        <Typography align="center" fontSize={mobile ? '18px' : '21px'} fontWeight={700}>
          TedaBir Tanıtım Videosu
        </Typography>
        <iframe
          height={mobile ? 220 : 340}
          width={mobile ? 320 : 600}
          src="https://www.youtube.com/embed/4cfJu05pOOM?iv_load_policy=3&rel=0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="TedaBir Tanıtım Videosu"
          align="center"
        ></iframe>
      </Box>
      <div className={classes.sectionDivider}></div>

      <Grid item xs={12}>
        <Box className={classes.lastSection}>
          <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="p" gutterBottom marginLeft={2}>TedaBir</Typography>
              {/* <Signature/> */}
              <Box className={classes.socialMediaIcons}>
                <IconButton
                  href="https://www.facebook.com/tedabircom"
                  target="_blank"
                  className={classes.socialIcon}
                >
                  <Facebook />
                </IconButton>
                <IconButton
                  href="https://twitter.com/TedaBir"
                  target="_blank"
                  className={classes.socialIcon}
                >
                  <Twitter />
                </IconButton>
                <IconButton
                  href="https://www.instagram.com/tedabircom/"
                  target="_blank"
                  className={classes.socialIcon}
                >
                  <Instagram />
                </IconButton>
                <IconButton
                  href="https://www.linkedin.com/company/tedabir"
                  target="_blank"
                  className={classes.socialIcon}
                >
                  <LinkedIn />
                </IconButton>
              </Box>
              <div className={classes.logoBandContainer}>
                <img src={logoBand} alt="Payment Logos" />
              </div>
            </Grid>
            <Grid item xs={3.25} sm={2}>
              <Typography gutterBottom>
                <a href="/#/help" target="_blank" className={classes.link}>
                  Hakkımızda
                </a>
              </Typography>
              <Typography>
                <a href="/#/help" target="_blank" className={classes.link}>
                  Sıkça Sorulan Sorular
                </a>
              </Typography>
              <Typography>
                <a href="/#/help" target="_blank" className={classes.link}>
                  Yardım
                </a>
              </Typography>
            </Grid>
            <Grid item xs={3.25} sm={2}>
              <Typography gutterBottom>
                <a href="/#/sozlesme" target="_blank" className={classes.link}>
                  Hizmet Sözleşmesi
                </a>
              </Typography>
              <Typography>
                <a href="/#/help" target="_blank" className={classes.link}>
                  Teslimat ve İade Şartları
                </a>
              </Typography>
              <Typography>
                <a href="https://www.freeprivacypolicy.com/live/0168608c-2fb1-4fef-92ca-6583529d1559" target="_blank" className={classes.link}>
                  Privacy policy
                </a>
              </Typography>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Typography gutterBottom>Tedarikçiler</Typography>
              <Typography>
                <a href="https://tedarikci.tedabir.com/#/auth/welcome" target="_blank" className={classes.link}>
                  Nasıl Kayıt Olunur
                </a>
              </Typography>
              <Typography>
                <a href="https://www.youtube.com/watch?v=yHQjcGkBf-4" target="_blank" className={classes.link}>
                  Tanıtım Videosu
                </a>
              </Typography>
            </Grid>
            <Grid item xs={1.5} sm={2}>
              <IconButton className={classes.scrollButton} onClick={handleScrollToTop}>
                <KeyboardArrowUpIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item xs={12} className={classes.footer}>
        <Typography variant="caption">
          © 2024 TedaBir. Tüm Hakları Saklıdır.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default React.memo(StoreContainer);
