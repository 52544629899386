import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    padding: theme.spacing(3),
  },
  marginHeader: {
    marginTop: '100px',
    width: '100%',
  },
  mainContainer: {
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
  },
  pageTitle: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  chartCard: {
    padding: theme.spacing(2),
  },
  supplierCard: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    margin: theme.spacing(2, 0),
    backgroundColor: '#f9f9f9',
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.02)',
    },
  },
  supplierAvatar: {
    backgroundColor: '#FF6B5E',
    color: 'white',
    width: theme.spacing(7),
    height: theme.spacing(7),
    fontSize: '1.5rem',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(2),
  },
  supplierName: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: '#333',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem', // Smaller font size for small screens
    },
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis', // Add ellipsis if text is too long
  },
  amountOwed: {
    marginLeft: theme.spacing(1),
    fontSize: '1rem',
    fontWeight: 'bold',
    color: '#F44336',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem', // Smaller font size for small screens
    },
  },
  supplierInfo: {
    flex: 1,
  },
  supplierChangeIcon: {
    fontSize: '1.5rem',
    color: '#4CAF50',
  },
  supplierChangeIconNegative: {
    color: '#F44336',
  },
  enhancedPaper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: '#E8F5E9',
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
  },
  enhancedTitle: {
    fontWeight: 'bold',
    color: '#388E3C',
  },
  reportButton: {
    marginLeft: theme.spacing(0), // Remove left margin on mobile
    backgroundColor: '#4CAF50',
    color: 'white',
    '&:hover': {
      backgroundColor: '#388E3C',
    },
    marginBottom: theme.spacing(1), // Add bottom margin for mobile
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(0), // Remove bottom margin on larger screens
    },
  },
  planButton: {
    marginLeft: theme.spacing(0), // Remove left margin on mobile
    borderColor: '#1E88E5',
    color: '#1E88E5',
    '&:hover': {
      borderColor: '#1565C0',
    },
    marginBottom: theme.spacing(1), // Add bottom margin for mobile
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(0), // Remove bottom margin on larger screens
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start', // Align items to the left
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    '& > *': { // Applies to all direct children (i.e., buttons)
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        marginBottom: 0,
        marginLeft: theme.spacing(1),
      },
    },
  },
}));

export default useStyles;
