import React, { useState, useEffect } from 'react';
import { Typography, Button,  Grid, Box, Card, TextField, Snackbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useParams } from 'react-router-dom';
import LinkHeader from '../Header/LoginHeader/LinkHeader';
import { addNewNote, getOrder } from '../../api/campaign_api';
import { formatDateToTurkish, parseOrderTime } from '../Utils';
import bg from "../../assets/images/background.png";
import clsx from "clsx";
import Error from "../../components/Modals/Error/Error.js";
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: 'white',
        borderRadius: 20,
        boxShadow: '0px 0px 38px rgba(0, 0, 0, 0.06)',
        textAlign: 'center',
        paddingBottom: 5,
        padding: '10px',
        marginTop: '20px',
        marginBottom: '30px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    heading: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        padding: '10px',
        borderRadius: 20,
        textAlign: 'left',
        marginBottom: '20px',
        textAlign: 'center',
    },
    orderDetail: {
        color: theme.palette.text.primary,
        marginBottom: '10px',
        textAlign: 'left',
    },
    orderDetailCard: {
        backgroundColor: theme.palette.background.paper,
        padding: '15px',
        borderRadius: 20,
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        marginBottom: '10px',
        textAlign: 'left',
    },
    boldText: {
        fontWeight: 'bold',
    },
    button: {
        margin: '10px 10px 10px 0',
        width: 'auto',
        borderRadius: 20,
        '&:hover': {
            backgroundColor: theme.palette.primary.main
        },
    },
    rejectButton: {
        backgroundColor: "#7D7D7D",
        color: 'white',
        margin: '10px 10px 10px 0',
        width: 'auto',
        borderRadius: 20,
        '&:hover': {
            backgroundColor: "#565656",
        },
        '&:disabled': {
            backgroundColor: "#9e9e9e",
            color: 'white',
        }
    },
    input: {
        width: '100%',
        marginBottom: '20px',
    },
    alert: {
        marginBottom: '20px',
    },
    welcomeMessage: {
        color: theme.palette.common.black,
        marginBottom: '15px',
    },
    actionButtons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginBottom: '20px',
    },
    registerPrompt: {
        marginTop: '20px',
        fontWeight: 'bold',
    },
    largeInput: {
        width: '100%',
        paddingTop: '10px',
        fontSize: '1rem',
        borderRadius: '4px',
        backgroundColor: theme.palette.background.paper
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));

const CampaignOrderHistory = () => {
    const [data, setData] = useState(null);
    let { id } = useParams();
    const classes = useStyles();
    const [newNote, setNewNote] = useState('');
    const [successMessage, setSuccessMessage] = useState("");
    const [errorModalOpen, setErrorModalOpen] = useState(false);

    const fetchData = async () => {
        try {
            const response = await getOrder(id);
            if (response && response.status === 200 && response.data) {
                setData(response.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const getOrderStatusMessage = (status) => {
        switch (status) {
            case 'ACCEPTED_BY_SUPPLIER':
                return 'Sipariş kabul edildi!';
            case 'REJECTED_BY_SUPPLIER':
                return 'Siparişi reddedildi!';
            case 'COMPLETED':
                return 'Sipariş tamamlandı!';
            case 'CANCELED':
                return 'Sipariş iptal edildi!';
            default:
                return '';
        }
    };

    const closeErrorModal = () => {
        setErrorModalOpen(false);
    };

    const addNote = async () => {
        const newNoteData = {
            orderId: data.orderId,
            content: newNote,
            owner: "RETAIL"
        }
        try {
            const response = await addNewNote(newNoteData);
            if (response && response.status === 200) {
                setNewNote("");
                setSuccessMessage("Notunuz hemen işletmeye iletilecektir!!");
                setTimeout(() => {
                    setSuccessMessage('');
                    fetchData(id);
                }, 2000);
            } else {
                setErrorModalOpen(true);
                setTimeout(() => {
                    setErrorModalOpen(false);
                }, 3000);
            }
        } catch (error) {
            setErrorModalOpen(true);
            setTimeout(() => {
                setErrorModalOpen(false);
            }, 3000);
        }
    };

    const renderContent = () => {
        return data?.products?.map((product, index) => (
            <Grid container key={index} className={classes.ph1}>
                <Grid item xs={12} md={6} className={classes.productItem}>
                    <Typography variant="body2" color="textSecondary" className={clsx(classes.productItem, classes.contentWrap)}>
                        {product.productName}  - {product.amount} {product.unit} {product.price ? `x ${product.price} TL` : ''}
                    </Typography>
                </Grid>
            </Grid>
        ));
    }

    const navigate = useNavigate();

    const handleRegistrationRedirect = () => {
        navigate('/register?campaignOId=' + data.orderId);
    };

    return (
        <>
            <LinkHeader showCatalogName={false} />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh',
                    padding: 0,
                    background: `url(${bg})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                }}
            >
                <Box
                    sx={{
                        overflowY: 'auto',
                        maxHeight: 'calc(100vh - 100px)',
                        paddingTop: '80px',
                    }}
                >
                    {(!id || !data) ?
                        <Box container sx={{ width: { xs: '90%' } }} className={classes.container}>
                            <Typography variant="h6" className={classes.welcomeMessage}>
                                Sipariş Bilgisi Bulunamadı!
                            </Typography>
                        </Box>
                        :
                        <Box container sx={{ width: { xs: '90%' } }} className={classes.container}>
                            <Typography variant="h6" className={classes.welcomeMessage}>
                                {data?.supplierBusinessName}'dan yeni bir siparişiniz var 🎉
                            </Typography>
                            <Typography variant="h6" className={classes.heading}>
                                Sipariş Bilgileri
                            </Typography>
                            <Box className={classes.orderDetailCard}>
                                <Typography className={classes.orderDetail}>
                                    <span className={classes.boldText}>Sipariş Numarası </span> {data?.orderId}
                                </Typography>
                                <Typography className={classes.orderDetail}>
                                    <span className={classes.boldText}>İşletme Adı </span> {data?.supplierBusinessName}
                                </Typography>
                                <Typography className={classes.orderDetail}>
                                    <span className={classes.boldText}>Teslimat Zamanı </span> {formatDateToTurkish(parseOrderTime(data?.orderTime))}
                                </Typography>
                                <Typography className={classes.orderDetail}>
                                    <span className={classes.boldText}>Adress </span> {data?.address}
                                </Typography>
                                <Typography className={classes.orderDetail}>
                                    <span className={classes.boldText}>İçerik </span>
                                    {renderContent()}
                                </Typography>
                            </Box>
                            <Typography variant="h6" className={classes.statusMessage}>
                                {getOrderStatusMessage(data?.status)}
                            </Typography>
                            <Card className={classes.orderDetailCard} style={{ marginTop: 20 }}>
                                <Typography variant="h6" style={{ margin: 10, color: '#333' }}>
                                    Sipariş Ek Notları
                                </Typography>
                                {data?.noteDtoList?.map((note, index) => (
                                    <Typography key={index} style={{ margin: 10, color: '#555' }}>
                                        <span style={{ fontWeight: 'bold' }}>{formatDateToTurkish(parseOrderTime(note.createdAt, false))} - {note.owner === "RETAIL" ? data.restaurantName : "Siz"}: </span> {note.content}
                                    </Typography>
                                ))}

                                {(data?.status === "APPROVAL_WAITING" || data?.status === "ACCEPTED_BY_SUPPLIER") &&
                                    <div style={{ margin: 10 }}>
                                        <Typography variant="p" >
                                            Herhangi bir ek notunuz var mı? İşletmeye bildirin.
                                        </Typography>
                                        <TextField
                                            multiline
                                            rows={4}
                                            variant="outlined"
                                            onChange={(e) => setNewNote(e.target.value)}
                                            className={classes.largeInput}
                                            value={newNote}
                                            placeholder="Notunuzu buraya yazın..."
                                        />
                                        <div className={classes.buttonContainer}>
                                            <Button variant="contained" color="primary" className={classes.button}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    addNote();
                                                }}>Gönder</Button>
                                        </div>
                                    </div>
                                }
                            </Card>

                            <Typography className={classes.registerPrompt}>
                                TedaBir'e kayıt olun ve işletmenizin tedarikçileriyle kolay iletişim kurun!
                                <Button
                                    variant="contained" color="primary"
                                    className={classes.button}
                                    onClick={handleRegistrationRedirect}>
                                    Şİmdİ Kaydol!
                                </Button>
                            </Typography>

                            <Snackbar
                                open={Boolean(successMessage)}
                                autoHideDuration={6000}
                                onClose={() => {
                                    setSuccessMessage("");
                                }}
                                message={successMessage}
                            />

                            <Error
                                isVisible={errorModalOpen}
                                toggleModal={closeErrorModal}
                            />
                        </Box>
                    }
                </Box>
            </Box>
        </>
    );
};

export default CampaignOrderHistory;
