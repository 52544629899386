import React from 'react';
import { Paper, Box } from '@mui/material';

function NotificationMessage(props) {
  return (
    <Box
      sx={{
        '&:not(:last-child)': {
          mb: 2,
        },
      }}
    >
      <Paper
        sx={{
          display: props.open ? 'flex' : 'none',
          justifyContent: 'space-between',
          p: 1.5,
          borderRadius: 2,
          boxShadow: 1,
          backgroundColor: '#DEE6ED',
        }}
      >
        <span>{props.alertMessage}</span>
      </Paper>
    </Box>
  );
}

export default NotificationMessage;
