import React from "react";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import vegetablesImg from "../../assets/categories/vegetables.png";
import dairyImg from "../../assets/categories/dairy.png";
import meatImg from "../../assets/categories/meat.png";
import fishImg from "../../assets/categories/fish.png";
import bakedImg from "../../assets/categories/baked.png";
import pastaImg from "../../assets/categories/pasta.png";
import kitchenImg from "../../assets/categories/kitchen.png";
import cleaningImg from "../../assets/categories/cleaning.png";
import spicesImg from "../../assets/categories/spices.png";
import coffeeImg from "../../assets/categories/coffee.png";
import drinksImg from "../../assets/categories/drinks.png";
import frozenVegImg from "../../assets/categories/frozen-vegetables.png";
import frozenBakeryImg from "../../assets/categories/baked.png";
import flowers from "../../assets/categories/flowers.png";
import allImg from "../../assets/categories/all.png";
import useStyles from "./styles";

const DeliveryTabs = ({ active, activeTabChange }) => {
   const styles = useStyles();

   return (
      <Grid container className={styles.center}>
         <Grid
            item
            className={
               `${styles.box} ${active === "vegetable" ? styles.activeTab : ""}`
            }
            mb={2}
            onClick={() => activeTabChange("vegetable")}
         >
            <Box className={styles.innerBox}>
               <Typography className={styles.text} component="div" variant="h6">
                  Sebze Meyve
               </Typography>
               <CardMedia
                  component="img"
                  className={styles.image}
                  image={vegetablesImg}
               />
            </Box>
         </Grid>
         <Grid
            item
            className={
               `${styles.box} ${active === "bakery" ? styles.activeTab : ""}`
            }
            mb={2}
            onClick={() => activeTabChange("bakery")}
         >
            <Box className={styles.innerBox}>
               <Typography className={styles.text} component="div" variant="h6">
                  Fırın Ürünleri
               </Typography>
               <CardMedia
                  component="img"
                  className={styles.image}
                  image={bakedImg}
               />
            </Box>
         </Grid>
         <Grid
            item
            className={`${styles.box} ${active === "dairy" ? styles.activeTab : ""}`}
            mb={2}
            onClick={() => activeTabChange("dairy")}
         >
            <Box className={styles.innerBox}>
               <Typography className={styles.text} component="div" variant="h6">
                  Süt Ürünleri
               </Typography>
               <CardMedia
                  component="img"
                  className={styles.image}
                  image={dairyImg}
               />
            </Box>
         </Grid>
         <Grid
            item
            className={`${styles.box} ${active === "pasta" ? styles.activeTab : ""}`}
            mb={2}
            onClick={() => activeTabChange("pasta")}
         >
            <Box className={styles.innerBox}>
               <Typography className={styles.text} component="div" variant="h6">
                  Makarna-Tahıl
               </Typography>
               <CardMedia
                  component="img"
                  className={styles.image}
                  image={pastaImg}
               />
            </Box>
         </Grid>
         <Grid
            item
            className={`${styles.box} ${active === "meat" ? styles.activeTab : ""}`}
            mb={2}
            onClick={() => activeTabChange("meat")}
         >
            <Box className={styles.innerBox}>
               <Typography className={styles.text} component="div" variant="h6">
                  Et - Tavuk
               </Typography>
               <CardMedia
                  component="img"
                  className={styles.image}
                  image={meatImg}
               />
            </Box>
         </Grid>
         <Grid
            item
            className={`${styles.box} ${active === "fish" ? styles.activeTab : ""}`}
            mb={2}
            onClick={() => activeTabChange("fish")}
         >
            <Box className={styles.innerBox}>
               <Typography className={styles.text} component="div" variant="h6">
                  Balık
               </Typography>
               <CardMedia
                  component="img"
                  className={styles.image}
                  image={fishImg}
               />
            </Box>
         </Grid>
         <Grid
            item
            className={`${styles.box} ${active === "coffee" ? styles.activeTab : ""}`}
            mb={2}
            onClick={() => activeTabChange("coffee")}
         >
            <Box className={styles.innerBox}>
               <Typography className={styles.text} component="div" variant="h6">
                  Kahve
               </Typography>
               <CardMedia
                  component="img"
                  className={styles.image}
                  image={coffeeImg}
               />
            </Box>
         </Grid>
         <Grid
            item
            className={`${styles.box} ${active === "drinks" ? styles.activeTab : ""}`}
            mb={2}
            onClick={() => activeTabChange("drinks")}
         >
            <Box className={styles.innerBox}>
               <Typography className={styles.text} component="div" variant="h6">
                  İçecekler
               </Typography>
               <CardMedia
                  component="img"
                  className={styles.image}
                  image={drinksImg}
               />
            </Box>
         </Grid>
         <Grid
            item
            className={`${styles.box} ${active === "spices" ? styles.activeTab : ""}`}
            mb={2}
            onClick={() => activeTabChange("spices")}
         >
            <Box className={styles.innerBox}>
               <Typography className={styles.text} component="div" variant="h6">
                  Sos-Çeşni
               </Typography>
               <CardMedia
                  component="img"
                  className={styles.image}
                  image={spicesImg}
               />
            </Box>
         </Grid>
         <Grid
            item
            className={`${styles.box} ${active === "kitchen" ? styles.activeTab : ""}`}
            mb={2}
            onClick={() => activeTabChange("kitchen")}
         >
            <Box className={styles.innerBox}>
               <Typography className={styles.text} component="div" variant="h6">
                  Araç-Gereç
               </Typography>
               <CardMedia
                  component="img"
                  className={styles.image}
                  image={kitchenImg}
               />
            </Box>

         </Grid>
         <Grid
            item
            className={`${styles.box} ${active === "cleaning" ? styles.activeTab : ""}`}
            mb={2}
            onClick={() => activeTabChange("cleaning")}
         >
            <Box className={styles.innerBox}>
               <Typography className={styles.text} component="div" variant="h6">
                  Temizlik
               </Typography>
               <CardMedia
                  component="img"
                  className={styles.image}
                  image={cleaningImg}
               />
            </Box>
         </Grid>
         <Grid
            item
            className={
               `${styles.box} ${active === "frozen-vegetable" ? styles.activeTab : ""}`
            }
            mb={2}
            onClick={() => activeTabChange("frozen-vegetable")}
         >
            <Box className={styles.innerBox}>
               <Typography className={styles.text} component="div" variant="h6">
                  Dondurulmuş Sebze-Meyve
               </Typography>
               <CardMedia
                  component="img"
                  className={styles.image}
                  image={frozenVegImg}
               />
            </Box>
         </Grid>
         <Grid
            item
            className={
               `${styles.box} ${active === "frozen-bakery" ? styles.activeTab : ""}`
            }
            mb={2}
            onClick={() => activeTabChange("frozen-bakery")}
         >
            <Box className={styles.innerBox}>
               <Typography className={styles.text} component="div" variant="h6">
                  Dondurulmuş Fırın Ürünleri
               </Typography>
               <CardMedia
                  component="img"
                  className={styles.image}
                  image={frozenBakeryImg}
                  alt="Delivery"
               />
            </Box>
         </Grid>
         <Grid
            item
            className={
               `${styles.box} ${active === "flowers" ? styles.activeTab : ""}`
            }
            mb={2}
            onClick={() => activeTabChange("flowers")}
         >
            <Box className={styles.innerBox}>
               <Typography className={styles.text} component="div" variant="h6">
                  Çiçek
               </Typography>
               <CardMedia
                  component="img"
                  className={styles.image}
                  image={flowers}
                  alt="Delivery"
               />
            </Box>
         </Grid>
         <Grid
            item
            className={
               `${styles.box} ${active === "all" ? styles.activeTab : ""}`
            }
            mb={2}
            onClick={() => activeTabChange("all")}
         >
            <Box className={styles.innerBox}>
               <Typography className={styles.text} component="div" variant="h6">
                  Hepsi
               </Typography>
               <CardMedia
                  component="img"
                  className={styles.image}
                  image={allImg}
               />
            </Box>
         </Grid>
      </Grid>
   );
};
export default DeliveryTabs;
