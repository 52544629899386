/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import FlashMessage from "../../components/FlashMessage";
import { Header, LoginHeader } from "../../components/Header";
import useStyles from "./styles";
import DeliveryTabs from "../../components/Tabs/Tabs";
import SupplierGrid from "../../components/SupplierComponent/SupplierGrid/SupplierGrid";
import SearchSupplier from "../../components/SupplierComponent/SearchSupplier/SearchSupplier";
import { getSuppliers, searchProducts } from "../../api/market_api";
import RestaurantContext from "../../context/Restaurant";

function MarketSuppliers() {
  const [message, setMessage] = useState({});
  const [search, setSearch] = useState("");
  const { isLoggedIn, profile } = useContext(RestaurantContext);
  const classes = useStyles();
  const [active, setActive] = useState("all");
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [search]);

  const showMessage = useCallback((messageObj) => {
    setMessage(messageObj);
  }, []);
  const toggleSnackbar = useCallback(() => {
    setMessage({});
  }, []);

  const fetchData = async () => {
    if (search) {
      const response = await searchProducts(search, profile.id);
      setData(response.data || []);
    } else {
      const response = await getSuppliers(profile.id);
      if (response && response.status === 200 && response.data) {
        setData(response.data);
      } else {
        setData([]);
      }
    }
  };

  const filterSuppliers = (suppliers) => {
    if (active === "all") return suppliers;
    if (active === "vegetable" || active === "frozen-vegetable") return suppliers.filter(s =>
      s.mainCategory === "VEGETABLES" ||
      s.mainCategory === "FROZEN_VEGETABLES" ||
      s.categories.includes("VEGETABLES") ||
      s.categories.includes("FROZEN_VEGETABLES")
    );
    if (active === "bakery" || active === "frozen-bakery") return suppliers.filter(s =>
      s.mainCategory === "BAKERY" ||
      s.mainCategory === "FROZEN_BAKERY" ||
      s.categories.includes("BAKERY") ||
      s.categories.includes("FROZEN_BAKERY")
    );
    if (active === "dairy") return suppliers.filter(s => s.mainCategory === "DAIRY" || s.categories.includes("DAIRY"));
    if (active === "meat") return suppliers.filter(s => s.mainCategory === "MEAT" || s.categories.includes("MEAT"));
    if (active === "fish") return suppliers.filter(s => s.mainCategory === "FISH" || s.categories.includes("FISH"));
    if (active === "coffee") return suppliers.filter(s => s.mainCategory === "COFFEE" || s.categories.includes("COFFEE"));
    if (active === "drinks") return suppliers.filter(s => s.mainCategory === "DRINKS" || s.categories.includes("DRINKS"));
    if (active === "pasta") return suppliers.filter(s => s.mainCategory === "PASTA_AND_GRAINS" || s.categories.includes("PASTA_AND_GRAINS"));
    if (active === "cleaning") return suppliers.filter(s => s.mainCategory === "CLEANING_AND_PACKAGING" || s.categories.includes("CLEANING_AND_PACKAGING"));
    if (active === "spices") return suppliers.filter(s => s.mainCategory === "SPICES_AND_SAUCES" || s.categories.includes("SPICES_AND_SAUCES"));
    if (active === "kitchen") return suppliers.filter(s => s.mainCategory === "KITCHEN_UTENSILS" || s.categories.includes("KITCHEN_UTENSILS"));
    if (active === "flowers") return suppliers.filter(s => s.mainCategory === "FLOWERS" || s.categories.includes("FLOWERS"));
    return suppliers;
  }

  const activeTabChange = (tab) => {
    setActive(tab);
  };

  return (
    <Grid container style={{ marginBottom: '5%' }}>
      <FlashMessage
        open={Boolean(message.type)}
        severity={message.type}
        alertMessage={message.message}
        handleClose={toggleSnackbar}
      />
      {isLoggedIn ? <Header /> : <LoginHeader showIcon />}
      <Grid container item className={classes.marginHeader}>
        <DeliveryTabs
          active={active}
          activeTabChange={activeTabChange}
        />
        <SearchSupplier search={search} setSearch={setSearch} />
      </Grid>
      <Grid container item>
        <SupplierGrid
          suppliers={filterSuppliers(data)}
          showMessage={showMessage}
        />
      </Grid>
    </Grid>
  );
}

export default MarketSuppliers;
