/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
  TextField,
  Chip,
  CircularProgress
} from "@mui/material";
import React, { useCallback, useEffect, useState, useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-scroll";
import Scrollspy from "react-scrollspy";
import FlashMessage from "../../components/FlashMessage";
import { Header } from "../../components/Header";
import useStyles from "./styles";
import SupplierChatCard from "./SupplierChatCard";
import { getSupplierByRestaurant } from "../../api/restaurant_api";
import { Pictures } from "../../components/Utils";
import SupplierOrderCard from "./SupplierOrderCard";
import { SupplierHeader } from "../../components/SupplierDetailComponent";
import SupplierInfo from "../../components/Modals/SupplierInfo/SupplierInfo";
import SupplierActiveOrderModal from "../../components/Modals/SupplierActiveOrder/SupplierActiveOrderModal";
import SupplierCancelOrderModal from "../../components/Modals/SupplierCancelOrder/SupplierCancelOrderModal";
import NoteAdded from "../../components/Modals/NoteAdded/NoteAdded";
import OrderCanceled from "../../components/Modals/OrderCanceled/OrderCanceled";
import SupplierPastOrderCard from "./SupplierPastOrderCard";
import SupplierPastOrderModal from "../../components/Modals/SupplierPastOrder/SupplierPastOrderModal";
import ProductCard from "../../components/SupplierComponent/ProductCard/ProductCard";
import { RestaurantCart } from "../../components/RestaurantDetailComponent";
import RestaurantContext from "../../context/Restaurant";
import CloseIcon from '@mui/icons-material/Close';

function SupplierDetail() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const { state } = useLocation();
  const [message, setMessage] = useState({});
  const [reviewModal, setReviewModal] = useState(false);
  const [openActiveOrderModal, setOpenActiveOrderModal] = useState(false);
  const [cancelOrderModal, setCancelOrderModal] = useState(false);
  const [noteAddedModal, setNoteAddedModal] = useState(false);
  const [orderCanceledModal, setOrderCanceledModal] = useState(false);
  const [data, setData] = useState({});
  const image = data.imageData ? `data:image/jpeg;base64,${data.imageData}` : Pictures[state?.category]?.source?.default ? Pictures[state?.category]?.source?.default : Pictures['Genel'].source.default;
  const { restaurantId } = useContext(RestaurantContext);
  const [activeOrder, setActiveOrder] = useState({});
  const [orderToBeCanceled, setOrderToBeCanceled] = useState({});

  const [reviewAddedModal, setReviewAddedModal] = useState(false);
  const [pastOrder, setPastOrder] = useState({});
  const [pastOrderDetailModal, setPastOrderDetailModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedMainCategory, setSelectedMainCategory] = useState('Hepsi');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [loading, setLoading] = useState(true);

  const {
    addCartItem,
  } = useContext(RestaurantContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchData();
  }, [restaurantId, state?.id]);

  const refetchOrderData = () => {
    fetchData();
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getSupplierByRestaurant(restaurantId, state?.id);
      if (response && response.status === 200 && response.data) {
        setData(response.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const headerData = {
    businessName: data?.businessName,
    personName: data?.personName,
    phoneNumber: data?.phoneNumber,
    email: data?.email,
    category: data?.category,
    isActive: true,
    orderTypes: data?.orderTypes,
    rating: data?.rating,
    qualityRating: data?.qualityRating,
    priceRating: data?.priceRating,
    deliveryRating: data?.deliveryRating,
    reviewCount: data?.reviewCount,
    isPartner: data?.isPartner,
    checkPartner: true,
    id: state?.id,
    type: "SUPPLIER"
  }

  const handleMainCategoryClick = (category) => {
    setSelectedMainCategory(category);
    setSelectedSubCategory('');
  };

  const handleSubCategoryClick = (subCategory) => {
    setSelectedSubCategory(subCategory);
  };

  const extractReviews = () => {
    return data?.orders?.INACTIVE.flatMap(order => {
      if (order.reviewDto) {
        return [{
          orderId: order.orderId,
          orderDate: order.orderTime,
          comment: order.reviewDto.comment,
          priceRating: order.reviewDto.priceRating,
          qualityRating: order.reviewDto.qualityRating,
          deliveryRating: order.reviewDto.deliveryRating
        }];
      }
      return [];
    });
  };

  const toggleReviewModal = useCallback(() => {
    setReviewModal((prev) => !prev);
  }, []);

  const toggleNoteAddedModal = useCallback(() => {
    setNoteAddedModal((prev) => !prev);
    refetchOrderData()
  }, []);

  const toggleReviewAddedModal = useCallback(() => {
    setReviewAddedModal((prev) => !prev);
  }, []);

  const toggleOrderCanceledModal = useCallback(() => {
    setOrderCanceledModal((prev) => !prev);
  }, []);

  const openActiveOrder = async (item) => {
    setActiveOrder(item)
    setOpenActiveOrderModal(true)
  };

  const closeActiveOrder = () => {
    setActiveOrder({})
    setOpenActiveOrderModal(false)
  };

  const openCancelOrderModal = (item) => {
    setOpenActiveOrderModal(false)
    setOrderToBeCanceled(item)
    setCancelOrderModal(true)
  };

  const closeCancelOrderModal = () => {
    setOrderToBeCanceled({})
    setCancelOrderModal(false)
    refetchOrderData()
  };

  const onNoteAdded = () => {
    setActiveOrder({})
    setOpenActiveOrderModal(false)
    setNoteAddedModal(true)
    setTimeout(() => {
      setNoteAddedModal(false)
      refetchOrderData()
    }, 3000);
  };

  const onReviewAdded = () => {
    setPastOrder({})
    setPastOrderDetailModal(false)
    setReviewAddedModal(true)
    setTimeout(() => {
      setReviewAddedModal(false)
      refetchOrderData()
    }, 3000);
  };

  const onOrderCanceled = () => {
    setOrderToBeCanceled({})
    setCancelOrderModal(false)
    setOrderCanceledModal(true)
    setTimeout(() => {
      setOrderCanceledModal(false)
      refetchOrderData()
    }, 3000);
  };

  const openPastOrderDetailModal = (item) => {
    setPastOrder(item)
    setPastOrderDetailModal(true)
  };

  const closePastOrderDetailModal = () => {
    setPastOrder({})
    setPastOrderDetailModal(false)
  };

  const showMessage = useCallback((messageObj) => {
    setMessage(messageObj);
  }, []);
  const toggleSnackbar = useCallback(() => {
    setMessage({});
  }, []);

  const addProductToCart = async (data) => {
    addCartItem(
      supplierCartInfo,
      data
    );
  };

  const supplierCartInfo = {
    id: data?.supplierId,
    name: data?.businessName,
    minimumOrder: 0,
    deliveryFee: 0,
  };

  function normalizeText(text) {
    if (typeof text !== 'string') {
      text = '';
    }
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      .replace(/ğ/g, 'g').replace(/ü/g, 'u')
      .replace(/ş/g, 's').replace(/ı/g, 'i')
      .replace(/ö/g, 'o').replace(/ç/g, 'c')
      .replace(/Ğ/g, 'G').replace(/Ü/g, 'U')
      .replace(/Ş/g, 'S').replace(/İ/g, 'I')
      .replace(/Ö/g, 'O').replace(/Ç/g, 'C')
      .toLowerCase();
  }

  const productSections = data.products ? Object.entries(data.products).map(([title, items]) => ({
    title,
    items: items.filter(item => normalizeText(item.name).includes(normalizeText(searchTerm)) || normalizeText(item.description).includes(normalizeText(searchTerm))
      || normalizeText(item.brand).includes(normalizeText(searchTerm))),
  })) : [];

  const mainCategories = data.products ? ["Hepsi", ...Object.keys(data.products)] : [];

  const subCategories = selectedMainCategory && data.products
    ? [...new Set(data.products[selectedMainCategory]?.map(product => product.subCategory))]
    : [];

  const filteredProductSections = useMemo(() => {
    if (selectedMainCategory === "Hepsi") {
      return productSections;
    }
    return productSections.filter(section =>
      !selectedMainCategory || section.title === selectedMainCategory
    ).map(section => ({
      ...section,
      items: section.items.filter(item =>
        !selectedSubCategory || item.subCategory === selectedSubCategory
      )
    }));
  }, [selectedMainCategory, selectedSubCategory, productSections]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const resetSearch = () => {
    setSearchTerm('');
  };

  const content = (
    <>
      <Container style={{ marginLeft: "0px", paddingLeft: "0px", paddingRight: "0px" }}>
        <Box className={classes.imageContainer} style={{ backgroundImage: `url(${image})` }} />
        <Box style={{ backgroundColor: theme.palette.common.white }}>
          <SupplierHeader toggleModal={toggleReviewModal} headerData={headerData} />
        </Box>
        <Divider orientation="horizontal" />
      </Container>
      <Container style={{ marginLeft: "0px", paddingLeft: "0px", paddingRight: "0px", position: "sticky", zIndex: 5, top: "100px", display: "flex" }}>
        <div className={classes.tabContainer}>
          <Container style={{ marginLeft: "0px" }}>
            <Scrollspy offset={-220} className={classes.scrollpyStyles} items={data.isPartner ? ['urunler', 'sohbet', 'aktif', 'gecmis'] : ['sohbet', 'aktif', 'gecmis']} currentClassName={classes.active}>
              {data.isPartner && (
                <li className={classes.tabListStyle}>
                  <Link to="urunler" spy={true} smooth={true} className={classes.anchorStyle} offset={-220}>
                    <Typography className={classes.tabTextStyle}>Ürünler</Typography>
                  </Link>
                </li>
              )}
              <li className={classes.tabListStyle}>
                <Link to="sohbet" spy={true} smooth={true} className={classes.anchorStyle} offset={-220}>
                  <Typography className={classes.tabTextStyle}>Sohbetle Sİparİş</Typography>
                </Link>
              </li>
              <li className={classes.tabListStyle}>
                <Link to="gecmis" spy={true} smooth={true} className={classes.anchorStyle} offset={-220}>
                  <Typography className={classes.tabTextStyle}>Geçmİş Sİparİşlere Gİt</Typography>
                </Link>
              </li>
            </Scrollspy>
          </Container>
        </div>
      </Container>
      <Container style={{ marginLeft: "0px", paddingLeft: "0px", paddingRight: "0px" }}>
        {data.isPartner && (
          <>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Ürün ara..."
              value={searchTerm}
              onChange={handleSearchChange}
              style={{
                width: '80%',
                marginLeft: '30px',
                marginTop: '10px',
                backgroundColor: 'white',
                borderRadius: '4px',
                display: 'flex',
                justifyContent: 'center',
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={resetSearch}
                    style={{ visibility: searchTerm ? 'visible' : 'hidden' }}
                  >
                    <CloseIcon />
                  </IconButton>
                ),
              }}
            />

            <Box style={{ marginTop: 10, paddingRight: '8px', paddingLeft: '8px' }}>
              <Box className={classes.chipContainer}>
                {mainCategories.map(category => (
                  <Chip
                    key={category}
                    label={category}
                    onClick={() => handleMainCategoryClick(category)}
                    className={selectedMainCategory === category ? classes.selectedMainChip : classes.chipMainCategory}
                  />
                ))}
              </Box>
              {subCategories && subCategories.length > 0 &&
                <Box className={classes.chipContainer} sx={{ marginTop: '8px' }}>
                  {subCategories.map(subCategory => (
                    <Chip
                      key={subCategory}
                      label={subCategory}
                      onClick={() => handleSubCategoryClick(subCategory)}
                      className={selectedSubCategory === subCategory ? classes.selectedSubChip : classes.chipSubCategory}
                    />
                  ))}
                </Box>
              }

              <section id="urunler">
                {filteredProductSections?.map((item, index) => (
                  <ProductCard
                    onPress={addProductToCart}
                    products={item}
                    title={item.title}
                    isDeliveryAvailable={true}
                  />
                ))}
              </section>
            </Box>
          </>
        )}

        <Grid container style={{ display: 'flex', backgroundColor: 'white' }}>
          <Grid item xs={12} md={6} style={{ display: 'flex' }}>
            <section id="sohbet" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <SupplierChatCard
                data={{
                  id: state?.id,
                  category: state?.category
                }}
                triggerFetch={refetchOrderData}
              />
            </section>
          </Grid>

          <Grid item xs={12} md={6} style={{ display: 'flex' }}>
            <section id="aktif" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <SupplierOrderCard
                title="Aktif Siparişler"
                orders={data?.orders?.ACTIVE}
                onPress={openActiveOrder}
                onCancel={openCancelOrderModal}
              />
            </section>
          </Grid>
        </Grid>

        <section id="gecmis">
          <SupplierPastOrderCard
            title="Geçmiş Siparişler"
            orders={data?.orders?.INACTIVE}
            onPress={openPastOrderDetailModal}
          />
        </section>
      </Container>
    </>
  )

  return (
    <>
      <FlashMessage
        open={Boolean(message.type)}
        severity={message.type}
        alertMessage={message.message}
        handleClose={toggleSnackbar}
      />
      <Grid container className={classes.mainContainer}>
        <Header />
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px" sx={{ marginTop: '50px' }}>
            <CircularProgress />
            <Typography variant="subtitle1" style={{ marginLeft: '16px' }}>Ürünler Yükleniyor...</Typography>
          </Box>
        ) : (
          <Grid container style={{ display: "flex" }}>
            {!data?.isPartner &&
              <>
                <Grid item lg={1}></Grid>
                <Grid item lg={11} xs={12}>
                  {content}
                </Grid>
              </>
            }
            {data?.isPartner &&
              <>
                <Grid item lg={9} sm={9} xs={12}>
                  {content}
                </Grid>
                {!isTablet && <RestaurantCart showMessage={showMessage} />}
              </>
            }
          </Grid>
        )
        }
        <Box style={{ backgroundColor: theme.palette.common.white }}>
        </Box>
        <SupplierInfo
          isVisible={reviewModal}
          toggleModal={toggleReviewModal}
          data={headerData}
          reviews={extractReviews()}
        />
        <SupplierActiveOrderModal
          isVisible={openActiveOrderModal}
          closeModal={closeActiveOrder}
          data={activeOrder}
          onFinish={onNoteAdded}
        />
        <SupplierCancelOrderModal
          isVisible={cancelOrderModal}
          closeModal={closeCancelOrderModal}
          data={orderToBeCanceled}
          onFinish={onOrderCanceled}
          orderType="SUPPLIER"
        />
        <SupplierPastOrderModal
          isVisible={pastOrderDetailModal}
          closeModal={closePastOrderDetailModal}
          data={pastOrder}
          onFinish={onReviewAdded}
          showMessage={showMessage}
        />
        <NoteAdded
          isVisible={noteAddedModal}
          toggleModal={toggleNoteAddedModal}
          title="Notunuz Alınmıştır!"
          detail="Hemen Tedarikçinize Iletilecektir!"
        />
        <OrderCanceled
          isVisible={orderCanceledModal}
          toggleModal={toggleOrderCanceledModal}
        />
        <NoteAdded
          isVisible={reviewAddedModal}
          toggleModal={toggleReviewAddedModal}
          title="Geri Bildiriminiz Alınmıştır!"
          detail="Teşekkür ederiz!"
        />
      </Grid>
    </>
  );

}

export default SupplierDetail;
