import { Box, Button, Container, Typography, useTheme } from "@mui/material";
import React, { useContext, useMemo, useState, useEffect } from "react";
import useStyles from "./styles";
import GuestCartContext from "../../../context/Guest";
import { useNavigate } from 'react-router-dom';

function GuestPricingView({ supplier, linkIdentifier, type, minOrder }) {
  const classes = useStyles();
  const theme = useTheme();
  const { cart } = useContext(GuestCartContext);

  const [deliveryCharges, setDeliveryCharges] = useState(0);

  const calculatePrice = useMemo(() => {
    let itemTotal = 0;
    cart?.forEach((cartItem) => {
      if (cartItem.price === 'Fiyat Belirtilmemiş') {
        return;
      }
      itemTotal += cartItem.price * cartItem.quantity;
    });
    const deliveryAmount = deliveryCharges > 0 ? deliveryCharges : 0;
    return (itemTotal + deliveryAmount).toFixed(2);
  }, [cart, deliveryCharges]);

  const calculateTotal = useMemo(() => {
    let total = 0;
    cart?.forEach((cartItem) => {
      if (cartItem.price === 'Fiyat Belirtilmemiş') {
        return;
      }
      total += cartItem.price * cartItem.quantity;
    });
    total += deliveryCharges;
    return total.toFixed(2);
  }, [cart, deliveryCharges]);

  useEffect(() => {
    let totalDeliveryFee = 0;
    cart.forEach((cartItem) => {
      if (cartItem.supplier && cartItem.supplier.deliveryFee) {
        totalDeliveryFee += cartItem.supplier.deliveryFee;
      }
    });
    setDeliveryCharges(totalDeliveryFee);
  }, [cart]);

  const navigate = useNavigate();

  const handleCheckout = () => {
    navigate('/guestCheckout', { state: { supplier: supplier, linkIdentifier: linkIdentifier, type: type, minOrder: minOrder } });
  };

  return (
    <Container style={{ paddingTop: theme.spacing(2), background: theme.palette.common.white }}>
      <Box style={{ display: "flex", justifyContent: "space-between", marginTop: theme.spacing(1) }}>
        <Typography className={classes.subtotalText}>Ara Toplam</Typography>
        <Typography className={classes.subtotalText}>{`${calculatePrice}`} TL</Typography>
      </Box>
      <Box style={{ display: "flex", justifyContent: "space-between", marginTop: theme.spacing(1) }}>
        <Typography className={classes.subtotalText}>Teslimat Ücreti</Typography>
        <Typography className={classes.subtotalText}>{`${deliveryCharges.toFixed(2)}`} TL</Typography>
      </Box>
      <Box style={{ display: "flex", justifyContent: "space-between", marginTop: theme.spacing(2) }}>
        <Typography style={{ fontWeight: 700, color: theme.palette.text.secondary }} className={classes.subtotalText}>Toplam</Typography>
        <Typography style={{ fontWeight: 700, color: theme.palette.text.secondary }} className={classes.subtotalText}>{`${calculateTotal}`} TL</Typography>
      </Box>
      <Button
        style={{ borderRadius: 30, backgroundColor: "#FF6B5E", marginTop: '5%', fontSize: "18px" }}
        className={classes.checkoutContainer}
        onClick={handleCheckout}
      >
        <Typography style={{ color: theme.palette.common.white }} className={classes.checkoutText}>Sİparİş Ver</Typography>
      </Button>
    </Container>
  );
}

export default React.memo(GuestPricingView);
