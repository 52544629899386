import React from "react";
import { Grid } from "@mui/material";
import { Header } from "../../components/Header";
import useStyles from "./styles";

function Onboard() {
  const classes = useStyles();
  return (
    <Grid container className={classes.mainContainer}>
      <Header />
      <Grid item xs={12} className={classes.videoContainer}>
        <iframe
          className={classes.video}
          src="https://www.youtube.com/embed/4cfJu05pOOM"
          title="TedaBir Tanıtım Videosu"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Grid>
    </Grid>
  );
}

export default Onboard;
