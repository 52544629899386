import React, { useState, useCallback, useContext } from "react";
import { Grid, Box, Button, Typography, useTheme, useMediaQuery } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { getLatestProductInfoForOrderRepeat } from "../../../api/market_api";
import RepeatMarketOrder from "../../Modals/RepeatSupplierOrder/RepeatMarketOrder";
import RepeatSupplierOrder from "../../Modals/RepeatSupplierOrder/RepeatSupplierOrder";
import { categorisMap, formatDateToTurkish, parseOrderTime } from "../../Utils";
import useStyles from "./styles";
import RestaurantContext from "../../../context/Restaurant";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

function PastOrderCard({ item, handleOrderCreated }) {
  const theme = useTheme();
  const classes = useStyles();
  const [supplierOrderModal, setSupplierOrderModal] = useState(false);
  const [marketOrderModal, setMarketOrderModal] = useState(false);
  const [availableProductsData, setAvailableProductsData] = useState([]);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [minimumOrder, setMinimumOrder] = useState(0);
  const { profile } = useContext(RestaurantContext);
  const [showMore, setShowMore] = useState(false);

  const onAddToCart = async () => {
    if (item.orderType === "SUPPLIER") {
      setSupplierOrderModal(true);
    } else {
      const productIds = item.products.map(product => product.productId);
      try {
        const response = await getLatestProductInfoForOrderRepeat(profile.id, productIds);
        if (response !== undefined && response.status === 200 && response.data !== undefined) {
          setAvailableProductsData(response.data.products);
          setDeliveryFee(response.data.deliveryFee);
          setMinimumOrder(response.data.minimumOrder);
          setMarketOrderModal(true);
        } else {
          setAvailableProductsData([]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const toggleSupplierOrderModal = useCallback(() => {
    setSupplierOrderModal((prev) => !prev);
  }, []);

  const toggleMarketOrderModal = useCallback(() => {
    setMarketOrderModal((prev) => !prev);
  }, []);

  const productsString = item.products
    ? item.products.map(p => `${p.amount} ${p.unit} - ${p.productName}`).join(', ')
    : '';
  const MAX_STRING_LENGTH = 50; // Adjust this value as needed

  // Determine if the product string or order content is long enough to warrant an ellipsis
  const isProductStringLong = productsString.length > MAX_STRING_LENGTH;
  const isOrderContentLong = item.orderContent && item.orderContent.length > MAX_STRING_LENGTH;

  return (
    <RouterLink
      to={{
        pathname: `/order-detail/${item.orderId}`,
        search: `?orderType=${item.orderType}`
      }}
      className={classes.link}
    >
      <Box>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body2" color="textSecondary">
            <span className={classes.orderNumber}>#{item.orderId}</span>{' '}
            <span className={classes.textBold}>
              {item.supplierBusinessName}
            </span>
            {item.supplierCategory && ` - ${categorisMap.get(item.supplierCategory)}`}
          </Typography>
          {item.totalAmount &&
            <Typography variant="body2" className={classes.smallText}>
              {`${item.totalAmount + (item.deliveryFee || 0)} TL`}
            </Typography>
          }
        </Box>
        <Box display="flex" justifyContent="space-between" pt={theme.spacing(1)}>
          <Box className={classes.statusButtonContainer}>
            <Typography variant="caption" color="black" className={classes.repeatOrderText}>
              {item.orderType === "SUPPLIER" ? "Tedarikçinizden Sipariş" : "Marketten Sipariş"}
            </Typography>
          </Box>
          {/* <Button
            display="flex"
            style={{
              maxWidth: "30%",
              border: `1px solid #FF6B5E`,
              borderRadius: '4px',
              padding: theme.spacing(0.5, 1),
            }}
            onClick={(e) => {
              e.preventDefault();
              onAddToCart();
            }}
          >
            <Typography
              variant="caption"
              color="black"
              className={classes.textBold}
            >
              Sİparİşİ Tekrarla
            </Typography>
          </Button> */}
          <ChevronRightIcon style={{ fill: "#FF6B5E" }} />
        </Box>
        <Typography
          gutterBottom
          className={classes.smallText}
        >
          {formatDateToTurkish(parseOrderTime(item.orderTime))}
        </Typography>
        <Typography
          variant="body2"
          className={`${classes.smallText} ${showMore ? '' : classes.truncateText}`}
        >
          {productsString}
        </Typography>
        {isProductStringLong && !showMore && (
          <Typography
            variant="body2"
            className={classes.moreText}
            onClick={() => setShowMore(true)}
          >
            ...
          </Typography>
        )}

        {item.orderContent && (
          <Grid item key={item.orderId} className={showMore ? '' : classes.truncateText}>
            <Typography
              variant="caption"
              className={classes.smallText}
            >
              {item.orderContent}
            </Typography>
            {isOrderContentLong && !showMore && (
              <Typography
                variant="body2"
                className={classes.moreText}
                onClick={() => setShowMore(true)}
              >
                ...
              </Typography>
            )}
          </Grid>
        )}

        <RepeatSupplierOrder
          isVisible={supplierOrderModal}
          toggleModal={toggleSupplierOrderModal}
          onOrderCreated={handleOrderCreated}
          orderContent={item.orderContent}
          supplierByRestaurantId={item.supplierByRestaurantId}
        />

        <RepeatMarketOrder
          isVisible={marketOrderModal}
          toggleModal={toggleMarketOrderModal}
          onOrderCreated={handleOrderCreated}
          products={item.products}
          availableProducts={availableProductsData}
          deliveryFee={deliveryFee}
          minimumOrder={minimumOrder}
          supplierCategory={item.supplierCategory}
          supplierId={item.supplierId}
          supplierBusinessName={item.supplierBusinessName}
        />
      </Box>
    </RouterLink>
  );
}

export default React.memo(PastOrderCard);
