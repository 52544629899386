import makeStyles from '@mui/styles/makeStyles';

const useStyle = makeStyles((theme) => ({
  root: {
    "& .MuiButton-containedPrimary:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    "& .MuiButton-outlinedPrimary": {
      margin: theme.spacing(1, 1),
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  MB2: {
    marginBottom: theme.spacing(2),
  },
  lightText: {
    fontWeight: theme.typography.fontWeightLight,
  },
  disabledText: {
    color: theme.palette.text.disabled,
  },
  titleText: {
    ...theme.typography.h6,
    color: theme.palette.text.disabled,
  },
  xSmallText: {
    ...theme.typography.caption,
    color: theme.palette.text.disabled,
  },
  smallText: {
    ...theme.typography.body2,
    color: theme.palette.text.disabled,
    fontSize: "0.875rem",
  },
  btnBase: {
    borderRadius: "0px",
    height: "50px",
  },
  linkBtn: {
    textDecoration: "none",
  },
  closeContainer: {
    position: "absolute",
    background: "rgba(0,0,0,0.1)",
    top: theme.spacing(1),
    right: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.3)",
    },
  },
  linkContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
    wordBreak: 'break-word',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
    },
  },
  linkContainer2: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
  dialogActions: {
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
  },
  copyButton: {
    backgroundColor: '#FF6B5E',
    color: 'white',
    borderRadius: 30,
    padding: '6px 12px',
    marginLeft: '2%'
  },
  dialogTitle: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
}));

export default useStyle;
