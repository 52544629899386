import makeStyles from "@mui/styles/makeStyles";
import { COLORS } from "../../config/constants";

const useStyles = makeStyles((theme) => ({
  font700: {
    fontWeight: theme.typography.fontWeightBold,
  },
  caption: {
    fontSize: "0.875rem",
  },
  fontGrey: {
    color: theme.palette.primary.dark,
  },
  pageContainer: {
    paddingTop: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(8),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(10),
    },
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

export default useStyles;
