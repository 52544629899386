/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { getUnreadNotifications, markAllAsRead, markAsRead } from "../api/restaurant_api";

const RestaurantContext = React.createContext({});

export const RestaurantProvider = (props) => {
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem("cartItems");
    return savedCart ? JSON.parse(savedCart) : [];
  });
  const [profile, setProfile] = useState(() => {
    const savedProfile = localStorage.getItem("profile");
    return savedProfile ? JSON.parse(savedProfile) : [];
  });
  const [justRegistered, setJustRegistered] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const setProfileAndToken = (data, cb = () => { }) => {
    setProfile(data);
    localStorage.setItem("profile", JSON.stringify(data));
    cb();
  };

  const [address, setAddress] = useState(() => {
    const savedAddress = localStorage.getItem("address");
    return savedAddress ? JSON.parse(savedAddress) : [];
  });

  const setAddressInfo = (data, cb = () => { }) => {
    setAddress(data);
    localStorage.setItem("address", JSON.stringify(data));
    cb();
  };

  const logout = async () => {
    try {
      localStorage.removeItem("profile");
      setProfile({});
      setCart([]);
    } catch (error) {
      console.log("error on logout", error);
    }
  };

  const clearCart = () => {
    setCart([]);
    localStorage.removeItem("cartItems");
  };

  const addQuantity = (itemKey) => {
    const newCart = cart.map((item) => {
      if (item.key === itemKey) {
        const newQuantity = item.quantity + 1;
        const newPrice = calculatePrice(item, newQuantity); // Recalculate price
        return { ...item, quantity: newQuantity, price: newPrice };
      }
      return item;
    });
    setCart(newCart);
    localStorage.setItem("cartItems", JSON.stringify(newCart));
  };

  const removeQuantity = (itemKey) => {
    const newCart = cart.map((item) => {
      if (item.key === itemKey) {
        const newQuantity = item.quantity - 1;
        if (newQuantity > 0) {
          const newPrice = calculatePrice(item, newQuantity);
          return { ...item, quantity: newQuantity, price: newPrice };
        }
        return null;
      }
      return item;
    }).filter(item => item !== null);

    setCart(newCart);
    localStorage.setItem("cartItems", JSON.stringify(newCart));
  };

  const deleteItem = async (key) => {
    const items = cart.filter((c) => c.key !== key);
    setCart(items);
    localStorage.setItem("cartItems", JSON.stringify(items));
  };

  const checkItemCart = (itemId) => {
    const cartIndex = cart.findIndex((c) => c.id === itemId);
    if (cartIndex < 0) {
      return {
        exist: false,
        quantity: 0,
      };
    } else {
      return {
        exist: true,
        quantity: cart[cartIndex].quantity,
        key: cart[cartIndex].key,
      };
    }
  };

  const numberOfCartItems = () => {
    return cart.length;
  };

  const calculatePrice = (item, newQuantity) => {
    const { priceStrategy, fixedPrice, variablePrices } = item;

    let calculatedPrice = null;

    if (priceStrategy === 'fixed') {
      calculatedPrice = fixedPrice;
    } else if (priceStrategy === 'variable' && variablePrices && variablePrices.length > 0) {
      const priceTier = variablePrices.find(price => newQuantity >= price.minAmount && newQuantity <= price.maxAmount);
      calculatedPrice = priceTier ? priceTier.price : null;
    }

    return calculatedPrice;
  };

  const addCartItem = (supplier, item) => {
    const newItem = { ...item, supplier };
    const existingItemIndex = cart.findIndex(cartItem => cartItem.productId === item.productId);
    if (existingItemIndex !== -1) {
      const updatedCart = [...cart];
      updatedCart[existingItemIndex].quantity += (item.quantity || 1);
      updatedCart[existingItemIndex].price = calculatePrice(updatedCart[existingItemIndex], updatedCart[existingItemIndex].quantity); // Recalculate price
      setCart(updatedCart);
      localStorage.setItem("cartItems", JSON.stringify(updatedCart));
    } else {
      newItem.quantity = item.quantity || 1;
      newItem.price = calculatePrice(newItem, newItem.quantity);
      setCart(currentCart => {
        const newCart = [...currentCart, newItem];
        localStorage.setItem("cartItems", JSON.stringify(newCart));
        return newCart;
      });
    }
  };

  const updateCart = async (newCart) => {
    setCart(newCart);
    localStorage.setItem("cartItems", JSON.stringify(newCart));
  };

  const markNotificationAsRead = async (notificationId) => {
    try {
      await markAsRead(notificationId);
      setNotifications(prevNotifications => prevNotifications.filter(notif => notif.id !== notificationId));
    } catch (error) {
      console.error('Error marking notification:', error);
    }
  };

  const markAllNotificationsAsRead = async () => {
    try {
      const unreadNotificationIds = notifications.filter(notif => !notif.read).map(notif => notif.id);
      await markAllAsRead(unreadNotificationIds);
      setNotifications([]);
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
    }
  };

  const fetchNotifications = async () => {
    if (profile?.id) {
      try {
        const response = await getUnreadNotifications(profile.id);
        if (response !== null && response.status === 200 && response.data !== null) {
          setNotifications(response.data)
        } else {
          setNotifications([])
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  return (
    <RestaurantContext.Provider
      value={{
        isLoggedIn: !!profile.id,
        profile,
        setProfileAndToken,
        justRegistered,
        setJustRegistered,
        address,
        setAddressInfo,
        logout,
        cart,
        cartCount: numberOfCartItems(),
        clearCart,
        updateCart,
        addQuantity,
        removeQuantity,
        addCartItem,
        checkItemCart,
        deleteItem,
        markNotificationAsRead,
        fetchNotifications,
        notifications,
        markAllNotificationsAsRead,
        restaurantId: profile?.id
      }}
    >
      {props.children}
    </RestaurantContext.Provider>
  );
};

export const RestaurantConsumer = RestaurantContext.Consumer;
export default RestaurantContext;
