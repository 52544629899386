import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from "@mui/material";
import clsx from "clsx";
import React from "react";
import useStyles from "./styles";

function SupplierAdded({ isVisible, toggleModal, action, sendInvitation, supplierId }) {
  const theme = useTheme();
  const classes = useStyles();
  const invitationLink = `https://tedarikci.tedabir.com/#/auth/register?refer=${supplierId}`;
  const handleCopyLink = () => {
    navigator.clipboard.writeText(invitationLink);
  };

  return (
    <Dialog
      onClose={toggleModal}
      open={isVisible}
      fullWidth={true}
      maxWidth="xs"
      className={classes.root}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h5" color="textSecondary" className={clsx(classes.boldText, classes.MB2)}>
          Tedarikçiniz başarıyla eklenmiştir!
        </Typography>
        <Typography variant="subtitle2" className={`${classes.disabledText} ${classes.lightText}`}>
          Hemen sipariş vermeye başlayın ve daha iyi bir deneyim için tedarikçinizi TedaBir'e davet edin!
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div className={classes.linkContainer}>
          <Typography variant="body1" className={classes.boldText}>
            {invitationLink}
          </Typography>
          <Button onClick={handleCopyLink} className={classes.copyButton}>
            Kopyala
          </Button>
        </div>
        <div className={classes.linkContainer2}>
          <Typography variant="subtitle2" className={classes.lightText}>
            Bu bağlantıyı tedarikçiye e-posta veya mesajla göndermemizi ister misiniz?
          </Typography>
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className={classes.btnBase}
          style={{
            borderRadius: 30,
            backgroundColor: "#FF6B5E",
            fontSize: "18px",
            margin: theme.spacing(1, 0),
            padding: theme.spacing(1, 3),
            width: '60%'
          }}
          onClick={async (e) => {
            e.preventDefault();
            await sendInvitation();
          }}
        >
          <Typography variant="subtitle2" className={classes.boldText}>
            Şİmdİ GÖnder!
          </Typography>
        </Button>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className={classes.btnBase}
          style={{
            borderRadius: 30,
            backgroundColor: "#7D7D7D",
            fontSize: "18px",
            margin: theme.spacing(1, 0),
            padding: theme.spacing(1, 3),
            borderWidth: 2,
            width: '60%'
          }}
          onClick={async (e) => {
            e.preventDefault();
            toggleModal();
            action()
          }}
        >
          <Typography variant="subtitle2" className={classes.boldText}>
            Sİparİş Ver!
          </Typography>
        </Button>
        <Button
          variant="outlined"
          fullWidth
          className={classes.btnBase}
          onClick={toggleModal}
          style={{
            borderRadius: 30,
            fontSize: "18px",
            borderColor: "#FF6B5E",
            borderWidth: "2px",
            margin: theme.spacing(1, 0),
            width: '60%'
          }}
        >
          <Typography variant="subtitle2" style={{ color: "#FF6B5E" }} className={classes.boldText}>
            KAPAT
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(SupplierAdded);
