import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3),
    },
  },
  marginHeader: {
    marginTop: '70px',
    width: '100%',
  },
  mainContainer: {
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
  },
  supplierName: {
    fontWeight: 'bold',
  },
  smallButton: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export default useStyles;
