import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { LocationProvider } from "./context/Location";
import "./index.css";
import theme from "./utils/theme";
import { RestaurantProvider } from "./context/Restaurant";
import { GuestCartProvider } from "./context/Guest";

function Main() {
   return (
      <StyledEngineProvider injectFirst>
         <ThemeProvider theme={theme}>
            <RestaurantProvider>
               <GuestCartProvider>
                  <LocationProvider>
                     <App />
                  </LocationProvider>
               </GuestCartProvider>
            </RestaurantProvider>
         </ThemeProvider>
      </StyledEngineProvider>
   );
}
ReactDOM.render(<Main />, document.getElementById("root"));
