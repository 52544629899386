import React from 'react';
import {
  Typography,
  Grid,
  Paper,
  Divider,
  IconButton,
  Tooltip,
} from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import useStyles from './styles';

function ProductAnalysis({ productData }) {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom>
        Ürün Analizi
      </Typography>
      {productData.map((product) => (
        <Paper key={product.name} className={classes.supplierCard}>
          <div className={classes.supplierInfo}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="subtitle1" className={classes.supplierName}>
                {product.name}
              </Typography>
              {product.change >= 0 ? (
                <Tooltip title="Siparişlerde Artış">
                  <IconButton className={classes.supplierChangeIcon}>
                    <TrendingUpIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Siparişlerde Düşüş">
                  <IconButton className={`${classes.supplierChangeIcon} ${classes.supplierChangeIconNegative}`}>
                    <TrendingDownIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Typography
                variant="body2"
                className={product.change >= 0 ? classes.supplierChangeIcon : classes.supplierChangeIconNegative}
              >
                {product.change}%
              </Typography>
            </div>
            <Typography variant="body2">
              Sipariş Miktarı: {product.quantity} {product.unit} - Tedarikçi: {product.supplier}
            </Typography>
          </div>
        </Paper>
      ))}
    </Grid>
  );
}

export default ProductAnalysis;
