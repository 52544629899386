/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Card as ICard,
  Typography,
  CardActionArea,
} from "@mui/material";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import { Pictures } from "../../Utils";

function SupplierCard(props) {
  const item = props.data ?? null;
  const navigateTo = useNavigate();
  const cardImageHeight = props.cardImageHeight
    ? props.cardImageHeight
    : "144px";
  const image = item.imageData ? `data:image/jpeg;base64,${item.imageData}` : Pictures[item.category]?.source?.default ? Pictures[item.category]?.source?.default : Pictures['Genel'].source.default;

  const classes = useStyles(props);

  const navigate = useCallback(() => {
    navigateTo(`/supplier/${item.id}`, {
      state: {
        id: item.id,
        category: item.category,
      },
    });
  }, [navigateTo, item._id]);

  return (
    <ICard className={classes.card}>
      <CardActionArea
        onClick={(e) => {
          e.preventDefault();
          navigate();
        }}
      >
        <Box style={{ minWidth: "200px" }}>
          <Box
            style={{
              height: cardImageHeight,
            }}
            className={classes.imageContainer}
          >
            <Box
              style={{
                height: cardImageHeight,
                backgroundImage: `url(${image})`,
              }}
              className={classes.imgContainer}
            />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography
              variant="subtitle1"
              color="textSecondary"
              className={classes.textBold}
            >
              {item.businessName} - {item.personName}
            </Typography>
          </Box>
          <Box>
            <Typography
              display="inline"
              noWrap
              variant="caption"
              className={classes.subDescription}
            >
              {item.category} Tedarikçim
            </Typography>
          </Box>
        </Box>
      </CardActionArea>
    </ICard>
  );
}

export default React.memo(SupplierCard);
