import axios from 'axios';
import { uri } from './restaurant_api';

export const getCatalog = async (catalogId) => {
    try {
        return await axios.get(`${uri}/catalogs/link/${catalogId}`);
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const createOrder = async (data) => {
    try {
        return await axios.post(`${uri}/guests/order`, data)
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const cancelOrder = async (data) => {
    try {
        return await axios.post(`${uri}/guests/cancel`, data)
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getOrder = async (orderId) => {
    try {
        return await axios.get(`${uri}/guests/order?id=${orderId}`);
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const addNewNote = async (data) => {
    try {
        const response = await axios.post(`${uri}/guests/addNote`, data);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};