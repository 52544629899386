import React from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
  Box,
  Container,
  Link
} from "@mui/material";
import { WhatsApp, Email, Facebook, Twitter, Instagram, LinkedIn } from "@mui/icons-material";
import useStyle from "./help-styles";
import { LoginHeader } from "../../../components/Header";

function Help() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyle({ mobile });

  return (
    <Container className={classes.root}>
      <LoginHeader showIcon={true} />
      <div id="how-it-works" className={classes.section}>
        <Typography variant="h4" gutterBottom>
          Hakkımızda
        </Typography>
        <Typography paragraph>
          TedaBir, kullanıcı dostu arayüzü ile işletmelerin ve tedarikçilerin sipariş yönetimini kolaylaştırır. İşletmeler, platform üzerinden mevcut tedarikçileriyle siparişlerini düzenleyebilir ve yeni tedarikçi seçeneklerine erişebilirler. Tedarikçiler için ise, süreç yönetimi ve yeni müşterilere ulaşma imkanı sunulur. TedaBir, sektördeki en güncel piyasa ve satış trendlerini takip ederek, her iki tarafın da iş süreçlerini iyileştirmelerine yardımcı olur.
        </Typography>
      </div>
      <div id="help" className={classes.section}>
        <Typography variant="h4" gutterBottom>
          İletişim
        </Typography>
        <Typography paragraph>
          Her türlü sorunuz ve desteğe ihtiyaç duyduğunuzda, 7/24 WhatsApp yardım hattımızdan bize ulaşabilirsiniz.<br />
          <WhatsApp className={classes.icon} /> 05340774506<br /><br />
          Destek için e-posta adresimiz: <Link href="mailto:tedabir-destek@toptaze.com"></Link>tedabir-destek@toptaze.com<Email className={classes.icon} /><br />
        </Typography>
      </div>

      <div id="delivery-returns" className={classes.section}>
        <Typography variant="h4" gutterBottom>
          Teslimat ve İade Şartları
        </Typography>
        <Typography paragraph>
          TedaBir, sipariş teslimatı ve iade işlemleri konusunda herhangi bir rol almaz ve sorumluluk taşımaz. Teslimat ve iade süreçleri, alıcı ve satıcı arasında doğrudan gerçekleştirilir. İşlemlerle ilgili herhangi bir anlaşmazlık, hasar ya da kayıp durumunda TedaBir sorumlu tutulamaz. Kullanıcılar, bu işlemleri kendi sorumlulukları altında yürütmelidirler.
        </Typography>
      </div>

      <div id="faq" className={classes.section}>
        <Typography variant="h4" gutterBottom>
          Sıkça Sorulan Sorular
        </Typography>
        <Typography paragraph>
          <strong>Q:</strong> TedaBir platformuna nasıl kayıt olabilirim?<br />
          <strong>A:</strong> Platforma kayıt olmak için <Link href="https://tedabir.com/register">buraya</Link> tıklayın.<br /><br />
          <strong>Q:</strong> Platformda yeni tedarikçilerle nasıl iletişime geçebilirim?<br />
          <strong>A:</strong> Platform içerisinde yer alan arama ve filtreleme özellikleriyle ihtiyacınıza uygun tedarikçileri bulabilirsiniz.<br /><br />
          <strong>Q:</strong> Tedarikçilerimin platformu kullanma zorunluluğu var mı?<br />
          <strong>A:</strong> Tedarikçilerin platformu kullanma zorunlulukları yoktur! Biz, verdiğiniz siparişleri düzgün bir formata dönüştürerek
          SMS ve e-posta yoluyla iletiyoruz.Tedarikçiler, aynı link üzerinden cevap verebilir veya bu link aracılığıyla
          platforma katılarak sipariş yönetimini kolaylaştırabilirler.<br /><br />
          <strong>Q:</strong> Siparişlerimi nasıl takip edebilirim?<br />
          <strong>A:</strong> TedaBir'de siparişlerinizin durumunu anlık olarak takip edebilir ve yönetebilirsiniz.<br /><br />
          <strong>Q:</strong> Platform ücretsiz mi?<br />
          <strong>A:</strong> Evet, TedaBir platformunu kullanmak tamamen ücretsizdir.<br /><br />
          <strong>Q:</strong> Tedarikçiler yeni müşterilere nasıl ulaşır?<br />
          <strong>A:</strong> Tedarikçiler, yeni müşterilere ulaşmak için <Link href="https://tedarikci.tedabir.com/#/auth/welcome">tedarikçi portalımızı</Link> kullanabilirler.<br /><br />
        </Typography>
      </div>

      <div id="how-it-works" className={classes.section}>
        <Typography variant="h4" gutterBottom>
          Nasıl Çalışıyoruz
        </Typography>
        <Typography paragraph>
          TedaBir, kullanıcı dostu arayüzü ile işletmelerin ve tedarikçilerin sipariş yönetimini kolaylaştırır. İşletmeler, platform üzerinden mevcut tedarikçileriyle siparişlerini düzenleyebilir ve yeni tedarikçi seçeneklerine erişebilirler. Tedarikçiler için ise, süreç yönetimi ve yeni müşterilere ulaşma imkanı sunulur. TedaBir, sektördeki en güncel piyasa ve satış trendlerini takip ederek, her iki tarafın da iş süreçlerini iyileştirmelerine yardımcı olur.
        </Typography>
      </div>

      <Grid container className={classes.mainContainer} justifyContent="center">
        <Box className={classes.socialMediaContainer}>
          <IconButton href="https://www.facebook.com/tedabircom" target="_blank">
            <Facebook />
          </IconButton>
          <IconButton href="https://twitter.com/TedaBir" target="_blank">
            <Twitter />
          </IconButton>
          <IconButton href="https://www.instagram.com/tedabircom/" target="_blank">
            <Instagram />
          </IconButton>
          <IconButton href="https://www.linkedin.com/company/tedabir" target="_blank">
            <LinkedIn />
          </IconButton>
        </Box>
        <Typography variant="caption" className={classes.footer}>
          © 2024 TedaBir. Tüm Hakları Saklıdır.
        </Typography>
      </Grid>
    </Container>
  );
}

export default React.memo(Help);
