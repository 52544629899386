import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Chart } from 'react-google-charts';

const fullDayNames = {
    'Pzt': 'Pazartesi',
    'Sa': 'Salı',
    'Çrş': 'Çarşamba',
    'Prş': 'Perşembe',
    'Cu': 'Cuma',
    'Cts': 'Cumartesi',
    'Pa': 'Pazar'
};

const generateRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const StackedBarChart = ({ ordersData }) => {
    const [supplierColors, setSupplierColors] = useState({});
    const [chartData, setChartData] = useState([]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (!Array.isArray(ordersData)) {
            console.error('ordersData is not an array', ordersData);
            return;
        }

        let isMounted = true;
        const colors = {};

        ordersData.forEach(day => {
            day.suppliers?.forEach(supplier => {
                if (!colors[supplier.name]) {
                    colors[supplier.name] = generateRandomColor();
                }
            });
        });

        if (isMounted) {
            setSupplierColors(colors);
        }

        const headers = ['Day'];
        const supplierKeys = Object.keys(colors);
        supplierKeys.forEach(supplierName => {
            headers.push(supplierName);
            headers.push({ type: 'string', role: 'tooltip', 'p': { 'html': true } });
        });

        const data = ordersData.map(day => {
            const dayData = [day.date];
            supplierKeys.forEach(supplierName => {
                const supplier = day.suppliers?.find(s => s.name === supplierName);
                dayData.push(supplier ? 1 : 0);
                const tooltip = supplier
                    ? `<div style="margin: 10px;"><strong>${fullDayNames[day.date] || day.date}</strong><br><strong>${supplier.name}</strong><br>${supplier.items.join('<br>')}</div>`
                    : 'No orders';
                dayData.push(tooltip);
            });
            return dayData;
        });

        if (isMounted) {
            setChartData([headers, ...data]);
        }

        return () => {
            isMounted = false;
        };
    }, [ordersData]);

    const baseOptions = {
        isStacked: true,
        legend: { position: 'top', maxLines: 3 },
        bar: { groupWidth: '75%' },
        colors: Object.values(supplierColors),
        tooltip: { isHtml: true },
        hAxis: {
            title: 'Günler',
            textStyle: {
                fontSize: 12
            }
        },
        vAxis: {
            title: 'Sipariş Sayısı',
            minValue: 0,
            gridlines: {interval: 1 },
            textStyle: {
                fontSize: 12 
            }
        },
        chartArea: {
            width: '70%',
            height: '70%'
        },
        animation: {
            duration: 1000,
            easing: 'out',
            startup: true,
        },
    };

    const mobileOptions = {
        hAxis: {
            textStyle: {
                fontSize: 10
            }
        },
        vAxis: {
            minValue: 0,
            gridlines: {interval: 1 },
            textStyle: {
                fontSize: 10
            }
        },
        legend: {
            position: 'top',
            textStyle: {
                fontSize: 9, 
            },
            alignment: 'center',
        },
        chartArea: {
            width: '85%',
            height: '70%'
        },
        bar: { groupWidth: '60%' }, 
    };

    const options = {
        ...baseOptions,
        ...(isMobile ? mobileOptions : {}),
    };

    return (
        <Chart
            chartType="ColumnChart"
            width="100%"
            height="400px"
            data={chartData}
            options={options}
        />
    );
};

export default StackedBarChart;
