import makeStyles from '@mui/styles/makeStyles';
import bg from "../../../assets/images/bg_home.png";
import footer from "../../../assets/images/footer.png";

const useStyle = makeStyles((theme) => ({
  mainContainer: {
    [theme.breakpoints.down('md')]: {
      marginTop: "80px"
    },
  },
  firstSection: {
    marginTop: "20px",
    width: "100%",
    backgroundImage: `url(${bg})`,
    backgroundSize: '100% auto',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    paddingTop: '41.8056%',
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    position: "relative",
  },
  lastSection: {
    backgroundImage: `url(${footer})`,
    backgroundSize: '100% 80%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    paddingTop: '15%',
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    position: "relative",
    padding: theme.spacing(6),
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      backgroundSize: '100% 100%',
    },
  },
  pageTitle: {
    color: "#f2f2f2",
    position: "absolute",
    top: "100px",
    left: "4%",
    fontSize: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      top: "5px",
    },
  },
  socialMediaIcons: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginBottom: "-15px",
      marginTop: theme.spacing(7),
    },
  },
  secondSectionContainer: {
    marginTop: '9%',
    backgroundColor: 'rgba(255, 107, 94, 0.1)'
  },
  playIcon: {
    fontSize: '5rem',
    color: '#666',
    cursor: 'pointer',
  },
  storeContainer: {
    marginTop: "250px",
    backgroundColor: "#ffb1ab",
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center',
  },
  storeTitle: {
    fontWeight: 600,
    color: "#333",
    marginBottom: theme.spacing(2),
  },
  storeDescription: {
    color: "#666",
    marginBottom: theme.spacing(3),
  },
  footer: {
    textAlign: "center",
    color: "#aaa",
  },
  socialMediaContainer: {
    textAlign: 'center',
  },
  socialIcon: {
    margin: theme.spacing(1),
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  supplierSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '70px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: theme.spacing(3),
    },
  },
  supplierImage: {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: 20,
    [theme.breakpoints.up('md')]: {
      width: '30%',
      marginLeft: '100px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      marginBottom: theme.spacing(2),
    },
  },
  supplierContent: {
    [theme.breakpoints.up('md')]: {
      width: '55%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%', // Full width on mobile
      padding: theme.spacing(1), // Adjust padding to give more space
      textAlign: 'left', // Align text to the left for better control
    },
  },
  bulletItem: {
    display: 'flex',
    alignItems: 'center',
    '&::before': {
      content: '"•"',
      fontWeight: 'bold',
      marginRight: theme.spacing(1), // Add some space between the bullet and the text
    },
    [theme.breakpoints.down('sm')]: {
      display: 'inline-flex', // Change to inline-flex for better control on small screens
      width: 'auto', // Adjust width to auto for better wrapping
      flexWrap: 'nowrap', // Prevent wrapping to the next line
    },
  },
  bulletItemSpacing: {
    marginBottom: theme.spacing(1),
  },
  sectionDivider: {
    paddingBottom: theme.spacing(4),
    borderTop: '1px solid #eee', // You can adjust the color as needed
    borderBottom: '1px solid #eee',
  },
  registerButton: {
    position: 'absolute',
    top: theme.spacing(4), // Adjust vertical position as needed
    right: theme.spacing(4), // Place it on the right
    border: '2px solid #FF6B5E', // Border color
    color: '#FF6B5E', // Text color
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '16px',
    '&:hover': {
      backgroundColor: '#FF6B5E', // Fill the button on hover
      color: '#FFFFFF', // Text color on hover
    },
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(2),
      right: theme.spacing(-5),
      fontSize: '10px'
    },
  },
  helpButton: {
    border: `1px solid ${theme.palette.primary.contrastText}`,
    color: theme.palette.primary.contrastText,
    borderRadius: theme.shape.borderRadius,
    textTransform: 'none',
  },
  scrollButton: {
    color: theme.palette.primary.contrastText,
  },
  howItWorks: {
    padding: theme.spacing(4),
    backgroundColor: '#f7f7f7', // Light background color for distinction
    textAlign: 'center',
  },
  featureItem: {
    // Adjust for a tighter layout on mobile
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  iconStyle: {
    fontSize: '48px', // Adjust if needed for mobile
    color: '#FF6B5E',
    marginBottom: theme.spacing(1),
  },
  howItWorksText: {
    padding: theme.spacing(0, 1), // Adjust text padding for mobile readability
    fontSize: '0.875rem', // Smaller font size for mobile
  },
  sectionTitle: {
    marginBottom: theme.spacing(3),
  },
  videoNewMobile: {
    display: 'flex', // Set display to flex
    flexDirection: 'column', // Stack children vertically
    justifyContent: 'center', // Center content horizontally
    alignItems: 'center', // Center content vertically
    textAlign: 'center',
    color: '#333',
    width: '100%', // Ensure the container takes full width
    marginTop: 10
  },
  videoNew: {
    display: 'flex', // Set display to flex
    flexDirection: 'column', // Stack children vertically
    justifyContent: 'center', // Center content horizontally
    alignItems: 'center', // Center content vertically
    textAlign: 'center',
    color: '#333', // Darker text for better readability
    width: '100%', // Ensure the container takes full width
    marginTop: 30
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline', 
    },
  },
  logoBandContainer: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginTop: 2
    },

    '& img': { 
      maxWidth: '100%', 
      height: 'auto', 
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },
}));

export default useStyle;
