import { Box, Grid, Paper, Typography, useTheme, Button } from "@mui/material";
import clsx from "clsx";
import React from "react";
import useStyles from "./styles";

export default function AmountCard(props) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Grid container xs={11} md={8} alignItems={"center"} flexWrap="nowrap" flexDirection="column" justifyContent="center" alignContent="center" marginTop={theme.spacing(-3)}>
      <Grid container justifyContent={"center"} xs={11} md={8}>
        <Paper style={{ paddingLeft: theme.spacing(3), width: "100%" }} elevation={1} className={classes.mt3}>
          <Grid container className={classes.mv2}>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                className={clsx(classes.disabledText, classes.smallText)}
              >
                Ara Toplam
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.ph1}>
              <Typography
                variant="body2"
                className={clsx(classes.disabledText, classes.smallText)}
              >
                {props?.totalAmount} TL
              </Typography>
            </Grid>
          </Grid>

          <Grid container className={clsx(classes.cardRow, classes.mv2)}>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                className={clsx(classes.disabledText, classes.smallText)}
              >
                Teslimat Ücreti
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.ph1}>
              <Typography
                variant="body2"
                className={clsx(classes.disabledText, classes.smallText)}
              >
                {props?.deliveryFee} TL
              </Typography>
            </Grid>
          </Grid>

          <Grid container className={clsx(classes.cardRow, classes.mv2)}>
            <Grid item xs={6}>
              <Box>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={clsx(classes.textBold, classes.smallText)}
                >
                  Toplam
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} className={classes.ph1}>
              <Typography
                variant="body2"
                color="textSecondary"
                className={clsx(classes.textBold, classes.smallText)}
              >
                {props?.deliveryFee ? props?.totalAmount + props?.deliveryFee : props?.totalAmount} TL
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
