import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import RestaurantContext from "../context/Restaurant";

function PrivateRoute({ children }) {
  const { isLoggedIn } = useContext(RestaurantContext);
  const location = useLocation();

  if (!isLoggedIn)
    return (
      <Navigate
        to={{
          pathname: `/login`,
        }}
        state={{ from: location.pathname }}
      />
    );
  return children;
}

export default PrivateRoute;
