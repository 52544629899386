import {
  Box, Divider, Grid, Paper, Typography, useTheme, FormControl,
  FormGroup, Button,
  OutlinedInput
} from "@mui/material";
import clsx from "clsx";
import React, { useState, useEffect } from "react";
import { addMarketOrderReview, addReview } from "../../../api/order_api";
import HeadingView from "../../Modals/SupplierPastOrder/HeadingView";
import StarRating from "../../StarRating";
import { formatDateToTurkish, parseOrderTime } from "../../Utils";
import useStyles from "./styles";

function DetailCard({ data, orderType, onReviewAdded }) {
  const classes = useStyles();
  const theme = useTheme();

  const [comment, setComment] = useState(data?.reviewDto?.comment || "");
  const [priceRating, setPriceRating] = useState(data?.reviewDto?.priceRating || 0);
  const [qualityRating, setQualityRating] = useState(data?.reviewDto?.qualityRating || 0);
  const [deliveryRating, setDeliveryRating] = useState(data?.reviewDto?.deliveryRating || 0);
  const [readOnly, setReadOnly] = useState(false);

  const renderContent = () => {
    if (orderType === "MARKET") {
      return data?.products?.map((product, index) => (
        <Grid container key={index} className={classes.ph1}>
          <Grid item xs={12} md={6} className={classes.productItem}>
            <Typography variant="body2" color="textSecondary" className={clsx(classes.productItem, classes.contentWrap)}>
              {product.productName}  - {product.amount} {product.unit} x {product.price !== null ? `${product.price} TL` : 'Fiyat Belirtilmemiş'}
            </Typography>
          </Grid>
        </Grid>
      ));
    } else {
      return (
        <Grid item xs={6} className={classes.ph1}>
          <Typography variant="body2" color="textSecondary" className={clsx(classes.textBold, classes.smallText, classes.contentWrap)}>
            {data?.orderContent ?? "..."}
          </Typography>
        </Grid>
      );
    }
  };

  useEffect(() => {
    setReadOnly(false);
    setComment("");
    setPriceRating(0);
    setQualityRating(0);
    setDeliveryRating(0);

    if (data?.reviewDto) {
      setReadOnly(true);
      setComment(data.reviewDto.comment || "");
      setPriceRating(data.reviewDto.priceRating || 0);
      setQualityRating(data.reviewDto.qualityRating || 0);
      setDeliveryRating(data.reviewDto.deliveryRating || 0);
    }
  }, [data]);

  const addNewReview = async () => {
    if (comment == "" && priceRating == 0 && qualityRating == 0 && deliveryRating == 0) {
      return;
    }
    const newReviewData = {
      orderId: data?.orderId,
      comment: comment,
      priceRating: priceRating,
      qualityRating: qualityRating,
      deliveryRating: deliveryRating
    }
    try {
      var response
      if (orderType === "SUPPLIER") {
        response = await addReview(newReviewData);
      } else {
        response = await addMarketOrderReview(newReviewData);
      }
      if (response?.status === 200) {
        setComment("");
        setPriceRating(0);
        setQualityRating(0);
        setDeliveryRating(0);
        onReviewAdded();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const renderNoteDetail = (label, value) => (
    <Grid container>
      <Grid item xs={6}>
        <Typography
          variant="body2"
          className={`${clsx(classes.disabledText, classes.smallText, classes.textBold)}`}
        >
          {label}
        </Typography>
      </Grid>
      <Grid item xs={6} className={classes.ph1}>
        <Typography
          variant="body2"
          color="textSecondary"
          className={`${clsx(classes.textBold, classes.smallText)}`}
        >
          {value}
        </Typography>
      </Grid>
    </Grid>
  );

  const NoteSection = () => {
    if (orderType !== "SUPPLIER" || !data?.noteDtoList || data?.noteDtoList?.length === 0) {
      return null;
    }

    return (
      <>
        <Typography
          className={`${classes.heading} ${classes.textBold}`}
          textAlign="center"
          color={theme.palette.primary.main}
        >
          Ek Notlar
        </Typography>
        <Divider orientation="horizontal" flexItem style={{ backgroundColor: theme.palette.primary.main }} variant="middle" />

        {data?.noteDtoList?.map((note, index) => (
          <Grid container justifyContent="center" xs={11} md={8} key={index}>
            <Paper style={{ padding: theme.spacing(3), width: "100%" }} elevation={1} className={classes.mt3}>
              {renderNoteDetail("Kimden:", note?.owner === "RETAIL" ? "Restoran" : "Tedarikçi", classes)}
              {renderNoteDetail("Not:", note?.content ?? "...", classes)}
              {renderNoteDetail("Gönderme Zamanı:", note?.createdAt ?? "...", classes)}
            </Paper>
          </Grid>
        ))}
      </>
    );
  };

  return (
    <Grid container xs={11} md={8} alignItems={"center"} flexWrap="nowrap" flexDirection="column" justifyContent="center" alignContent="center" marginTop={theme.spacing(3)}>
      {data?.status === "COMPLETED" &&
        <>
          <Typography
            className={`${classes.heading} ${classes.textBold}`}
            textAlign="center"
            color={theme.palette.primary.main}
          >
            Sipariş Değerlendirmesi
          </Typography>
          <Divider
            orientation="horizontal"
            flexItem
            style={{ backgroundColor: theme.palette.primary.main }}
            variant="middle" /><Grid container justifyContent={"center"} xs={11} md={8}>
            <Paper
              style={{ padding: theme.spacing(3), width: "100%" }}
              elevation={1}
              className={classes.mt3}
            >
              <FormControl style={{ flex: 1, display: "flex" }}>
                <FormGroup>
                  {readOnly ? (
                    <Typography variant="body2">{comment}</Typography>
                  ) : (
                    <>
                      <HeadingView title="Yorum Bırakın" subTitle="Siparişle ilgili bir yorumunuz var mı? Tedarikçiye bildirin." error={false} />
                      <Box mt={theme.spacing(2)} />
                      <OutlinedInput
                        multiline
                        value={comment}
                        onChange={event => setComment(event.target.value)}
                        placeholder={"Domatesler Bozuktu..."}
                        variant="filled"
                        color="primary"
                        minRows={2}
                        maxRows={3}
                        inputProps={{ style: { color: theme.palette.grey[600] }, maxLength: 144 }}
                        size="small" />
                    </>
                  )}

                  <Box mt={theme.spacing(2)}>
                    <HeadingView title="Fiyat Değerlendirmesi" subTitle={readOnly ? "" : "Ürün fiyatını değerlendirin (1-5 yıldız)"} status="" error={false} />
                    <StarRating value={priceRating} onChange={setPriceRating} readOnly={readOnly} />
                  </Box>

                  <Box mt={theme.spacing(2)}>
                    <HeadingView title="Kalite Değerlendirmesi" subTitle={readOnly ? "" : "Ürün kalitesini değerlendirin (1-5 yıldız)"} status="" error={false} />
                    <StarRating value={qualityRating} onChange={setQualityRating} readOnly={readOnly} />
                  </Box>

                  <Box mt={theme.spacing(2)}>
                    <HeadingView title="Teslimat Değerlendirmesi" subTitle={readOnly ? "" : "Teslimat hızını değerlendirin (1-5 yıldız)"} status="" error={false} />
                    <StarRating value={deliveryRating} onChange={setDeliveryRating} readOnly={readOnly} />
                  </Box>
                </FormGroup>
              </FormControl>
              {!readOnly && (
                <Box display="flex" justifyContent="flex-end">
                  <Button variant="contained" style={{ borderRadius: 30, backgroundColor: "#FF6B5E", padding: "5px 15px", fontSize: "14px" }} onClick={addNewReview}>
                    <Typography className={classes.checkoutText}>Gönder</Typography>
                  </Button>
                </Box>
              )}
            </Paper>
          </Grid>
        </>
      }

      <Typography
        className={`${classes.heading} ${classes.textBold}`}
        textAlign="center"
        color={theme.palette.primary.main}
      >
        Sipariş Detayları
      </Typography>
      <Divider
        orientation="horizontal"
        flexItem
        style={{ backgroundColor: theme.palette.primary.main }}
        variant="middle"
      />

      <Grid container justifyContent={"center"} xs={11} md={8} marginBottom={theme.spacing(3)} >
        <Paper
          style={{ padding: theme.spacing(3), width: "100%" }}
          elevation={1}
          className={classes.mt3}
        >
          <Grid container className={classes.mv2}>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                className={`${clsx(classes.disabledText)} ${clsx(
                  classes.smallText
                )} ${clsx(classes.textBold)}`}
              >
                Sipariş Numarası:
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.ph1}>
              <Typography
                variant="body2"
                color="textSecondary"
                className={`${clsx(classes.textBold)} ${clsx(
                  classes.smallText
                )}`}
              >
                {data?.orderId ?? "..."}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                className={`${clsx(classes.disabledText)} ${clsx(
                  classes.smallText
                )} ${clsx(classes.textBold)}`}
              >
                Tedarikçi:
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.ph1}>
              <Typography
                variant="body2"
                color="textSecondary"
                className={`${clsx(classes.textBold)} ${clsx(
                  classes.smallText
                )}`}
              >
                {data?.supplierBusinessName ?? "..."}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                className={`${clsx(classes.disabledText)} ${clsx(
                  classes.smallText
                )} ${clsx(classes.textBold)}`}
              >
                Sipariş Tarihi:
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.ph1}>
              <Typography
                variant="body2"
                color="textSecondary"
                className={`${clsx(classes.textBold)} ${clsx(
                  classes.smallText
                )}`}
              >
                {formatDateToTurkish(parseOrderTime(data?.orderTime))}
              </Typography>
            </Grid>
          </Grid>
          {orderType !== "MARKET" && (
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="body2" className={`${clsx(classes.disabledText)} ${clsx(classes.smallText)} ${clsx(classes.textBold)}`}>
                  Sipariş İçeriği:
                </Typography>
              </Grid>
              {renderContent()}
            </Grid>
          )}
          {orderType === "MARKET" && (
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body2" className={`${clsx(classes.disabledText)} ${clsx(classes.smallText)} ${clsx(classes.textBold)}`}>
                  Ürünler:
                </Typography>
              </Grid>
              {renderContent()}
            </Grid>
          )}
        </Paper>
      </Grid>

      <NoteSection />
    </Grid>
  );
}

export default DetailCard;
