/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Typography,
  Container,
  Grid,
  useTheme,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useCallback, useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Header } from "../../components/Header";
import {
  DetailCard,
  StatusCard,
  AmountCard,
} from "../../components/Orders";
import useStyles from "./styles";
import { getOrder } from "../../api/order_api";
import SupplierCancelOrderModal from "../../components/Modals/SupplierCancelOrder/SupplierCancelOrderModal";
import OrderCanceled from "../../components/Modals/OrderCanceled/OrderCanceled";
import NoteAdded from "../../components/Modals/NoteAdded/NoteAdded";

function OrderDetail() {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  let { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orderType = searchParams.get('orderType');
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const [orderCanceledModal, setOrderCanceledModal] = useState(false);
  const [cancelOrderModal, setCancelOrderModal] = useState(false);
  const [reviewAddedModal, setReviewAddedModal] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getOrder(id, orderType);
      if (response && response.status === 200 && response.data) {
        setData(response.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const onReviewAdded = () => {
    setReviewAddedModal(true)
    setTimeout(() => {
      setReviewAddedModal(false)
      fetchData();
    }, 3000);
  };

  const toggleReviewAddedModal = useCallback(() => {
    setReviewAddedModal(false);
    fetchData();
  }, []);

  const closeCancelOrderModal = () => {
    setCancelOrderModal(false)
  };

  const onOrderCanceled = () => {
    setCancelOrderModal(false)
    setOrderCanceledModal(true)
    setTimeout(() => {
      setOrderCanceledModal(false)
      navigate('/orders', { replace: true });
    }, 3000);
  };

  const toggleOrderCanceledModal = useCallback(() => {
    setOrderCanceledModal((prev) => !prev);
  }, []);

  return (
    <>
      <Grid container>
        <Header />
        <Grid container flexWrap={"nowrap"} flexDirection={"column"} justifyContent={"center"} alignItems="center" item className={classes.marginHeader}>
          <Container
            maxWidth={small ? "100%" : "md"}
            className={classes.orderStatus}
          >
            <StatusCard data={data} orderType={orderType} />
          </Container>
          <DetailCard data={data} orderType={orderType} onReviewAdded={onReviewAdded} />
          {orderType === "MARKET" &&
            <AmountCard {...data} />
          }
          {data.status != "CANCELED" && data.status != "COMPLETED" &&
            <Grid container xs={11} md={8} alignItems={"center"} flexWrap="nowrap" flexDirection="column" justifyContent="center" alignContent="center">
              <Grid container justifyContent={"center"} xs={11} md={8} marginTop={theme.spacing(3)} >
                <Button
                  variant="contained"
                  style={{
                    borderRadius: 30,
                    backgroundColor: "#7D7D7D",
                    padding: "15px 25px",
                    fontSize: "18px"
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setCancelOrderModal(true);
                  }}
                >
                  <Typography
                    variant="body2"
                    color="secondary"
                    className={classes.textBold}
                  >
                    Sİparİşİ İptal Et
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          }
        </Grid>
      </Grid>
      <SupplierCancelOrderModal
        isVisible={cancelOrderModal}
        closeModal={closeCancelOrderModal}
        data={data}
        onFinish={onOrderCanceled}
        orderType={orderType}
      />
      <OrderCanceled
        isVisible={orderCanceledModal}
        toggleModal={toggleOrderCanceledModal}
      />
      <NoteAdded
        isVisible={reviewAddedModal}
        toggleModal={toggleReviewAddedModal}
        title="Geri Bildiriminiz Alınmıştır!"
        detail="Teşekkür ederiz!"
      />
    </>
  );
}

export default OrderDetail;
