import {
  Box,
  Button,
  Divider,
  Checkbox,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import clsx from "clsx";
import React, { useContext } from "react";
import _ from "lodash";
import RestaurantContext from "../../context/Restaurant";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";

function CartItemsCard({
  calculatePrice,
  calculateTotal,
  deliveryCharges,
  selectedSuppliers,
  setSelectedSuppliers
}) {
  const theme = useTheme();
  const classes = useStyles();
  const { cart, clearCart } = useContext(RestaurantContext);
  const navigate = useNavigate();

  const groupedCartItems = _.groupBy(cart, 'supplier.id');

  const handleSupplierSelection = (supplierId, isSelected) => {
    const newSelectedSuppliers = new Set(selectedSuppliers);
    if (isSelected) {
      newSelectedSuppliers.add(supplierId.toString());
    } else {
      newSelectedSuppliers.delete(supplierId.toString());
    }
    setSelectedSuppliers(newSelectedSuppliers);
  };

  const navigateToSupplier = (supplierId) => {
    navigate(`/market/${supplierId}`, {
      state: {
        id: supplierId,
        category: "",
      },
    });
  };

  return (
    <>
      {Object.entries(groupedCartItems).map(([supplierId, items]) => (
        <React.Fragment key={supplierId}>
          <Paper style={{ background: theme.palette.common.white, display: 'flex', alignItems: 'center', padding: theme.spacing(1) }}>
            <Checkbox
              checked={selectedSuppliers.has(supplierId)}
              onChange={(e) => handleSupplierSelection(supplierId, e.target.checked)}
            />
            <div onClick={() => navigateToSupplier(supplierId)} style={{ cursor: 'pointer', flexGrow: 1 }}>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.boldText}
              >
                {`${items[0].supplier?.name ?? "..."} Tedarikçisinden Siparişiniz`}
              </Typography>
            </div>
          </Paper>
          <Box>
            <Box
              style={{
                maxHeight: theme.spacing(30),
                padding: theme.spacing(0, 1),
                overflow: "auto",
              }}
            >
              {items.map((item, index) => (
                <Box
                  key={item.id + index}
                  display="flex"
                  justifyContent="space-between"
                  pt={theme.spacing(2)}
                  pb={theme.spacing(2)}
                >
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    className={clsx(
                      classes.flex,
                      classes.boldText,
                      classes.smallText
                    )}
                  >
                    {item?.quantity} {item?.unit}
                    <Typography
                      variant="caption"
                      className={clsx(classes.smallText, classes.disableText)}
                    >
                      {` ${item?.name}`}
                    </Typography>
                  </Typography>
                  <Typography
                    variant="caption"
                    align="right"
                    className={clsx(classes.smallText, classes.disableText)}
                  >
                    {item.price && `${(
                      parseFloat(item?.price) * item?.quantity
                    ).toFixed(2)} TL`}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </React.Fragment>
      ))}
      <Box className={classes.PH1}>
        <Divider light orientation="horizontal" className={classes.MV2} />
        <Box display="flex" justifyContent="space-between">
          <Typography
            variant="caption"
            className={clsx(classes.disableText, classes.smallText)}
          >
            Ara Toplam
          </Typography>
          <Typography
            variant="caption"
            className={clsx(classes.disableText, classes.smallText)}
          >
            {`${calculatePrice(0, false)} TL`}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" className={classes.MV1}>
          <Typography variant="caption" className={clsx(classes.disableText, classes.smallText)}>
            Teslimat Ücreti
          </Typography>
          <Typography variant="caption" className={clsx(classes.disableText, classes.smallText)}>
            {`${deliveryCharges.toFixed(2)} TL`}
          </Typography>
        </Box>
        <Divider light orientation="horizontal" className={classes.MV1} />
        <Box display="flex" justifyContent="space-between">
          <Box display="flex">
            <Typography
              variant="caption"
              color="textSecondary"
              className={clsx(classes.smallText)}
            >
              Toplam
            </Typography>
          </Box>
          <Typography
            variant="caption"
            className={clsx(classes.disableText, classes.smallText)}
          >
            {`${calculateTotal()} TL`}
          </Typography>
        </Box>

        {cart.length > 0 &&
          <Box display="flex" justifyContent="space-between">
            <Button
              style={{
                maxWidth: "auto",
                padding: `${theme.spacing(2)} 0`,
                background: "#7D7D7D",
                marginTop: theme.spacing(2),
                width: "100%",
                borderRadius: 0,
              }}
              onClick={(e) => {
                e.preventDefault()
                clearCart();
              }}
            >
              <Typography
                style={{
                  ...theme.typography.body1,
                  color: theme.palette.common.white,
                  fontWeight: 700,
                }}
              >
                Sepetİ Temİzle
              </Typography>
            </Button>
          </Box>}

      </Box>
    </>
  );
}

export default React.memo(CartItemsCard);
