import {
  Box,
  Container,
  Paper,
  Typography,
  useTheme,
  Grid,
  Button
} from "@mui/material";
import React from "react";
import useStyle from "./styles";
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { trTR } from '@mui/x-date-pickers/locales';
import tr from 'date-fns/locale/tr';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/tr';

dayjs.extend(utc);
dayjs.extend(timezone);

function OrderOption({ selectedDate, handleDateChange, error }) {
  const theme = useTheme();
  const classes = useStyle();

  const setToTomorrowAtTen = () => {
    const newDate = dayjs().tz('Europe/Istanbul').add(1, 'day').hour(10).minute(0);
    handleDateChange(newDate);
  };

  const setToDayAfterTomorrow = () => {
    const newDate = dayjs().tz('Europe/Istanbul').add(2, 'day').hour(10).minute(0);
    handleDateChange(newDate);
  };

  const dayAfterTomorrowName = dayjs().add(2, 'day').locale('tr').format('dddd');

  return (
    <Paper
      className={classes.root}
      style={{
        background: theme.palette.common.white,
        borderRadius: "inherit",
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
      }}
    >
      <Container>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex">
            <Box
              style={{
                background: "#FF6B5E",
                width: "32px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography style={theme.typography.body1}><ArrowForwardIcon style={{ paddingTop: 5 }} /></Typography>
            </Box>
            <Box ml={theme.spacing(1)} />
            <Typography variant="h5" color="textSecondary">
              Teslimat Detayları
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pt={theme.spacing(1)}
          pb={theme.spacing(1)}
        />
        <Grid container display="flex"
          justifyContent="space-between"
          alignItems="center">
          <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDayjs} locale={trTR} localeText={trTR.components.MuiLocalizationProvider.defaultProps.localeText}>
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
              <DateTimePicker
                ampm={false}
                disablePast
                label="Sipariş günü ve saati"
                format="DD.MM.YYYY HH:mm"
                onChange={(date) => {
                  handleDateChange(date)
                }}
                value={selectedDate}
              />
              <Button
                onClick={setToTomorrowAtTen}
                variant="outlined"
                style={{ marginLeft: 8 }}
              >
                Yarın
              </Button>
              <Button
                onClick={setToDayAfterTomorrow}
                variant="outlined"
                style={{ marginLeft: 8 }}
              >
                {dayAfterTomorrowName}
              </Button>
            </Grid>
            {error && (
              <Typography variant="body2" color="error">
                {error}
              </Typography>
            )}
          </LocalizationProvider>
        </Grid>
      </Container>
    </Paper>
  );
}

export default React.memo(OrderOption);
