import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  },
  marginHeader: {
    marginTop: '100px',
    width: '100%',
  },
  mainContainer: {
    justifyContent: 'center',
  },
  orderBox: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    margin: theme.spacing(1, 0), // Adjusted for spacing between supplier boxes
    transition: 'box-shadow 0.3s',
    '&:hover': {
      boxShadow: theme.shadows[4],
    },
  },
  editIcon: {
    color: theme.palette.primary.main,
  },
  deleteIcon: {
    color: theme.palette.error.main,
  },
  confirmAllButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2)
  },
  dateTitle: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  supplierPaper: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  supplierName: {
    paddingLeft: theme.spacing(2),
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  dayDivider: {
    margin: theme.spacing(2, 0),
  },
  productName: {
    marginRight: theme.spacing(1),
  },
  productAmount: {
    marginLeft: theme.spacing(1),
  },
  editInput: {
    width: '80px',
  },
  saveIcon: {
    color: theme.palette.success.main,
  },
  cancelIcon: {
    color: theme.palette.error.main,
  },
  newProductFormRow: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    marginTop: '20px',
  },
  textField: {
    '& .MuiInputBase-root': {
      height: 40,
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(30% - 10px)', // Adjust width for smaller screens
    },
  },
  selectField: {
    '& .MuiInputBase-root': {
      height: 40,
    },
    width: 80, // Fixed width for larger screens
    [theme.breakpoints.down('sm')]: {
      width: 'calc(20% - 10px)', // Adjust width for smaller screens
    },
  },
  smallIconButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    padding: theme.spacing(0.2),
  },
  button: {
    margin: theme.spacing(1),
    borderRadius: 10,
    backgroundColor: "#FF6B5E",
    fontSize: "15px",
    textTransform: 'none',
    "&:hover": {
      backgroundColor: "#FF6B5E",
    },
  },
  saveButton: {
    margin: theme.spacing(1),
    borderRadius: 10,
    backgroundColor: theme.palette.primary.main,
    fontSize: "15px",
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    },
  },
  confirmButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10
  },
  formControl: {
    flex: 1,
    minWidth: '120px',
  },
  title: {
    justifyContent: "center",
    padding: theme.spacing(1, 5),
    margin: theme.spacing(1, 2, 2, 2),
  },
  profileContainer: {
    padding: theme.spacing(0, 2),
  },
  inputRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  },
  breadcrumbContainer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    margin: theme.spacing(2, 0),
  },
  breadcrumbText: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
  },
  supplierAddContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  supplierSelect: {
    width: '200px',
    '& .MuiOutlinedInput-input': {
      padding: '10px 14px',
    },
  },
  addSupplierButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },

}));

export default useStyles;
