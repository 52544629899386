import React, { useEffect, useState, useContext, useCallback } from "react";
import { Button, Grid, TextField, Typography, List, ListItem, IconButton, Box, Divider, Select, MenuItem, FormControl, InputLabel, ListItemText, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useStyles from './styles';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { trTR } from '@mui/x-date-pickers/locales';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import { Header } from "../../components/Header";
import tr from 'date-fns/locale/tr';
import { createBulkOrder, createShoppingList, deleteShoppingList, getShoppingList, getSuppliers, updateShoppingList } from '../../api/restaurant_api';
import RestaurantContext from '../../context/Restaurant';
import Error from "../../components/Modals/Error/Error.js";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import { useParams } from "react-router-dom";
import { TimePicker } from '@mui/x-date-pickers';
import OrderInfo from "../../components/Modals/OrderInfo/OrderInfo";

dayjs.extend(utc);
dayjs.extend(timezone);

function AddShoppingList() {
  const { id } = useParams();
  const [listId, setListId] = useState(id);
  const classes = useStyles();
  const [date, setDate] = useState(null);
  const [items, setItems] = useState([]);
  const [product, setProduct] = useState('');
  const [amount, setAmount] = useState('');
  const [unit, setUnit] = useState('');
  const [supplier, setSupplier] = useState('');
  const [editIndex, setEditIndex] = useState(-1);
  const [orderTime, setOrderTime] = useState(null);
  const [confirmOrderModalOpen, setConfirmOrderModalOpen] = useState(false);
  const [orderTimeError, setOrderTimeError] = useState("");
  const [dateError, setDateError] = useState("");

  const [suppliers, setSuppliers] = useState([]);
  const { restaurantId } = useContext(RestaurantContext);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [supplierError, setSupplierError] = useState("");

  const [orderInfoModal, setOrderInfoModal] = useState(false);
  const toggleOrderInfo = useCallback(() => {
    setOrderInfoModal((prev) => !prev);
    handleDelete()
    navigate()
  }, []);

  const fetchListData = async () => {
    try {
      const response = await getShoppingList(listId);
      if (response && response.status === 200 && response.data) {
        setDate(dayjs(response.data.date, "DD-MM-YYYY"));
        setItems(response.data.productList);
      }
    } catch (error) {
      console.error('Error fetching list data:', error);
    }
  };

  const fetchSuppliers = async () => {
    try {
      const response = await getSuppliers(restaurantId);
      if (response !== null && response.status === 200 && response.data !== undefined) {
        setSuppliers(response.data);
      } else {
        setSuppliers([])
      }
    } catch (error) {
      console.error('Error fetching suppliers:', error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchListData(id);
    }
    fetchSuppliers();
  }, [id]);

  const handleAddOrEditItem = () => {
    if (product && amount && unit) {
      const supplierObject = suppliers.find(sup => sup.id === supplier);

      const newItemData = {
        product,
        amount,
        unit,
        supplierId: supplierObject ? supplierObject.id : '',
        supplierName: supplierObject ? supplierObject.businessName : ''
      };

      if (editIndex >= 0) {
        const updatedItems = [...items];
        updatedItems[editIndex] = newItemData;
        setItems(updatedItems);
        setEditIndex(-1);
      } else {
        setItems([...items, newItemData]);
      }
      setProduct('');
      setAmount('');
      setUnit('');
      setSupplier('');
    }
  };

  const handleEditItem = (index) => {
    setEditIndex(index);
    setProduct(items[index].product);
    setAmount(items[index].amount);
    setUnit(items[index].unit);
    setSupplier(items[index].supplierId);
  };

  const handleDeleteItem = (index) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const handleSaveList = async () => {
    setDateError("")
    if (date == null) {
      setDateError("Lütfen günü giriniz!")
      return;
    }
    if (dayjs().isAfter(date)) {
      setDateError("Geçmiş tarih seçilemez.");
      return;
    }

    const shoppingListPayload = {
      restaurantId: restaurantId,
      date: date.format('YYYY-MM-DD'),
      products: items.map(item => ({
        product: item.product,
        amount: parseFloat(item.amount),
        unit: item.unit,
        supplierId: item.supplierId,
        supplierName: item.supplierName
      }))
    };
    try {
      let response;
      if (listId) {
        response = await updateShoppingList(listId, shoppingListPayload);
        if (response && response.status === 200) {
          setSuccessMessage("Liste başarıyla kaydedildi.");
          setTimeout(() => {
            setSuccessMessage('');
            fetchListData()
          }, 3000);
        }
      } else {
        response = await createShoppingList(shoppingListPayload);
        if (response && response.status === 200) {
          const newListId = response.data;
          setListId(newListId);
          setSuccessMessage("Liste başarıyla kaydedildi.");
          setTimeout(() => {
            setSuccessMessage('');
            navigateTo(`/shopList/${newListId}`);
          }, 3000);
          return;
        }
      }
      if (response == null || response.status !== 200) {
        setErrorModalOpen(true);
        setTimeout(() => {
          setErrorModalOpen(false);
        }, 3000);
        return;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const setToTodayPlusTwoHours = () => {
    const newDate = dayjs();
    setDate(newDate);
  };

  const setToTomorrowAtTen = () => {
    const newDate = dayjs().tz('Europe/Istanbul').add(1, 'day');
    setDate(newDate);
  };

  const closeErrorModal = () => {
    setErrorModalOpen(false);
  };

  const handleSubmit = () => {
    setOrderTimeError("");
    setSupplierError("");

    if (items.length === 0) {
      setSuccessMessage("Listede ürün bulunmamaktadır.");
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
      return;
    }

    const missingSupplier = items.some(item => !item.supplierId || item.supplierId === '');
    if (missingSupplier) {
      setSupplierError("Tüm ürünler için tedarikçi bilgisi gereklidir.");
      return;
    }

    setConfirmOrderModalOpen(true);
  };

  const handleOrderConfirm = async () => {
    if (orderTime == null) {
      setOrderTimeError("Lütfen sipariş saati giriniz!")
      return;
    }
    const groupedProducts = items.reduce((acc, item) => {
      acc[item.supplierId] = acc[item.supplierId] || [];
      acc[item.supplierId].push({
        product: item.product,
        amount: parseFloat(item.amount),
        unit: item.unit
      });
      return acc;
    }, {});

    const bulkOrderRequest = {
      restaurantId: restaurantId,
      date: dayjs(date).format('YYYY-MM-DD'),
      time: dayjs(orderTime).format('HH:mm'),
      products: Object.entries(groupedProducts).map(([supplierId, products]) => ({
        supplierId: parseInt(supplierId),
        products: products.map(product => ({
          product: product.product,
          amount: product.amount,
          unit: product.unit
        }))
      }))
    };

    try {
      const response = await createBulkOrder(bulkOrderRequest);
      if (response == null || response.status !== 200) {
        setErrorModalOpen(true);
        setTimeout(() => {
          setErrorModalOpen(false);
        }, 3000);
        return;
      }
      if (response && response.status === 200) {
        setOrderInfoModal(true);
        await handleDelete()
        setTimeout(() => {
          setOrderInfoModal(false);
          navigate()
        }, 3000);
      }
    } catch (error) {
      console.error('Error sending bulk order:', error);
    }

    setConfirmOrderModalOpen(false);
  };

  const handleDelete = async () => {
    try {
      await deleteShoppingList(listId);
    } catch (error) {
      console.error('Error deleting list:', error);
    }
  };

  const navigateTo = useNavigate();
  const navigate = useCallback(() => {
    navigateTo(`/shopList`);
  }, [navigateTo]);

  return (
    <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDayjs} locale={trTR} localeText={trTR.components.MuiLocalizationProvider.defaultProps.localeText}>
      <Grid container className={classes.root}>
        <Header />
        <Grid container item className={classes.marginHeader}>
          <Grid container item xs={12} className={classes.mainContainer}>
            <Grid item xs={12} className={classes.title}>
              <Typography variant="h5" align="center" color="textSecondary" className={classes.textBold}>
                {listId ? <strong>{dayjs(date, "DD-MM-YYYY").format('DD MMMM')} İhtiyaç Listem</strong> : 'Alışveriş Listem'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={6} className={classes.profileContainer}>
              {!listId &&
                <>
                  <Box className={classes.datePickerContainer}>
                    <DatePicker
                      label="Tarih Seçin"
                      value={date}
                      onChange={(newDate) => setDate(newDate ? dayjs(newDate) : null)}
                      disablePast
                      format="DD.MM.YYYY"
                      renderInput={(params) => <TextField {...params} className={classes.datePicker} />}
                    />
                    <Button onClick={setToTodayPlusTwoHours} variant="outlined">
                      Bugün
                    </Button>
                    <Button onClick={setToTomorrowAtTen} variant="outlined">
                      Yarın
                    </Button>
                  </Box>
                  {dateError && (
                    <Typography variant="body2" color="error">
                      {dateError}
                    </Typography>
                  )}
                </>
              }
              <Box className={classes.inputRow}>
                <TextField
                  label="Ürün Adı"
                  variant="outlined"
                  value={product}
                  onChange={(e) => setProduct(e.target.value)}
                  className={classes.inputField}
                />
                <TextField
                  label="Miktar"
                  variant="outlined"
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  className={classes.inputField}
                />
                <FormControl className={classes.selectField}>
                  <InputLabel>Birim</InputLabel>
                  <Select
                    value={unit}
                    onChange={(e) => setUnit(e.target.value)}
                  >
                    <MenuItem value="KG">KG</MenuItem>
                    <MenuItem value="Adet">Adet</MenuItem>
                  </Select>
                </FormControl>
                <FormControl className={classes.selectField}>
                  <InputLabel>Tedarikçi</InputLabel>
                  <Select
                    value={supplier}
                    onChange={(e) => setSupplier(e.target.value)}
                  >
                    {suppliers.map((sup, index) => (
                      <MenuItem key={index} value={sup.id}>{sup.businessName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box className={classes.buttonContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleAddOrEditItem}
                >
                  {editIndex >= 0 ? 'Düzenle' : 'Ekle'}
                </Button>
              </Box>
              <Divider />
              <List>
                {items.map((item, index) => (
                  <ListItem key={index} className={classes.listItem}>
                    <ListItemText
                      primary={`${item.product} - ${item.amount} ${item.unit}`}
                      secondary={`Tedarikçi: ${item.supplierName ? item.supplierName : ''}`}
                    />
                    <Box>
                      <IconButton onClick={() => handleEditItem(index)} className={classes.editButton}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteItem(index)} className={classes.deleteButton}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </ListItem>
                ))}
              </List>

              <Box className={classes.buttonContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.saveButton}
                  onClick={handleSaveList}
                >
                  Kaydet
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={handleSubmit}
                >
                  Siparişe Dönüştür
                </Button>
              </Box>
              <Snackbar
                open={Boolean(successMessage || supplierError)}
                autoHideDuration={6000}
                onClose={() => {
                  setSuccessMessage("");
                  setSupplierError("");
                }}
                message={supplierError || successMessage}
              />

            </Grid>
          </Grid>
        </Grid>
        <Error
          isVisible={errorModalOpen}
          toggleModal={closeErrorModal}
        />

        <Dialog
          open={confirmOrderModalOpen}
          onClose={() => setConfirmOrderModalOpen(false)}
          PaperProps={{
            style: {
              padding: '20px 40px 20px 40px',
            },
          }}>
          <DialogTitle>Siparişi Onayla</DialogTitle>
          <DialogContent>
            <InputLabel>Saat Seçin</InputLabel>
            <TimePicker
              ampm={false}
              value={orderTime}
              onChange={setOrderTime}
              renderInput={(params) => <TextField {...params} />}
            />
            {orderTimeError && (
              <Typography variant="body2" color="error">
                {orderTimeError}
              </Typography>
            )}
            <List>
              {items.map((item, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={`${item.product} - ${item.amount} ${item.unit}`}
                    secondary={`Tedarikçi: ${item.supplierName ? item.supplierName : ''}`}
                  />
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              fullWidth
              className={`${classes.btnBase} ${classes.cancelButton}`}
              onClick={() => setConfirmOrderModalOpen(false)}
            >
              <Typography variant="subtitle2" color="#FF6B5E">
                İptal
              </Typography>
            </Button>
            <Button
              variant="contained"
              fullWidth
              className={classes.btnBase}
              onClick={handleOrderConfirm}
              style={{ backgroundColor: "#FF6B5E", textTransform: 'none' }}
            >
              <Typography variant="subtitle2">
                Onayla
              </Typography>
            </Button>
          </DialogActions>
        </Dialog>

        <OrderInfo
          isVisible={orderInfoModal}
          toggleModal={toggleOrderInfo}
        />
      </Grid>
    </LocalizationProvider>
  );
}

export default AddShoppingList;
