import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
   marginHeader: {
      marginTop: "200px",
   },
   center: {
      flexDirection: "row",
      display: "flex",
      flexWrap: 'wrap',  // Added for responsiveness
      justifyContent: 'center',  // Center the items
      marginLeft: "auto",  // Center the tabs
      marginRight: "auto",  // Center the tabs
      maxWidth: 1040,
      marginTop: '-30px'
   },
   box: {
      display: "flex",
      flexDirection: "column", // Change to column for vertical alignment
      alignItems: "center", // Align items to the center
      justifyContent: "center", // Center content vertically
      //minHeight: 120, // Set a minimum height
      width: 120,
      background: theme.palette.common.white,
      marginRight: 8,  // Reduced margin
      marginBottom: 8,  // Space between rows when they wrap
      cursor: "pointer",
      [theme.breakpoints.down('sm')]: {
         width: 'calc(100% / 4 - 16px)', // Four items per row on small devices and up
      },
   },
   boxAll: {
      display: "flex",
      justifyContent: "space-between",
      width: 100,  // Reduced width
      background: theme.palette.common.white,
      cursor: "pointer",
      padding: theme.spacing(1),
   },
   innerBox: {
      display: "flex",
      flexDirection: "column",
      background: theme.palette.common.white,
      padding: '10px 0',  // Added padding inside the box for better spacing
   },
   innerBoxAll: {
      display: "flex",
      flexDirection: "row", // Align icon and text in a row
      alignItems: "center",
      justifyContent: "center",
      padding: theme.spacing(1),
   },
   allIcon: {
      marginRight: theme.spacing(1), // Add some space between icon and text
   },
   text: {
      textAlign: "center", // Center the text
      fontSize: '0.8rem',
      marginBottom: theme.spacing(1),
      paddingLeft: "10px",  // Adjusted padding
      color: theme.palette.primary.main,
      fontWeight: "bold",
      fontSize: '0.8rem',
      [theme.breakpoints.down('sm')]: {
         fontSize: '0.7rem',
      },
   },
   image: {
      maxWidth: '80%', // Limit image size
      height: 'auto',
      marginLeft: 'auto',  // Center the image
      marginRight: 'auto',  // Center the image
      [theme.breakpoints.down('sm')]: {
         marginBottom: theme.spacing(0.5), // Reduce bottom margin on mobile
         marginTop: theme.spacing(0.5), // Reduce top margin on mobile
      },
   },
   activeTab: {
      borderBottom: `4px solid ${theme.palette.primary.main}`,
      boxShadow: `0 0 5px ${theme.palette.grey[300]}`,
   },
}));

export default useStyles;
