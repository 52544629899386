import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCatalog } from "../../api/catalog_api";
import LinkHeader from "../Header/LoginHeader/LinkHeader";
import { Chip, Box, Grid, Typography, Card, CardContent, DialogActions, InputAdornment, Button, TextField, IconButton, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText, CircularProgress } from "@mui/material";
import { Facebook, Twitter, Instagram, LinkedIn, ShoppingCart, AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import makeStyles from '@mui/styles/makeStyles';
import { Pictures } from "../Utils";
import GuestCartContext from "../../context/Guest";
import GuestCartView from "../RestaurantDetailComponent/RestaurantCart/GuestCartView";
import GuestEmptyView from "../RestaurantDetailComponent/RestaurantCart/GuestEmptyView";
import CloseIcon from '@mui/icons-material/Close';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SearchIcon from '@mui/icons-material/Search';

const useStyle = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        paddingTop: theme.spacing(13),
        backgroundColor: theme.palette.background.default,
        overflowX: 'hidden',
        boxSizing: 'border-box',
    },
    gridContent: {
        marginLeft: 10,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0
        },
    },
    content: {
        flex: 1,
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            margin: '0 auto',
            paddingBottom: theme.spacing(5),
        },
    },
    supplierInfo: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        marginBottom: theme.spacing(3),
        textAlign: 'center',
        boxShadow: theme.shadows[1],
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            marginRight: theme.spacing(4),
        },
    },
    productCard: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[3],
        padding: theme.spacing(1),
        height: 240,
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            height: 200,
            marginBottom: theme.spacing(2),
        },
    },
    productImage: {
        width: '100%',
        height: '90px',
        objectFit: 'contain',
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            height: '70px',
            maxWidth: '100%',
        },
    },
    categoryHeader: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        fontWeight: 'bold',
        color: "#FF6B5E",
    },
    footerContainer: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[1],
    },
    socialMediaContainer: {
        display: 'flex',
        justifyContent: 'center',
        gap: theme.spacing(2),
    },
    footer: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    addToCartButton: {
        color: theme.palette.success.main,
        borderColor: theme.palette.success.main,
        fontSize: '0.7rem',
        padding: theme.spacing(0.5, 1),
        minWidth: 'auto',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.6rem',
        },
    },
    quantityInput: {
        width: '100%',
        maxWidth: '70px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '60px',
        },
    },
    quantityControlButton: {
        width: '30px',
        padding: '4px',
        [theme.breakpoints.down('sm')]: {
            width: '25px',
        },
    },
    itemDesc: {
        color: theme.palette.text.disabled,
        padding: '2px 6px',
        borderRadius: '4px',
        fontSize: '0.6rem',
        textAlign: 'justify',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
    },
    brand: {
        backgroundColor: '#f0f0f0',
        padding: '2px 6px',
        borderRadius: '4px',
        marginBottom: '8px',
        fontSize: '0.7rem',
    },
    clickableCard: {
        cursor: 'pointer',
        '&:hover': {
            boxShadow: theme.shadows[6],
        },
    },
    contentContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        justifyContent: 'flex-start',
        marginBottom: theme.spacing(1),
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'flex-start',
    },
    priceContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: theme.spacing(1),
    },
    productName: {
        fontSize: '0.8rem',
        fontWeight: 'bold',
        lineHeight: '1.2',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.7rem',
        },
    },
    priceText: {
        fontSize: '0.7rem',
        fontWeight: 'normal',
        color: theme.palette.text.primary,
    },
    actionsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    cardContentGrow: {
        flexGrow: 1,
        padding: '8px',
        [theme.breakpoints.down('sm')]: {
            padding: '4px',
        },
    },
    floatingCartButton: {
        display: 'none',
        position: 'fixed',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        zIndex: 1000,
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    defaultCartView: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    cartIcon: {
        fontSize: '3rem',
    },
    chipContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(1),
        marginBottom: theme.spacing(2),
        justifyContent: 'center',
        overflowX: 'auto',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start',
            padding: theme.spacing(1),
        },
    },
    chipMainCategory: {
        marginRight: theme.spacing(1),
        borderRadius: '8px',
        padding: '20px 8px',
        boxShadow: theme.shadows[1],
    },
    chipSubCategory: {
        marginRight: theme.spacing(1),
        borderRadius: '4px',
        padding: '6px 8px',
        boxShadow: theme.shadows[1],
        backgroundColor: '#FFD7C2',
    },
    selectedMainChip: {
        backgroundColor: '#FF6B5E',
        color: 'white',
        borderRadius: '8px',
        boxShadow: theme.shadows[2],
        padding: '20px 8px',
        '&:hover': {
            backgroundColor: '#FF6B5E',
        },
    },
    selectedSubChip: {
        backgroundColor: '#FF6B5E',
        color: 'white',
        borderRadius: '8px',
        boxShadow: theme.shadows[2],
        '&:hover': {
            backgroundColor: '#FF6B5E',
        },
    },
    supplierInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(2),
    },
    filterSection: {
        marginBottom: theme.spacing(3),
        textAlign: 'center',
    },
    searchField: {
        maxWidth: '600px',
        margin: '0 auto',
        marginBottom: theme.spacing(2),
    },
    categoryMainHeader: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        fontWeight: 'bold',
        color: "#FF6B5E",
        fontSize: '1.5rem',
    },
    categorySubHeader: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        fontWeight: 'bold',
        fontSize: '1.0rem',
    },
}));

function Catalog() {
    const classes = useStyle();
    const { catalogId } = useParams();
    const [data, setData] = useState(null);
    const { cart, addCartItem } = useContext(GuestCartContext);
    const [quantities, setQuantities] = useState({});
    const [productDetailOpen, setProductDetailOpen] = useState(false);
    const [productDetailQuantity, setProductDetailQuantity] = useState(1);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [cartModalOpen, setCartModalOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [mainCategoryFilter, setMainCategoryFilter] = useState('Hepsi');
    const [subCategoryFilter, setSubCategoryFilter] = useState('Hepsi');
    const [loading, setLoading] = useState(true);

    const toggleCartModal = () => {
        setCartModalOpen(!cartModalOpen);
    };

    const handleProductClick = (product) => {
        setSelectedProduct(product);
        setProductDetailOpen(true);
    };

    useEffect(() => {
        if (catalogId) {
            fetchData(catalogId);
        }
    }, [catalogId]);

    const fetchData = async (id) => {
        setLoading(true);
        try {
            const response = await getCatalog(id);
            if (response && response.status === 200 && response.data) {
                setData(response.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleOpenVariablePrices = (product) => {
        setSelectedProduct(product);
        setOpenDialog(true);
    };

    const incrementQuantity = (productId) => {
        setQuantities(currentQuantities => ({
            ...currentQuantities,
            [productId]: (currentQuantities[productId] || 0) + 1
        }));
    };

    const decrementQuantity = (productId) => {
        setQuantities(currentQuantities => ({
            ...currentQuantities,
            [productId]: Math.max(0, (currentQuantities[productId] || 0) - 1)
        }));
    };

    const handleAddToCartFromDetail = () => {
        if (productDetailQuantity > 0) {
            addCartItem({
                ...selectedProduct,
                quantity: productDetailQuantity,
                key: selectedProduct.productId,
            });
            setProductDetailOpen(false);
        }
    };

    function normalizeText(text) {
        if (!text) return '';
        return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            .replace(/ğ/g, 'g').replace(/ü/g, 'u')
            .replace(/ş/g, 's').replace(/ı/g, 'i')
            .replace(/ö/g, 'o').replace(/ç/g, 'c')
            .replace(/Ğ/g, 'G').replace(/Ü/g, 'U')
            .replace(/Ş/g, 'S').replace(/İ/g, 'I')
            .replace(/Ö/g, 'O').replace(/Ç/g, 'C')
            .toLowerCase();
    }

    const filteredProducts = data?.products?.filter(product => {
        const normalizedSearchTerm = normalizeText(searchTerm);
        const normalizedProductName = normalizeText(product.name || '');
        const normalizedProductBrand = normalizeText(product.brand || '');
        const normalizedProductDesc = normalizeText(product.description || '');

        return (
            (mainCategoryFilter === 'Hepsi' || product.mainCategory === mainCategoryFilter) &&
            (subCategoryFilter === 'Hepsi' || product.subCategory === subCategoryFilter) &&
            (normalizedProductName.includes(normalizedSearchTerm) ||
                normalizedProductBrand.includes(normalizedSearchTerm) ||
                normalizedProductDesc.includes(normalizedSearchTerm))
        );
    });

    const renderProducts = () => {
        const mainCategories = data ? new Set(filteredProducts.map(product => product.mainCategory)) : new Set();

        return Array.from(mainCategories).map(mainCategory => {
            const subCategories = new Set(
                filteredProducts
                    .filter(product => product.mainCategory === mainCategory)
                    .map(product => product.subCategory)
            );

            return (
                <React.Fragment key={mainCategory}>
                    <Typography variant="h5" className={classes.categoryMainHeader}>
                        {mainCategory}
                    </Typography>
                    {Array.from(subCategories).map(subCategory => (
                        <React.Fragment key={subCategory}>
                            <Typography variant="h6" className={classes.categorySubHeader}>
                                {subCategory}
                            </Typography>
                            <Grid container spacing={3} style={{ maxWidth: '100%' }}>
                                {filteredProducts
                                    .filter(product => product.mainCategory === mainCategory && product.subCategory === subCategory)
                                    .map(product => (
                                        <Grid item xs={6} sm={4} md={3} key={product.productId}>
                                            <Card
                                                className={`${classes.productCard} ${classes.clickableCard}`}
                                                onClick={() => handleProductClick(product)}
                                            >
                                                <CardContent className={classes.cardContentGrow}>
                                                    <div className={classes.contentContainer}>
                                                        <img
                                                            src={product.imageData ? `data:image/jpeg;base64,${product.imageData}` : Pictures[mainCategory]?.source.default ? Pictures[mainCategory]?.source.default : Pictures['Genel'].source.default}
                                                            className={classes.productImage}
                                                        />
                                                        <div className={classes.textContainer}>
                                                            {product.brand && (
                                                                <Typography component="span" variant="body2" className={classes.brand}>
                                                                    {product.brand}
                                                                </Typography>
                                                            )}
                                                            <Typography variant="caption" className={classes.itemDesc}>
                                                                {product.shortDescription ? product.shortDescription : product.description}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                    <div className={classes.priceContainer}>
                                                        <Typography gutterBottom variant="subtitle1" className={classes.productName}>
                                                            {product.name}
                                                        </Typography>
                                                        {{
                                                            'none': <Typography variant="body2" className={classes.priceText}>Fiyat belirtilmemiş</Typography>,
                                                            'fixed': <Typography variant="body2" className={classes.priceText}>Fiyat: ₺{product.fixedPrice}</Typography>,
                                                            'variable': product.variablePrices && product.variablePrices.length > 0 && (
                                                                <Button size="small" color="primary" onClick={(e) => { e.stopPropagation(); handleOpenVariablePrices(product); }}>
                                                                    Fiyat Listesi
                                                                </Button>
                                                            ),
                                                        }[product.priceStrategy || 'none']}
                                                    </div>
                                                </CardContent>
                                                <div className={classes.actionsWrapper}>
                                                    <IconButton size="small" onClick={(e) => {
                                                        e.stopPropagation();
                                                        decrementQuantity(product.productId);
                                                    }} className={classes.quantityControlButton}>
                                                        <RemoveCircleOutline />
                                                    </IconButton>
                                                    <TextField
                                                        className={classes.quantityInput}
                                                        label={product.unit}
                                                        type="number"
                                                        size="small"
                                                        variant="outlined"
                                                        InputProps={{
                                                            inputProps: { min: 0, style: { fontSize: '0.75rem' } },
                                                        }}
                                                        InputLabelProps={{
                                                            style: { fontSize: '0.75rem' },
                                                        }}
                                                        value={quantities[product.productId] || ''}
                                                        onChange={(e) => {
                                                            e.stopPropagation();
                                                            const val = e.target.value;
                                                            setQuantities(current => ({
                                                                ...current,
                                                                [product.productId]: val === '' ? '' : Math.max(0, parseInt(val, 10))
                                                            }));
                                                        }}
                                                        onClick={(e) => e.stopPropagation()}
                                                        disabled={!product.isAvailable}
                                                    />
                                                    <IconButton
                                                        size="small"
                                                        className={classes.quantityControlButton}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            incrementQuantity(product.productId);
                                                        }}
                                                        disabled={!product.isAvailable}
                                                    >
                                                        <AddCircleOutline />
                                                    </IconButton>
                                                    <Button
                                                        variant="outlined"
                                                        className={classes.addToCartButton}
                                                        startIcon={<ShoppingCart />}
                                                        disabled={!product.isAvailable}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (quantities[product.productId] > 0) {
                                                                addCartItem({
                                                                    ...product,
                                                                    quantity: quantities[product.productId],
                                                                    key: product.productId,
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Card>
                                        </Grid>
                                    ))}
                            </Grid>
                        </React.Fragment>
                    ))}
                </React.Fragment>
            );
        });
    };

    return (
        <Grid className={classes.root}>
            <LinkHeader supplierName={data?.supplierName} />
            <Box className={classes.content}>
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                        <CircularProgress />
                        <Typography variant="subtitle1" style={{ marginLeft: '16px' }}>Ürünler Yükleniyor...</Typography>
                    </Box>
                ) : (
                    <Grid container className={classes.gridContent} spacing={2}>
                        <Grid item xs={12} md={9} lg={9}>
                            <Box className={classes.supplierInfo}>
                                <Box className={classes.supplierInfoContainer}>
                                    <Typography variant="subtitle2">
                                        Toplam {data?.products.length} ürün bulunmaktadır. Aşağıdan ürünleri hemen sipariş verebilirsiniz.
                                    </Typography>
                                    <TextField
                                        className={classes.searchField}
                                        fullWidth
                                        placeholder="Ürün ara..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <IconButton>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                searchTerm && (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={() => setSearchTerm('')}>
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            ),
                                        }}
                                        variant="outlined"
                                        margin="normal"
                                    />
                                </Box>

                                <Box className={classes.filterSection}>
                                    <Box className={classes.chipContainer}>
                                        {['Hepsi', ...new Set(data?.products.map(product => product.mainCategory))].map((category) => (
                                            <Chip
                                                key={category}
                                                label={category}
                                                clickable
                                                className={mainCategoryFilter === category ? classes.selectedMainChip : classes.chipMainCategory}
                                                onClick={() => {
                                                    setMainCategoryFilter(category);
                                                    setSubCategoryFilter('Hepsi');
                                                }}
                                            />
                                        ))}
                                    </Box>
                                </Box>

                                <Box className={classes.filterSection}>
                                    <Box className={classes.chipContainer}>
                                        {[...new Set(data?.products
                                            .filter(product => mainCategoryFilter === 'Hepsi' || product.mainCategory === mainCategoryFilter)
                                            .map(product => product.subCategory)
                                        )].map(subcategory => (
                                            <Chip
                                                key={subcategory}
                                                label={subcategory}
                                                clickable
                                                className={subCategoryFilter === subcategory ? classes.selectedSubChip : classes.chipSubCategory}
                                                onClick={() => {
                                                    const mainCategory = data.products.find(product => product.subCategory === subcategory)?.mainCategory;
                                                    setMainCategoryFilter(mainCategory || 'Hepsi');
                                                    setSubCategoryFilter(subcategory);
                                                }}
                                            />
                                        ))}
                                    </Box>
                                </Box>
                            </Box>
                            {data && renderProducts()}
                            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                                <DialogTitle>Değişken Fiyat Listesi</DialogTitle>
                                <DialogContent>
                                    <List>
                                        {selectedProduct?.variablePrices?.map((price, index) => (
                                            <ListItem key={index}>
                                                <ListItemText primary={`₺${price.price} / ${price.minAmount}-${price.maxAmount} ${selectedProduct?.unit}`} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </DialogContent>
                            </Dialog>

                            <Dialog open={productDetailOpen} onClose={() => setProductDetailOpen(false)} maxWidth="md" fullWidth>
                                <DialogTitle>
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        Ürün Detayları
                                        <IconButton onClick={() => setProductDetailOpen(false)}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </DialogTitle>
                                <DialogContent dividers>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <Box
                                                component="img"
                                                sx={{
                                                    maxHeight: '300px',
                                                    maxWidth: '100%',
                                                    width: 'auto',
                                                    height: 'auto',
                                                    objectFit: 'contain',
                                                    borderRadius: '4px',
                                                    boxShadow: '0 8px 24px rgba(0,0,0,0.12)'
                                                }}
                                                src={selectedProduct?.imageData ? `data:image/jpeg;base64,${selectedProduct?.imageData}` : Pictures[selectedProduct?.mainCategory]?.source.default ? Pictures[selectedProduct?.mainCategory]?.source.default : Pictures['Genel'].source.default}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="h5" gutterBottom>
                                                {selectedProduct?.name}
                                            </Typography>
                                            <Box my={2}>
                                                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                                    Marka
                                                </Typography>
                                                <Typography variant="body1">
                                                    {selectedProduct?.brand || 'Bilgi Yok'}
                                                </Typography>
                                            </Box>
                                            <Box my={2}>
                                                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                                    Açıklama
                                                </Typography>
                                                <Typography variant="body2">
                                                    {selectedProduct?.description || 'Bilgi Yok'}
                                                </Typography>
                                            </Box>
                                            <Box my={2}>
                                                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                                    Fiyat
                                                </Typography>
                                                {selectedProduct?.priceStrategy === 'none' ? (
                                                    <Typography variant="body2">Fiyat belirtilmemiş</Typography>
                                                ) : selectedProduct?.priceStrategy === 'fixed' ? (
                                                    <Typography variant="body2">{`₺${selectedProduct?.fixedPrice}`}</Typography>
                                                ) : (
                                                    <List dense>
                                                        {selectedProduct?.variablePrices?.map((price, index) => (
                                                            <ListItem key={index}>
                                                                <ListItemText primary={`₺${price.price} / ${price.minAmount}-${price.maxAmount} ${selectedProduct?.unit}`} />
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                )}
                                            </Box>
                                            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                                Miktar
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                type="number"
                                                value={productDetailQuantity}
                                                onChange={(e) => setProductDetailQuantity(Math.max(1, parseInt(e.target.value, 10) || 1))}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">{selectedProduct?.unit}</InputAdornment>,
                                                }}
                                            />
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<ShoppingCartIcon />}
                                                onClick={handleAddToCartFromDetail}
                                                sx={{
                                                    mt: 2,
                                                    '&:hover': {
                                                        bgcolor: 'primary.main',
                                                        '@media (hover: none)': {
                                                            bgcolor: 'primary.main',
                                                        },
                                                    },
                                                }}
                                            >
                                                Sepete Ekle
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setProductDetailOpen(false)} color="primary">
                                        Kapat
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Grid>

                        <IconButton
                            className={classes.floatingCartButton}
                            color="primary"
                            onClick={toggleCartModal}
                        >
                            <ShoppingCart className={classes.cartIcon} />
                        </IconButton>

                        <Dialog open={cartModalOpen} onClose={toggleCartModal} fullWidth maxWidth="sm">
                            <DialogContent>
                                {!cart?.length ? <GuestEmptyView /> : <GuestCartView supplier={data?.supplierName} linkIdentifier={catalogId} />}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={toggleCartModal} color="primary">
                                    Kapat
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Grid item lg={2.8} sm={3} xs={false} className={classes.defaultCartView}>
                            {!cart?.length ? <GuestEmptyView /> : <GuestCartView supplier={data?.supplierName} linkIdentifier={catalogId} />}
                        </Grid>
                    </Grid>)}
            </Box>

            <Box className={classes.footerContainer}>
                <Box className={classes.socialMediaContainer}>
                    <IconButton href="https://www.facebook.com" target="_blank"><Facebook /></IconButton>
                    <IconButton href="https://twitter.com" target="_blank"><Twitter /></IconButton>
                    <IconButton href="https://www.instagram.com" target="_blank"><Instagram /></IconButton>
                    <IconButton href="https://www.linkedin.com" target="_blank"><LinkedIn /></IconButton>
                </Box>
                <Typography variant="body2" className={classes.footer}>
                    © 2024 TedaBir. Tüm Hakları Saklıdır.
                </Typography>
            </Box>
        </Grid>
    );
}

export default React.memo(Catalog);
