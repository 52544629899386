import axios from 'axios';

export const uri = 'https://admin-api.tedabir.com/api/v1';
//export const uri = 'http://localhost:5000/api/v1';

export const handleExistingPhoneNumber = (err) => {
    if (err?.response?.status === 400 && err?.response?.data?.errorMessage) {
        if (err.response.data.errorMessage === "Phone number already registered.") {
            return "Phone number already registered.";
        }
    }
    return null;
}

// Create New Retail
export const createNewRetail = async (data) => {
    try {
        return await axios.post(`${uri}/auth/signup/retail`, data)
            .then(async response => {
                return response;
            })
            .catch((err) => {
                return handleExistingPhoneNumber(err);
            })
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const changePwd = async (data) => {
    try {
        return await axios.post(`${uri}/auth/retail/change-password`, data)
    } catch (error) {
        console.log(error);
        return null;
    }
};

// Login Retail
export const loginRetail = async (data) => {
    try {
        const response = await axios.post(`${uri}/auth/signin/retail`, data);
        if (response === null || response.status !== 200) {
            return null;
        }
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const updateRetail = async (id, data) => {
    try {
        return await axios.put(`${uri}/restaurants/${id}`, data);
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const addNewSupplier = async (data) => {
    try {
        const response = await axios.post(`${uri}/restaurants/supplier`, data);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const sendInvite = async (id) => {
    try {
        const response = await axios.post(`${uri}/restaurants/invite?supplierByRestId=${id}`, {});
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getSuppliers = async (id) => {
    try {
        const response = await axios.get(`${uri}/restaurants/${id}/supplier`);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getSupplierByRestaurant = async (restaurantId, id) => {
    try {
        const response = await axios.get(`${uri}/restaurants/supplier/${id}?restaurantId=${restaurantId}`);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const setAddress = async (data) => {
    try {
        return await axios.post(`${uri}/restaurants/setAddress`, data)
            .then(async response => {
                return response;
            })
            .catch((err) => {
                return handleExistingPhoneNumber(err);
            })
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const setToken = async (data) => {
    try {
        const response = await axios.post(`${uri}/restaurants/token`, data);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const markAsRead = async (notificationId) => {
    try {
        const response = await axios.put(`${uri}/notifications/${notificationId}/read`);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const markAllAsRead = async (unreadNotificationIds) => {
    try {
        const response = await axios.put(`${uri}/notifications/read-all`, unreadNotificationIds);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getUnreadNotifications = async (id) => {
    try {
        return await axios.get(`${uri}/notifications/retail/${id}`);
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getShoppingLists = async (restaurantId) => {
    try {
        return await axios.get(`${uri}/restaurants/shopList?restaurantId=${restaurantId}`);
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getShoppingList = async (id) => {
    try {
        return await axios.get(`${uri}/restaurants/shopList/${id}`);
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const createShoppingList = async (data) => {
    try {
        return await axios.post(`${uri}/restaurants/shopList`, data);
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const updateShoppingList = async (id, data) => {
    try {
        const response = await axios.put(`${uri}/restaurants/shopList/${id}`, data);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const deleteShoppingList = async (id) => {
    try {
        const response = await axios.delete(`${uri}/restaurants/shopList/${id}`);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const createBulkOrder = async (data) => {
    try {
        const response = await axios.post(`${uri}/restaurants/bulk`, data);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const addFeedback = async (data) => {
    try {
        const response = await axios.post(`${uri}/restaurants/feedback`, data);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getWeeklyReport = async (retailId, week) => {
    try {
        return await axios.get(`${uri}/predictions?retailId=${retailId}&week=${week}`);
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getWeeklyPlan = async (retailId, week) => {
    try {
        return await axios.get(`${uri}/predictions?retailId=${retailId}&week=${week}`);
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const updateWeeklyPlan = async (data) => {
    try {
        return await axios.post(`${uri}/predictions`, data);
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getReferralData = async (referralCode, orderId) => {
    try {
        let query = '';
        if (referralCode !== undefined) {
            query += `code=${referralCode}`;
        }
        if (orderId !== undefined) {
            if (query.length) query += '&';
            query += `orderId=${orderId}`;
        }
        const response = await axios.get(`${uri}/restaurants/referral?${query}`);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};
