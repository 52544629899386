/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Container, Typography, useTheme } from "@mui/material";
import React, { useContext, useMemo, useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import useStyles from "./styles";
import RestaurantContext from "../../../context/Restaurant";

function PricingView(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { cart } = useContext(RestaurantContext);

  const [deliveryCharges, setDeliveryCharges] = useState(0)

  const calculatePrice = useMemo(
    () => (amount = 0) => {
      let itemTotal = 0;
      cart?.forEach((cartItem) => {
        itemTotal += cartItem.price * cartItem.quantity;
      });
      const deliveryAmount = amount > 0 ? deliveryCharges : 0
      return (itemTotal + deliveryAmount).toFixed(2);
    },
    [deliveryCharges, cart]
  );

  const calculateTotal = useMemo(
    () => () => {
      let total = 0;
      cart?.forEach((cartItem) => {
        total += cartItem.price * cartItem.quantity;
      });
      total += deliveryCharges;
      return parseFloat(total).toFixed(2);
    },
    [cart, deliveryCharges]
  );

  useEffect(() => {
    let totalDeliveryFee = 0;
    let uniqueSuppliers = new Set();

    cart.forEach((cartItem) => {
      if (!uniqueSuppliers.has(cartItem.supplier.id)) {
        uniqueSuppliers.add(cartItem.supplier.id);
        totalDeliveryFee += cartItem.supplier.deliveryFee;
      }
    });

    setDeliveryCharges(totalDeliveryFee);
  }, [cart]);

  return (
    <Container
      style={{
        paddingTop: theme.spacing(2),
        background: theme.palette.common.white,
      }}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: theme.spacing(1),
        }}
      >
        <Typography className={classes.subtotalText}>Ara Toplam</Typography>
        <Typography className={classes.subtotalText}>
          {`${calculatePrice(0)}`} TL
        </Typography>
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: theme.spacing(1),
        }}
      >
        <Typography className={classes.subtotalText}>Teslimat Ücreti</Typography>
        <Typography className={classes.subtotalText}>
          {`${deliveryCharges.toFixed(2)}`} TL
        </Typography>
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: theme.spacing(2),
        }}
      >
        <Typography style={{ fontWeight: 700, color: theme.palette.text.secondary }} className={classes.subtotalText}>
          Toplam
        </Typography>
        <Typography style={{ fontWeight: 700, color: theme.palette.text.secondary }} className={classes.subtotalText}>
          {`${calculateTotal()}`} TL
        </Typography>
      </Box>
      <RouterLink to="/checkout" style={{ textDecoration: "none" }}>
        <Button
          style={{
            borderRadius: 30,
            backgroundColor: "#FF6B5E",
            marginTop: '5%',
            fontSize: "18px"
          }}
          className={classes.checkoutContainer}
        >
          <Typography style={{ color: theme.palette.common.white }} className={classes.checkoutText}>Sepete Gİt</Typography>
        </Button>
      </RouterLink>
    </Container>
  );
}

export default React.memo(PricingView);
