import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      marginBottom: theme.spacing(1),
    },
    "& .MuiInputBase-input": {
      color: theme.palette.text.secondary,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.grey[200],
      },
      "&:hover fieldset": {
        borderColor: theme.palette.text.disabled,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },
    marginBottom: '5%'
  },
  marginHeader: {
    marginTop: "100px",
  },
  mainContainer: {
    justifyContent: "center",
  },
  title: {
    justifyContent: "center",
    padding: theme.spacing(1, 5),
    marginTop: theme.spacing(1),
  },
  profileContainer: {
    padding: theme.spacing(0, 2),
  },
  textBold: {
    fontWeight: 700,
  },
  inputRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  },
  inputField: {
    flex: 1,
    minWidth: '120px',
  },
  selectField: {
    flex: 1,
    minWidth: '120px',
  },
  datePickerContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  datePicker: {
    flex: 1,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    width: '100%',
  },
  button: {
    margin: theme.spacing(1),
    borderRadius: 10,
    backgroundColor: "#FF6B5E",
    fontSize: "18px",
    textTransform: 'none',
    "&:hover": {
      backgroundColor: "#FF6B5E",
    },
  },
  listContainer: {
    width: '90%',
    [theme.breakpoints.up('md')]: {
      width: '40%',
    },
    margin: 'auto',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    margin: theme.spacing(1, 0),
    transition: 'box-shadow 0.3s',
    '&:hover': {
      boxShadow: theme.shadows[4],
    },
  },
  listItemText: {
    flex: '1 1 auto',
    color: theme.palette.text.primary,
  },
  listItemActions: {
    display: 'flex',
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
  editIcon: {
    color: theme.palette.primary.main,
  },
  deleteIcon: {
    color: theme.palette.error.main,
  },
  addIcon: {
    color: 'white',
  },
  addIconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    width: '90%',
    [theme.breakpoints.up('md')]: {
      width: '40%',
    },
    margin: 'auto',
  },
  addIconButton: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    borderRadius: '50%',
    padding: theme.spacing(1),
    boxShadow: theme.shadows[2],
  },
  btnBase: {
    borderRadius: "0px",
    borderRadius: 30,
    fontSize: "18px",
    borderColor: "#FF6B5E",
    borderWidth: "2px",
  },
  cancelButton: {
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: "#FF6B5E",
    },
    textTransform: 'none'
  },
}));

export default useStyles;
