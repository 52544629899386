/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Divider,
  Grid,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
  Radio,
  FormControlLabel,
  Checkbox, Box
} from "@mui/material";
import React, { useCallback, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import useStyles from "./addsupplier-styles.js";
import "react-phone-input-2/lib/style.css";
import { Header } from "../../components/Header";
import HeadingView from "../../components/Modals/VariationModal/HeadingView";
import { addNewSupplier, sendInvite } from "../../api/restaurant_api";
import { categories, orderTypes, validatePhoneNumber } from "../../components/Utils";
import SupplierAdded from "../../components/Modals/Supplier/SupplierAdded";
import Error from "../../components/Modals/Error/Error.js";
import RestaurantContext from "../../context/Restaurant.js";
import NoteAdded from "../../components/Modals/NoteAdded/NoteAdded.js";

function AddSupplier() {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedOrderTypes, setSelectedOrderTypes] = useState([]);

  const [supplierId, setSupplierId] = useState();

  const [createdModal, setCreatedModal] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [inviteSentModal, setInviteSentModal] = useState(false);

  const [nameError, setNameError] = useState("");
  const [businessNameError, setBusinessNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [selectedOrderTypeError, setSelectedOrderTypeError] = useState("");
  const [emailError, setEmailError] = useState('');
  const [categoryError, setCategoryError] = useState('');
  const [mainError, setMainError] = useState('');

  const { profile } = useContext(RestaurantContext);

  const validateEmail = () => {
    if (selectedOrderTypes.includes('EMAIL') && !email) {
      setEmailError('Mail Adresi Girmelisiniz');
      setMainError("Mail Adresi Girmelisiniz")
      return false;
    }
    setEmailError('');
    return true;
  };

  const validatePhone = () => {
    if (selectedOrderTypes.includes('SMS') || selectedOrderTypes.includes('WHATSAPP') || selectedOrderTypes.includes('PHONE_CALL')) {
      if (phone == "") {
        setPhoneError("Telefon Numarası Girmelisiniz")
        setMainError("Telefon Numarası Girmelisiniz")
        return false;
      } else if (!validatePhoneNumber(phone)) {
        setPhoneError("0 ile başlayan geçerli bir telefon numarası giriniz")
        setMainError("0 ile başlayan geçerli bir telefon numarası giriniz")
        return false;
      }
    }
    setEmailError('');
    return true;
  };

  const handleAction = async () => {
    clearErrors();
    if (businessName == "") {
      setBusinessNameError("İşletme Adı Girmelisiniz")
      setMainError("İşletme Adı Girmelisiniz")
      return;
    }
    if (name == "") {
      setNameError("İşletme Sorumlusu İsim Soyisim Girmelisiniz")
      setMainError("İşletme Sorumlusu İsim Soyisim Girmelisiniz")
      return;
    }
    if (!validatePhone()) {
      return;
    }
    if (!validateEmail()) {
      return;
    }
    if (selectedCategory === '') {
      setCategoryError('Bir kategori seçmelisiniz');
      setMainError("Bir kategori seçmelisiniz")
      return
    }
    if (selectedOrderTypes.length === 0) {
      setSelectedOrderTypeError("Siparişlerinizi Verme Şeklinizi Belirtmelisiniz")
      setMainError("Siparişlerinizi Verme Şeklinizi Belirtmelisiniz")
      return;
    }

    const data = {
      restaurantId: profile.id,
      businessName: businessName,
      personName: name,
      phoneNumber: phone,
      email: email,
      category: selectedCategory,
      orderTypes: selectedOrderTypes
    }
    try {
      const response = await addNewSupplier(data);
      if (response == null || response.status !== 200) {
        setErrorModalOpen(true);
        setTimeout(() => {
          setErrorModalOpen(false);
        }, 3000);
        return;
      }
      if (response !== undefined && response !== null && response.status === 200) {
        setSupplierId(response.data)
        toggleSupplierAdded();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleOrderTypeChange = (event) => {
    const value = event.target.name;
    setSelectedOrderTypes((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };

  const toggleSupplierAdded = useCallback(() => {
    setCreatedModal((prev) => !prev);
  }, []);

  const toggleInviteSentModal = useCallback(() => {
    setInviteSentModal((prev) => !prev);
  }, []);


  const closeSupplierAdded = () => {
    setCreatedModal(false);
    navigate(`/supplier`);
  };

  const closeErrorModal = () => {
    setErrorModalOpen(false);
  };

  const navigateSupplierCard = () => {
    navigate(`/supplier/${supplierId}`, { replace: true, state: { id: supplierId, category: selectedCategory } });
  };

  const sendInvitationLink = async () => {
    try {
      const response = await sendInvite(supplierId);
      if (response == null || response.status !== 200) {
        setErrorModalOpen(true);
        setTimeout(() => {
          setErrorModalOpen(false);
        }, 3000);
        return;
      }
      if (response !== undefined && response !== null && response.status === 200) {
        toggleInviteSentModal()
        setTimeout(() => {
          toggleInviteSentModal()
          toggleSupplierAdded();
          navigate(`/supplier`);
        }, 3000);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const clearErrors = () => {
    setBusinessNameError("");
    setNameError("");
    setPhoneError("")
    setSelectedOrderTypeError("")
    setEmailError("")
    setCategoryError("")
    setMainError("")
  };

  return (
    <Grid container className={classes.root}>
      <Header />
      <Grid container item className={classes.marginHeader}>
        <Grid container item xs={12} className={classes.mainContainer}>
          <Grid item xs={12} className={classes.title}>
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              className={classes.textBold}
            >
              Yeni Lezzet Ortağımız
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
            md={8}
            lg={6}
            className={classes.profileContainer}
          >
            <Divider light orientation="horizontal" className={classes.MH3} />
            <TextField
              name={"businessName"}
              variant="outlined"
              label="İşletme Adı"
              defaultValue={businessName}
              onChange={(e) => {
                setBusinessName(e.target.value);
              }}
              error={Boolean(businessNameError)}
              helperText={businessNameError}
              fullWidth
              InputLabelProps={{
                style: {
                  color: theme.palette.grey[600],
                },
              }}
            />
            <TextField
              name={"name"}
              variant="outlined"
              label="İşletme Sorumlusu İsim Soyisim"
              defaultValue={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              error={Boolean(nameError)}
              helperText={nameError}
              fullWidth
              InputLabelProps={{
                style: {
                  color: theme.palette.grey[600],
                },
              }}
            />

            <TextField
              name={"phone"}
              variant="outlined"
              label="Telefon Numarası"
              defaultValue={phone}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              error={Boolean(phoneError)}
              helperText={phoneError}
              fullWidth
              type="number"
              InputLabelProps={{
                style: {
                  color: theme.palette.grey[600],
                },
              }}
            />

            <TextField
              name={"email"}
              variant="outlined"
              label="Mail Adresi"
              defaultValue={email}
              error={Boolean(emailError)}
              helperText={emailError}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              fullWidth
              InputLabelProps={{
                style: {
                  color: theme.palette.grey[600],
                },
              }}
            />

            <HeadingView title="Tedarik Kategorisi" subTitle="Bu tedarikçiden aldığınız ana ürün kategorisi nedir?" status="ZORUNLU" error={false} />

            <RadioGroup
              value={selectedCategory}
              onChange={handleCategoryChange}
              style={{ flexDirection: 'row' }}
            >
              {categories.map((category, index) => (
                index % 2 === 0 && (
                  <Grid container item key={category.id}>
                    <Grid item xs={6}>
                      <FormControlLabel
                        value={category.id}
                        control={<Radio color="primary" />}
                        label={category.title}
                      />
                    </Grid>
                    {categories.length > index + 1 && (
                      <Grid item xs={6}>
                        <FormControlLabel
                          value={categories[index + 1].id}
                          control={<Radio color="primary" />}
                          label={categories[index + 1].title}
                        />
                      </Grid>
                    )}
                  </Grid>
                )
              ))}
            </RadioGroup>

            {categoryError && (
              <Typography variant="body2" color="error">
                {categoryError}
              </Typography>
            )}

            <HeadingView title="Siparişlerinizi Verme Şekliniz" subTitle="Bu tedarikçiye siparişlerinizi nasıl vermektesiniz?" status="ZORUNLU" error={false} />

            {orderTypes.map((option) => (
              <FormControlLabel
                key={option.id}
                control={
                  <Checkbox
                    name={option.id}
                    checked={selectedOrderTypes.includes(option.id)}
                    onChange={handleOrderTypeChange}
                  />
                }
                label={option.title}
              />
            ))}
            {selectedOrderTypeError && (
              <Typography variant="body2" color="error">
                {selectedOrderTypeError}
              </Typography>
            )}

            <Grid item xs={12} className={classes.btnContainer}>
              <Button
                disableElevation
                variant="contained"
                style={{
                  borderRadius: 30,
                  backgroundColor: "#7D7D7D",
                  padding: "18px 36px",
                  fontSize: "18px"
                }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/supplier`)
                }}
              >
                <Typography
                  variant="body2"
                  color="secondary"
                  className={classes.textBold}
                >
                  Vazgeç
                </Typography>
              </Button>
              <Box ml={theme.spacing(1)} />
              <Button
                disableElevation
                variant="contained"
                style={{
                  borderRadius: 30,
                  backgroundColor: "#FF6B5E",
                  padding: "18px 36px",
                  fontSize: "18px"
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handleAction();
                }}
              >
                <Typography
                  variant="body2"
                  color="secondary"
                  className={classes.textBold}
                >
                  Kaydet
                </Typography>
              </Button>
            </Grid>

            <Grid item xs={12} className={classes.btnContainer}>
              {mainError && (
                <Typography variant="body2" color="error">
                  {mainError}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SupplierAdded
        isVisible={createdModal}
        toggleModal={closeSupplierAdded}
        action={navigateSupplierCard}
        sendInvitation={sendInvitationLink}
        supplierId={supplierId}
      />
      <Error
        isVisible={errorModalOpen}
        toggleModal={closeErrorModal}
      />
      <NoteAdded
        isVisible={inviteSentModal}
        toggleModal={toggleInviteSentModal}
        title="Davetiniz Gönderilmiştir!"
        detail="Teşekkür ederiz!"
      />
    </Grid>
  );
}

export default AddSupplier;
