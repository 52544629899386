import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import React from "react";
import useStyles from "./styles";
import { formatDateToTurkish, parseOrderTime } from "../../Utils";
import moment from 'moment';

function CreateOrder({ isVisible, toggleModal, action, orderContent, orderDate }) {
  const classes = useStyles();
  return (
    <Dialog
      onClose={toggleModal}
      open={isVisible}
      fullWidth={true}
      maxWidth="xs"
      className={classes.root}
    >
      <DialogTitle>
        <Box component="div">
          <Typography variant="h5" color="textSecondary" className={clsx(classes.boldText, classes.MB2)}>
            {orderDate && formatDateToTurkish(parseOrderTime(orderDate.format('DD-MM-YYYY HH:mm')))} tarihli aşağıdaki siparişi onaylıyor musunuz?
          </Typography>
          <Typography variant="subtitle" className={`${classes.disabledText} ${classes.lightText}`}>
            {orderContent}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={classes.btnBase}
            onClick={async (e) => {
              e.preventDefault();
              await action();
            }}
            style={{
              borderRadius: 30,
              backgroundColor: "#FF6B5E",
              padding: "18px 36px",
              fontSize: "18px"
            }}
          >
            <Typography variant="subtitle2" className={classes.boldText}>
              Sİparİş oluştur!
            </Typography>
          </Button>
          <Button
            variant="outlined"
            fullWidth
            className={classes.btnBase}
            onClick={toggleModal}
            style={{
              borderRadius: 30,
              padding: "18px 36px",
              fontSize: "18px",
              borderColor: "#FF6B5E",
              borderWidth: "2px",
            }}
          >
            <Typography variant="subtitle2" style={{ color: "#FF6B5E" }} className={classes.boldText}>
              Geri dön
            </Typography>
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default React.memo(CreateOrder);
