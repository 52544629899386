import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import useStyles from "./supplier-order-card-styles";
import { formatDateToTurkish, parseOrderTime } from "../../components/Utils";

function SupplierPastOrderCard(props) {
  const { title, orders } = props;

  const classes = useStyles();
  return (
    <Container className={classes.cardContainer}>
      <Typography variant="h5" color="textSecondary" className={`${classes.titleText} ${classes.boldText}`}>
        {title}
      </Typography>
      <Grid container spacing={2}>
        {orders?.map((item, index) => (
          <Grid key={index} item xs={12} md={6}>
            <Paper
              elevation={1}
              square
              className={classes.itemContainer}
              onClick={() => props.onPress({ ...item })}
            >
              {item.status && item.status === 'CANCELED'
                &&
                <Box className={classes.status}>
                  <Typography variant="subtitle2" color="secondary">
                    İPTAL EDİLDİ
                  </Typography>
                </Box>
              }
              <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Box>
                  <Typography variant="body1" color="textSecondary" className={classes.boldText}>
                    Sipariş Numarası: {item.orderId}
                  </Typography>
                  <Typography variant="caption" className={classes.itemDesc}>
                    {formatDateToTurkish(parseOrderTime(item?.orderTime))}
                  </Typography>
                </Box>
                <Typography variant="body2" color="textSecondary" className={classes.orderContent}>
                  {item.orderContent}
                </Typography>
              </Box>
              {item.status && item.status !== 'CANCELED'
                &&
                <Box className={classes.buttonContainer}>
                  <Button
                    variant="contained"
                    className={classes.btnBase}
                    style={{
                      borderRadius: 30,
                      backgroundColor: "#FF6B5E",
                      padding: "14px 28px",
                      fontSize: "14px"
                    }}
                    onClick={() => props.onPress({ ...item })}
                  >
                    Detaylara Git
                  </Button>
                </Box>
              }
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default React.memo(SupplierPastOrderCard);
