import {
  Box,
  Container,
  Paper,
  Typography,
  useTheme,
  TextField
} from "@mui/material";
import React from "react";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function PersonalCard({ personalInfo, handlePersonalInfoChange, businessNameError, personNameError, phoneNumberError }) {
  const theme = useTheme();
  return (
    <Paper
      style={{
        background: theme.palette.common.white,
        borderRadius: "inherit",
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        marginTop: theme.spacing(4),
      }}
    >
      <Container>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex">
            <Box
              style={{
                background: "#FF6B5E",
                width: "32px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography style={theme.typography.body1}><ArrowForwardIcon style={{ paddingTop: 5 }} /></Typography>
            </Box>
            <Box ml={theme.spacing(1)} />
            <Typography variant="h5" color="textSecondary">
              İşletme Bilgileri
            </Typography>
          </Box>
        </Box>
        <Box mt={theme.spacing(4)}>
          <TextField
            fullWidth
            error={!!businessNameError}
            helperText={businessNameError}
            label="İşletme Adı"
            name="businessName"
            value={personalInfo.businessName}
            onChange={handlePersonalInfoChange}
            margin="dense"
            size="small"
          />
          <TextField
            fullWidth
            error={!!phoneNumberError}
            helperText={phoneNumberError}
            label="Telefon Numarası"
            name="phoneNumber"
            value={personalInfo.phoneNumber}
            onChange={handlePersonalInfoChange}
            margin="dense"
            size="small"
          />
          <TextField
            fullWidth
            label="Adres"
            name="address"
            value={personalInfo.address}
            onChange={handlePersonalInfoChange}
            margin="dense"
            size="small"
          />
          <TextField
            fullWidth
            error={!!personNameError}
            helperText={personNameError}
            label="Yetkili Kişi Adı"
            name="personName"
            value={personalInfo.personName}
            onChange={handlePersonalInfoChange}
            margin="dense"
            size="small"
          />
        </Box>
      </Container>
    </Paper>
  );
}

export default React.memo(PersonalCard);
