/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormGroup,
  OutlinedInput,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import HeadingView from "./HeadingView";
import useStyles from "./styles";
import { formatDateToTurkish, parseOrderTime } from "../../Utils";
import { cancelOrder } from "../../../api/order_api";
import { cancelMarketOrder } from "../../../api/market_api";
import Error from "../Error/Error";

function SupplierCancelOrderModal({ isVisible, closeModal, data, onFinish, orderType }) {
  const theme = useTheme();
  const classes = useStyles();
  const extraSmall = useMediaQuery(theme.breakpoints.down('lg'));
  const [cancelReason, setCancelReason] = useState("");
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const handleCancelOrder = async () => {
    const cancelData = {
      orderId: data?.orderId,
      cancelReason: cancelReason
    }
    try {
      const response = orderType && orderType === "SUPPLIER" ? await cancelOrder(cancelData) : await cancelMarketOrder(cancelData);
      if (response !== undefined && response !== null && response.status === 200) {
        setCancelReason("")
        onFinish();
      } else {
        setErrorModalOpen(true);
        setTimeout(() => {
          setErrorModalOpen(false);
        }, 3000);
        return;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setErrorModalOpen(true);
      setTimeout(() => {
        setErrorModalOpen(false);
      }, 3000);
      return;
    }
  };

  const closeErrorModal = () => {
    setErrorModalOpen(false);
  };

  return (
    <>
      <Dialog
        onClose={closeModal}
        open={isVisible}
        fullWidth={true}
        maxWidth="sm"
        className={classes.root}
      >
        <Box mb={theme.spacing(2)} />
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            <Typography className={classes.itemTitle}>
              Sipariş Numarası: {data?.orderId}
            </Typography>
            <Button onClick={closeModal} className={classes.closeContainer}>
              <CloseIcon style={{ fill: "#FF6B5E" }} />
            </Button>
          </Box>
          <Box mb={theme.spacing(2)}>
            <Typography className={classes.priceTitle}>
              {formatDateToTurkish(parseOrderTime(data?.orderTime))}
            </Typography>
          </Box>
          <Box mb={theme.spacing(2)}>
            <Typography className={classes.priceTitle}>
              {data?.orderContent ?? ""}
            </Typography>
          </Box>
          <Divider light orientation="horizontal" />
        </DialogTitle>
        <DialogContent>
          <Box />
          <FormControl style={{ flex: 1, display: "flex" }}>
            <FormGroup>
              <HeadingView title="Siparişi İptal Etmek İstediğinize Emin misiniz?" subTitle="Sebebini tedarikçinize bildirin." />
              <Box mt={theme.spacing(2)} />
              <OutlinedInput
                onChange={(e) => setCancelReason(e.target.value)}
                multiline
                minRows={2}
                maxRows={3}
                value={cancelReason}
                placeholder={"İhtiyaç Değişikliği..."}
                variant="filled"
                color="primary"
                inputProps={{ style: { color: theme.palette.grey[600] }, maxLength: 144 }}
                size="small"
              />
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Box
            style={{ background: "white", width: "100%", height: "75px" }}
            display="flex"
            alignItems="center"
            justifyContent="space-around"
          >
            <Button
              variant="contained"
              fullWidth
              style={{
                width: '50%',
                borderRadius: 30,
                backgroundColor: "#FF6B5E",
                padding: "18px 36px",
                fontSize: "18px"
              }}
              onClick={(e) => {
                e.preventDefault();
                handleCancelOrder();
              }}
            >
              <Typography className={classes.checkoutText}>
                İptal Et
              </Typography>
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Error
        isVisible={errorModalOpen}
        toggleModal={closeErrorModal}
      />
    </>
  );
}
export default React.memo(SupplierCancelOrderModal);
