/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Container,
  Typography,
  useTheme,
} from "@mui/material";
import clsx from "clsx";
import React, { useContext } from "react";
import RestaurantContext from "../../../context/Restaurant";
import CartItem from "./CartItem";
import PricingView from "./PricingView";
import useStyles from "./styles";
import _ from 'lodash';

function CartView(props) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    clearCart,
    cart,
    cartCount,
    addQuantity,
    removeQuantity,
    updateCart,
  } = useContext(RestaurantContext);

  const groupedCartItems = _.groupBy(cart, 'supplier.id');

  return (
    <Box style={{ position: "sticky", top: "100px" }}>
      <Container
        style={{
          maxHeight: "50vh",
          overflow: "scroll",
          background: theme.palette.common.white,
        }}
      >
        {Object.entries(groupedCartItems).map(([supplierId, items]) => (
          <React.Fragment key={supplierId}>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                background: theme.palette.common.white,
                marginTop: '3%'
              }}
            >
              <Typography
                variant="h6"
                color="textSecondary"
                className={clsx(classes.mediumFont, classes.textBold)}
              >
                {`${items[0].supplier?.name ?? "..."} Tedarikçisinden Siparişiniz`}
              </Typography>
            </Box>
            {items.map(item => (
              <CartItem
                key={`ITEM_${item.key}`}
                quantity={item.quantity}
                name={item.name}
                price={item.price}
                unit={item.unit}
                addQuantity={() => {
                  addQuantity(item.key);
                }}
                removeQuantity={() => {
                  removeQuantity(item.key);
                }}
              />
            ))}
          </React.Fragment>
        ))}
      </Container>
      <PricingView
      />
    </Box>
  );
}
export default React.memo(CartView);
