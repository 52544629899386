import axios from 'axios';
import { uri } from './restaurant_api';

export const getCampaign = async (campaignId) => {
    try {
        return await axios.get(`${uri}/campaigns/link/${campaignId}`);
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const createCampaignOrder = async (data) => {
    try {
        return await axios.post(`${uri}/campaigns/order`, data)
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getOrder = async (orderId) => {
    try {
        return await axios.get(`${uri}/campaigns/order?id=${orderId}`);
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const addNewNote = async (data) => {
    try {
        const response = await axios.post(`${uri}/campaigns/addNote`, data);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};