import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  textBold: {
    fontWeight: 700,
  },
  smallText: {
    fontSize: "0.875rem",
    color: theme.palette.text.disabled,
  },
  link: {
    textDecoration: "none",
  },
  statusButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%', 
  },
  statusLabel: {
    minWidth: '150px',
    textAlign: 'left',
  },
  repeatOrderText: {
    border: '1px solid #FF6B5E',
    borderRadius: '4px',
    padding: theme.spacing(0.5, 1),
    fontSize: '0.875rem',
    fontWeight: 700,
  },
  orderNumber: {
    top: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: '#FF6B5E',
    color: 'white',
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    fontWeight: 700,
    fontSize: '0.75rem',
  },
  truncateText: {
    display: '-webkit-box',
    '-webkit-line-clamp': 4,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: `calc(1.2em * 4)`,
  },
  moreText: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

export default useStyles;
