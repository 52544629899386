import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  PV3: {
    padding: theme.spacing(3, 0),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 0),
    },
  },
  contentContainer: {
    padding: theme.spacing(1, 4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2),
    },
  },
  mainContainer: {
    marginTop: "100px",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 1400,
  },
  title: {
    fontWeight: theme.typography.fontWeightLight,
    fontSize: "3rem",
    color: theme.palette.secondary.light,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  textBold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '180px',
    backgroundColor: '#fff',
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
    transition: 'transform 0.3s ease-in-out',
    position: 'relative',
    '&:hover': {
      transform: 'scale(1.05)',
      backgroundColor: '#fff',
    },
  },
  smallCard: {
    height: '120px',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    textAlign: 'center',
  },
  iconTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
  },
  textBold: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'center',
    },
  },
  statusChip: {
    position: 'absolute',
    top: theme.spacing(0),
    right: theme.spacing(0),
    borderRadius: 0,
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.getContrastText(theme.palette.warning.light),
    width: 'auto',
    height: '25px',
    '&.MuiChip-colorSuccess': {
      backgroundColor: theme.palette.success.light,
    },
  },
  cardActionArea: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  smallCardMargin: {
    marginTop: theme.spacing(3),
  },
  raporlarMargin: {
    marginTop: theme.spacing(4),
  },
  whatsappFab: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    color: 'white',
    backgroundColor: '#25D366',
    '&:hover': {
      backgroundColor: '#128C7E',
    },
  },
}));

export default useStyles;
