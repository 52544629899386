import React, { useContext, useState, useEffect } from 'react';
import { Grid, Card, CardContent, Typography, Button, Chip, Divider } from '@mui/material';
import { tr } from 'date-fns/locale';
import { Header } from '../../components/Header';
import useStyles from './styles';
import { mockOrders } from '../../components/Utils';
import { useNavigate } from 'react-router-dom';
import { getWeeklyPlan } from '../../api/restaurant_api';
import RestaurantContext from '../../context/Restaurant';
import { format, addDays, parseISO, getISOWeek } from 'date-fns';

const DayCard = ({ dayName, date, routingDate, status, suppliers }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const getStatusLabelStyle = (status) => {
    switch (status) {
      case 'Planlandı':
        return {
          backgroundColor: '#6FCF97',
          color: 'white',
        };
      case 'Bekleniyor':
        return {
          backgroundColor: '#FF9800',
          color: 'white',
        };
      case 'Tahminlendi':
        return {
          backgroundColor: '#FF6B5E',
          color: 'white',
        };
      default:
        return {
          backgroundColor: 'transparent',
          color: '#333',
        };
    }
  };
  const statusLabelStyle = getStatusLabelStyle(status);

  const handlePlanClick = () => {
    navigate(`/planner/${routingDate}`);
  };

  return (
    <Card sx={{ height: '100%', boxShadow: 3 }}>
      <CardContent sx={{ position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <div>
          <Typography variant="h6">{dayName} - {date}</Typography>
          <Divider sx={{ marginBottom: 2 }} />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
              {suppliers.map(supplier => (
                <Typography variant="body2" key={supplier} className={classes.supplierName}>
                  • {supplier}
                </Typography>
              ))}
            </div>
            <Button variant="contained" className={classes.smallButton} onClick={handlePlanClick}>Planla</Button>
          </div>
        </div>
        <Chip label={status} sx={{ ...statusLabelStyle, position: 'absolute', top: 0, right: 0, borderRadius: 0 }} size="small" />
      </CardContent>
    </Card>
  );
};

function WeeklyPlanner() {
  // const startDate = startOfWeek(new Date(), { weekStartsOn: 1 });
  // const endDate = endOfWeek(new Date(), { weekStartsOn: 1 });
  const startDate = parseISO('2024-02-12');
  const endDate = parseISO('2024-02-19');
  const classes = useStyles();
  const [orders, setOrders] = useState([]);
  const currentWeekNumber = getISOWeek(new Date());
  const { restaurantId } = useContext(RestaurantContext);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await getWeeklyPlan(restaurantId, currentWeekNumber);
        if (response && response.status === 200 && response.data) {
          setOrders(response.data);
        } else {
          setOrders([]);
        }
      } catch (error) {
        console.error('Error fetching weekly plan:', error);
      }
    };

    fetchOrders();
  }, [currentWeekNumber]);
  
  const findSuppliersForDate = (date) => {
    const order = mockOrders.find(order => order.date === date);
    return order ? order.suppliers.map(supplier => supplier.name) : [];
  };

  const getStatusLabel = (formattedDate) => {
    const isDateInOrders = mockOrders.some(order => order.date === formattedDate);
    if (isDateInOrders) return "Tahminlendi";
    return "Bekleniyor";
  };

  const weekRange = `${format(startDate, 'd MMMM', { locale: tr })} - ${format(endDate, 'd MMMM', { locale: tr })}`;

  return (
    <Grid container className={classes.root}>
      <Header />
      <Grid container item className={classes.marginHeader}>
        <Grid item xs={12} className={classes.mainContainer}>
          <Header />
          <Typography variant="h5" align="center" sx={{ marginY: 3 }}>Haftalık Planlayıcı - {weekRange}</Typography>
          <Grid container spacing={3} sx={{ padding: 2 }}>
            {[...Array(7).keys()].map(i => {
              const dayName = format(addDays(startDate, i), 'EEEE', { locale: tr });
              const formattedDate = format(addDays(startDate, i), 'yyyy-MM-dd');
              const displayDate = format(addDays(startDate, i), 'd MMMM', { locale: tr });
              const status = getStatusLabel(formattedDate);
              const suppliers = findSuppliersForDate(formattedDate);

              return (
                <Grid item xs={12} sm={6} md={4} lg={4} key={i}>
                  <DayCard dayName={dayName} date={displayDate} routingDate={formattedDate} status={status} suppliers={suppliers} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default WeeklyPlanner;
