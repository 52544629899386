import axios from 'axios';
import { uri } from './restaurant_api';

export const getAllOrders = async (id) => {
    try {
        const response = await axios.get(`${uri}/restaurants/allOrders?restaurantId=${id}`);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getOrder = async (orderId, orderType) => {
    try {
        const response = await axios.get(`${uri}/restaurants/order?id=${orderId}&type=${orderType}`);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const createOrder = async (data) => {
    try {
        const response = await axios.post(`${uri}/restaurants/order`, data);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const cancelOrder = async (data) => {
    try {
        const response = await axios.post(`${uri}/restaurants/cancel`, data);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const addNewNote = async (data) => {
    try {
        const response = await axios.post(`${uri}/restaurants/addNote`, data);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const addReview = async (data) => {
    try {
        const response = await axios.post(`${uri}/restaurants/review`, data);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const addMarketOrderReview = async (data) => {
    try {
        const response = await axios.post(`${uri}/markets/review`, data);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};