import { Box, Divider } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import CartIcon from "../../../assets/icons/CartIcon";
import ProfileIcon from "../../../assets/icons/ProfileIcon";
import useStyle from "./styles";
import { ReactComponent as Logo } from "../../../assets/images/solo_logo.svg";
import { useTheme } from "@emotion/react";

function LoginDesktopHeader({ title, showIcon, showCart = false }) {
  const classes = useStyle();
  const theme = useTheme();
  const location = useLocation();

  return (
    <AppBar elevation={0} position="fixed">
      <Toolbar className={classes.toolbar}>
        <RouterLink to={"/"} className={classes.linkDecoration}>
          <Logo height={80} width={80} />
        </RouterLink>
        <Box className={classes.flex}>
          {showIcon && (
            <>
              <Divider flexItem orientation="vertical" light />
              <RouterLink to={"/login"} className={classes.linkDecoration}>
                <Button aria-controls="simple-menu" aria-haspopup="true">
                  <ProfileIcon />
                  <Typography
                    variant="button"
                    color="white"
                    className={`${classes.ml} ${classes.font700} ${classes.mobileText}`}
                  >
                    {"İŞLETME GİRİŞİ"}
                  </Typography>
                </Button>
              </RouterLink>
              <Divider flexItem orientation="vertical" light />
            </>
          )}
          {showIcon && (
            <>
              <a
                href="https://tedarikci.tedabir.com/#/auth/welcome"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.supplierButton}
              >
                <Button aria-controls="simple-menu" aria-haspopup="true">
                  <Typography
                    variant="button"
                    color="white"
                    className={`${classes.ml} ${classes.font700} ${classes.mobileText}`}
                  >
                    {"TEDARİKÇİ GİRİŞİ"}
                  </Typography>
                </Button>
              </a>
              <Divider flexItem orientation="vertical" light />
            </>
          )}
          {showCart && (
            <Box style={{ alignSelf: "center" }}>
              <RouterLink to="/" className={classes.linkDecoration}>
                <Button>
                  <CartIcon />
                </Button>
              </RouterLink>
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default React.memo(LoginDesktopHeader);
