export const Pictures = {
  'Temel Gıda': {
    source: require('../assets/new-images/pasta.png'),
  },
  'Süt ve Kahvaltılık': {
    source: require('../assets/new-images/milk.png'),
  },
  'Sebze ve Meyve': {
    source: require('../assets/new-images/vegetables.png'),
  },
  'İçecekler': {
    source: require('../assets/new-images/drinks.png'),
  },
  'Et, Tavuk ve Şarküteri': {
    source: require('../assets/new-images/meat.png'),
  },
  'Dondurulmuş Ürünler': {
    source: require('../assets/new-images/frozen.png'),
  },
  'Deniz Ürünleri': {
    source: require('../assets/new-images/seafood.png'),
  },
  'Pastacılık Ürünleri': {
    source: require('../assets/new-images/pastry.png'),
  },
  'Unlu Mamuller': {
    source: require('../assets/new-images/baked.png'),
  },
  'Organik Ürünler': {
    source: require('../assets/new-images/organic.png'),
  },
  'Vegan Ürünler': {
    source: require('../assets/new-images/vegan.png'),
  },
  'Glutensiz Ürünler': {
    source: require('../assets/new-images/gluten.png'),
  },
  'Temizlik Ürünleri': {
    source: require('../assets/new-images/cleaning.png'),
  },
  'Ambalaj Malzemeleri': {
    source: require('../assets/new-images/packaging.png'),
  },
  'Mutfak Araç Gereç ve Dekorasyon': {
    source: require('../assets/new-images/utensils.png'),
  },
  'Genel': {
    source: require('../assets/new-images/general.png'),
  }
}

export const Icons = {
  VEGETABLES: {
    source: require('../assets/categories/icons/vegetable.png'),
  },
  COFFEE: {
    source: require('../assets/categories/icons/coffee.png'),
  },
  DRINKS: {
    source: require('../assets/categories/icons/drinks.png'),
  },
  FISH: {
    source: require('../assets/categories/icons/fish.png'),
  },
  BAKERY: {
    source: require('../assets/categories/icons/baked.png'),
  },
  MEAT: {
    source: require('../assets/categories/icons/meat.png'),
  },
  DAIRY: {
    source: require('../assets/categories/icons/dairy.png'),
  },
  PASTA_AND_GRAINS: {
    source: require('../assets/categories/icons/pasta.png'),
  },
  KITCHEN_UTENSILS: {
    source: require('../assets/categories/icons/kitchen.png'),
  },
  CLEANING_AND_PACKAGING: {
    source: require('../assets/categories/icons/cleaning.png'),
  },
  SPICES_AND_SAUCES: {
    source: require('../assets/categories/icons/spices.png'),
  },
  FLOWERS: {
    source: require('../assets/categories/icons/flowers.png'),
  }
}

export const categories = [
  {
    id: "VEGETABLES",
    title: "Sebze-Meyve"
  },
  {
    id: "FROZEN_VEGETABLES",
    title: "Dondurulmuş Sebze-Meyve"
  },
  {
    id: "MEAT",
    title: "Et-Tavuk"
  },
  {
    id: "COFFEE",
    title: "Kahve"
  },
  {
    id: "DRINKS",
    title: "İçecek"
  },
  {
    id: "BAKERY",
    title: "Fırın Ürünleri"
  },
  {
    id: "FROZEN_BAKERY",
    title: "Dondurulmuş Fırın Ürünleri"
  },
  {
    id: "FISH",
    title: "Balık"
  },
  {
    id: "DAIRY",
    title: "Süt Ürünleri"
  },
  {
    id: "PASTA_AND_GRAINS",
    title: "Makarna ve Tahıllar"
  },
  {
    id: "KITCHEN_UTENSILS",
    title: "Mutfak Araç Gereçleri"
  },
  {
    id: "CLEANING_AND_PACKAGING",
    title: "Temizlik ve Ambalaj Malzemeleri"
  },
  {
    id: "SPICES_AND_SAUCES",
    title: "Baharatlar ve Soslar"
  },
  {
    id: "FLOWERS",
    title: "Çiçek"
  }
];

export const categorisMap = new Map();

categories.forEach(category => {
  categorisMap.set(category.id, category.title);
});

export const statusTranslations = {
  NEW: "YENİ",
  IN_PLANNING: "PLANLANIYOR",
  APPROVAL_WAITING: "ONAY BEKLİYOR",
  ACCEPTED_BY_SUPPLIER: "KABUL EDİLDİ",
  REJECTED_BY_SUPPLIER: "REDDEDİLDİ",
  IN_PROGRESS: "DEVAM EDİYOR",
  COMPLETED: "TAMAMLANDI",
  CANCELED: "İPTAL EDİLDİ"
};
export function parseOrderTime(orderTime) {
  if (!orderTime) {
    return null;
  }

  const parts = orderTime.split(' ');
  // Check if both date and time parts are present
  if (parts.length !== 2) {
    return null; // Return null instead of throwing an error
  }

  const [datePart, timePart] = parts;
  const dateParts = datePart.split('-');
  const timeParts = timePart.split(':');

  // Check if all parts of the date and time are present
  if (dateParts.length !== 3 || timeParts.length < 2) {
    return null; // Return null for incomplete input
  }

  const [day, month, year] = dateParts;
  const [hour, minute] = timeParts;

  // Check for completeness of each part
  if (!day || !month || !year || !hour || !minute) {
    return null; // Return null for incomplete input
  }

  return new Date(year, month - 1, day, hour, minute);
}


export function formatDateToTurkish(date) {
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    localeMatcher: 'best fit'
  };

  return new Intl.DateTimeFormat('tr-TR', options).format(date);
}

export const TURKISH_DAYS = {
  "SUN": "PAZAR",
  "MON": "PAZARTESİ",
  "TUE": "SALI",
  "WED": "ÇARŞAMBA",
  "THU": "PERŞEMBE",
  "FRI": "CUMA",
  "SAT": "CUMARTESİ"
};

export const orderTypes = [
  {
    id: "SMS",
    title: "SMS aracılığıyla"
  },
  {
    id: "WHATSAPP",
    title: "WhatsApp aracılığıyla"
  },
  {
    id: "EMAIL",
    title: "Mail aracılığıyla"
  },
  {
    id: "PHONE_CALL",
    title: "Arayarak"
  },
]

export const orderTypesMap = new Map();

orderTypes.forEach(oT => {
  orderTypesMap.set(oT.id, oT.title);
});

export const validatePhoneNumber = (phoneNumber) => {
  const phoneRegex = /^5\d{9}$/;
  return phoneRegex.test(phoneNumber);
};

export const mockData = {
  userName: "Kullanıcı",
  ordersData: [
    { date: 'Pzt', suppliers: [{ name: 'Sebzeci Fatma', items: ["3 KG Patates", "Limon 5 kilo"] }, { name: 'Kasap Ali', items: ["Ürün 3", "Ürün 4"] }] },
    { date: 'Sa', suppliers: [{ name: 'Sebzeci Fatma', items: ["Kabak 5 adet", "Lolorosso 4 adet"] }, { name: 'Ahmet Kahve', items: ["Ürün 6", "Ürün 7"] }] },
    { date: 'Çrş', suppliers: [] },
    { date: 'Prş', suppliers: [{ name: 'Deniz Balikci', items: ["Kabak 5 adet", "Lolorosso 4 adet"] }] },
    { date: 'Cu', suppliers: [] },
    { date: 'Cts', suppliers: [{ name: 'Sebzeci Fatma', items: ["Kabak 5 adet", "Lolorosso 4 adet"] }, { name: 'Ahmet Kahve', items: ["Ürün 6", "Ürün 7"] }] },
    { date: 'Pa', suppliers: [] },
  ],
  totalAmount: 15000,
  newSuppliers: ['Tedarikçi A', 'Tedarikçi B'],
  detailedOrders: [
    { supplier: "Tedarikçi A", amount: 5000, items: ["Ürün 1", "Ürün 2"] },
    { supplier: "Tedarikçi B", amount: 3000, items: ["Ürün 3", "Ürün 4"] },
  ],
};

export const mockOrders = [
  {
    date: "2024-02-12",
    suppliers: [
      {
        name: "Et Tedarikçim",
        products: [
          { name: "Tavuk", amount: 100, unit: "KG", status: "Tahminlendi" },
          { name: "Dana Et", amount: 200, unit: "KG", status: "Tahminlendi" },
          { name: "Ciğer", amount: 150, unit: "KG", status: "Tahminlendi" }
        ]
      },
      {
        name: "Sebzecim",
        products: [
          { name: "Domates", amount: 55, unit: "KG", status: "Tahminlendi" },
          { name: "Maydonoz", amount: 40, unit: "Adet", status: "Tahminlendi" },
          { name: "Marul", amount: 50, unit: "Adet", status: "Tahminlendi" }
        ]
      }
    ]
  },
  {
    date: "2024-02-13",
    suppliers: [
      {
        name: "Et Tedarikçim",
        products: [
          { name: "Tavuk", amount: 100, unit: "KG", status: "Tahminlendi" },
          { name: "Dana Et", amount: 200, unit: "KG", status: "Tahminlendi" },
          { name: "Ciğer", amount: 150, unit: "KG", status: "Tahminlendi" }
        ]
      },
      {
        name: "Eve İçecekler",
        products: [
          { name: "Tavuk", amount: 100, unit: "KG", status: "Tahminlendi" },
          { name: "Dana Et", amount: 200, unit: "KG", status: "Tahminlendi" },
          { name: "Ciğer", amount: 150, unit: "KG", status: "Tahminlendi" }
        ]
      },
      {
        name: "Antep Tatlıcım",
        products: [
          { name: "Tavuk", amount: 100, unit: "KG", status: "Tahminlendi" },
          { name: "Dana Et", amount: 200, unit: "KG", status: "Tahminlendi" },
          { name: "Ciğer", amount: 150, unit: "KG", status: "Tahminlendi" }
        ]
      }
    ]
  },
  {
    date: "2024-02-15",
    suppliers: [
      {
        name: "Et Tedarikçim",
        products: [
          { name: "Tavuk", amount: 100, unit: "KG", status: "Tahminlendi" },
          { name: "Dana Et", amount: 200, unit: "KG", status: "Tahminlendi" },
          { name: "Ciğer", amount: 150, unit: "KG", status: "Tahminlendi" }
        ]
      },
      {
        name: "Sebzecim",
        products: [
          { name: "Domates", amount: 55, unit: "KG", status: "Tahminlendi" },
          { name: "Maydonoz", amount: 40, unit: "Adet", status: "Tahminlendi" },
          { name: "Marul", amount: 50, unit: "Adet", status: "Tahminlendi" }
        ]
      },
      {
        name: "Sos dünyası",
        products: [
          { name: "Domates", amount: 55, unit: "KG", status: "Tahminlendi" },
          { name: "Maydonoz", amount: 40, unit: "Adet", status: "Tahminlendi" },
          { name: "Marul", amount: 50, unit: "Adet", status: "Tahminlendi" }
        ]
      }
    ]
  },
  {
    date: "2024-02-17",
    suppliers: [
      {
        name: "Sebzecim",
        products: [
          { name: "Domates", amount: 55, unit: "KG", status: "Tahminlendi" },
          { name: "Maydonoz", amount: 40, unit: "Adet", status: "Tahminlendi" },
          { name: "Marul", amount: 50, unit: "Adet", status: "Tahminlendi" }
        ]
      },
      {
        name: "Eve İçecekler",
        products: [
          { name: "Domates", amount: 55, unit: "KG", status: "Tahminlendi" },
          { name: "Maydonoz", amount: 40, unit: "Adet", status: "Tahminlendi" },
          { name: "Marul", amount: 50, unit: "Adet", status: "Tahminlendi" }
        ]
      }
    ]
  },
  {
    date: "2024-02-18",
    suppliers: [
      {
        name: "Et Tedarikçim",
        products: [
          { name: "Tavuk", amount: 100, unit: "KG", status: "Tahminlendi" },
          { name: "Dana Et", amount: 200, unit: "KG", status: "Tahminlendi" },
          { name: "Ciğer", amount: 150, unit: "KG", status: "Tahminlendi" }
        ]
      },
    ]
  }
];
