import { Box, CircularProgress } from "@mui/material";
import { useJsApiLoader } from "@react-google-maps/api";
import React, { useEffect, useState, useContext } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { getToken, onMessage } from "firebase/messaging";
import { initialize, isFirebaseSupported } from "./firebase";
import { GOOGLE_MAPS_KEY, LIBRARIES } from "./config/constants";
import Checkout from "./screens/Checkout/Checkout";
import Suppliers from "./screens/Suppliers/Suppliers";
import Home from "./screens/Home/Home";
import Login from "./screens/Login/Login";
import MyOrders from "./screens/MyOrders/MyOrders";
import NewLogin from "./screens/NewLogin/NewLogin";
import OrderDetail from "./screens/OrderDetail/OrderDetail";
import Profile from "./screens/Profile/Profile";
import Registration from "./screens/Registration/Registration";
import SupplierDetail from "./screens/SupplierDetail/SupplierDetail";
import FlashMessage from "./components/FlashMessage";
import * as Sentry from "@sentry/react";
import AuthRoute from "./routes/AuthRoute";
import PrivateRoute from "./routes/PrivateRoute";
import AddSupplier from "./screens/Suppliers/AddSupplier";
import MarketSuppliers from "./screens/MarketSuppliers/MarketSuppliers";
import MarketSupplierDetail from "./screens/MarketSupplierDetail/MarketSupplierDetail";
import SelectAddress from "./screens/SelectAddress/SelectAddress";
import { setToken } from "./api/restaurant_api";
import RestaurantContext from "./context/Restaurant";
import Onboard from "./screens/Onboard/Onboard";
import AddShoppingList from "./screens/ShoppingLists/AddShoppingList";
import ShoppingLists from "./screens/ShoppingLists/ShoppingLists";
import FeedbackScreen from "./screens/Feedback/Feedback";
import Help from "./components/HomeScreen/StoreContainer/Help";
import PredictedOrders from "./screens/PredictedOrders/PredictedOrders";
import WeeklyReport from "./screens/WeeklyReport/WeeklyReport";
import WeeklyPlanner from "./screens/WeeklyPlanner/WeeklyPlanner";
import Aggrement from "./components/HomeScreen/StoreContainer/Aggrement";
import Catalog from "./components/Catalog/Catalog";
import GuestCheckout from "./screens/Checkout/GuestCheckout";
import OrderHistory from "./components/OrderHistory/OrderHistory";
import Campaign from "./components/Campaign/Campaign";
import CampaignOrderHistory from "./components/CampaignOrderHistory/CampaignOrderHistory";

function App() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_KEY,
    libraries: LIBRARIES,
  });
  const { profile, markNotificationAsRead, fetchNotifications } = useContext(RestaurantContext);
  const [notification, setNotification] = useState({ message: null, id: null });

  const handleToken = async (token) => {
    try {
      const data = {
        userId: profile.id,
        pushToken: token,
      }
      const response = await setToken(data);
    } catch (error) {
      console.error('Error setting token:', error);
    }
  };

  useEffect(() => {
    const msgToken = localStorage.getItem('messaging-token')

    const initializeFirebase = async () => {
      if (await isFirebaseSupported()) {
        const messaging = initialize();
        if (profile.id && !msgToken) {
          Notification.requestPermission()
            .then(() => {
              getToken(messaging, {
                vapidKey:
                  "BC93S49fedWl_IBWhd42su4AigFnEdvqsABF9T6HoiRh7gYRPoJxQSJ2LsfKXy7j8mlyNnej6E_RYYwD5durCdg"
              })
                .then((token) => {
                  localStorage.setItem("messaging-token", token);
                  handleToken(token);
                })
                .catch((err) => {
                  console.log("getToken error", err);
                });
            })
            .catch(console.log);
        }

        onMessage(messaging, (payload) => {
          const { title, body } = payload.notification;
          const notificationId = payload.data.notificationId;

          if (body !== undefined) {
            setNotification({ message: `${title} ${body}`, id: notificationId });
          } else {
            setNotification({ message: `${title}`, id: notificationId });
          }
        });
      }
    };
    initializeFirebase();
  }, [profile.id]);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const handleClose = () => {
    if (notification.id) {
      markNotificationAsRead(notification.id)
        .catch(console.error);
    }
    setNotification({ message: null, id: null });
  };

  return !isLoaded ? (
    <Box
      component="div"
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      width="100vw"
      padding="5px"
    >
      <CircularProgress color="primary" />
    </Box>
  ) : (
    <BrowserRouter>
      <FlashMessage
        severity={"info"}
        alertMessage={notification.message}
        open={notification.message !== null}
        handleClose={handleClose}
      />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/help" element={<Help />} />
        <Route path="/guestCheckout" element={<GuestCheckout />} />
        <Route path="/catalog/:catalogId" element={<Catalog />} />
        <Route path="/campaign/:campaignId" element={<Campaign />} />
        <Route path="/order/:id" element={<OrderHistory />} />
        <Route path="/campaignOrder/:id" element={<CampaignOrderHistory />} />
        <Route path="/sozlesme" element={<Aggrement />} />
        <Route
          path={"/supplier"}
          element={
            <PrivateRoute>
              <Suppliers />
            </PrivateRoute>
          }
        />
        <Route
          path={"/supplier/:id"}
          element={
            <PrivateRoute>
              <SupplierDetail />
            </PrivateRoute>
          }
        />
        <Route
          path={"/market"}
          element={
            <PrivateRoute>
              <MarketSuppliers />
            </PrivateRoute>
          }
        />
        <Route
          path={"/market/:id"}
          element={
            <PrivateRoute>
              <MarketSupplierDetail />
            </PrivateRoute>
          }
        />
        <Route
          path={"/newSupplier"}
          element={
            <PrivateRoute>
              <AddSupplier />
            </PrivateRoute>
          }
        />
        <Route
          path={"/shopList"}
          element={
            <PrivateRoute>
              <ShoppingLists />
            </PrivateRoute>
          }
        />
        <Route
          path="/shopList/new"
          element={
            <PrivateRoute>
              <AddShoppingList />
            </PrivateRoute>
          }
        />
        <Route
          path="/shopList/:id"
          element={
            <PrivateRoute>
              <AddShoppingList />
            </PrivateRoute>
          }
        />
        <Route
          path="/planner/:date"
          element={
            <PrivateRoute>
              <PredictedOrders />
            </PrivateRoute>
          }
        />
        <Route
          path="/weekly"
          element={
            <PrivateRoute>
              <WeeklyReport />
            </PrivateRoute>
          }
        />
        <Route
          path="/planner"
          element={
            <PrivateRoute>
              <WeeklyPlanner />
            </PrivateRoute>
          }
        />
        <Route
          path={"/feedback"}
          element={
            <PrivateRoute>
              <FeedbackScreen />
            </PrivateRoute>
          }
        />
        <Route
          path={"/orders"}
          element={
            <PrivateRoute>
              <MyOrders />
            </PrivateRoute>
          }
        />
        <Route
          path={"/order-detail/:id"}
          element={
            <PrivateRoute>
              <OrderDetail />
            </PrivateRoute>
          }
        />
        <Route
          path={"/profile"}
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path={"/onboard"}
          element={
            <PrivateRoute>
              <Onboard />
            </PrivateRoute>
          }
        />
        <Route
          path={"/checkout"}
          element={
            <PrivateRoute>
              <Checkout />
            </PrivateRoute>
          }
        />
        <Route path={"/select-address"}
          element={
            <PrivateRoute>
              <SelectAddress />
            </PrivateRoute>
          } />
        <Route
          path={"/login"}
          element={
            <AuthRoute>
              <Login />
            </AuthRoute>
          }
        />
        <Route
          path={"/register"}
          element={
            <AuthRoute>
              <Registration />
            </AuthRoute>
          }
        />
        <Route
          path={"/new-login"}
          element={
            <AuthRoute>
              <NewLogin />
            </AuthRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default Sentry.withProfiler(App);
