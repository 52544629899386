/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Typography, Card as ICard, CardActionArea } from "@mui/material";
import clsx from "clsx";
import React, { useCallback, useEffect, useRef, useState, useContext } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import GroupsIcon from '@mui/icons-material/Groups';
import FlashMessage from "../../components/FlashMessage";
import { Header } from "../../components/Header";
import useStyles from "./styles";
import AddIcon from '@mui/icons-material/Add';
import { getSuppliers } from "../../api/restaurant_api";
import SupplierCard from "../../components/SupplierComponent/SupplierCard/SupplierCard";
import RestaurantContext from "../../context/Restaurant";

function EmptyView() {
  const classes = useStyles();
  return (
    <Grid item xs={12} className={classes.mt2}>
      <Box className={classes.mt2} display="flex" justifyContent="center">
        <Typography variant="h6" className={classes.textBold}>
          Henüz tedarikçi eklemediniz.
        </Typography>
      </Box>
      <Box className={classes.mt2} display="flex" justifyContent="center">
        <Typography variant="caption" className={clsx(classes.disableText, classes.smallText)}>
          Daha hızlı sipariş vermek için, tüm tedarikçileriniz burada!
        </Typography>
      </Box>
      <Box className={classes.mt2} display="flex" justifyContent="center">
        <Box className={classes.heartBG}>
          <GroupsIcon
            sx={{
              color: "#FF6B5E",
              fontSize: 27,
            }}
          />
        </Box>
      </Box>
      <Box className={classes.mt2} display="flex" justifyContent="center">
        <RouterLink to="/newSupplier" style={{ textDecoration: "none" }}>
          <Button
            variant="outlined"
            style={{
              borderRadius: 30,
              backgroundColor: "#FF6B5E",
              padding: "18px 36px",
              fontSize: "18px"
            }}
            size="large"
            disableElevation
            disableRipple
            disableFocusRipple
            disableTouchRipple
            className={`${classes.btnBase} add-supplier-button`}
          >
            <Typography variant="caption" color="secondary" className={clsx(classes.textBold, classes.smallText)}>
              Şİmdİ tedarİkçİ ekle!
            </Typography>
          </Button>
        </RouterLink>
      </Box>
    </Grid>
  );
}

function Suppliers() {
  const navigate = useNavigate();
  const firstTime = useRef(true);
  const classes = useStyles();
  const [mainError, setMainError] = useState({});
  const [navigateData, setNavigateData] = useState({});
  const [data, setData] = useState([]);
  const { profile } = useContext(RestaurantContext);

  const navigateAddSupplier = useCallback(async () => {
    navigate(`/newSupplier`,
      { state: navigateData },
    );
  }, [navigateData]);

  useEffect(() => {
    if (!firstTime.current) {
      firstTime.current = true;
    }
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getSuppliers(profile.id);
      if (response && response.status === 200 && response.data) {
        setData(response.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const showMessage = useCallback((messageObj) => {
    setMainError(messageObj);
  }, []);

  const toggleSnackbar = useCallback(() => {
    setMainError({});
  }, []);

  return (
    <Grid container >
      <FlashMessage
        open={Boolean(mainError.type)}
        severity={mainError.type}
        alertMessage={mainError.message}
        handleClose={toggleSnackbar}
      />
      <Header />
      <Grid container item className={classes.mainContainer}>
        <Grid item xs={12} sm={10} className={classes.contentContainer}>
          <Typography variant="h4" className={classes.title}>
            Tedarikçilerim
          </Typography>
          <Grid container spacing={2} className={classes.PV3}>
            {data.length < 1 ? (
              <EmptyView />
            ) : (
              <>
                {data.map((item) => (
                  <Grid key={item.id} item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <SupplierCard data={item} grid={true} cardImageHeight="200px" showMessage={showMessage} />
                  </Grid>
                ))}
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <ICard className={classes.card}>
                    <CardActionArea
                      onClick={(e) => {
                        e.preventDefault();
                        navigateAddSupplier();
                      }}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '20px',
                        backgroundColor: '#f7f7f7',
                        height: '200px'
                      }}
                    >
                      <AddIcon style={{ fontSize: '48px', color: "#FF6B5E" }} />
                      <Typography
                        variant="subtitle1"
                        color="textSecondary"
                        className={classes.textBold}
                        style={{
                          textAlign: 'center',
                          marginTop: '10px',
                        }}
                      >
                        Yeni Tedarikçi Ekle
                      </Typography>
                    </CardActionArea>
                  </ICard>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item sm={1} />
      </Grid>
    </Grid>
  );
}

export default Suppliers;
