// Import the functions you need from the SDKs you need
import * as firebase from "firebase/app";
import { getMessaging, isSupported } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
   apiKey: "AIzaSyCGAYmaStIEAEVmhqoB01tLYxnc5WY4aOk",
   authDomain: "tedabir.firebaseapp.com",
   projectId: "tedabir",
   storageBucket: "tedabir.appspot.com",
   messagingSenderId: "508844715177",
   appId: "1:508844715177:web:6772dfa7ac60aaf0bc4db8",
   measurementId: "G-M81NKKR1H3"
};

export const initialize = () => {
   // Initialize Firebase
   const app = firebase.initializeApp(firebaseConfig);
   const messaging = getMessaging(app);
   return messaging;
};

export const isFirebaseSupported = async () => {
   return await isSupported();
};
