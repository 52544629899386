import { Box, Container } from "@mui/material";
import React, { useEffect, useContext } from "react";
import useStyles from "./styles";
import { SearchContainer } from "../../components/HomeScreen";
import { LoginHeader } from "../../components/Header";
import RestaurantContext from "../../context/Restaurant";

function SelectAddress() {
  const { setJustRegistered } = useContext(RestaurantContext);

  useEffect(() => {
    setJustRegistered(false);
  }, [setJustRegistered]);

  const classes = useStyles();
  return (
    <Box className={classes.pageContainer}>
      <LoginHeader />
      <Box className={classes.mainContainer}>
        <Container maxWidth="xs" className={classes.loginBox}>
          <SearchContainer />
        </Container>
      </Box>
    </Box>
  );
}

export default SelectAddress;
