import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import RestaurantContext from "../context/Restaurant";

function AuthRoute({ children }) {
  const { isLoggedIn, justRegistered } = useContext(RestaurantContext);
  const location = useLocation();
  if (isLoggedIn){
    if (isLoggedIn && justRegistered) {
      return <Navigate replace to="/select-address" />;
    } 
    return (
      <Navigate
        replace
        to={{
          pathname: location.state?.from ? location.state.from : "/",
        }}
      />
    );
  }
  return children;
}

export default AuthRoute;
