import { Box, List, InputAdornment, ListItem, ListItemText, DialogActions, DialogTitle, Dialog, DialogContent, Button, Card, Grid, CardContent, Typography, IconButton, TextField } from "@mui/material";
import React, { useState } from "react";
import useStyles from "./styles";
import { Pictures } from "../../Utils";
import { ShoppingCart, AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

function ProductCard(props) {
  const defaultSource = Pictures[props?.title]?.source?.default ? Pictures[props?.title]?.source?.default : Pictures['Genel'].source.default;
  const [quantities, setQuantities] = useState({});

  const [productDetailOpen, setProductDetailOpen] = useState(false);
  const [productDetailQuantity, setProductDetailQuantity] = useState(1);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setProductDetailOpen(true);
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenVariablePrices = (product) => {
    setSelectedProduct(product);
    setOpenDialog(true);
  };

  const incrementQuantity = (productId) => {
    setQuantities(currentQuantities => ({
      ...currentQuantities,
      [productId]: (currentQuantities[productId] || 0) + 1
    }));
  };

  const decrementQuantity = (productId) => {
    setQuantities(currentQuantities => ({
      ...currentQuantities,
      [productId]: Math.max(0, (currentQuantities[productId] || 0) - 1)
    }));
  };

  const groupedBySubCategory = props.products?.items?.reduce((acc, item) => {
    if (!acc[item.subCategory]) {
      acc[item.subCategory] = [];
    }
    acc[item.subCategory].push(item);
    return acc;
  }, {});

  const classes = useStyles();
  return (
    <Box className={classes.cardContainer}>
      <React.Fragment>
        <Typography variant="h5" className={classes.categoryMainHeader}>
          {props.title}
        </Typography>
        {Object.keys(groupedBySubCategory).map((subCategory, index) => (
          <React.Fragment key={index}>
            <Typography variant="h6" className={classes.categorySubHeader}>
              {subCategory}
            </Typography>
            <Grid container spacing={3} style={{ maxWidth: '100%' }}>
              {groupedBySubCategory[subCategory].map((item, itemIndex) => (
                <Grid key={itemIndex} xs={6} sm={4} md={3} sx={{ paddingLeft: '16px', paddingTop: '24px' }}>
                  <Card
                    className={`${classes.productCard} ${classes.clickableCard}`}
                    onClick={() => handleProductClick(item)}
                  >
                    <CardContent className={classes.cardContentGrow}>
                      <div className={classes.contentContainer}>
                        <img
                          src={item.imageData ? `data:image/jpeg;base64,${item.imageData}` : defaultSource}
                          className={classes.productImage}
                        />
                        <div className={classes.textContainer}>
                          {item.brand && (
                            <Typography component="span" variant="body2" className={classes.brand}>
                              {item.brand}
                            </Typography>
                          )}
                          <Typography variant="caption" className={classes.itemDesc}>
                            {item.shortDescription ? item.shortDescription : item.description}
                          </Typography>
                        </div>
                      </div>
                      <div className={classes.priceContainer}>
                        <Typography gutterBottom variant="subtitle1" className={classes.productName}>
                          {item.name}
                        </Typography>
                        {
                          {
                            'none': <Typography variant="body2" className={classes.priceText} marginTop='4px'>Fiyat belirtilmemiş</Typography>,
                            'fixed': <Typography variant="body2" className={classes.priceText}>Fiyat: ₺{item.fixedPrice}</Typography>,
                            'variable': item.variablePrices && item.variablePrices.length > 0 && (
                              <Button style={{ marginLeft: '-8px', marginTop: '-8px', marginBottom: '-4px' }} color="primary" onClick={(e) => { e.stopPropagation(); handleOpenVariablePrices(item); }}>
                                Fİyat Lİstesİ
                              </Button>
                            ),
                          }[item.priceStrategy || 'none']
                        }
                      </div>
                    </CardContent>
                    <div className={classes.actionsWrapper}>
                      <IconButton size="small" onClick={(e) => {
                        e.stopPropagation();
                        decrementQuantity(item.productId);
                      }} className={classes.quantityControlButton}>
                        <RemoveCircleOutline />
                      </IconButton>
                      <TextField
                        className={classes.quantityInput}
                        label={item.unit}
                        type="number"
                        size="small"
                        variant="outlined"
                        value={quantities[item.productId] || ''}
                        onChange={(e) => {
                          e.stopPropagation();
                          const val = e.target.value;
                          setQuantities(current => ({
                            ...current,
                            [item.productId]: val === '' ? '' : Math.max(0, parseInt(val, 10))
                          }));
                        }}
                        InputProps={{
                          inputProps: { min: 0, style: { fontSize: '0.75rem' } },
                        }}
                        InputLabelProps={{
                          style: { fontSize: '0.75rem' },
                        }}
                        onClick={(e) => e.stopPropagation()}
                        disabled={!item.isAvailable}
                      />
                      <IconButton
                        size="small"
                        className={classes.quantityControlButton}
                        onClick={(e) => {
                          e.stopPropagation();
                          incrementQuantity(item.productId);
                        }}
                        disabled={!item.isAvailable}
                      >
                        <AddCircleOutline />
                      </IconButton>
                      <Button
                        variant="outlined"
                        className={classes.addToCartButton}
                        startIcon={<ShoppingCart />}
                        disabled={!item.isAvailable}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (quantities[item.productId] > 0) {
                            const {
                              brand,
                              code,
                              imageData,
                              description,
                              shortDescription,
                              category,
                              isAvailable,
                              ...cartItem
                            } = item;
                            cartItem.quantity = quantities[item.productId];
                            cartItem.key = item.productId;
                            props.onPress(cartItem);
                          }
                        }}
                      />
                    </div>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </React.Fragment>
        ))}
      </React.Fragment>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Değişken Fiyat Listesi</DialogTitle>
        <DialogContent>
          <List>
            {selectedProduct?.variablePrices.map((price, index) => (
              <ListItem key={index}>
                <ListItemText primary={`₺${price.price} / ${price.minAmount}-${price.maxAmount} ${selectedProduct?.unit}`} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>

      <Dialog open={productDetailOpen} onClose={() => setProductDetailOpen(false)}>
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            Ürün Detayları
            <IconButton onClick={() => setProductDetailOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                sx={{
                  height: '100px',
                  maxWidth: '100%',
                  width: 'auto',
                  height: 'auto',
                  objectFit: 'contain',
                  borderRadius: '4px',
                  boxShadow: '0 8px 24px rgba(0,0,0,0.12)'
                }}
                src={selectedProduct?.imageData ? `data:image/jpeg;base64,${selectedProduct?.imageData}` : Pictures[selectedProduct?.mainCategory]?.source.default}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h5" gutterBottom>
                {selectedProduct?.name}
              </Typography>
              <Box my={2}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                  Marka
                </Typography>
                <Typography variant="body1">
                  {selectedProduct?.brand || 'Bilgi Yok'}
                </Typography>
              </Box>
              <Box my={2}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                  Açıklama
                </Typography>
                <Typography variant="body2">
                  {selectedProduct?.description || 'Bilgi Yok'}
                </Typography>
              </Box>
              <Box my={2}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                  Fiyat
                </Typography>
                {selectedProduct?.priceStrategy === 'none' ? (
                  <Typography variant="body2">Fiyat belirtilmemiş</Typography>
                ) : selectedProduct?.priceStrategy === 'fixed' ? (
                  <Typography variant="body2">{`₺${selectedProduct?.fixedPrice}`}</Typography>
                ) : (
                  <List dense>
                    {selectedProduct?.variablePrices?.map((price, index) => (
                      <ListItem key={index}>
                        <ListItemText primary={`₺${price.price} / ${price.minAmount}-${price.maxAmount} ${selectedProduct?.unit}`} />
                      </ListItem>
                    ))}
                  </List>
                )}
              </Box>
              <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                Miktar
              </Typography>
              <TextField
                fullWidth
                type="number"
                value={productDetailQuantity}
                onChange={(e) => setProductDetailQuantity(Math.max(1, parseInt(e.target.value, 10) || 1))}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{selectedProduct?.unit}</InputAdornment>,
                }}
              />
              <Button
                variant="contained"
                color="primary"
                startIcon={<ShoppingCartIcon />}
                onClick={(e) => {
                  e.stopPropagation();
                  if (productDetailQuantity > 0) {
                    const {
                      brand,
                      code,
                      imageData,
                      description,
                      shortDescription,
                      category,
                      isAvailable,
                      ...cartItem
                    } = selectedProduct;
                    cartItem.quantity = productDetailQuantity;
                    cartItem.key = selectedProduct.productId;
                    props.onPress(cartItem);
                    setProductDetailOpen(false)
                  }
                }}
                sx={{
                  mt: 2,
                  '&:hover': {
                    bgcolor: 'primary.main',
                    '@media (hover: none)': {
                      bgcolor: 'primary.main',
                    },
                  },
                }}
              >
                Sepete Ekle
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setProductDetailOpen(false)} color="primary">
            Kapat
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default React.memo(ProductCard);
