/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { LoginWrapper } from "../Wrapper";
import useStyles from "./styles";
import { useLocation } from "react-router";

function Login() {
  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();
  return (
    <Box className={classes.pageContainer}>
      <LoginWrapper>
        <Typography variant="h5" className={classes.font700}>
          Hoş geldiniz!
        </Typography>
        <Typography
          variant="caption"
          className={`${classes.fontGrey} ${classes.caption} `}
        >
          Devam Etmek İçin Giriş Yapınız ya da Kaydolunuz
        </Typography>

        <RouterLink
          to="/new-login"
          state={{ from: location.state?.from }}
          style={{ textDecoration: "none" }}
        >
          <Button
            variant="contained"
            disableElevation
            disableRipple
            disableFocusRipple
            disableTouchRipple
            style={{
              borderRadius: 30,
              backgroundColor: "#FF6B5E",
              padding: "18px 36px",
              fontSize: "18px",
              marginTop: '5%'
            }}
          >
            <Typography
              variant="caption"
              color="secondary"
              className={`${classes.font700} ${classes.caption}`}
            >
              Gİrİş Yap
            </Typography>
          </Button>
        </RouterLink>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "80%",
              display: "flex",
              alignSelf: "center",
              justifyContent: "space-around",
              alignItems: "center",
              margin: theme.spacing(2, 0),
            }}
          >
          </div>
        </Box>

        <RouterLink
          to="/register"
          state={{ from: location.state?.from }}
          style={{ textDecoration: "none" }}
        >
          <Button
            variant="contained"
            disableElevation
            disableRipple
            disableFocusRipple
            disableTouchRipple
            style={{
              borderRadius: 30,
              backgroundColor: "#FF6B5E",
              padding: "18px 36px",
              fontSize: "18px"
            }}
          >
            <Typography
              variant="caption"
              color="secondary"
              className={`${classes.font700} ${classes.caption}`}
            >
              Kaydol
            </Typography>
          </Button>
        </RouterLink>
      </LoginWrapper>
    </Box>
  );
}

export default Login;
