/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Container,
  Grid,
  Typography,
  Button,
  useTheme
} from "@mui/material";
import React, { useCallback, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CodIcon from "../../assets/icons/CodIcon";
import {
  CartItemCard,
  PaymentCard,
  PersonalCard,
  OrderOption,
} from "../../components/Checkout";
import FlashMessage from "../../components/FlashMessage";
import { Header } from "../../components/Header";
import { createOrder } from "../../api/market_api";
import RestaurantContext from "../../context/Restaurant";
import useStyle from "./styles";
import NoteAdded from "../../components/Modals/NoteAdded/NoteAdded";
import dayjs from 'dayjs';
import AddressRequiredModal from "../../components/Modals/AddressRequiredModal/AddressRequiredModal";

const PAYMENT = {
  id: 2,
  payment: "COD",
  label: "Cash",
  icon: <CodIcon />,
};

function Checkout() {
  const classes = useStyle();
  const navigate = useNavigate();
  const [mainError, setMainError] = useState({});
  const {
    updateCart,
    cart,
    profile
  } = useContext(RestaurantContext);

  const [paymentMethod, setPaymentMethod] = useState(PAYMENT);
  const [selectedDate, setOrderDate] = useState(null);
  const [orderDateError, setOrderDateError] = useState("");
  const [emptyCartError, setEmptyCartError] = useState("");
  const [deliveryCharges, setDeliveryCharges] = useState(0);
  const [createdModal, setCreatedModal] = useState(false);
  const [supplierErrors, setSupplierErrors] = useState([]);
  const [addressRequiredModal, setAddressRequiredModal] = useState(false);
  const [selectedSuppliers, setSelectedSuppliers] = useState(new Set());

  const filterCartItems = () => {
    return cart.filter(item => selectedSuppliers.has(item.supplier.id));
  };

  function checkMinimumOrders() {
    const supplierTotals = cart.reduce((acc, item) => {
      const supplierId = item.supplier.id;
      const orderValue = item.quantity * item.price;
      acc[supplierId] = (acc[supplierId] || 0) + orderValue;
      return acc;
    }, {});

    const errorMessages = [];
    cart.forEach(item => {
      const supplier = item.supplier;
      if (supplierTotals[supplier.id] < supplier.minimumOrder) {
        const errorMessage = `Tedarikçi '${supplier.name}' için: minimum ${supplier.minimumOrder} TL, şu an ${supplierTotals[supplier.id]} TL`;
        errorMessages.push(errorMessage);
      }
    });
    return [...new Set(errorMessages)];
  }

  useEffect(() => {
    let totalDeliveryFee = 0;
    let uniqueSuppliers = new Set();
    cart.forEach((cartItem) => {
      uniqueSuppliers.add(cartItem.supplier.id.toString());
      totalDeliveryFee += cartItem.supplier.deliveryFee;
    });
    setDeliveryCharges(totalDeliveryFee);
    setSelectedSuppliers(uniqueSuppliers);
  }, [cart]);

  useEffect(() => {
    const errors = checkMinimumOrders();
    setSupplierErrors(errors);
  }, [cart]);

  const showMessage = useCallback((messageObj) => {
    setMainError(messageObj);
  }, []);

  const toggleSnackbar = useCallback(() => {
    setMainError({});
  }, []);

  const calculatePrice = (delivery = 0) => {
    let itemTotal = 0;
    cart.forEach((cartItem) => {
      if (selectedSuppliers.has(cartItem.supplier.id.toString())) {
        itemTotal += cartItem.price * cartItem.quantity;
      }
    });
    const deliveryAmount = delivery > 0 ? deliveryCharges : 0;
    return (itemTotal + deliveryAmount).toFixed(2);
  };

  const calculateTotal = () => {
    let total = 0;
    cart.forEach((cartItem) => {
      if (selectedSuppliers.has(cartItem.supplier.id.toString())) {
        total += cartItem.price * cartItem.quantity;
      }
    });
    total += deliveryCharges;
    return parseFloat(total).toFixed(2);
  };

  useEffect(() => {
    let totalDeliveryFee = 0;

    cart.forEach((cartItem) => {
      if (selectedSuppliers.has(cartItem.supplier.id.toString())) {
        totalDeliveryFee += cartItem.supplier.deliveryFee;
      }
    });

    setDeliveryCharges(totalDeliveryFee);
  }, [cart, selectedSuppliers]);

  const toggleCreatedModal = useCallback(() => {
    setCreatedModal((prev) => !prev);
  }, []);

  const toggleAddressRequiredModal = useCallback(() => {
    setAddressRequiredModal((prev) => !prev);
  }, []);

  function transformCartItems(filteredCart) {
    const groupedBySupplier = filteredCart.reduce((acc, item) => {
      const supplierKey = item.supplier.id;

      const productToAdd = {
        productId: item.productId,
        catalogProductId: item.catalogProductId,
        amount: item.quantity,
        price: item.price,
      };
      if (acc[supplierKey]) {
        acc[supplierKey].products.push(productToAdd);
      } else {
        acc[supplierKey] = {
          supplierId: item.supplier.id,
          products: [productToAdd],
        };
      }
      return acc;
    }, {});
    return Object.values(groupedBySupplier);
  }

  const createNewOrder = async () => {
    setEmptyCartError("");
    setOrderDateError("");

    if (cart.length === 0) {
      setEmptyCartError("Sepetinizde ürün bulunmamaktadır.");
      return;
    }

    const filteredCart = cart.filter(item => selectedSuppliers.has(item.supplier.id.toString()));

    if (filteredCart.length === 0) {
      setEmptyCartError("Seçili tedarikçilerden sepetinizde ürün bulunmamaktadır!");
      return;
    }

    if (selectedDate == null) {
      setOrderDateError("Lütfen Sipariş Günü ve Saati Giriniz!");
      return;
    }

    if (dayjs().isAfter(selectedDate)) {
      setOrderDateError("Geçmiş tarih seçilemez.");
      return;
    }

    const currentSupplierErrors = checkMinimumOrders();
    if (currentSupplierErrors.length > 0) {
      setMainError({
        type: 'error',
        message: 'Tüm tedarikçilerin minimum sipariş değerlerini karşılayamadınız. Lütfen sepetinizi kontrol edin.'
      });
      return;
    }

    const data = {
      restaurantId: profile.id,
      orderTime: selectedDate.format('YYYY-MM-DDTHH:mm:ss'),
      orderContentDtos: transformCartItems(filteredCart)
    };

    try {
      const response = await createOrder(data);
      if (response !== null && response === "Address missing") {
        setAddressRequiredModal(true);
        return;
      }
      if (response && response.status === 200) {
        setCreatedModal(true);
        setOrderDate(null);
        updateCartAfterOrder(filteredCart);
        setTimeout(() => {
          setCreatedModal(false);
          navigate(`/orders`, { replace: true });
        }, 3000);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const updateCartAfterOrder = (orderedItems) => {
    const newCart = cart.filter(cartItem =>
      !orderedItems.some(orderedItem => orderedItem.id === cartItem.id)
    );
    updateCart(newCart);
  };

  const theme = useTheme();

  return (
    <Grid container className={classes.root}>
      <FlashMessage
        open={Boolean(mainError.type)}
        severity={mainError.type}
        alertMessage={mainError.message}
        handleClose={toggleSnackbar}
        alive={mainError.alive || false}
      />
      <Header />
      <Grid
        container
        item
        className={classes.mainContainer}
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <CartItemCard
                setFlashMessage={showMessage}
                calculateTotal={() => calculateTotal(filterCartItems())}
                calculatePrice={() => calculatePrice(filterCartItems())}
                deliveryCharges={deliveryCharges}
                selectedSuppliers={selectedSuppliers}
                setSelectedSuppliers={setSelectedSuppliers}
              />
              {supplierErrors.map((error, index) => (
                <Typography key={index} variant="body2" color="error">
                  {error}
                </Typography>
              ))}
            </Grid>
            <Grid item xs={12} sm={8}>
              <OrderOption
                selectedDate={selectedDate}
                handleDateChange={setOrderDate}
                error={orderDateError}
              />
              <PersonalCard />
              {/* <PaymentCard
                paymentMethod={paymentMethod}
                setPaymentMethod={setPaymentMethod}
                createOrder={createNewOrder}
              /> */}
              <Button
                style={{
                  maxWidth: "auto",
                  padding: `${theme.spacing(2)} 0`,
                  background: "#FF6B5E",
                  marginTop: theme.spacing(2),
                  width: "100%",
                  borderRadius: 0,
                }}
                onClick={(e) => {
                  e.preventDefault()
                  createNewOrder();
                }}
              >
                <Typography
                  style={{
                    ...theme.typography.body1,
                    color: theme.palette.common.white,
                    fontWeight: 700,
                  }}
                >
                  Sİparİş Ver
                </Typography>
              </Button>
              {emptyCartError && (
                <Typography variant="body2" color="error">
                  {emptyCartError}
                </Typography>
              )}
              {orderDateError && (
                <Typography variant="body2" color="error">
                  {orderDateError}
                </Typography>
              )}
            </Grid>
            <NoteAdded
              isVisible={createdModal}
              toggleModal={toggleCreatedModal}
              title="Siparişiniz Alınmıştır!"
              detail="TedaBir'i kullandığınız için teşekkür ederiz! Gelişmeler hakkında sizi bilgilendireceğiz!"
            />
            <AddressRequiredModal
              isVisible={addressRequiredModal}
              toggleModal={toggleAddressRequiredModal}
            />
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
}

export default Checkout;
