import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormGroup,
  OutlinedInput,
  Typography,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect } from "react";
import HeadingView from "./HeadingView";
import useStyles from "./styles";
import { addReview } from "../../../api/order_api";
import StarRating from "../../StarRating";
import { formatDateToTurkish, parseOrderTime } from "../../Utils";

function SupplierPastOrderModal({ isVisible, closeModal, data, onFinish }) {
  const theme = useTheme();
  const classes = useStyles();

  const [comment, setComment] = useState(data?.reviewDto?.comment || "");
  const [priceRating, setPriceRating] = useState(data?.reviewDto?.priceRating || 0);
  const [qualityRating, setQualityRating] = useState(data?.reviewDto?.qualityRating || 0);
  const [deliveryRating, setDeliveryRating] = useState(data?.reviewDto?.deliveryRating || 0);
  const [readOnly, setReadOnly] = useState(false);
 
  useEffect(() => {
    setReadOnly(false);
    setComment("");
    setPriceRating(0);
    setQualityRating(0);
    setDeliveryRating(0);

    if (data?.reviewDto) {
      setReadOnly(true);
      setComment(data.reviewDto.comment || "");
      setPriceRating(data.reviewDto.priceRating || 0);
      setQualityRating(data.reviewDto.qualityRating || 0);
      setDeliveryRating(data.reviewDto.deliveryRating || 0);
    }
  }, [data]);

  const addNewReview = async () => {
    const newReviewData = {
      orderId: data?.orderId,
      comment: comment,
      priceRating: priceRating,
      qualityRating: qualityRating,
      deliveryRating: deliveryRating
    }
    try {
      const response = await addReview(newReviewData);
      if (response?.status === 200) {
        setComment("");
        setPriceRating(0);
        setQualityRating(0);
        setDeliveryRating(0);
        onFinish();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <Dialog
      onClose={closeModal}
      open={isVisible}
      fullWidth={true}
      className={classes.root}
    >
      <Box mb={theme.spacing(2)} />
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.itemTitle}>
            Sipariş Numarası: {data?.orderId}
          </Typography>
          <Button onClick={closeModal} className={classes.closeContainer}>
            <CloseIcon style={{ fill: "#FF6B5E" }} />
          </Button>
        </Box>
        <Box>
          <Typography className={classes.priceTitle}>
            {formatDateToTurkish(parseOrderTime(data?.orderTime))}
          </Typography>
        </Box>
        <Box>
          <Typography className={classes.content}>
            {data?.orderContent ?? ""}
          </Typography>
        </Box>
        <Divider light orientation="horizontal" />
      </DialogTitle>
      <DialogContent>
        <Box />
        <FormControl style={{ flex: 1, display: "flex" }}>
          <FormGroup>
            {readOnly ? (
              <Typography variant="body2">{comment}</Typography>
            ) : (
              <>
                <HeadingView title="Yorum Bırakın" subTitle="Siparişle ilgili bir yorumunuz var mı? Tedarikçiye bildirin." error={false} />
                <Box mt={theme.spacing(2)} />
                <OutlinedInput
                  multiline
                  value={comment}
                  onChange={event => setComment(event.target.value)}
                  placeholder={"Domatesler Bozuktu..."}
                  variant="filled"
                  color="primary"
                  minRows={2}
                  maxRows={3}
                  inputProps={{ style: { color: theme.palette.grey[600] }, maxLength: 144 }}
                  size="small" />
              </>
            )}

            <Box mt={theme.spacing(2)}>
              <HeadingView title="Fiyat Değerlendirmesi" subTitle={readOnly ? "" : "Ürün fiyatını değerlendirin (1-5 yıldız)"} status="" error={false} />
              <StarRating value={priceRating} onChange={setPriceRating} readOnly={readOnly} />
            </Box>

            <Box mt={theme.spacing(2)}>
              <HeadingView title="Kalite Değerlendirmesi" subTitle={readOnly ? "" : "Ürün kalitesini değerlendirin (1-5 yıldız)"} status="" error={false} />
              <StarRating value={qualityRating} onChange={setQualityRating} readOnly={readOnly} />
            </Box>

            <Box mt={theme.spacing(2)}>
              <HeadingView title="Teslimat Değerlendirmesi" subTitle={readOnly ? "" : "Teslimat hızını değerlendirin (1-5 yıldız)"} status="" error={false} />
              <StarRating value={deliveryRating} onChange={setDeliveryRating} readOnly={readOnly} />
            </Box>
          </FormGroup>
        </FormControl>
      </DialogContent>
      {!readOnly && (
        <DialogActions>
          <Box style={{ background: "white", width: "100%", height: "75px" }} display="flex" alignItems="center" justifyContent="space-around">
            <Button variant="contained" fullWidth style={{ width: '50%', borderRadius: 30, backgroundColor: "#FF6B5E", padding: "18px 36px", fontSize: "18px" }} onClick={addNewReview}>
              <Typography className={classes.checkoutText}>Gönder</Typography>
            </Button>
          </Box>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default React.memo(SupplierPastOrderModal);
