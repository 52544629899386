import React from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
  Box,
  Container,
  Divider
} from "@mui/material";
import { Facebook, Twitter, Instagram, LinkedIn } from "@mui/icons-material";
import useStyle from "./help-styles";
import { LoginHeader } from "../../Header";

function Aggrement() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyle({ mobile });

  return (
    <Container className={classes.root}>
      <LoginHeader showIcon={true} />

      <Typography variant="h4" component="h1" gutterBottom>
        Sözleşmeler
      </Typography>

      <Typography variant="h5" component="h2" gutterBottom>
        Hizmet Sözleşmesi
      </Typography>
      <Typography paragraph>
        1. Genel Şartlar
        - TedaBir platformu, işletmeler ve tedarikçiler arasında sipariş yönetimi süreçlerini kolaylaştırmayı amaçlar. Platform üzerinden sunulan hizmetler, işletmelerin mevcut tedarikçileriyle etkileşimini ve yeni tedarikçi seçeneklerine erişimini sağlar.
      </Typography>
      <Typography paragraph>
        2. Kullanıcı Sorumlulukları
        - Kullanıcılar, platformu kullanırken yasalara uygun davranmakla yükümlüdür. TedaBir, platform üzerinden gerçekleştirilen her türlü yasa dışı faaliyetten sorumlu tutulamaz.
      </Typography>
      <Typography paragraph>
        3. Ücretsiz Kullanım
        - Platform, şu an için kullanıcılarından herhangi bir ücret almamaktadır. Ancak TedaBir, hizmet politikalarında değişiklik yapma hakkını saklı tutar.
      </Typography>
      <Typography paragraph>
        4. Fikri Mülkiyet Hakları
        - Platform ve platform içeriğinin tüm fikri mülkiyet hakları TedaBir'e aittir. Kullanıcılar, TedaBir'in yazılı izni olmaksızın bu içerikleri çoğaltamaz veya kullanamaz.
      </Typography>
      <Typography paragraph>
        5. Sözleşme Değişiklikleri
        - TedaBir, bu Hizmet Sözleşmesi'nde önceden haber vermek koşuluyla değişiklik yapma hakkını saklı tutar.
      </Typography>
      <Typography paragraph>
        Bu Hizmet Sözleşmesi, 31/1/2024 tarihinde yürürlüğe girmiştir.
      </Typography>

      <Typography variant="h5" component="h2" gutterBottom>
        Gizlilik Sözleşmesi
      </Typography>
      <Typography paragraph>
        1. Veri Toplama ve Kullanımı
        - TedaBir, kullanıcıların kişisel verilerini, hizmet kalitesini artırmak ve kullanıcı deneyimini iyileştirmek amacıyla toplar.
      </Typography>
      <Typography paragraph>
        2. Veri Güvenliği
        - Kullanıcı verilerinin güvenliği, TedaBir için en büyük önceliklerden biridir. Kişisel veriler, en yüksek güvenlik standartlarına uygun şekilde korunmaktadır.
      </Typography>
      <Typography paragraph>
        3. Üçüncü Parti Paylaşımı
        - TedaBir, kullanıcıların kişisel verilerini üçüncü partilere yalnızca yasal zorunluluklar çerçevesinde ve kullanıcıların açık rızası ile paylaşır.
      </Typography>
      <Typography paragraph>
        Bu sözleşme, 31/1/2024 tarihinde yürürlüğe girmiştir.
      </Typography>
      <Divider />

      <Typography variant="h5" component="h2" gutterBottom>
        Mesafeli Satış Sözleşmesi
      </Typography>
      <Typography paragraph>
        1. Taraflar
        - Bu sözleşme, TedaBir (‘Satıcı’) ve platform kullanıcıları (‘Alıcı’) arasında elektronik ortamda yapılmıştır.
      </Typography>
      <Typography paragraph>
        2. Hizmetlerin Tanımı
        - TedaBir, HORECA sektöründe faaliyet gösteren işletmelere ve tedarikçilere yönelik sipariş yönetimi, iletişim ve stok takibi hizmetleri sunmaktadır.
      </Typography>
      <Typography paragraph>
        3. Ödeme ve Ücretlendirme
        - Tüm ödemeler, alıcı ve satıcı arasında, kabul edilen ödeme koşulları çerçevesinde gerçekleştirilir.
      </Typography>
      <Typography paragraph>
        4. Sözleşmenin Feshi
        - Alıcılar, sözleşmeyi belirli koşullar altında feshedebilir.
      </Typography>
      <Typography paragraph>
        Bu sözleşme, 31/1/2024 tarihinde yürürlüğe girmiştir.
      </Typography>
      <Divider />

      <Grid container className={classes.mainContainer} justifyContent="center">
        <Box className={classes.socialMediaContainer}>
          <IconButton href="https://www.facebook.com/tedabircom" target="_blank">
            <Facebook />
          </IconButton>
          <IconButton href="https://twitter.com/TedaBir" target="_blank">
            <Twitter />
          </IconButton>
          <IconButton href="https://www.instagram.com/tedabircom/" target="_blank">
            <Instagram />
          </IconButton>
          <IconButton href="https://www.linkedin.com/company/tedabir" target="_blank">
            <LinkedIn />
          </IconButton>
        </Box>
        <Typography variant="caption" className={classes.footer}>
          © 2024 TedaBir. Tüm Hakları Saklıdır.
        </Typography>
      </Grid>
    </Container>
  );
}

export default React.memo(Aggrement);
