import {
  Box,
  Container,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useContext } from "react";
import GuestCartContext from "../../../context/Guest";
import CartItem from "./CartItem";
import GuestPricingView from "./GuestPricingView";
import useStyles from "./styles";

function CampaignCartView(props) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    cart,
    addCartItem, removeCampaignItem, removeCampaignQuantity
  } = useContext(GuestCartContext);
  return (
    <Box style={{ position: "sticky" }}>
      <Container
        style={{
          maxHeight: "50vh",
          overflow: "scroll",
          background: theme.palette.common.white,
        }}
      >
        {cart.length > 0 && (
          <React.Fragment>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                background: theme.palette.common.white,
                marginTop: '3%'
              }}
            >
              <Typography
                variant="h6"
                color="textSecondary"
                className={classes.mediumFont}
                style={{ fontWeight: 'bold' }}
              >
                {`${props.supplier} Tedarikçisinden Siparişiniz`}
              </Typography>
            </Box>
            {cart.map(item => (
              <CartItem
                key={item.productId}
                quantity={item.quantity}
                name={item.name}
                price={item.price}
                unit={item.unit}
                addQuantity={() => {
                  addCartItem({ productId: item.productId, quantity: 1 });
                }}
                removeQuantity={() => {
                  if (item.quantity > 1) {
                    removeCampaignQuantity(item.productId);
                  } else if (item.quantity == 1) {
                    removeCampaignItem(item.productId);
                  }
                }}
              />
            ))}
          </React.Fragment>
        )}
      </Container>
      <GuestPricingView supplier={props.supplier} linkIdentifier={props.linkIdentifier} type="campaign" minOrder={props.minOrder} />
    </Box>
  );
}

export default React.memo(CampaignCartView);
