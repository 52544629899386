import { Box, Grid, Hidden } from "@mui/material";
import React, { useContext } from "react";
import RestaurantContext from "../../../context/Restaurant";
import CartView from "./CartView";
import EmptyView from "./EmptyView";

function RestaurantCart(props) {
  const { cart, clearCart } = useContext(RestaurantContext);
  return (
    <Grid
      item
      lg={3}
      sm={3}
      xs={false}
    >
      {!cart?.length ? <EmptyView showMessage={props.showMessage} /> : <CartView showMessage={props.showMessage} />}
    </Grid>
  );
}

export default React.memo(RestaurantCart);
