import { useMediaQuery, useTheme } from "@mui/material";
import React, { useContext } from "react";
import RestaurantContext from "../../context/Restaurant";
import DHeader from "./desktop.header";
import { useNavigate } from 'react-router-dom';

const TITLE = "TedaBir";
const NAME = "...";
const NAVITEMS = [
  {
    title: "Siparişler",
    link: "/orders",
  },
  {
    title: "Alışveriş Listelerim",
    link: "/shopList",
  },
  {
    title: "Profil",
    link: "/profile",
  },
  {
    title: "Keşfedin: Tanıtım Videomuz",
    link: "/onboard",
  },
  {
    title: "Çıkış",
  },
];

function Header() {
  const theme = useTheme();
  const { logout, loadingProfile, profile, cartCount } =
    useContext(RestaurantContext);
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async (route) => {
    setAnchorEl(null);
    if (route === "Çıkış") {
      await logout();
      navigate('/login');
    }
  };

  return (
    <DHeader
      navitems={NAVITEMS}
      title={TITLE}
      close={handleClose}
      open={handleOpen}
      anchor={anchorEl}
      name={loadingProfile ? NAME : profile?.businessName}
      cartCount={cartCount}
      mobile={mobile}
    />
  );
}

export default React.memo(Header);
