/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import FlashMessage from "../../components/FlashMessage";
import { LoginHeader } from "../../components/Header";
import {
   StoreContainer,
} from "../../components/HomeScreen";
import RestaurantContext from "../../context/Restaurant";
import { useLocation } from "../../hooks";
import Summary from "../Summary/Summary";
import useStyles from "./styles";

function Home() {
   const classes = useStyles();
   const { error, loading } = useLocation();
   const [open, setOpen] = useState(!!error);
   const { isLoggedIn } = useContext(RestaurantContext);
   let check = false;

   const handleClose = useCallback(() => {
      setOpen(false);
   }, []);

   useEffect(() => {
      if (check) {
         setOpen(!!error);
      } else {
         check = true;
      }
   }, [error]);

   return (
      <Grid className={classes.root}>
         <FlashMessage
            severity={loading ? "info" : "error"}
            alertMessage={error}
            open={open}
            handleClose={handleClose}
         />
         {isLoggedIn ?
            <Summary />
            :
            <>
               <LoginHeader showIcon={true} />
               <Grid container item>
                  <StoreContainer />
               </Grid>
            </>
         }
      </Grid>
   );
}
export default Home;
