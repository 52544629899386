import React, { useEffect, useState, useCallback, useContext } from "react";
import {
  Grid, Typography, List, ListItem, ListItemText, IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogContentText
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useStyles from './shoppinglist-styles';
import { Header } from "../../components/Header";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import { deleteShoppingList, getShoppingLists } from "../../api/restaurant_api";
import RestaurantContext from "../../context/Restaurant";
import dayjs from 'dayjs';

function ShoppingLists() {
  const classes = useStyles();
  const [shoppingLists, setShoppingLists] = useState([]);
  const { restaurantId } = useContext(RestaurantContext);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const fetchShoppingLists = async () => {
    try {
      const response = await getShoppingLists(restaurantId);
      if (response && response.status === 200 && response.data) {
        setShoppingLists(response.data);
      } else {
        setShoppingLists([]);
      }
    } catch (error) {
      console.error('Error fetching suppliers:', error);
    }
  };

  useEffect(() => {
    fetchShoppingLists();
  }, []);

  const navigateTo = useNavigate();
  const navigate = useCallback((list) => {
    const path = list && list.id ? `/shopList/${list.id}` : '/shopList/new';
    navigateTo(path);
  }, [navigateTo]);

  const handleAddNewList = () => {
    navigateTo('/shopList/new');
  };

  const handleDeleteClick = (id, event) => {
    event.stopPropagation();
    setDeleteId(id);
    setDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await deleteShoppingList(deleteId);
      if (response && response.status === 200) {
        fetchShoppingLists()
      }
    } catch (error) {
      console.error('Error fetching suppliers:', error);
    }
    setDeleteModal(false);
    setDeleteId(null);
  };

  const formatDate = (dateTimeString) => {
    const parsedDate = dayjs(dateTimeString, "DD-MM-YYYY");

    const today = dayjs();
    const tomorrow = dayjs().add(1, 'day');

    if (parsedDate.isSame(today, 'day')) {
      return 'Bugünkü';
    } else if (parsedDate.isSame(tomorrow, 'day')) {
      return 'Yarınki';
    } else {
      return parsedDate.format('DD MMMM');
    }
  };

  return (
    <Grid container className={classes.root}>
      <Header />
      <Grid container item className={classes.marginHeader}>
        <Grid item xs={12} className={classes.mainContainer}>
          <Grid item xs={12} className={classes.title}>
            <Typography variant="h5" align="center" color="textSecondary" className={classes.textBold}>
              Alışveriş Listelerim
            </Typography>
          </Grid>
          <div className={classes.listContainer}>
            <List>
              {shoppingLists.map((list, index) => (
                <ListItem
                  button
                  key={index}
                  className={classes.listItem}
                  onClick={() => navigate(list)}
                >
                  <ListItemText
                    primary={`${formatDate(list.date)} Listem`}
                    className={classes.listItemText}
                  />
                  <div className={classes.listItemActions}>
                    <IconButton onClick={() => navigate(list)} className={classes.editIcon}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={(event) => handleDeleteClick(list.id, event)} className={classes.deleteIcon}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </ListItem>
              ))}
            </List>
          </div>
          <div className={classes.addIconContainer}>
            <IconButton
              onClick={handleAddNewList}
              className={classes.addIconButton}
            >
              <AddIcon className={classes.addIcon} />
            </IconButton>
          </div>
        </Grid>

        <Dialog
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              padding: '20px'
            },
          }}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Bu listeyi silmek istediğinizden emin misiniz?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              fullWidth
              className={classes.btnBase}
              onClick={handleDeleteConfirm}
              style={{ backgroundColor: "#FF6B5E", textTransform: 'none' }}
            >
              <Typography variant="subtitle2">
                Sil
              </Typography>
            </Button>
            <Button
              variant="outlined"
              fullWidth
              className={`${classes.btnBase} ${classes.cancelButton}`}
              onClick={() => setDeleteModal(false)}
            >
              <Typography variant="subtitle2" color="#FF6B5E">
                İptal
              </Typography>
            </Button>
          </DialogActions>
        </Dialog>

      </Grid>
    </Grid>
  );
}

export default ShoppingLists;
