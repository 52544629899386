import { Box, IconButton, Typography, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import React from "react";
import useStyles from "./styles";

function CartItem(props) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box style={{ marginTop: theme.spacing(2) }}>
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography className={classes.itemTextStyle}>
          {props.name ? props.name : ""}
        </Typography>
        <Typography
          style={{ color: theme.palette.text.disabled }}
          className={classes.subtotalText}
        >
          {`${props.price ? `${props.price} TL` : 'Fiyat Belirtilmemiş'}`}
        </Typography>
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box display="flex" alignItems="flex-start" width="75px">
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              width: "75px",
              justifyContent: "space-between",
            }}
          >
            <IconButton
              size="small"
              onClick={(e) => {
                e.preventDefault();
                props.removeQuantity();
              }}
            >
              <RemoveIcon fontSize="small" color="primary" />
            </IconButton>
            <Typography
              style={{
                ...theme.typography.caption,
                fontWeight: 700,
                color: theme.palette.text.secondary,
              }}
            >
              {props.quantity ? props.quantity : 0} {props.unit}
            </Typography>
            <IconButton
              size="small"
              onClick={(e) => {
                e.preventDefault();
                props.addQuantity();
              }}
            >
              <AddIcon fontSize="small" color="primary" />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default React.memo(CartItem);
