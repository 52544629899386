import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  marginHeader: {
    marginTop: "200px",
  },
  active: {
    color: theme.palette.text.secondary,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  anchorStyle: {
    textDecoration: "none",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    cursor: "pointer",
  },
  tabTextStyle: {
    ...theme.typography.subtitle2,
    color: theme.palette.text.disabled,
    fontWeight: 600,
  },
  imageContainer: {
    height: "150px",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: 'no-repeat',
    marginTop: '100px',
    width: "100%",
    '@media (min-width: 1024px)': {
      width: "60%",
      margin: "auto",
      marginTop: '100px',
    },
  },
  tabContainer: {
    width: "100%",
    background: theme.palette.primary.light,
    height: "71px",
    display: "flex",
    alignItems: "center",
    position: "sticky",
    top: theme.spacing(2),
    zIndex: 1200,
  },
  scrollpyStyles: {
    display: "flex",
    alignItems: "center",
    listStyleType: "none",
    width: "100%",
    paddingLeft: 0,
    paddingBottom: "4px",
    overflowX: "auto",
  },
  tabListStyle: {
    padding: theme.spacing(3),
    paddingLeft: theme.spacing(10),
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      flex: 1,
      padding: theme.spacing(2),
    }
  },
  categoriesStyle: {
    ...theme.typography.subtitle2,
    color: theme.palette.text.disabled,
  },
  spinnerContainer: {
    flexDirection: "row",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(20, 0),
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: theme.palette.grey[200],
    marginBottom: '2%',
    overflowX: 'hidden',
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    justifyContent: 'center',
    overflowX: 'auto',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      padding: theme.spacing(1),
    },
  },
  chipMainCategory: {
    marginRight: theme.spacing(1),
    borderRadius: '8px',
    padding: '20px 8px',
    boxShadow: theme.shadows[1],
  },
  chipSubCategory: {
    marginRight: theme.spacing(1),
    borderRadius: '4px',
    padding: '6px 8px',
    boxShadow: theme.shadows[1],
    backgroundColor: '#FFD7C2',
  },
  selectedMainChip: {
    backgroundColor: '#FF6B5E',
    color: 'white',
    borderRadius: '8px',
    boxShadow: theme.shadows[2],
    padding: '20px 8px',
    '&:hover': {
      backgroundColor: '#FF6B5E',
    },
  },
  selectedSubChip: {
    backgroundColor: '#FF6B5E',
    color: 'white',
    borderRadius: '8px',
    boxShadow: theme.shadows[2],
    '&:hover': {
      backgroundColor: '#FF6B5E',
    },
  },
}));

export default useStyles;
