/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormGroup,
  OutlinedInput,
  Typography,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect } from "react";
import HeadingView from "./HeadingView";
import useStyles from "./styles";
import { addNewNote } from "../../../api/order_api";
import { formatDateToTurkish, parseOrderTime } from "../../Utils";
import Error from "../Error/Error";

function SupplierActiveOrderModal({ isVisible, closeModal, data, onFinish }) {
  const theme = useTheme();
  const classes = useStyles();
  const [note, setNote] = useState("");
  const [history, setHistory] = useState(data.history);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [noteMissingError, setNoteMissingError] = useState("");

  useEffect(() => {
    if (data?.history?.length > 0) {
      const updatedHistory = data.history.map(obj => ({
        ...obj,
        note: createNote(obj)
      }));
      setHistory(updatedHistory);
    }
  }, [data.history]);

  const addNote = async () => {
    setNoteMissingError("")
    if (note == "") {
      setNoteMissingError("Lütfen Notunuzu Giriniz!")
      return;
    }
    const newNoteData = {
      orderId: data?.orderId,
      content: note,
      owner: "RETAIL"
    }
    try {
      const response = await addNewNote(newNoteData);
      if (response && response.status === 200) {
        setNote("")
        onFinish();
      }
      else {
        setErrorModalOpen(true);
        setTimeout(() => {
          setErrorModalOpen(false);
        }, 3000);
        return;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setErrorModalOpen(true);
      setTimeout(() => {
        setErrorModalOpen(false);
      }, 3000);
      return;
    }
  };

  function createNote(obj) {
    if (obj?.type === "ORDER_RECEIVED")
      return "Sipariş oluşturuldu."
    else if (obj?.type === "STATUS_CHANGE") {
      if (obj?.status == "ACCEPTED_BY_SUPPLIER") {
        return "Sipariş kabul edildi."
      } else if (obj?.status == "REJECTED_BY_SUPPLIER") {
        return "Sipariş reddedildi."
      }
    }
    return obj?.note
  }

  const closeErrorModal = () => {
    setErrorModalOpen(false);
  };

  return (
    <>
      <Dialog
        onClose={closeModal}
        open={isVisible}
        fullWidth={true}
        maxWidth="sm"
        className={classes.root}
      >
        <Box mb={theme.spacing(2)} />
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            <Typography className={classes.itemTitle}>
              Sipariş Numarası: {data?.orderId}
            </Typography>
            <Button onClick={closeModal} className={classes.closeContainer}>
              <CloseIcon style={{ fill: "#FF6B5E" }} />
            </Button>
          </Box>
          <Box mb={theme.spacing(2)}>
            <Typography className={classes.priceTitle}>
              {formatDateToTurkish(parseOrderTime(data?.orderTime))}
            </Typography>
          </Box>
          <Box mb={theme.spacing(2)}>
            <Typography className={classes.priceTitle}>
              {data?.orderContent ?? ""}
            </Typography>
          </Box>
          <Divider light orientation="horizontal" />
        </DialogTitle>
        <DialogContent>
          <Box mb={theme.spacing(2)}>
            {history && history.map((history) => (
              <Box
                display="flex"
                flexDirection="column"
                alignItems={history.owner === "SUPPLIER" ? "flex-end" : "flex-start"}
                className={classes.chatContainer}
                key={history.id}
              >
                <Box
                  className={
                    history.owner === "RETAIL"
                      ? classes.bubbleLeft
                      : classes.bubbleRight
                  }
                >
                  {history.note}
                </Box>
                <Typography
                  className={
                    history.owner === "RETAIL"
                      ? classes.timeStampRetail
                      : classes.timeStampSupplier
                  }
                >
                  {(history.createdAt)}
                </Typography>
              </Box>
            ))}
          </Box>
          <Divider light orientation="horizontal" />
          <FormControl style={{ flex: 1, display: "flex", marginTop: '3%' }}>
            <FormGroup>
              <HeadingView title="Ek Talimatlar" subTitle="Herhangi bir ek talimatınız var mı? Tedarikçiye bildirin." error={false} />
              <Box mt={theme.spacing(2)} />
              <OutlinedInput
                multiline
                minRows={2}
                maxRows={3}
                value={note}
                onChange={event => setNote(event.target.value)}
                placeholder={"5 KG daha et getirir misiniz?"}
                variant="filled"
                color="primary"
                inputProps={{ style: { color: theme.palette.grey[600] }, maxLength: 144 }}
                size="small"
              />
              {noteMissingError && (
                <Typography variant="body2" color="error">
                  {noteMissingError}
                </Typography>
              )}
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Box
            style={{ background: "white", width: "100%", height: "75px" }}
            display="flex"
            alignItems="center"
            justifyContent="space-around"
          >
            <Button
              variant="contained"
              fullWidth
              style={{
                width: '50%',
                borderRadius: 30,
                backgroundColor: "#FF6B5E",
                padding: "18px 36px",
                fontSize: "18px"
              }}
              onClick={(e) => {
                e.preventDefault();
                addNote();
              }}
            >
              <Typography className={classes.checkoutText}>
                Gönder
              </Typography>
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Error
        isVisible={errorModalOpen}
        toggleModal={closeErrorModal}
      />
    </>
  );
}
export default React.memo(SupplierActiveOrderModal);
