import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useCallback, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { loginRetail } from "../../api/restaurant_api";
import FlashMessage from "../../components/FlashMessage";
import LoginError from "../../components/Modals/LoginError/LoginError";
import { validatePhoneNumber } from "../../components/Utils";
import RestaurantContext from "../../context/Restaurant";
import { LoginWrapper } from "../Wrapper";
import useStyles from "./styles";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function NewLogin() {
  const theme = useTheme();
  const classes = useStyles();
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { setProfileAndToken, setAddressInfo } = useContext(RestaurantContext);

  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const closeErrorModal = () => {
    setErrorModalOpen(false);
  };

  const clearErrors = () => {
    setPasswordError("");
    setPhoneError("");
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleAction = async () => {
    clearErrors();
    let validate = true;
    if (phone == "") {
      setPhoneError("Telefon Numarası Girmelisiniz")
      validate = false;
      return;
    }
    if (!validatePhoneNumber(phone)) {
      setPhoneError("Geçerli bir telefon numarası giriniz");
      validate = false;
      return;
    }
    if (password == "") {
      setPasswordError("Şifre Girmelisiniz")
      validate = false;
      return;
    }
    if (validate) {
      const data = {
        phoneNumber: `0${phone}`,
        password: password
      }
      try {
        const response = await loginRetail(data);
        if (response == null || response.status !== 200) {
          setErrorModalOpen(true);
          setTimeout(() => {
            setErrorModalOpen(false);
          }, 3000);
          return;
        }
        else if (response && response.status === 200) {
          const createdProfile = response.data
          await setProfileAndToken(
            {
              id: createdProfile.id,
              businessName: createdProfile.businessName,
              personName: createdProfile.personName,
              phoneNumber: createdProfile.phoneNumber,
              token: createdProfile.token,
            }
          );
          await setAddressInfo({
            addressInfo: response.data.address,
            town: response.data.district,
            city: response.data.city,
          });
          navigate("/", {
            replace: true,
          });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const toggleSnackbar = useCallback(() => {
    setError("");
  }, []);

  return (
    <Box className={classes.pageContainer}>
      <LoginWrapper>
        <FlashMessage
          open={Boolean(error)}
          severity={"error"}
          alertMessage={error}
          handleClose={toggleSnackbar}
        />
        <Box mt={theme.spacing(1)} />
        <Typography variant="h5" className={classes.font700}>
          Giriş Yap
        </Typography>
        <Box mt={theme.spacing(4)} />
        <TextField
          name={"phoneNumber"}
          error={Boolean(phoneError)}
          helperText={phoneError}
          variant="outlined"
          label="Telefon Numarası"
          type="text"
          fullWidth
          InputLabelProps={{
            style: {
              color: theme.palette.grey[600],
            },
          }}
          value={phone}
          onChange={(e) => {
            const value = e.target.value.replace(/[^\d]/g, '').slice(0, 10);
            setPhone(value.startsWith('5') ? value : '');
          }}
          className={classes.rowField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                0
              </InputAdornment>
            ),
            placeholder: "5XX XXX XXXX",
          }}
        />
        <Box mt={theme.spacing(4)} />
        <TextField
          name={"password"}
          InputLabelProps={{
            style: {
              color: theme.palette.grey[600],
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  size="large"
                >
                  {showPassword ? (
                    <Visibility color="primary" />
                  ) : (
                    <VisibilityOff color="primary" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={Boolean(passwordError)}
          helperText={passwordError}
          fullWidth
          variant="outlined"
          label="Şifre"
          type={showPassword ? "text" : "password"}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          className={classes.rowField}
        />
        <Box mt={theme.spacing(8)} />
        <Button
          variant="contained"
          type="email"
          disableElevation
          style={{
            borderRadius: 30,
            backgroundColor: "#FF6B5E",
            padding: "18px 36px",
            fontSize: "18px"
          }}
          onClick={(e) => {
            e.preventDefault();
            handleAction();
          }}
        >
          <Typography
            variant="caption"
            className={`${classes.caption} ${classes.font700}`}
          >
            Gİrİş
          </Typography>
        </Button>
        <LoginError
          isVisible={errorModalOpen}
          toggleModal={closeErrorModal}
        />
      </LoginWrapper>
    </Box>
  );
}

export default NewLogin;
