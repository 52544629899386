/* eslint-disable no-unused-vars */
const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const WS_SERVER_URL = process.env.REACT_APP_WS_SERVER_URL;

const GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;

const LIBRARIES = process.env.REACT_APP_GOOGLE_MAP_LIBRARIES.split(",");
const COLORS = {
  GOOGLE: process.env.REACT_APP_GOOGLE_COLOR,
};
export {
  SERVER_URL,
  WS_SERVER_URL,
  COLORS,
  GOOGLE_MAPS_KEY,
  LIBRARIES,
};
