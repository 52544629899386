import makeStyles from '@mui/styles/makeStyles';

const useStyle = makeStyles((theme) => ({
  restaurantTitle: {
    ...theme.typography.h6,
    color: theme.palette.text.secondary,
    fontWeight: 700,
  },
  tagStyles: {
    ...theme.typography.caption,
    color: theme.palette.primary.light,
    fontWeight: 700,
  },
  tagContainer: {
    backgroundColor: "#FF6B5E",
    display: "inline-block",
    padding: "2px 8px",
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    height: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0',
    },
  },
  tagMobileContainer: {
    backgroundColor: "#FF6B5E",
    display: "inline-block",
    padding: "2px 8px",
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    height: 'fit-content',
  },
  closeContainer: {
    backgroundColor: theme.palette.warning.light,
    display: "inline-block",
    padding: "2px 8px",
    borderRadius: "8px",
  },
  closeTag: {
    color: theme.palette.common.black,
    fontWeight: 700,
  },
  currentRatingText: {
    ...theme.typography.caption,
    fontWeight: 700,
    color: theme.palette.text.secondary,
  },
  totalRatingText: {
    ...theme.typography.caption,
    color: theme.palette.text.disabled,
  
  },
  inviteLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'none',
    },
    marginLeft: '30px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '10px'
    },
  },
}));

export default useStyle;
