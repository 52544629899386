import { Box, Typography, useTheme } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import useStyles from "./styles";
import { categorisMap, formatDateToTurkish, parseOrderTime, statusTranslations } from "../../Utils";

function ActiveOrderCard(props) {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <RouterLink
      to={{
        pathname: `/order-detail/${props.orderId}`,
        search: `?orderType=${props.orderType}`
      }}
      className={classes.link}
    >
      <Box>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body2" color="textSecondary">
            <span className={classes.orderNumber}>#{props.orderId}</span>{' '}
            <span className={classes.textBold}>
              {props.supplierBusinessName}
            </span>
            {props.supplierCategory && ` - ${categorisMap.get(props.supplierCategory)}`}
          </Typography>
          {props.totalAmount &&
            <Typography variant="body2" className={classes.disabledText}>
              {`${props.totalAmount + (props.deliveryFee || 0)} TL`}
            </Typography>
          }
        </Box>
        <Box display="flex" justifyContent="space-between" pt={theme.spacing(1)}>
          <Box className={classes.statusButtonContainer}>
            <Typography className={`${classes.textBold} ${classes.smallText} ${classes.statusLabel}`} color="textSecondary">
              {statusTranslations[props.status]}
            </Typography>
            <Typography variant="caption" color="black" className={classes.repeatOrderText}>
              {props.orderType === "SUPPLIER" ? "Tedarikçinizden Sipariş" : "Marketten Sipariş"}
            </Typography>
          </Box>
          <ChevronRightIcon style={{ fill: "#FF6B5E" }} />
        </Box>
        <Typography
          gutterBottom
          className={`${classes.disabledText} ${classes.smallText}`}
        >
          {formatDateToTurkish(parseOrderTime(props.orderTime))}
        </Typography>
      </Box>
    </RouterLink>
  );
}

export default React.memo(ActiveOrderCard);
