import {
  Box,
  Button,
  Divider,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useContext } from "react";
import useStyles from "./styles";
import GuestCartContext from "../../context/Guest";

function GuestCartItemCard({
  calculateTotal
}) {
  const theme = useTheme();
  const classes = useStyles();
  const { cart, clearCart } = useContext(GuestCartContext);

  return (
    <>
      <Paper style={{ background: theme.palette.common.white, padding: theme.spacing(1) }}>
        <Box
          style={{
            maxHeight: theme.spacing(30),
            padding: theme.spacing(0, 1),
            overflow: "auto",
          }}
        >
          {cart.map((item, index) => (
            <Box
              key={item.key}
              display="flex"
              justifyContent="space-between"
              pt={theme.spacing(2)}
              pb={theme.spacing(2)}
            >
              <Typography
                variant="caption"
                color="textSecondary"
              >
                {item.quantity} {item.unit} {` ${item.name}`}
              </Typography>
              <Typography
                variant="caption"
                align="right"
              >
                {item.price ? `${(parseFloat(item.price) * item.quantity).toFixed(2)} TL` : 'Fiyat Belirtilmemiş'}
              </Typography>
            </Box>
          ))}
        </Box>
      </Paper>
      <Box className={classes.PH1}>
        <Divider orientation="horizontal" className={classes.MV2} />
        <Box display="flex" justifyContent="space-between">
          <Typography
            variant="caption"
            className={classes.disableText}
          >
            Toplam
          </Typography>
          <Typography
            variant="caption"
            className={classes.disableText}
          >
            {`${calculateTotal()} TL`}
          </Typography>
        </Box>

        {cart.length > 0 &&
          <Box display="flex" justifyContent="space-between">
            <Button
              style={{
                maxWidth: "auto",
                padding: `${theme.spacing(2)} 0`,
                background: "#7D7D7D",
                marginTop: theme.spacing(2),
                width: "100%",
                borderRadius: 0,
              }}
              onClick={clearCart}
            >
              <Typography
                style={{
                  ...theme.typography.body1,
                  color: theme.palette.common.white,
                  fontWeight: 700,
                }}
              >
                Sepetİ Temİzle
              </Typography>
            </Button>
          </Box>}
      </Box>
    </>
  );
}

export default React.memo(GuestCartItemCard);
