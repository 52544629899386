import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  mt2: {
    marginTop: theme.spacing(2),
  },
  PV3: {
    padding: theme.spacing(3, 0),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 0),
    },
  },
  contentContainer: {
    padding: theme.spacing(2, 5),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2),
    },
  },
  mainContainer: {
    marginTop: "100px",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 1400,
  },
  center: {
    justifyContent: "center",
  },
  spinnerContainer: {
    flexDirection: "row",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  title: {
    fontWeight: theme.typography.fontWeightLight,
    fontSize: "3rem",
    color: theme.palette.secondary.light,
    textAlign: 'center', // Add this to center the text
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem', // Smaller font size for small and down screens
    },
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%', // Ensure it takes the full width
  },
  textBold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  disableText: {
    color: theme.palette.text.disabled,
  },
  smallText: {
    fontSize: "0.875rem",
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
    },
  },
  btnBase: {
    borderRadius: "0px",
    height: "50px",
    [theme.breakpoints.down('xs')]: {
      height: '40px',
    },
  },
  heartBG: {
    display: "flex",
    backgroundColor: theme.palette.common.white,
    alignItems: "center",
    justifyContent: "center",
    width: 40,
    height: 40,
    borderRadius: 15,
    boxShadow: theme.shadows[5],
  },
}));

export default useStyles;
