/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material";
import React from "react";
import { Header } from "../../components/Header";
import { PasswordCard, ProfileCard } from "../../components/Profile";
import useStyles from "./styles";
import AddressCard from "../../components/Profile/ProfileCard/AddressCard";

function Profile() {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Header />
      <Grid container item className={classes.marginHeader}>
        <ProfileCard />
      </Grid>
      <Grid container item className={classes.marginAddress}>
        <AddressCard />
      </Grid>
      <Grid container item className={classes.marginPwd}>
        <PasswordCard />
      </Grid>
    </Grid>
  );
}

export default Profile;
