import axios from 'axios';
import { uri } from './restaurant_api';

export const getSuppliers = async (restaurantId) => {
    try {
        return await axios.get(`${uri}/markets/suppliers?restaurantId=${restaurantId}`);
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const searchProducts = async (product, restaurantId) => {
    try {
        return await axios.get(`${uri}/markets/search?name=${product}&restaurantId=${restaurantId}`);
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getSupplier = async (supplierId, restaurantId) => {
    try {
        const response = await axios.get(`${uri}/markets/supplier?supplierId=${supplierId}&restaurantId=${restaurantId}`);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const handleOrderProblems = (err) => {
    console.log("hey error")
    console.log(err)
    if (err?.response?.status === 400 && err.response.data === "Address missing") {
        return "Address missing";
    }
    return null;
}


export const createOrder = async (data) => {
    try {
        return await axios.post(`${uri}/markets/order`, data)
            .then(async response => {
                return response;
            })
            .catch((err) => {
                return handleOrderProblems(err);
            })
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const cancelMarketOrder = async (data) => {
    try {
        const response = await axios.post(`${uri}/markets/cancel`, data);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const addFavourite = async (data) => {
    try {
        const response = await axios.post(`${uri}/markets/favourite`, data);
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getLatestProductInfoForOrderRepeat = async (restaurantId, productIds) => {
    try {
        const queryParams = productIds.map(id => `pIds=${id}`).join('&');
        return await axios.get(`${uri}/markets/availableAmounts?rId=${restaurantId}&${queryParams}`);
    } catch (error) {
        console.log(error);
        return null;
    }
};
