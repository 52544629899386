import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      marginBottom: theme.spacing(1),
    },
    "& .MuiInputBase-input": {
      color: theme.palette.text.secondary,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.grey[200],
      },
      "&:hover fieldset": {
        borderColor: theme.palette.text.disabled,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },
    marginBottom: '5%'
  },
  marginHeader: {
    marginTop: "100px",
  },
  mainContainer: {
    justifyContent: "center",
  },
  title: {
    justifyContent: "center",
    padding: theme.spacing(1, 5),
    margin: theme.spacing(1, 2, 2, 2),
  },
  profileContainer: {
    padding: theme.spacing(0, 2),
  },
  textBold: {
    fontWeight: 700,
  },
  inputRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  },
  inputField: {
    flex: 1,
    minWidth: '120px',
  },
  selectField: {
    flex: 1,
    minWidth: '120px',
  },
  datePickerContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  datePicker: {
    flex: 1,
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'currentColor',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'currentColor', 
      },
    },
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    width: '100%',
  },
  button: {
    margin: theme.spacing(1),
    borderRadius: 10,
    backgroundColor: "#FF6B5E",
    fontSize: "18px",
    textTransform: 'none',
    "&:hover": {
      backgroundColor: "#FF6B5E",
    },
  },
  editButton: {
    color: theme.palette.primary.main,
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  saveButton: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    },
  },
  btnBase: {
    borderRadius: "0px",
    borderRadius: 30,
    fontSize: "18px",
    borderColor: "#FF6B5E",
    borderWidth: "2px",
  },
  cancelButton: {
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: "#FF6B5E",
    },
    textTransform: 'none'
  },
}));

export default useStyles;
