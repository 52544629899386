import React, { useEffect, useState, useContext } from "react";
import { Grid, Card, Box, DialogActions, InputAdornment, Typography, Dialog, DialogTitle, DialogContent, Button, Snackbar, Alert, IconButton, TextField, CircularProgress, Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import tomatoBackgroundImage from '../../assets/categories/try.png';
import { Pictures } from "../Utils";
import { useParams } from "react-router-dom";
import { getCampaign } from "../../api/campaign_api";
import { ReactComponent as Logo } from "../../assets/images/solo_logo.svg";
import { Link as RouterLink } from "react-router-dom";
import { Facebook, Twitter, Instagram, LinkedIn, ShoppingCart } from "@mui/icons-material";
import GuestEmptyView from "../RestaurantDetailComponent/RestaurantCart/GuestEmptyView";
import GuestCartContext from "../../context/Guest";
import Countdown from "react-countdown";
import CloseIcon from '@mui/icons-material/Close';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CampaignCartView from "../RestaurantDetailComponent/RestaurantCart/CampaignCartView";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        overflowX: 'hidden',
        boxSizing: 'border-box',
    },
    campaignHeader: {
        backgroundImage: `url(${tomatoBackgroundImage})`,
        backgroundSize: "contain",
        backgroundPosition: "center center",
        color: "#fff",
        display: "flex",
        height: "210px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: theme.spacing(2),
        textAlign: "center",
        position: "relative",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
        "& > *": {
            zIndex: 2,
        },
        "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2))",
            zIndex: 1,
        },
        [theme.breakpoints.down('sm')]: {
            height: "330px",
            padding: theme.spacing(1),
            backgroundSize: "cover",
        },
    },
    supplierImage: {
        width: "180px",
        objectFit: "contain",
        borderRadius: "10%",
        position: "absolute",
        top: "70px",
        left: "250px",
        border: "3px solid #fff",
        zIndex: 2,
        [theme.breakpoints.down('sm')]: {
            width: "100px",
            margin: theme.spacing(1),
            position: "relative",
            top: "0",
            left: "0",
        },
    },
    headerTextMedium: {
        fontSize: "1.8rem",
        fontWeight: "bold",
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            fontSize: "1.4rem",
            alignSelf: "flex-start",
            textAlign: "left",
            marginLeft: 10
        },
    },
    headerTextLarge: {
        fontSize: "1.4rem",
        fontWeight: "bold",
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            fontSize: "1.2rem",
            alignSelf: "flex-start",
            textAlign: "left",
            marginLeft: 10
        },
    },
    urgencyText: {
        fontSize: "1rem",
        fontWeight: "bold",
        marginTop: theme.spacing(1),
        color: "#FFD700",
        [theme.breakpoints.down('sm')]: {
            fontSize: "0.9rem",
            alignSelf: "flex-start",
            textAlign: "left",
            marginLeft: 10
        },
    },
    countdown: {
        fontSize: "1rem",
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            alignSelf: "flex-start",
            textAlign: "left",
            marginLeft: 10
        },
    },
    categoryChips: {
        marginTop: theme.spacing(2),
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1),
        },
    },
    chip: {
        marginLeft: theme.spacing(1),
        backgroundColor: "#FF6B5E",
        color: "white",
        fontWeight: "bold",
        fontSize: "1rem",
        borderRadius: "25px",
        padding: theme.spacing(1, 2),
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(0.5),
            fontSize: "0.8rem",
            padding: theme.spacing(0.5, 1.5),
        },
    },
    gridContainer: {
        padding: theme.spacing(2),
        maxWidth: "1200px",
        margin: "0 auto",
        justifyContent: "center",
    },
    card: {
        position: "relative",
        padding: theme.spacing(1),
        margin: theme.spacing(2),
        textAlign: "center",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        borderRadius: "12px",
        width: "200px",
        height: "300px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        [theme.breakpoints.down('sm')]: {
            width: "80%",
            margin: theme.spacing(1),
        },
        transition: "transform 0.2s",
        '&:hover': {
            transform: "scale(1.05)",
            boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
        },
    },
    discountLabel: {
        position: "absolute",
        top: 2,
        left: -16,
        backgroundColor: "#FF6B5E",
        color: "white",
        padding: "8px 32px",
        borderRadius: 4,
        fontWeight: "bold",
        fontSize: "1.0rem",
        transform: "rotate(-37deg)",
        [theme.breakpoints.down('sm')]: {
            fontSize: "0.8rem",
            padding: "6px 24px",
        },
    },
    image: {
        width: "100%",
        height: "120px",
        objectFit: "contain",
        marginBottom: theme.spacing(1),
    },
    priceSection: {
        marginBottom: theme.spacing(1),
    },
    noPrice: {
        fontWeight: "bold",
        fontSize: "0.8rem",
    },
    price: {
        fontWeight: "bold",
        fontSize: "1.2rem",
        [theme.breakpoints.down('sm')]: {
            fontSize: "1rem",
        },
    },
    originalPrice: {
        textDecoration: "line-through",
        color: theme.palette.text.secondary,
        fontSize: "0.9rem",
        [theme.breakpoints.down('sm')]: {
            fontSize: "0.7rem",
        },
    },
    addToCartSection: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    footer: {
        backgroundColor: "#FF6B5E",
        color: "white",
        textAlign: "center",
        marginTop: theme.spacing(4),
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
    },
    footerContent: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            alignItems: "center",
        },
    },
    footerText: {
        flexGrow: 1,
        fontSize: "1.2rem",
        [theme.breakpoints.down('sm')]: {
            fontSize: "1rem",
            marginBottom: theme.spacing(1),
        },
    },
    linkDecoration: {
        textDecoration: 'none',
        color: 'inherit',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    lastFooter: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(2),
    },
    footerLinksContainer: {
        display: 'flex',
        justifyContent: 'center',
        gap: theme.spacing(3),
        flexWrap: 'wrap',
        marginTop: theme.spacing(2),
    },
    footerLink: {
        color: 'white',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    footerImage: {
        width: "80px",
        height: "80px",
        objectFit: "contain",
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
        },
    },
    contactInfo: {
        fontSize: "1.5rem",
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            fontSize: "1.2rem",
        },
    },
    hotLabel: {
        position: "absolute",
        top: -4,
        right: 8,
        backgroundColor: "#28a745",
        color: "white",
        padding: "8px",
        borderRadius: 4,
        fontWeight: "bold",
        fontSize: "0.8rem",
        [theme.breakpoints.down('sm')]: {
            fontSize: "0.7rem",
            padding: "6px",
        },
    },
    stockLabel: {
        position: "absolute",
        top: -4,
        right: 8,
        backgroundColor: "#28a745",
        color: "white",
        padding: "8px 12px",
        borderRadius: 4,
        fontWeight: "bold",
        fontSize: "0.8rem",
        [theme.breakpoints.down('sm')]: {
            fontSize: "0.7rem",
            padding: "6px 10px",
        },
    },
    footerContainer: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[1],
    },
    socialMediaContainer: {
        display: 'flex',
        justifyContent: 'center',
        gap: theme.spacing(2),
    },
    lastFooter: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    floatingCartButton: {
        display: 'none',
        position: 'fixed',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        zIndex: 1000,
        backgroundColor: '#fff',
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: '50%',
        width: '56px',
        height: '56px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
        },
    },
    cartIcon: {
        fontSize: '2rem',
    },
    defaultCartView: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    gridContent: {
        marginLeft: 10,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0
        },
    },
    content: {
        flex: 1,
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            margin: '0 auto',
            paddingBottom: theme.spacing(5),
        },
    },
    minOrderBadge: {
        backgroundColor: "#FFD700",
        color: "black",
        padding: theme.spacing(1, 2),
        borderRadius: "20px",
        fontWeight: "bold",
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            fontSize: "0.8rem",
            padding: theme.spacing(0.5, 1.5),
            alignSelf: "flex-start",
            textAlign: "left",
            marginLeft: 10
        },
    },
    chip: {
        marginLeft: theme.spacing(2),
        backgroundColor: "#FF6B5E",
        color: "white",
        fontWeight: "bold",
        fontSize: "1rem",
        borderRadius: "25px",
        padding: theme.spacing(1, 2),
        transition: 'transform 0.2s, box-shadow 0.2s, background-color 0.2s, border 0.2s',
        '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
            backgroundColor: "#FF6B5E",
        },
        '&.selected': {
            backgroundColor: "#FF3B3B",
            border: '2px solid #FFC107',
            color: 'white',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(1),
            fontSize: "0.8rem",
            padding: theme.spacing(0.5, 1.5),
        },
    },
    socialMediaIcons: {
        marginTop: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            marginBottom: "-15px",
            marginTop: theme.spacing(7),
        },
    },
    socialIcon: {
        margin: theme.spacing(1),
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

function Campaign() {
    const classes = useStyles();
    const { campaignId } = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { cart, addCartItem, removeCampaignItem, removeCampaignQuantity } = useContext(GuestCartContext);
    const [productDetailOpen, setProductDetailOpen] = useState(false);
    const [productDetailQuantity, setProductDetailQuantity] = useState(1);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [cartModalOpen, setCartModalOpen] = useState(false);
    const [averageDiscount, setAverageDiscount] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [minimumOrderAmount, setMinimumOrderAmount] = useState(0);
    const [targetDate, setTargetDate] = useState(null);
    const [dateRangeText, setDateRangeText] = useState("");
    const [supplierName, setSupplierName] = useState("");

    useEffect(() => {
        if (campaignId) {
            fetchData(campaignId);
        }
    }, [campaignId]);

    const toggleCartModal = () => {
        setCartModalOpen(!cartModalOpen);
    };

    const handleProductClick = (product) => {
        setSelectedProduct(product);
        setProductDetailQuantity(getQuantity(product.id));
        setProductDetailOpen(true);
    };

    const fetchData = async (id) => {
        setLoading(true);
        try {
            const response = await getCampaign(id);
            if (response && response.status === 200 && response.data) {
                setData(response.data);
                calculateAverageDiscount(response.data.products);
                setMinimumOrderAmount(response.data.minOrder);
                setSupplierName(response.data.supplierName)

                const { startDate, endDate, createdAt } = response.data;

                // Parse startDate, endDate, and createdAt
                const start = startDate ? new Date(startDate.split('-').reverse().join('-')) : null;
                const end = endDate ? new Date(`${endDate.split('-').reverse().join('-')}T00:00:00Z`) : null; // Set end date to UTC midnight
                const created = new Date(createdAt.split(' ')[0].split('-').reverse().join('-') + 'T' + createdAt.split(' ')[1] + 'Z'); // Ensure createdAt is in UTC

                // Check if the date objects are valid
                if (start && end && !isNaN(start.getTime()) && !isNaN(end.getTime())) {
                    const options = { day: 'numeric', month: 'long', weekday: 'long' };
                    setDateRangeText(`${start.toLocaleDateString('tr-TR', options)} ve ${end.toLocaleDateString('tr-TR', options)} arası geçerli! Acele Edin!`);
                    setTargetDate(end);
                } else if (created && !isNaN(created.getTime())) {
                    // Set target date to one day after createdAt if start and end dates are not available
                    const oneDayAfterCreated = new Date(created.getTime() + 24 * 60 * 60 * 1000);
                    setDateRangeText("Bu teklif sınırlı bir süre için geçerlidir! Acele Edin!");
                    setTargetDate(oneDayAfterCreated);
                } else {
                    console.error("Invalid date formats:", startDate, endDate, createdAt);
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const calculateAverageDiscount = (products) => {
        if (!products || products.length === 0) return;

        const maxDiscount = products.reduce((max, product) => {
            if (product.price && product.discountedPrice) {
                const discountPercent = ((product.price - product.discountedPrice) / product.price) * 100;
                return Math.max(max, discountPercent);
            }
            return max;
        }, 0);

        setAverageDiscount(Math.round(maxDiscount));
    };

    const handleQuantityChange = (id, value) => {
        if (value > 0) {
            showSnackbar("Sepete Eklendi");
            addCartItem({ productId: id, quantity: value });
        } else {
            showSnackbar("Sepetten Çıkarıldı");
        }
    };

    const handleAddQuantity = (product) => {
        const price = product.discountedPrice ? product.discountedPrice : product.price;
        addCartItem({
            productId: product.id,
            name: product.name,
            quantity: 1,
            unit: product.unit,
            fixedPrice: price,
            priceStrategy: 'fixed'
        });
        showSnackbar("Sepete Eklendi");
    };

    const handleRemoveQuantity = (product) => {
        const currentQuantity = getQuantity(product.id) || 0;
        if (currentQuantity > 1) {
            removeCampaignQuantity(product.id);
            showSnackbar("Sepetten Azaltıldı");
        } else if (currentQuantity == 1) {
            removeCampaignItem(product.id);
            showSnackbar("Sepetten Çıkarıldı");
        }
    };

    const getQuantity = (productId) => {
        const item = cart.find(item => item.productId === productId);
        return item ? item.quantity : 0;
    };

    const handleAddToCartFromDetail = () => {
        const price = selectedProduct.discountedPrice ? selectedProduct.discountedPrice : selectedProduct.price;
        addCartItem({
            productId: selectedProduct.id,
            name: selectedProduct.name,
            quantity: productDetailQuantity,
            unit: selectedProduct.unit,
            fixedPrice: price,
            priceStrategy: 'fixed'
        });
        showSnackbar("Sepete Eklendi");
        setProductDetailOpen(false);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const showSnackbar = (message) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    return (
        <Grid className={classes.root}>
            <Grid item xs={12} className={classes.campaignHeader}>
                {data.supplierImage && (
                    <img
                        src={`data:image/jpeg;base64,${data.supplierImage}`}
                        alt="Supplier Logo"
                        className={classes.supplierImage}
                    />
                )}
                <Typography className={classes.headerTextMedium}>
                    {`${supplierName} ${data.name ? data.name : `Büyük İndirim `} Kampanyası`}
                </Typography>
                <Typography className={classes.headerTextLarge}>
                    {averageDiscount ? `%${averageDiscount}'a Varan İndirim` : ``}
                </Typography>
                <Typography className={classes.urgencyText}>
                    {dateRangeText || "Sadece Bugün Geçerli! Acele Edin!"}
                </Typography>
                {minimumOrderAmount > 0 && (
                    <Typography className={classes.minOrderBadge}>
                        Minimum Sipariş Tutarı: {minimumOrderAmount}₺
                    </Typography>
                )}
                {targetDate && !isNaN(targetDate.getTime()) && (
                    <Countdown
                        date={targetDate}
                        renderer={({ days, hours, minutes, seconds, completed }) => {
                            if (completed) {
                                return <span>Fırsat Süresi Doldu!</span>;
                            } else {
                                return (
                                    <span className={classes.countdown}>
                                        Kalan Süre: {days > 0 ? `${days} gün ` : ''}{hours}:{minutes}:{seconds}
                                    </span>
                                );
                            }
                        }}
                    />
                )}
            </Grid>
            <Box className={classes.content}>
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                        <CircularProgress />
                        <Typography variant="subtitle1" style={{ marginLeft: '16px' }}>Ürünler Yükleniyor...</Typography>
                    </Box>
                ) : (
                    <Grid container className={classes.gridContent}>
                        <Grid item xs={12} md={9} lg={9}>
                            <Grid container spacing={2} style={{ marginTop: 5, marginLeft: '-8px' }}>
                                {data.products?.map((item) => (
                                    <Grid item xs={6} sm={4} md={3} key={item.id}>
                                        <Card className={classes.card} onClick={(e) => {
                                            e.stopPropagation();
                                            handleProductClick(item)
                                        }}>
                                            {item.types.includes('Yeni Ürün') && <div className={classes.hotLabel}>Yeni Ürün</div>}
                                            {item.types.includes('Stokta') && <div className={classes.stockLabel}>Stokta</div>}
                                            {item.discountedPrice && (
                                                <div className={classes.discountLabel}>
                                                    %{Math.round(((item.price - item.discountedPrice) / item.price) * 100)}
                                                </div>
                                            )}
                                            <img
                                                src={item.imageUrl || Pictures['Genel'].source.default}
                                                alt={item.name}
                                                className={classes.image}
                                            />
                                            <Typography variant="body1" noWrap>
                                                {item.name}
                                            </Typography>
                                            <div className={classes.priceSection}>
                                                {item.price ? (
                                                    item.discountedPrice ? (
                                                        <>
                                                            <Typography variant="body2" className={classes.originalPrice}>
                                                                {item.price}₺
                                                            </Typography>
                                                            <Typography variant="body1" className={classes.price}>
                                                                {item.discountedPrice}₺
                                                            </Typography>
                                                        </>
                                                    ) : (
                                                        <Typography variant="body1" className={classes.price}>
                                                            {item.price}₺
                                                        </Typography>
                                                    )
                                                ) : (
                                                    <Typography className={classes.noPrice}>Fiyat Belirtilmemiş</Typography>
                                                )}
                                            </div>
                                            <div className={classes.addToCartSection}>
                                                <IconButton onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleRemoveQuantity(item)
                                                }}>
                                                    <RemoveIcon />
                                                </IconButton>
                                                <TextField
                                                    type="number"
                                                    value={getQuantity(item.id)}
                                                    label={item.unit}
                                                    onChange={(e) => handleQuantityChange(item.id, parseInt(e.target.value))}
                                                    InputProps={{ inputProps: { min: 0 } }}
                                                />
                                                <IconButton onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleAddQuantity(item)
                                                }}>
                                                    <AddIcon />
                                                </IconButton>
                                            </div>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>

                        <Grid item lg={2.8} sm={3} xs={false} className={classes.defaultCartView}>
                            {!cart?.length ? <GuestEmptyView /> : <CampaignCartView supplier={data?.supplierName} linkIdentifier={campaignId} minOrder={minimumOrderAmount} />}
                        </Grid>

                        <IconButton
                            className={classes.floatingCartButton}
                            color="primary"
                            onClick={toggleCartModal}
                        >
                            <ShoppingCart className={classes.cartIcon} />
                        </IconButton>

                        <Snackbar
                            open={snackbarOpen}
                            autoHideDuration={3000}
                            onClose={handleSnackbarClose}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        >
                            <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                                {snackbarMessage}
                            </Alert>
                        </Snackbar>
                    </Grid>
                )}
            </Box>

            <Dialog open={productDetailOpen} onClose={() => setProductDetailOpen(false)} maxWidth="md" fullWidth>
                <DialogTitle>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        Ürün Detayları
                        <IconButton onClick={() => setProductDetailOpen(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Box
                                component="img"
                                sx={{
                                    maxHeight: '300px',
                                    maxWidth: '100%',
                                    width: 'auto',
                                    height: 'auto',
                                    objectFit: 'contain',
                                    borderRadius: '4px',
                                    boxShadow: '0 8px 24px rgba(0,0,0,0.12)'
                                }}
                                src={selectedProduct?.imageUrl || Pictures[selectedProduct?.mainCategory]?.source.default || Pictures['Genel'].source.default}
                                alt={selectedProduct?.name}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5" gutterBottom>
                                {selectedProduct?.name}
                            </Typography>
                            <Box my={2}>
                                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                    Marka
                                </Typography>
                                <Typography variant="body1">
                                    {selectedProduct?.brand || 'Bilgi Yok'}
                                </Typography>
                            </Box>
                            <Box my={2}>
                                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                    Açıklama
                                </Typography>
                                <Typography variant="body2">
                                    {selectedProduct?.description || 'Bilgi Yok'}
                                </Typography>
                            </Box>
                            <Box my={2}>
                                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                    Fiyat
                                </Typography>
                                {selectedProduct?.price ? (
                                    selectedProduct?.discountedPrice ? (
                                        <Box>
                                            <Typography variant="body2" className={classes.originalPrice}>
                                                {`₺${selectedProduct.price}`}
                                            </Typography>
                                            <Typography variant="body1" className={classes.price}>
                                                {`₺${selectedProduct.discountedPrice}`}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Typography variant="body1" className={classes.price}>
                                            {`₺${selectedProduct.price}`}
                                        </Typography>
                                    )
                                ) : (
                                    <Typography variant="body2" className={classes.noPrice}>
                                        Fiyat Belirtilmemiş
                                    </Typography>
                                )}
                            </Box>
                            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                Miktar
                            </Typography>
                            <TextField
                                fullWidth
                                type="number"
                                value={productDetailQuantity}
                                onChange={(e) => setProductDetailQuantity(Math.max(1, parseInt(e.target.value, 10) || 1))}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{selectedProduct?.unit}</InputAdornment>,
                                }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<ShoppingCartIcon />}
                                onClick={handleAddToCartFromDetail}
                                sx={{
                                    mt: 2,
                                    '&:hover': {
                                        bgcolor: 'primary.main',
                                        '@media (hover: none)': {
                                            bgcolor: 'primary.main',
                                        },
                                    },
                                }}
                            >
                                Sepete Ekle
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setProductDetailOpen(false)} color="primary">
                        Kapat
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={cartModalOpen} onClose={toggleCartModal} fullWidth maxWidth="sm">
                <DialogContent>
                    {!cart?.length ? <GuestEmptyView /> : <CampaignCartView supplier={data?.supplierName} linkIdentifier={campaignId} minOrder={minimumOrderAmount} />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleCartModal} color="primary">
                        Kapat
                    </Button>
                </DialogActions>
            </Dialog>

            <div className={classes.footer}>
                <div className={classes.footerContent}>
                    <Typography className={classes.footerText}>
                        Hemen alışveriş yapın ve tasarruf etmeye başlayın! 500₺ harcayın ve sonraki ziyaretinizde kullanmak için 50₺ kupon kazanın!
                    </Typography>
                    <RouterLink to={"/"} className={classes.linkDecoration}>
                        <Logo height={80} width={80} />
                    </RouterLink>
                </div>
                <Typography className={classes.contactInfo}>
                    0534 077 45 06
                </Typography>

                <div className={classes.footerLinksContainer}>
                    <Typography>
                        <RouterLink to="/help" target="_blank" className={classes.footerLink}>
                            İletişim
                        </RouterLink>
                    </Typography>
                    <Typography>
                        <RouterLink to="/help" target="_blank" className={classes.footerLink}>
                            Teslimat ve İade Şartları
                        </RouterLink>
                    </Typography>
                </div>
            </div>

            <Box className={classes.footerContainer}>
                <Box className={classes.socialMediaContainer}>
                    <IconButton href="https://www.facebook.com" target="_blank" className={classes.socialIcon}><Facebook /></IconButton>
                    <IconButton href="https://twitter.com" target="_blank" className={classes.socialIcon}><Twitter /></IconButton>
                    <IconButton href="https://www.instagram.com" target="_blank" className={classes.socialIcon}><Instagram /></IconButton>
                    <IconButton href="https://www.linkedin.com" target="_blank" className={classes.socialIcon}><LinkedIn /></IconButton>
                </Box>
                <Typography variant="body2" className={classes.lastFooter}>
                    © 2024 TedaBir. Tüm Hakları Saklıdır.
                </Typography>
            </Box>
        </Grid>
    );
}

export default React.memo(Campaign);
