import React, { useState, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
  Divider
} from "@mui/material";
import useStyles from "./styles.js";
import { Header } from "../../components/Header";
import { addFeedback } from "../../api/restaurant_api.js";
import Error from "../../components/Modals/Error/Error.js";
import NoteAdded from "../../components/Modals/NoteAdded/NoteAdded.js";
import RestaurantContext from "../../context/Restaurant.js";

function FeedbackScreen() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [feedbackAnswers, setFeedbackAnswers] = useState({
    generalSatisfaction: "",
    recommendProduct: "",
    usability: "",
    bestFeatures: "",
    missingFeatures: "",
    customerServiceExperience: "",
    technicalIssues: "",
    note: ""
  });
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [addedModal, setAddedModal] = useState(false);
  const { restaurantId } = useContext(RestaurantContext);

  const handleTextFieldChange = (event) => {
    setFeedbackAnswers({ ...feedbackAnswers, [event.target.name]: event.target.value });
  };

  const handleRadioChange = (name) => (event) => {
    setFeedbackAnswers({ ...feedbackAnswers, [name]: event.target.value });
  };

  const handleSubmit = async () => {
    try {
      const finalData = {
        ...feedbackAnswers,
        userId: restaurantId,
        userType: 'RETAIL'
      }
      const response = await addFeedback(finalData);
      if (response == null || response.status !== 200) {
        setErrorModalOpen(true);
        setTimeout(() => {
          setErrorModalOpen(false);
        }, 3000);
        return;
      }
      if (response && response.status === 200) {
        setAddedModal(true);
        setTimeout(() => {
          setAddedModal(false);
          navigate("/");
        }, 4000);
        return;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const toggleAddedModal = useCallback(() => {
    setAddedModal((prev) => !prev);
    navigate("/");
  }, []);

  return (
    <Grid container className={classes.root}>
      <Header />
      <Grid container item className={classes.marginHeader}>
        <Grid container item xs={12} className={classes.mainContainer}>
          <Grid item xs={12} className={classes.title}>
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              className={classes.textBold}
            >
              Ürün Geri Bildirim Formu
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
            md={8}
            lg={6}
            className={classes.profileContainer}
          >
            <Divider light orientation="horizontal" className={classes.MH3} />
            <Box className={classes.questionContainer}>
              <Typography variant="h6" className={classes.questionTitle}>
                Genel memnuniyet düzeyiniz nedir?
              </Typography>
              <RadioGroup
                row
                name="generalSatisfaction"
                onChange={handleRadioChange('generalSatisfaction')}
                value={feedbackAnswers.generalSatisfaction}
              >
                <FormControlLabel value="verySatisfied" control={<Radio />} label="Çok Memnunum" />
                <FormControlLabel value="satisfied" control={<Radio />} label="Memnunum" />
                <FormControlLabel value="neutral" control={<Radio />} label="Ne Memnun Ne Memnun Değilim" />
                <FormControlLabel value="dissatisfied" control={<Radio />} label="Memnun Değilim" />
                <FormControlLabel value="veryDissatisfied" control={<Radio />} label="Hiç Memnun Değilim" />
              </RadioGroup>
            </Box>
            <Box className={classes.questionContainer}>
              <Typography variant="h6" className={classes.questionTitle}>
                Bu ürünü başkalarına tavsiye eder misiniz?
              </Typography>
              <RadioGroup
                row
                name="recommendProduct"
                onChange={handleRadioChange('recommendProduct')}
                value={feedbackAnswers.recommendProduct}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Evet" />
                <FormControlLabel value="no" control={<Radio />} label="Hayır" />
              </RadioGroup>
            </Box>
            <Box className={classes.questionContainer}>
              <Typography variant="h6" className={classes.questionTitle}>
                Ürünün kullanım kolaylığı hakkında ne düşünüyorsunuz?
              </Typography>
              <TextField
                name="usability"
                fullWidth
                multiline
                rows={2}
                variant="outlined"
                placeholder="Yorumunuzu buraya yazınız"
                onChange={handleTextFieldChange}
                value={feedbackAnswers.usability}
              />
            </Box>
            <Box className={classes.questionContainer}>
              <Typography variant="h6" className={classes.questionTitle}>
                Hangi özellikler ürün deneyiminizi daha iyi hale getirdi?
              </Typography>
              <TextField
                name="bestFeatures"
                fullWidth
                multiline
                rows={2}
                variant="outlined"
                placeholder="En beğendiğiniz özellikler"
                onChange={handleTextFieldChange}
                value={feedbackAnswers.bestFeatures}
              />
            </Box>
            <Box className={classes.questionContainer}>
              <Typography variant="h6" className={classes.questionTitle}>
                Hangi özellikler veya işlevler eksik veya geliştirilebilir?
              </Typography>
              <TextField
                name="missingFeatures"
                fullWidth
                multiline
                rows={2}
                variant="outlined"
                placeholder="Eksik veya geliştirilebilecek özellikler"
                onChange={handleTextFieldChange}
                value={feedbackAnswers.missingFeatures}
              />
            </Box>
            <Box className={classes.questionContainer}>
              <Typography variant="h6" className={classes.questionTitle}>
                Müşteri hizmetleri deneyiminiz nasıldı?
              </Typography>
              <TextField
                name="customerServiceExperience"
                fullWidth
                multiline
                rows={2}
                variant="outlined"
                placeholder="Müşteri hizmetleri hakkındaki düşünceleriniz"
                onChange={handleTextFieldChange}
                value={feedbackAnswers.customerServiceExperience}
              />
            </Box>
            <Box className={classes.questionContainer}>
              <Typography variant="h6" className={classes.questionTitle}>
                Ürünle ilgili herhangi bir teknik sorun yaşadınız mı?
              </Typography>
              <TextField
                name="technicalIssues"
                fullWidth
                multiline
                rows={2}
                variant="outlined"
                placeholder="Karşılaştığınız teknik sorunlar"
                onChange={handleTextFieldChange}
                value={feedbackAnswers.technicalIssues}
              />
            </Box>

            <Box className={classes.questionContainer}>
              <Typography variant="h6" className={classes.questionTitle}>
                Eklemek istediğiniz başka birşey var mı?
              </Typography>
              <TextField
                name="note"
                fullWidth
                multiline
                rows={2}
                variant="outlined"
                onChange={handleTextFieldChange}
                value={feedbackAnswers.note}
              />
            </Box>

            <Grid container justifyContent={"center"} xs={11} md={8} marginLeft={5}>
              <Button
                variant="contained"
                color="primary"
                className={classes.submitButton}
                onClick={handleSubmit}
              >
                Gerİ Bİldİrİm Gönder
              </Button>
            </Grid>
          </Grid>

          <Error
            isVisible={errorModalOpen}
            toggleModal={() => setErrorModalOpen(false)}
          />
          <NoteAdded
            isVisible={addedModal}
            toggleModal={toggleAddedModal}
            title="Geri Bildiriminiz Alınmıştır!"
            detail="Ürünümüzü Geliştirmeye Katkı Verdiğiniz İçin Teşekkür Ederiz!"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FeedbackScreen;
