import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, Card as ICard, CardActionArea, Chip, IconButton, Tooltip } from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AssessmentIcon from '@mui/icons-material/Assessment';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ListAltIcon from '@mui/icons-material/ListAlt';
import useStyles from './styles';
import { Header } from '../../components/Header';
import { useLocation } from 'react-router-dom';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import WelcomeModal from "../../components/Modals/WelcomeModal/WelcomeModal";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EventNoteIcon from '@mui/icons-material/EventNote';

const mainItemDescriptions = {
  'Tedarikçilerim': 'Tedarikçilerinizi eklemeye başlamak için buraya tıklayın!',
  'Market': 'Eksik ürünlerinizi karşılamak için markete göz atın!',
  'Siparişlerim': 'Siparişlerinizi görüntülemek ve yönetmek için buraya tıklayın!',
  'Raporlar': 'İşletmenizin performansını analiz etmek ve geliştirmek için raporlara buradan ulaşabilirsiniz!'
};

const smallItemDescriptions = {
  'Haftalık Planlayıcım': 'Haftalık siparişlerinizi planlamak ve organize etmek için bu bölümü kullanabilirsiniz!',
  'Alışveriş Listelerim': 'Alışveriş listelerinizi bu bölümde oluşturabilir ve hızlıca siparişe dönüştürebilirsiniz!',
  'Geri Bildirim Ver': 'Deneyimlerinizi paylaşarak hizmetlerimizi geliştirmemiza katkıda bulunabilirsiniz!'
};

function Summary() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [startTour, setStartTour] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.state?.fromAddressPage) {
      setShowWelcomeModal(true);
    }
  }, [location.state]);

  useEffect(() => {
    if (startTour) {
      introJs().setOptions({
        steps: [
          ...mainItems.map(item => ({
            element: document.querySelector(`.${item.title.replace(/\s+/g, '-')}-card`),
            intro: mainItemDescriptions[item.title] || 'Description not available'
          })),
          ...smallItems.map(item => ({
            element: document.querySelector(`.${item.title.replace(/\s+/g, '-')}-card`),
            intro: smallItemDescriptions[item.title] || 'Description not available'
          })),
        ],
        showBullets: true,
        showProgress: true,
        exitOnOverlayClick: false,
        doneLabel: "Hadi Başlayalım!",
        nextLabel: "İleri",
        prevLabel: "Geri",
        skipLabel: "Atla",
      }).start();
    }
  }, [startTour]);

  const handleWhatsAppClick = () => {
    window.open('https://wa.me/+905340774506', '_blank');
  };

  const toggleWelcomeModal = useCallback(() => {
    setShowWelcomeModal((prev) => !prev);
  }, []);

  const mainItems = [
    { title: 'Tedarikçilerim', icon: <GroupsIcon />, route: '/supplier', color: 'purple' },
    // { title: 'Market', icon: <StorefrontIcon />, route: '/market', color: 'green' },
    { title: 'Siparişlerim', icon: <ShoppingCartIcon />, route: '/orders', color: 'blue' },
    { title: 'Raporlar', icon: <AssessmentIcon />, route: '/', status: 'Çok Yakında!', color: 'orange' },
  ];

  const smallItems = [
    // { title: 'Haftalık Planlayıcım', icon: <EventNoteIcon />, route: '/', status: 'Çok Yakında!', color: 'orange' },
    { title: 'Alışveriş Listelerim', icon: <ListAltIcon />, route: '/shopList', status: 'Yeni!', statusColor: 'success', color: 'green' },
    { title: 'Geri Bildirim Ver', icon: <FeedbackIcon />, route: '/feedback', color: 'teal' },
  ];

  const renderItem = (item, isSmall) => (
    <Grid
      item
      xs={6}
      sm={6}
      md={3}
      lg={3}
      xl={3}
      className={isSmall ? classes.smallCardMargin : ''}
    >
      <ICard
        className={`${classes.card} ${isSmall ? classes.smallCard : ''} ${item.title.replace(/\s+/g, '-')}-card`}
      >
        <CardActionArea
          onClick={() => {
            navigate(item.route);
          }}
          className={classes.cardActionArea}
        >
          <div className={classes.cardContent}>
            <div className={classes.iconTextContainer}>
              {React.cloneElement(item.icon, { style: { fontSize: 40, color: '#FF6B5E' } })}
              <Typography
                variant="subtitle1"
                color="#555"
                className={classes.textBold}
              >
                {item.title}
              </Typography>
            </div>
            {item.status && (
              <Chip
                label={item.status}
                className={classes.statusChip}
                color={item.statusColor || 'default'}
              />
            )}
          </div>
        </CardActionArea>
      </ICard>
    </Grid>
  );

  return (
    <Grid container>
      <Header />
      <Grid container item className={classes.mainContainer}>
        <Grid item xs={12} sm={10} className={classes.contentContainer}>
          <Grid container spacing={2} className={classes.PV3}>
            {mainItems.map(item => renderItem(item))}
            {smallItems.map(item => renderItem(item, true))}
            <Tooltip title={'Her türlü ihtiyacınız için WhatsApp üzerinden bize ulaşabilirsiniz.'}>
              <IconButton
                className={classes.whatsappFab}
                onClick={handleWhatsAppClick}
                aria-label="WhatsApp ile iletişim"
              >
                <WhatsAppIcon />
              </IconButton>
            </Tooltip>
            <WelcomeModal
              isVisible={showWelcomeModal}
              toggleModal={toggleWelcomeModal}
              onStartTour={() => setStartTour(true)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Summary;
