import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  activeCardContainer: {
    backgroundColor: theme.palette.common.white,
  },
  cardContainer: {
    backgroundColor: theme.palette.common.white,
    marginTop: '20px',
    border: '1px solid #E0E0E0',
    marginBottom: '1%'
  },
  itemContainer: {
    display: "flex",
    flexDirection: "column",  // Added Flex Direction
    justifyContent: "space-between",  // Ensures max space between top and bottom content
    padding: "15px",
    cursor: "pointer",
    height: '120px',
    marginBottom: '3%',
    position: 'relative', // Added to enable positioning of status
    minHeight: '120px',
    width: '400px',
    [theme.breakpoints.down('sm')]: {
      width: '300px',
    }
  },
  status: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: "#7D7D7D",
    padding: "5px 10px",
    borderRadius: "0px 0px 0px 20px", // Adjust the border radius as needed
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end', // Aligns buttons to the right
    alignItems: 'center',
    width: '100%',
    marginTop: 'auto',
    gap: theme.spacing(3), // Adjust the space between buttons
  },
  titleText: {
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  boldText: {
    fontWeight: 700,
  },
  itemDesc: {
    color: theme.palette.text.disabled,
    fontSize: "0.875rem",
  },
  discountText: {
    color: theme.palette.text.disabled,
    fontWeight: theme.typography.fontWeightLight,
    fontSize: "0.875rem",
    textDecoration: "line-through",
    marginLeft: "5px",
  },
  imageContainer: {
    width: "88px",
    height: "88px",
    backgroundSize: "cover",
    backgroundPositionX: "center",
    backgroundPositionY: "center",
    position: "relative",
  },
  addContainer: {
    backgroundColor: "#FF6B5E",
    padding: "1px",
    borderRadius: "inherit",
    minWidth: "auto",
    position: "absolute",
    right: 0,
    bottom: 0,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  updateButton: {
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
    marginRight: theme.spacing(1),
  },
  btnBase: {
    borderRadius: "0px",
    height: "50px",
  },
  orderContent: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap', // Prevents the text from wrapping to the new line
  },
}));

export default useStyles;
