import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Grid
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useCallback, useContext } from "react";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import RestaurantContext from "../../../context/Restaurant";

function RepeatMarketOrder({ isVisible, toggleModal, products, deliveryFee, minimumOrder, availableProducts, supplierId, supplierCategory, supplierBusinessName }) {

  const classes = useStyles();
  const navigateTo = useNavigate();
  const { addCartItem } = useContext(RestaurantContext);
  const getProductAvailability = (productId) => {
    const product = availableProducts.find(p => p.id === productId);
    return product || { isAvailable: false, availableAmount: 0, price: 0, unit: '' };
  };

  const navigateToSupplier = useCallback(() => {
    navigateTo(`/market/${supplierId}`, {
      state: {
        id: supplierId,
        category: supplierCategory,
      },
    });
  }
  );

  const handleAddToCart = () => {
    products.forEach((product) => {
      const availableProduct = availableProducts.find(p => p.id === product.productId);
      if (availableProduct && availableProduct.isAvailable) {
        addCartItem(
          {
            id: supplierId,
            name: supplierBusinessName,
            minimumOrder: minimumOrder,
            deliveryFee: deliveryFee
          },
          availableProduct.id,
          availableProduct.name,
          product.amount < availableProduct.availableAmount ? product.amount : availableProduct.availableAmount,
          availableProduct.discountedPrice ? availableProduct.discountedPrice : availableProduct.price,
          availableProduct.unit
        );
      }
      navigateTo('/checkout');
    });
  }

  return (
    <Dialog
      onClose={(e) => {
        e.stopPropagation();
        e.preventDefault();
        toggleModal();
      }}
      open={isVisible}
      fullWidth
      maxWidth="md"
      className={classes.root}
      onClick={(e) => e.stopPropagation()}
    >
      <Box display="flex" justifyContent="flex-end">
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            toggleModal();
          }}
          className={classes.closeContainer}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogTitle className={classes.titleText}>Ürün Stok Kontrolü</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {products?.map((item) => {
            const availability = getProductAvailability(item.productId);
            return (
              <Grid item xs={12} key={item.productId} className={classes.MB2}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography style={{ color: availability.isAvailable ? 'inherit' : 'red' }}>
                    {item.productName} - {availability.isAvailable ? `Mevcut Miktar: ${item.amount < availability.availableAmount ? item.amount : availability.availableAmount} ${availability.unit} 
                     - Fiyat: ${availability.discountedPrice ? availability.discountedPrice : availability.price}₺` : 'Mevcut Değil'}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              style={{
                borderRadius: 30,
                backgroundColor: "#FF6B5E",
                padding: "18px 36px",
                fontSize: "14px"
              }}
              fullWidth
              onClick={(e) => {
                e.preventDefault();
                handleAddToCart();
              }}
              className={classes.btnBase}
            >
              Tümünü Sepete Ekle
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              style={{
                borderRadius: 30,
                backgroundColor: "#7D7D7D",
                padding: "18px 36px",
                fontSize: "14px"
              }}
              fullWidth
              onClick={(e) => {
                e.preventDefault();
                navigateToSupplier();
              }}
              className={classes.btnBase}
            >
              Satıcının Sayfasına Git
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}

export default React.memo(RepeatMarketOrder);
