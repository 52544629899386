import {
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback, useContext, useState, useEffect } from "react";
import FlashMessage from "../../FlashMessage";
import useStyle from "./styles";
import RestaurantContext from "../../../context/Restaurant";
import { setAddress } from "../../../api/restaurant_api";

function AddressCard() {
  const theme = useTheme();
  const classes = useStyle();

  const [addressInfoError, setAddressInfoError] = useState("");
  const [townError, setTownError] = useState("");

  const { address, setAddressInfo, profile } = useContext(RestaurantContext);
  const [error, setError] = useState({});

  const [data, setData] = useState({ addressInfo: "", town: "", city: "" });

  useEffect(() => {
    if (address) {
      setData({
        addressInfo: address.addressInfo || "",
        town: address.town || "",
        city: address.city || ""
      });
    }
  }, [address]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const clearErrors = useCallback(() => {
    setAddressInfoError("");
    setTownError("");
    setError({});
  }, []);

  const handleAction = async () => {
    clearErrors();
    let validate = true;

    if (!data.addressInfo) {
      setAddressInfoError("Adres zorunludur");
      validate = false;
      return;
    }
    if (!data.town) {
      setTownError("İlçe zorunludur");
      validate = false;
      return;
    }
    const updateData = {
      restaurantId: profile.id,
      address: data.addressInfo,
      district: data.town,
      city: data.city,
    }
    try {
      const response = await setAddress(updateData);
      if (response === null || response.status === undefined || response.status !== 200) {
        setError({ type: "error", message: "Kısa süreli bir sistem problemi yaşanmaktadır, Özür Dileriz! Lütfen En Kısa Zamanda Tekrar Deneyin!" });
      }
      else if (response !== undefined && response !== null && response.status === 200) {
        await setAddressInfo({
          addressInfo: data.addressInfo,
          town: data.town,
          city: data.city,
        })
        setError({ type: "success", message: "Adres bilgileriniz başarıyla güncellenmiştir!" });
      }
    } catch (error) {
      setError({ type: "error", message: "Kısa süreli bir sistem problemi yaşanmaktadır, Özür Dileriz! Lütfen En Kısa Zamanda Tekrar Deneyin!" });
    }

  };

  const toggleSnackbar = useCallback(() => {
    setError({});
  }, []);

  return (
    <Grid container item xs={12} className={classes.mainContainer}>
      <FlashMessage
        open={Boolean(error.type)}
        severity={error.type}
        alertMessage={error.message}
        handleClose={toggleSnackbar}
      />
      <Grid item xs={12}>
        <Typography
          variant="h6"
          align="center"
          color="textSecondary"
          className={classes.textBold}
        >
          Adres Bilgilerim
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={10}
        md={8}
        lg={6}
        className={classes.profileContainer}
      >
        <Divider light orientation="horizontal" className={classes.MH3} />
        <TextField
          name="addressInfo"
          variant="outlined"
          label="Adres"
          value={data.addressInfo}
          onChange={handleChange}
          error={Boolean(addressInfoError)}
          helperText={addressInfoError}
          fullWidth
          InputLabelProps={{
            style: {
              color: theme.palette.grey[600],
            },
          }}
        />
        <TextField
          name="town"
          variant="outlined"
          label="İlçe"
          value={data.town}
          onChange={handleChange}
          error={Boolean(townError)}
          helperText={townError}
          fullWidth
          InputLabelProps={{
            style: {
              color: theme.palette.grey[600],
            },
          }}
        />
        <TextField
          variant="outlined"
          label="İl"
          value={data.city}
          fullWidth
          disabled
          InputLabelProps={{
            style: {
              color: theme.palette.grey[600],
            },
          }}
        />

        <Grid item xs={12} className={classes.btnContainer}>
          <Button
            disableElevation
            variant="contained"
            style={{
              borderRadius: 30,
              backgroundColor: "#FF6B5E",
              padding: "18px 36px",
              fontSize: "18px"
            }}
            onClick={(e) => {
              e.preventDefault();
              handleAction();
            }}
          >
            <Typography
              variant="body2"
              color="secondary"
              className={classes.textBold}
            >
              Kaydet
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default React.memo(AddressCard);
