/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, Grid, Typography, Tabs, Tab } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { getAllOrders } from "../../api/order_api";
import FlashMessage from "../../components/FlashMessage";
import { Header } from "../../components/Header";
import { ActiveOrderCard, PastOrderCard } from "../../components/Orders";
import RestaurantContext from "../../context/Restaurant";
import useStyles from "./styles";

function MyOrders() {
  const classes = useStyles();
  const [error, setError] = useState({});
  const [activeOrders, setActiveOrders] = useState([]);
  const [pastOrders, setPastOrders] = useState([]);
  const [refetchTrigger, setRefetchTrigger] = useState(false);
  const { profile } = useContext(RestaurantContext);

  useEffect(() => {
    fetchData();
  }, [refetchTrigger]);

  const fetchData = async () => {
    try {
      const response = await getAllOrders(profile.id);
      if (response !== undefined && response.status === 200 && response.data !== undefined) {
        setActiveOrders(response.data?.ACTIVE);
        setPastOrders(response.data?.INACTIVE);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleOrderCreated = () => {
    setRefetchTrigger(prev => !prev);
    setTabValue(0);
  };

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Grid container className={classes.root}>
      <FlashMessage
        open={Boolean(error.type)}
        severity={error.type}
        alertMessage={error.message}
      />
      <Header />

      <Grid container item className={classes.mainContainer}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          className={classes.tabs}
        >
          <Tab label="Aktİf Sİparİşler" />
          <Tab label="Geçmİş Sİparİşler" />
        </Tabs>
        {tabValue === 0 && (
          <Grid container className={classes.center}>
            <Grid container item className={classes.center}>
              <Grid item xs={12} className={classes.title}>
                <Typography variant="h5" align="center" color="textSecondary" className={classes.textBold}>
                  Aktif Siparişler
                </Typography>
              </Grid>
              <Grid item xs={12} sm={10} md={8} lg={6} className={classes.contentContainer}>
                <Divider orientation="horizontal" className={classes.line} />
                {activeOrders?.length > 0 ? activeOrders.map((item) => (
                  <>
                    <ActiveOrderCard key={item.orderId} {...item} />
                    <Divider orientation="horizontal" className={classes.line} />
                  </>
                )) : <Typography variant="body1" color="textSecondary">
                  Aktif siparişiniz bulunmamaktadır.
                </Typography>
                }
              </Grid>
            </Grid>
          </Grid>
        )}
        {tabValue === 1 && (
          <Grid container className={classes.center}>
            <Grid container item className={classes.center}>
              <Grid item xs={12} className={classes.title}>
                <Typography variant="h5" align="center" color="textSecondary" className={classes.textBold}>
                  Geçmiş Siparişler
                </Typography>
              </Grid>
              <Grid item xs={12} sm={10} md={8} lg={6} className={classes.contentContainer}>
                <Divider orientation="horizontal" className={classes.line} />
                {pastOrders?.length > 0 ? pastOrders.map((item) => (
                  <>
                    <PastOrderCard key={item.orderId} item={item} handleOrderCreated={handleOrderCreated}/>
                    <Divider orientation="horizontal" className={classes.line} />
                  </>
                )) : <Typography variant="body1" color="textSecondary">
                  Geçmiş siparişiniz bulunmamaktadır.
                </Typography>}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default MyOrders;
