import {
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback, useContext, useState, useEffect } from "react";
import FlashMessage from "../../FlashMessage";
import useStyle from "./styles";
import RestaurantContext from "../../../context/Restaurant";
import { updateRetail } from "../../../api/restaurant_api";

function ProfileCard() {
  const theme = useTheme();
  const classes = useStyle();

  const [personNameError, setPersonNameError] = useState("");

  const { profile, setProfileAndToken } = useContext(RestaurantContext);
  const [error, setError] = useState({});

  const [data, setData] = useState({ businessName: "", personName: "", email: "", phone: "", address: "" });

  useEffect(() => {
    if (profile) {
      setData({
        businessName: profile.businessName || "",
        personName: profile.personName || "",
        email: profile.email || "",
        phone: profile.phoneNumber || "",
      });
    }
  }, [profile]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const clearErrors = useCallback(() => {
    setPersonNameError("");
    setError({});
  }, []);

  const handleAction = async () => {
    clearErrors();
    let validate = true;

    if (!data.personName) {
      setPersonNameError("İsim zorunlu");
      validate = false;
      return;
    }
    const updateData = {
      personName: data.personName,
      email: data.email,
    }
    try {
      const response = await updateRetail(profile.id, updateData);
      if (response === null || response.status === undefined || response.status !== 200) {
        setError({ type: "error", message: "Kısa süreli bir sistem problemi yaşanmaktadır, Özür Dileriz! Lütfen En Kısa Zamanda Tekrar Deneyin!" });
      }
      else if (response !== undefined && response !== null && response.status === 200) {
        // set user profile here too
        profile.personName = data.personName
        await setProfileAndToken(profile);
        setError({ type: "success", message: "Bilgileriniz başarıyla güncellenmiştir!" });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError({ type: "error", message: "Kısa süreli bir sistem problemi yaşanmaktadır, Özür Dileriz! Lütfen En Kısa Zamanda Tekrar Deneyin!" });
    }
  };

  const toggleSnackbar = useCallback(() => {
    setError({});
  }, []);

  return (
    <Grid container item xs={12} className={classes.mainContainer}>
      <FlashMessage
        open={Boolean(error.type)}
        severity={error.type}
        alertMessage={error.message}
        handleClose={toggleSnackbar}
      />
      <Grid item xs={12}>
        <Typography
          variant="h6"
          align="center"
          color="textSecondary"
          className={classes.textBold}
        >
          Bilgilerim
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={10}
        md={8}
        lg={6}
        className={classes.profileContainer}
      >
        <Divider light orientation="horizontal" className={classes.MH3} />
        <TextField
          name="businessName"
          variant="outlined"
          label="İşletme İsmi"
          value={data.businessName}
          onChange={handleChange}
          fullWidth
          disabled
          InputLabelProps={{
            style: {
              color: theme.palette.grey[600],
            },
          }}
        />
        <TextField
          name="personName"
          variant="outlined"
          label="İşletme Sorumlusu"
          value={data.personName}
          onChange={handleChange}
          error={Boolean(personNameError)}
          helperText={personNameError}
          fullWidth
          InputLabelProps={{
            style: {
              color: theme.palette.grey[600],
            },
          }}
        />
        <TextField
          name={"phone"}
          variant="outlined"
          label="Telefon Numarası"
          value={data.phone}
          onChange={handleChange}
          fullWidth
          disabled
          InputLabelProps={{
            style: {
              color: theme.palette.grey[600],
            },
          }}
        />
        <TextField
          name="email"
          variant="outlined"
          label="Mail Adresi"
          value={data.email}
          onChange={handleChange}
          fullWidth
          InputLabelProps={{
            style: {
              color: theme.palette.grey[600],
            },
          }}
        />
        <Grid item xs={12} className={classes.btnContainer}>
          <Button
            disableElevation
            variant="contained"
            style={{
              borderRadius: 30,
              backgroundColor: "#FF6B5E",
              padding: "18px 36px",
              fontSize: "18px"
            }}
            onClick={(e) => {
              e.preventDefault();
              handleAction();
            }}
          >
            <Typography
              variant="body2"
              color="secondary"
              className={classes.textBold}
            >
              Kaydet
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default React.memo(ProfileCard);
