import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginTop: "70px",
    padding: theme.spacing(2),
    backgroundColor: "#f7f7f7",
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(5),
      marginTop: "80px",
    },
  },
  videoContainer: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    position: 'relative',
    overflow: 'hidden',
    '&::before': {
      content: '""',
      display: 'block',
      paddingTop: '56.25%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '800px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

export default useStyles;