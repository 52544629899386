import { Divider, Grid, TextField, Typography, useTheme } from "@mui/material";
import React, { useCallback, useState, useContext } from "react";
import FlashMessage from "../../FlashMessage";
import useStyle from "./styles";
import InputAdornment from "@mui/material/InputAdornment";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { changePwd } from "../../../api/restaurant_api";
import RestaurantContext from "../../../context/Restaurant";

function PasswordCard() {
  const theme = useTheme();
  const classes = useStyle();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [oldPasswordError, setOldPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const { profile } = useContext(RestaurantContext);
  const [error, setError] = useState({});

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleClickShowOldPassword = () => setShowOldPassword(!showOldPassword);

  const clearErrors = useCallback(() => {
    setOldPasswordError("");
    setNewPasswordError("");
    setError({});
  }, []);

  const handleAction = async () => {
    clearErrors();
    if (oldPassword == "") {
      setOldPasswordError("Şifre Girmelisiniz");
      return;
    }
    if (newPassword == "") {
      setNewPasswordError("Şifre Girmelisiniz");
      return;
    }
    const updateData = {
      userId: profile.id,
      oldPassword: oldPassword,
      newPassword: newPassword,
    }
    try {
      const response = await changePwd(updateData);
      if (response === null || response.status === undefined || response.status !== 200) {
        setError({ type: "error", message: "Kısa süreli bir sistem problemi yaşanmaktadır, Özür Dileriz! Lütfen En Kısa Zamanda Tekrar Deneyin!" });
      }
      else if (response !== undefined && response !== null && response.status === 200) {
        if (response.data == true) {
          setOldPassword("")
          setNewPassword("")
          setError({ type: "success", message: "Şifreniz başarıyla güncellenmiştir!" });
          setTimeout(() => {
            setError({});
          }, 3000);
        } else {
          setError({ type: "error", message: "Girdiğiniz şifre doğru değil!" });
          setTimeout(() => {
            setError({});
          }, 3000);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError({ type: "error", message: "Kısa süreli bir sistem problemi yaşanmaktadır, Özür Dileriz! Lütfen En Kısa Zamanda Tekrar Deneyin!" });
    }
  };

  const toggleSnackbar = useCallback(() => {
    setError({});
  }, []);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Grid container item xs={12} className={classes.mainContainer}>
      <FlashMessage
        open={Boolean(error.type)}
        severity={error.type}
        alertMessage={error.message}
        handleClose={toggleSnackbar}
      />
      <Grid item xs={12}>
        <Typography variant="h6" align="center" color="textSecondary" className={classes.textBold}>
          Şifre
        </Typography>
      </Grid>
      <Grid item xs={12} sm={10} md={8} lg={6} className={classes.profileContainer}>
        <Divider light orientation="horizontal" className={classes.MH3} />

        <TextField
          name={"password"}
          InputLabelProps={{
            style: {
              color: theme.palette.grey[600],
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowOldPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  size="large"
                >
                  {showOldPassword ? (
                    <Visibility color="primary" />
                  ) : (
                    <VisibilityOff color="primary" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={Boolean(oldPasswordError)}
          helperText={oldPasswordError}
          fullWidth
          variant="outlined"
          label="Güncel Şifre"
          type={showOldPassword ? "text" : "password"}
          onChange={(e) => {
            setOldPassword(e.target.value);
          }}
          className={classes.rowField}
        />

        <TextField
          name={"password"}
          InputLabelProps={{
            style: {
              color: theme.palette.grey[600],
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowNewPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  size="large"
                >
                  {showNewPassword ? (
                    <Visibility color="primary" />
                  ) : (
                    <VisibilityOff color="primary" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={Boolean(newPasswordError)}
          helperText={newPasswordError}
          fullWidth
          variant="outlined"
          label="Yeni Şifre"
          type={showNewPassword ? "text" : "password"}
          onChange={(e) => {
            setNewPassword(e.target.value);
          }}
          className={classes.rowField}
        />
        <Grid item xs={12} className={classes.btnContainer}>
          <Button
            disableElevation
            variant="contained"
            style={{
              borderRadius: 30,
              backgroundColor: "#FF6B5E",
              padding: "18px 36px",
              fontSize: "18px"
            }}
            onClick={(e) => {
              e.preventDefault();
              handleAction();
            }}
          >
            <Typography variant="body2" color="secondary" className={classes.textBold}>
              Kaydet
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default React.memo(PasswordCard);
