import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Grid
} from "@mui/material";
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { trTR } from '@mui/x-date-pickers/locales';
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useCallback } from "react";
import useStyles from "./styles";
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import { createOrder } from "../../../api/order_api";
import tr from 'date-fns/locale/tr';
import OrderInfo from "../OrderInfo/OrderInfo";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.locale('tr');

dayjs.extend(utc);
dayjs.extend(timezone);

function RepeatSupplierOrder({ isVisible, toggleModal, orderContent, supplierByRestaurantId, onOrderCreated }) {

  const theme = useTheme();
  const classes = useStyles();
  const extraSmall = useMediaQuery(theme.breakpoints.down('md'));

  const [orderDate, setOrderDate] = useState(null);
  const [orderDateError, setOrderDateError] = useState("");

  const [orderInfoModal, setOrderInfoModal] = useState(false);

  const handleAction = async () => {
    setOrderDateError("");
    if (orderDate == null) {
      setOrderDateError("Lütfen Sipariş Günü ve Saati Giriniz!")
      return;
    }
    if (dayjs().isAfter(orderDate)) {
      setOrderDateError("Geçmiş tarih seçilemez.");
      return;
    }

    const data = {
      supplierByRestaurantId: supplierByRestaurantId,
      orderTime: orderDate.format('YYYY-MM-DDTHH:mm:ss'),
      orderContent: orderContent
    }

    try {
      const response = await createOrder(data);
      if (response !== undefined && response !== null && response.status === 200) {
        toggleModal()
        setOrderInfoModal(true);
        setTimeout(() => {
          setOrderInfoModal(false);
          onOrderCreated()
        }, 3000);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const setToTodayPlusTwoHours = () => {
    const newDate = dayjs().add(2, 'hour');
    setOrderDate(newDate);
  };

  const setToTomorrowAtTen = () => {
    const newDate = dayjs().tz('Europe/Istanbul').add(1, 'day').hour(10).minute(0);
    setOrderDate(newDate);
  };

  const toggleOrderInfo = useCallback(() => {
    setOrderInfoModal((prev) => !prev);
    onOrderCreated()
  }, []);

  return (
    <>
      <Dialog
        onClose={(e) => {
          e.stopPropagation();
          e.preventDefault();
          toggleModal();
        }}
        open={isVisible}
        fullWidth={true}
        maxWidth="md"
        className={classes.root}
        onClick={(e) => e.stopPropagation()}
      >
        <Box display="flex" justifyContent="flex-end">
          <IconButton size={extraSmall ? "medium" : "small"}
            onClick={(e) => {
              e.preventDefault();
              toggleModal();
            }}
            className={classes.closeContainer}>
            <CloseIcon color="#FF6B5E" />
          </IconButton>
        </Box>
        <DialogTitle>
          <Box component="div">

            <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDayjs} locale={trTR} localeText={trTR.components.MuiLocalizationProvider.defaultProps.localeText}>
              <Grid item xs={12}>
                <DateTimePicker
                  ampm={false}
                  disablePast
                  label="Sipariş günü ve saati"
                  onChange={(newDate) => setOrderDate(newDate)}
                  format="DD.MM.YYYY HH:mm"
                  value={orderDate} />
                {orderDateError && (
                  <Typography variant="body2" color="error">
                    {orderDateError}
                  </Typography>
                )}
                <Button
                  onClick={setToTodayPlusTwoHours}
                  variant="outlined"
                  style={{ marginLeft: 8 }}
                >
                  Bugün
                </Button>
                <Button
                  onClick={setToTomorrowAtTen}
                  variant="outlined"
                  style={{ marginLeft: 8 }}
                >
                  Yarın
                </Button>
              </Grid>
            </LocalizationProvider>
            <Box mt={theme.spacing(1)} />

            <Typography variant="subtitle" className={`${classes.disabledText} ${classes.lightText}`}>
              {orderContent}
            </Typography>
          </Box>
        </DialogTitle>
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                style={{
                  borderRadius: 30,
                  backgroundColor: "#FF6B5E",
                  padding: "18px 36px",
                  fontSize: "14px"
                }}
                fullWidth
                onClick={async (e) => {
                  e.preventDefault();
                  await handleAction();
                }}
                className={classes.btnBase}
              >
                Sİparİş oluştur!
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                style={{
                  borderRadius: 30,
                  backgroundColor: "#7D7D7D",
                  padding: "18px 36px",
                  fontSize: "14px"
                }}
                fullWidth
                onClick={(e) => {
                  e.preventDefault();
                  toggleModal();
                }}
                className={classes.btnBase}
              >
                Gerİ dön
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
      <OrderInfo
        isVisible={orderInfoModal}
        toggleModal={toggleOrderInfo} />
    </>
  );
}

export default React.memo(RepeatSupplierOrder);
