import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  disabledText: {
    color: theme.palette.text.disabled,
  },
  textBold: {
    fontWeight: 700,
  },
  smallText: {
    fontSize: "0.875rem",
  },
  link: {
    textDecoration: "none",
  },
  statusButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexGrow: 1,
  },
  statusLabel: {
    minWidth: '150px',
    textAlign: 'left', 
  },
  repeatOrderText: {
    marginLeft: theme.spacing(2),
    border: '1px solid #FF6B5E',
    borderRadius: '4px', 
    padding: theme.spacing(0.5, 1),
    fontSize: '0.875rem',
    fontWeight: 700,
  },
  orderNumber: {
    top: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: '#FF6B5E',
    color: 'white',
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    fontWeight: 700,
    fontSize: '0.75rem',
  },
}));

export default useStyles;
