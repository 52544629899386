import { Box, Divider, Menu, MenuItem, useTheme, } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import CartIcon from "../../assets/icons/CartIcon";
import GroupsIcon from '@mui/icons-material/Groups';
import ProfileIcon from "../../assets/icons/ProfileIcon";
import useStyle from "./styles";
import { ReactComponent as Logo } from "../../assets/images/solo_logo.svg";
import CloseIcon from '@mui/icons-material/Close';
import NotificationMessage from "./NotificationMessage";
import NotificationsIcon from '@mui/icons-material/Notifications';
import RestaurantContext from "../../context/Restaurant";

function DHeader({
  navitems,
  title,
  close,
  open,
  anchor,
  name,
  favLength = 0,
  cartCount = 0,
  mobile,
}) {
  const theme = useTheme();
  const classes = useStyle();
  const { markAllNotificationsAsRead, notifications, fetchNotifications } = useContext(RestaurantContext);

  const [openNot, setOpenNot] = useState(false);

  const handleNotificationClick = () => {
    if (!openNot) {
      fetchNotifications();
    }
    setOpenNot(!openNot);
  };

  const handleClose = async () => {
    await markAllNotificationsAsRead();
    setOpenNot(false);
  };

  return (
    <AppBar elevation={0} position="fixed">
      <Toolbar className={classes.toolbar}>
        <Box display="flex" alignItems="center" className={classes.linkDecoration}>
          <RouterLink to="/">
            <Logo height={80} width={80} />
          </RouterLink>
          {/* <RouterLink to="/market" className={classes.noUnderline}>
            <Typography
              color="white"
              variant="h6"
              style={{
                fontWeight: 'bold',
                fontSize: mobile ? '0.60rem' : '1.25rem',
                marginTop: '15%',
                marginLeft: theme.spacing(0.2),
              }}
              className="market-discovery"
            >
              MARKET
            </Typography>
          </RouterLink> */}
        </Box>

        <Box className={classes.flex}>
          <Box className={classes.flex} sx={{ marginRight: '2%' }}>
            <IconButton color="inherit" onClick={handleNotificationClick}>
              <Badge
                badgeContent={notifications && notifications.filter(notification => !notification.read).length}
                sx={{ "& .MuiBadge-badge": { backgroundColor: '#FF5733' } }}
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Box>
          <Divider flexItem orientation="vertical" light />
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(event) => open(event)}
          >
            <ProfileIcon />
            {!mobile && (
              <Typography
                variant="button"
                color="white"
                className={`${classes.ml} ${classes.font700}`}
                style={{ textTransform: "none", maxWidth: 70 }}
              >
                {name}
              </Typography>
            )}

            {anchor === null ? (
              <ExpandMoreIcon style={{ color: "white" }} className="orders-nav-item" />
            ) : (
              <ExpandLessIcon style={{ color: "white" }} className="orders-nav-item" />
            )}
          </Button>
          <Divider flexItem orientation="vertical" light />
          <Menu
            id="long-menu"
            anchorEl={anchor}
            keepMounted
            open={Boolean(anchor)}
            onClose={close}
            getcontentanchorel={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            style={{ marginTop: "40px" }}
          >
            {navitems.map((item, index) => (
              <Box key={index}>
                <RouterLink to={item.link} className={classes.linkDecoration}>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => close(item.title)}
                  >
                    <Typography color="textSecondary" variant="body2">
                      {item.title}
                    </Typography>
                  </MenuItem>
                </RouterLink>
                {index === 1 && <Divider light />}
              </Box>
            ))}
          </Menu>
          <Box display="flex" alignItems="center">
            <Box mr={theme.spacing(2)} ml={theme.spacing(2)}>
              <RouterLink to="/supplier">
                <GroupsIcon
                  sx={{
                    color: 'white',
                    fontSize: 35,
                  }}
                  className="supplier-icon"
                />
              </RouterLink>
            </Box>
            <>
              <Divider orientation="vertical" light />
              <RouterLink to="/checkout" className={classes.linkDecoration}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  ml={theme.spacing(2)}
                >
                  <CartIcon />
                  <Typography
                    variant="caption"
                    color="white"
                    className={classes.cartText}
                  >
                    {cartCount}
                  </Typography>
                </Box>
              </RouterLink>
            </>
          </Box>

          {openNot && (
            <div className={classes.notificationDropdown}>
              {notifications && notifications.filter(notification => !notification.read).length > 0 ? (
                <>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
                    <IconButton size="small" onClick={handleClose} sx={{ bgcolor: 'grey.300', '&:hover': { bgcolor: 'grey.400' }, borderRadius: '50%' }}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  {notifications.filter(notification => !notification.read).map(notification => (
                    <NotificationMessage
                      key={notification.id}
                      alertMessage={notification.message}
                      open={true}
                    />
                  ))}
                </>
              ) : (
                <>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
                    <IconButton size="small" onClick={handleNotificationClick} sx={{ bgcolor: 'grey.300', '&:hover': { bgcolor: 'grey.400' }, borderRadius: '50%' }}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <NotificationMessage
                    key={0}
                    alertMessage="Bildiriminiz yok"
                    open={true} />
                </>
              )}
            </div>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default React.memo(DHeader);
