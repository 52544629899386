/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-scroll";
import Scrollspy from "react-scrollspy";
import FlashMessage from "../../components/FlashMessage";
import { Header } from "../../components/Header";
import {
  AddCart,
} from "../../components/Modals";
import { MarketSupplierInfo } from "../../components/Modals";
import {
  RestaurantCart,
} from "../../components/RestaurantDetailComponent";
import useStyles from "./styles";
import { getSupplier } from "../../api/market_api";
import { categorisMap, Pictures } from "../../components/Utils";
import { SupplierHeader } from "../../components/SupplierDetailComponent";
import ProductCard from "../../components/SupplierComponent/ProductCard/ProductCard";
import RestaurantContext from "../../context/Restaurant";

function MarketSupplierDetail() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const { state } = useLocation();
  const [mainError, setMainError] = useState({});
  const [reviewModal, setReviewModal] = useState(false);
  const [addCartModal, setAddCartModal] = useState(false);
  const [addCartData, setAddCartData] = useState(null);
  const [data, setData] = useState({});
  const imageSource = data?.imageData ? `data:image/jpeg;base64,${data?.imageData}` : Pictures[data?.categoryDto?.mainCategory]?.source?.default;

  const {
    address,
    profile
  } = useContext(RestaurantContext);

  const productSections = data?.products ? Object.entries(data?.products)?.map(([title, items]) => ({
    title,
    items,
  })) : [];

  function getDeliveryDetailsByArea(data) {
    const result = data?.filter(item => item.area === address.town);
    if (result?.length > 0) {
      return {
        deliveryTime: result[0].deliveryTime,
        deliveryFee: result[0].deliveryFee
      };
    } else {
      return null;
    }
  }

  const delivery = getDeliveryDetailsByArea(data?.deliveryDtos)
  const supplierInfo = {
    businessName: data?.businessName,
    personName: data?.personName,
    address: data?.address,
    category: data?.categoryDto?.mainCategory,
    minimumOrder: data?.minimumOrder,
    rating: data?.rating,
    reviewCount: data?.reviewCount,
    priceRating: data?.priceRating,
    qualityRating: data?.qualityRating,
    deliveryRating: data?.deliveryRating,
    openingTimes: data?.openingTimes,
    delivery: delivery,
    checkPartner: false,
    reviews: data?.reviewDtos,
    type: "MARKET",
    deliveryDtos: data?.deliveryDtos,
    isPartner: data?.isPartner
  };

  const supplierCartInfo = {
    id: data?.id,
    name: data?.businessName,
    minimumOrder: data?.minimumOrder,
    deliveryFee: delivery?.deliveryFee
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getSupplier(state?.id, profile.id);
      if (response && response.status === 200 && response.data) {
        setData(response.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const toggleAddCartModal = useCallback(() => {
    setAddCartModal((prev) => !prev);
  }, []);

  const toggleReviewModal = useCallback(() => {
    setReviewModal((prev) => !prev);
  }, []);

  const addProductToCart = async (data) => {
    setAddCartData(data)
    toggleAddCartModal()
  };

  const showMessage = useCallback((messageObj) => {
    setMainError(messageObj);
  }, []);

  const toggleSnackbar = useCallback(() => {
    setMainError({});
  }, []);

  return (
    <>
      <FlashMessage
        open={Boolean(mainError.type)}
        severity={mainError.type}
        alertMessage={mainError.message}
        handleClose={toggleSnackbar}
      />
      <Grid container className={classes.mainContainer}>
        <Header />
        <Grid
          container
          style={{ display: "flex", flexDirection: "row-reverse" }}
        >
          {!isTablet && <RestaurantCart showMessage={showMessage} />}
          <Grid item lg={9} sm={9} xs={12}>
            <Container
              maxWidth="xl"
              style={{
                marginLeft: "0px",
                paddingLeft: "0px",
                paddingRight: "0px",
              }}
            >
              <Box
                className={classes.imageContainer}
                style={{
                  backgroundImage: `url(${imageSource})`,
                }}
              />
              <Box style={{ backgroundColor: theme.palette.common.white }}>
                <SupplierHeader
                  toggleModal={toggleReviewModal}
                  headerData={supplierInfo}
                />
              </Box>
              <Divider orientation="horizontal" light />
            </Container>

            <Container
              maxWidth="xl"
              style={{
                marginLeft: "0px",
                paddingLeft: "0px",
                paddingRight: "0px",
              }}
            >
              <div className={classes.tabContainer}>
                <Container style={{ marginLeft: "0px" }}>
                  <Scrollspy
                    offset={-220}
                    className={classes.scrollpyStyles}
                    items={productSections.map((section) => section.title)}
                    currentClassName={classes.active}
                  >
                    {productSections.map((section, index) => (
                      <li key={`STICKY_${index}`} className={classes.tabListStyle}>
                        <Link
                          to={section.title}
                          spy={true}
                          smooth={true}
                          className={classes.anchorStyle}
                          offset={-220}
                        >
                          <Typography className={classes.tabTextStyle}>
                            {categorisMap.get(section.title)}
                          </Typography>
                        </Link>
                      </li>
                    ))}
                  </Scrollspy>
                </Container>
              </div>

            </Container>

            <Container
              maxWidth="xl"
              style={{
                marginLeft: "0px",
                paddingLeft: "0px",
                paddingRight: "0px",
              }}
            >
              {productSections?.map((item, index) => (
                <section key={`SECTION_${index}`} id={item.title}>
                  <ProductCard
                    onPress={addProductToCart}
                    products={item}
                    title={item.title}
                    isDeliveryAvailable={delivery !== null}
                  />
                </section>
              ))}
            </Container>
          </Grid>
        </Grid>

        <AddCart
          isVisible={addCartModal}
          toggleModal={toggleAddCartModal}
          data={addCartData}
          supplier={supplierCartInfo}
        />
        <MarketSupplierInfo
          isVisible={reviewModal}
          toggleModal={toggleReviewModal}
          data={supplierInfo}
        />
        {/* {isTablet && cart.length > 0 && (
          <MRestaurantCart showMessage={showMessage} />
        )} */}
      </Grid>
    </>
  );
}

export default MarketSupplierDetail;
