import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { useTheme } from "@mui/material/styles";
import { TextField, MenuItem } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import React, { useCallback, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FlashMessage from "../../components/FlashMessage";
import { LoginWrapper } from "../Wrapper";
import useStyles from "./styles";
import "react-phone-input-2/lib/style.css";
import RestaurantContext from "../../context/Restaurant";
import { createNewRetail, getReferralData } from "../../api/restaurant_api";
import Error from "../../components/Modals/Error/Error";
import { validatePhoneNumber } from "../../components/Utils";
import { useLocation } from 'react-router-dom';
import Link from "@mui/material/Link";

function Registration() {
  const theme = useTheme();
  const classes = useStyles();
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { setProfileAndToken, setJustRegistered } = useContext(RestaurantContext);
  const location = useLocation();
  const [referralCode, setReferralCode] = useState('');
  const [referralDataLoaded, setReferralDataLoaded] = useState(false);
  const [orderId, setOrderId] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const referral = searchParams.get('refer');
    if (referral) {
      setReferralCode(referral);
    }
    const orderIdParam = searchParams.get('orderId');
    if (orderIdParam) {
      setOrderId(orderIdParam);
    }
  }, [location.search]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let phone = "";
        let email = "";
        if (referralCode || orderId) {
          const response = await getReferralData(referralCode, orderId);
          if (response && response.status === 200 && response.data) {
            // Adjust phone number by removing the initial '0' if it exists
            phone = response.data.phoneNumber.startsWith('0') ? response.data.phoneNumber.substring(1) : response.data.phoneNumber;
            email = response.data.email || "";
            setReferralDataLoaded(true);
          } else {
            console.error("Failed to fetch referral data");
          }
        }
        setPhone(phone);
        setEmail(email);
      } catch (error) {
        console.error("Error fetching referral data:", error);
      }
    };
  
    fetchData();
  }, [referralCode, orderId]);  

  const [businessName, setBusinessName] = useState("");
  const [personName, setPersonName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [type, setType] = useState("");

  const [businessNameError, setBusinessNameError] = useState("");
  const [personNameError, setPersonNameError] = useState("");
  const [typeError, setTypeError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const closeErrorModal = () => {
    setErrorModalOpen(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const clearErrors = () => {
    setBusinessNameError("");
    setPersonNameError("");
    setPhoneError("");
    setPasswordError("");
    setError("");
  };

  const handleAction = async () => {
    clearErrors();
    if (businessName == "") {
      setBusinessNameError("İşletme İsmi Girmelisiniz")
      return;
    }
    if (personName == "") {
      setPersonNameError("İşletme Sorumlusunu Girmelisiniz")
      return;
    }
    if (type == "") {
      setTypeError("İşletme Türü Girmelisiniz")
      return;
    }
    if (phone == "") {
      setPhoneError("Telefon Numarası Girmelisiniz")
      return;
    }
    if (!validatePhoneNumber(phone)) {
      setPhoneError("Geçerli bir telefon numarası giriniz");
      return;
    }
    if (password == "") {
      setPasswordError("Şifre Girmelisiniz")
      return;
    }
    const data = {
      businessName: businessName,
      personName: personName,
      email: email,
      phoneNumber: `0${phone}`,
      password: password,
      type: type,
      referralCode: referralCode,
      orderId: orderId
    }
    try {
      const response = await createNewRetail(data);
      if (response && response === "Phone number already registered.") {
        setPhoneError("Telefon Numarası Kayıtlıdır, Giriş Yapınız!");
        return;
      }
      if (response === null || response.status === undefined || response.status !== 200) {
        setErrorModalOpen(true);
        setTimeout(() => {
          setErrorModalOpen(false);
        }, 3000);
        return;
      }
      else if (response && response.status === 200) {
        const createdProfile = response.data
        await setProfileAndToken(
          {
            id: createdProfile.id,
            businessName: createdProfile.businessName,
            personName: createdProfile.personName,
            phoneNumber: createdProfile.phoneNumber,
            token: createdProfile.token,
          });
        setJustRegistered(true);
        navigate("/select-address", {
          replace: true,
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const toggleSnackbar = useCallback(() => {
    setError("");
  }, []);

  const linkStyle = {
    display: 'block',
    textAlign: 'center',
    marginTop: theme.spacing(1),
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  };

  return (
    <Box className={classes.pageContainer}>
      <LoginWrapper>
        <FlashMessage
          open={Boolean(error)}
          severity={"error"}
          alertMessage={error}
          handleClose={toggleSnackbar}
        />
        <Typography variant="h5" className={classes.font700}>
          Hadi Başlayalım!
        </Typography>
        <Typography
          variant="caption"
          className={`${classes.caption} ${classes.fontGrey}`}
        >
          İlk Önce Hesabınızı Oluşturalım
        </Typography>
        <TextField
          fullWidth
          name={"businessName"}
          error={Boolean(businessNameError)}
          helperText={businessNameError}
          variant="outlined"
          label="İşletme İsmi"
          className={classes.rowField}
          InputLabelProps={{
            style: {
              color: theme.palette.grey[600],
            },
          }}
          onChange={(e) => {
            setBusinessName(e.target.value);
          }}
        />

        <TextField
          fullWidth
          name={"personName"}
          error={Boolean(personNameError)}
          helperText={personNameError}
          variant="outlined"
          label="İşletme Sorumlusu"
          className={classes.rowField}
          InputLabelProps={{
            style: {
              color: theme.palette.grey[600],
            },
          }}
          onChange={(e) => {
            setPersonName(e.target.value);
          }}
        />

        <TextField
          id="select"
          label="İşletme Türü"
          select
          className={classes.rowField}
          onChange={(e) => {
            setType(e.target.value);
          }}
          error={Boolean(typeError)}
          helperText={typeError}
          fullWidth>
          <MenuItem value={"RESTAURANT"}>RESTORAN</MenuItem>
          <MenuItem value={"CAFE"}>CAFE</MenuItem>
          <MenuItem value={"HOTEL"}>OTEL</MenuItem>
          <MenuItem value={"MARKET"}>MARKET</MenuItem>
        </TextField>

        <TextField
          name={"phoneNumber"}
          error={Boolean(phoneError)}
          helperText={phoneError}
          variant="outlined"
          label="Telefon Numarası"
          type="text"
          fullWidth
          InputLabelProps={{
            style: {
              color: theme.palette.grey[600],
            },
          }}
          value={phone}
          onChange={(e) => {
            const value = e.target.value.replace(/[^\d]/g, '').slice(0, 10);
            setPhone(value.startsWith('5') ? value : '');
          }}
          className={classes.rowField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                0
              </InputAdornment>
            ),
            placeholder: "5XX XXX XXXX",
          }}
          disabled={referralDataLoaded && phone !== ''}
        />
        <TextField
          name={"email"}
          variant="outlined"
          label="Email"
          fullWidth
          InputLabelProps={{
            style: {
              color: theme.palette.grey[500],
            },
          }}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          className={classes.rowField}
          disabled={referralDataLoaded && email !== ''}
        />

        <TextField
          name={"password"}
          InputLabelProps={{
            style: {
              color: theme.palette.grey[600],
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  size="large"
                >
                  {showPassword ? (
                    <Visibility color="primary" />
                  ) : (
                    <VisibilityOff color="primary" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={Boolean(passwordError)}
          helperText={passwordError}
          fullWidth
          variant="outlined"
          label="Şifre"
          type={showPassword ? "text" : "password"}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          className={classes.rowField}
        />

        <Button
          variant="contained"
          type="email"
          disableElevation
          style={{
            borderRadius: 30,
            backgroundColor: "#FF6B5E",
            padding: "18px 36px",
            fontSize: "18px"
          }}
          fullWidth
          onClick={(e) => {
            e.preventDefault();
            handleAction();
          }}
        >
          <Typography
            variant="caption"
            className={`${classes.caption} ${classes.font700}`}
          >
            Devam Et
          </Typography>
        </Button>

        <Box mt={2} textAlign="center">
          <Link href="/#/sozlesme" target="_blank" style={linkStyle}>
            Gizlilik Sözleşmesi
          </Link>
          <Link href="/#/sozlesme" target="_blank" style={linkStyle}>
            Mesafeli Satış Sözleşmesi
          </Link>
        </Box>
        <Error
          isVisible={errorModalOpen}
          toggleModal={closeErrorModal}
        />
      </LoginWrapper>
    </Box>
  );
}

export default Registration;
