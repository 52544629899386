import { OutlinedInput, Grid, Button, Typography, useTheme, Box } from "@mui/material";
import React, { useCallback, useState } from "react";
import useStyles from "./supplier-chat-card-styles.js";
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { trTR } from '@mui/x-date-pickers/locales';
import FlashMessage from "../../components/FlashMessage";
import { Header } from "../../components/Header";
import HeadingView from "../../components/Modals/VariationModal/HeadingView";
import CreateOrder from "../../components/Modals/CreateOrder/CreateOrder.js";
import { createOrder } from "../../api/order_api.js";
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import OrderInfo from "../../components/Modals/OrderInfo/OrderInfo.js";
import tr from 'date-fns/locale/tr';
import Error from "../../components/Modals/Error/Error.js";
import { useMediaQuery } from '@mui/material';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

function SupplierChatCard({ data, triggerFetch }) {
  const theme = useTheme()
  const classes = useStyles();
  const [error, setError] = useState("");
  const [orderContentError, setOrderContentError] = useState("");
  const [orderDateError, setOrderDateError] = useState("");
  const [orderModal, setOrderModal] = useState(false);
  const [orderInfoModal, setOrderInfoModal] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [orderContent, setOrderContent] = useState("");
  const [orderDate, setOrderDate] = useState(null);

  const clearErrors = () => {
    setOrderDateError("");
    setOrderContentError("");
  };

  const toggleSnackbar = useCallback(() => {
    setError("");
  }, []);

  const createNewOrder = async () => {
    const orderData = {
      supplierByRestaurantId: data?.id,
      orderTime: orderDate.format('YYYY-MM-DDTHH:mm:ss'),
      orderContent: orderContent
    }
    try {
      const response = await createOrder(orderData);
      if (response !== undefined && response !== null && response.status === 200) {
        toggleCreateOrder()
        setOrderContent("")
        setOrderDate(null)
        setOrderInfoModal(true);
        setTimeout(() => {
          setOrderInfoModal(false);
          triggerFetch()
        }, 3000);
      } else {
        setErrorModalOpen(true);
        setTimeout(() => {
          setErrorModalOpen(false);
        }, 3000);
        return;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setErrorModalOpen(true);
      setTimeout(() => {
        setErrorModalOpen(false);
      }, 3000);
      return;
    }
  };

  const handleAction = () => {
    clearErrors();
    if (orderDate == null) {
      setOrderDateError("Lütfen Sipariş Günü ve Saati Giriniz!")
      return;
    }
    if (orderContent == "") {
      setOrderContentError("Lütfen Sipariş İçeriğini Giriniz!")
      return;
    }
    if (dayjs().isAfter(orderDate)) {
      setOrderDateError("Geçmiş tarih seçilemez.");
      return;
    }
    setOrderModal(true)
  };

  const toggleCreateOrder = useCallback(() => {
    setOrderModal((prev) => !prev);
  }, []);

  const toggleOrderInfo = useCallback(() => {
    setOrderInfoModal((prev) => !prev);
    triggerFetch();
  }, [triggerFetch]);

  const closeErrorModal = () => {
    setErrorModalOpen(false);
  };

  const setToTodayPlusTwoHours = () => {
    const newDate = dayjs().add(2, 'hour');
    setOrderDate(newDate);
  };

  const setToTomorrowAtTen = () => {
    const newDate = dayjs().tz('Europe/Istanbul').add(1, 'day').hour(10).minute(0);
    setOrderDate(newDate);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isIpad = useMediaQuery('(min-width:600px) and (max-width:900px)');
  return (
    <Grid container className={classes.root}>
      <Header />
      <Grid container item className={classes.marginHeader}>
        <Grid container item xs={12} className={classes.mainContainer}>
          <FlashMessage
            open={Boolean(error.type)}
            severity={error.type}
            alertMessage={error.message}
            handleClose={toggleSnackbar}
          />
          <Box className={classes.inputBox}>
            <Grid container spacing={2} direction="column" alignItems="flex-start">
              <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDayjs} locale={trTR} localeText={trTR.components.MuiLocalizationProvider.defaultProps.localeText}>
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                  <DateTimePicker
                    ampm={false}
                    disablePast
                    label="Sipariş günü ve saati"
                    onChange={(newDate) => setOrderDate(newDate ? dayjs(newDate) : null)}
                    error={Boolean(orderContentError)}
                    helperText={orderContentError ? "Lütfen Sipariş İçeriğini Giriniz!" : null}
                    format="DD.MM.YYYY HH:mm"
                    value={orderDate}
                  />
                  <Button
                    onClick={setToTodayPlusTwoHours}
                    variant="outlined"
                    style={{ marginLeft: 8 }}
                  >
                    Bugün
                  </Button>
                  <Button
                    onClick={setToTomorrowAtTen}
                    variant="outlined"
                    style={{ marginLeft: 8 }}
                  >
                    Yarın
                  </Button>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                  {orderDateError && (
                    <Typography variant="body2" color="error">
                      {orderDateError}
                    </Typography>
                  )}
                </Grid>
              </LocalizationProvider>
              <Grid item xs={12} className={classes.headingContainer}>
                <HeadingView
                  title="Sipariş İçeriği"
                  subTitle="Bu tedarikçiden vermek istediğiniz ürünleri miktarları ile aşağıda belirtiniz."
                  error={false}
                />
                <OutlinedInput
                  onChange={(e) => setOrderContent(e.target.value)}
                  multiline
                  fullWidth
                  minRows={8}
                  maxRows={8}
                  value={orderContent}
                  placeholder={"5 KG Domates, 5 KG Biber, 10 KG Et..."}
                  className={classes.input}
                />
              </Grid>
              {orderContentError && (
                <Typography variant="body2" color="error">
                  {orderContentError}
                </Typography>
              )}
              <Box display="flex" alignSelf="flex-end" margin={`15px ${isMobile ? '0px' : isIpad ? '300px' : '60px'} 5px 0px`}>
                <Button
                  disableElevation
                  variant="contained"
                  style={{
                    borderRadius: 30,
                    backgroundColor: "#FF6B5E",
                    fontSize: "18px"
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    handleAction();
                  }}
                >
                  <Typography
                    variant="body2"
                    color="secondary"
                    className={classes.textBold}
                  >
                    Sİparİş Ver
                  </Typography>
                </Button>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <CreateOrder
        isVisible={orderModal}
        toggleModal={toggleCreateOrder}
        action={createNewOrder}
        orderDate={orderDate}
        orderContent={orderContent}
      />
      <OrderInfo
        isVisible={orderInfoModal}
        toggleModal={toggleOrderInfo}
      />
      <Error
        isVisible={errorModalOpen}
        toggleModal={closeErrorModal}
      />
    </Grid>
  );
}

export default React.memo(SupplierChatCard);
