/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography, useTheme, Avatar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { formatDateToTurkish, parseOrderTime } from "../../Utils";
import useStyles from "./styles";

export default function StatusCard({ data, orderType }) {
  const history = orderType ? orderType === "MARKET" ? data?.marketOrderHistory : data?.history : [];
  const theme = useTheme();
  const classes = useStyles();
  const small = useMediaQuery(theme.breakpoints.down("sm"));

  const getStatusInfo = (historyEntry) => {
    if (historyEntry.status === "APPROVAL_WAITING") {
      return {
        status: "Sipariş Alındı",
      }
    }
    else if (historyEntry.status === "ACCEPTED_BY_SUPPLIER") {
      if (historyEntry.type === "STATUS_CHANGE") {
        return {
          status: "Sipariş Kabul Edildi",
        };
      } else if (historyEntry.type === "MESSAGE_RECEIVED") {
        if (historyEntry.owner === "RETAIL") {
          return {
            status: "Restorandan Ek Not Alındı",
            note: historyEntry.note
          }
        } else {
          return {
            status: "Tedarikçiden Ek Not Alındı",
            note: historyEntry.note
          }
        }
      }
    }
    else if (historyEntry.status === "REJECTED_BY_SUPPLIER") {
      return {
        status: "Sipariş Reddedildi",
      }
    }
    else if (historyEntry.status === "CANCELED") {
      return {
        status: "Sipariş iptal edildi",
      }
    }
    else if (historyEntry.status === "COMPLETED") {
      return {
        status: "Sipariş Tamamlandı",
      }
    }
  };

  return (
    <Box className={classes.bottomOrder} mt={!small && theme.spacing(2)}>
      {history?.map((historyEntry, index) => {
        const { status, note } = getStatusInfo(historyEntry);
        return (
          <StatusRow
            status={status}
            time={historyEntry.createdAt}
            note={note}
            first={index === 0}
            number={index + 1}
          />
        );
      })}
      {!history?.some(item => item.status === "COMPLETED") &&
        <StatusRow
          status="Sipariş Tamamlandı"
          time={data.orderTime}
          last={true}
        />
      }
    </Box>
  );
}

function StatusRow({ first, status, time, number, last, note }) {
  const theme = useTheme();
  const classes = useStyles();
  const small = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      className={classes.innerBottomOrder}
      ml={!small && first && theme.spacing(2)}
    >
      <Avatar
        sx={{
          bgcolor: last ? theme.palette.grey[500] : theme.palette.primary.main,
          width: 20,
          height: 20,
        }}
      >
        <Typography variant="body2" color="common" className={classes.textBold}>
          {number}
        </Typography>
      </Avatar>
      <Box className={classes.typos}>
        <Typography
          variant="body2"
          color={last ? theme.palette.grey[500] : theme.palette.primary.main}
          className={`${classes.textBold} ${classes.smallText} `}
          sx={{ ml: theme.spacing(2), mr: theme.spacing(2) }}
        >
          {status}
        </Typography>
        <Typography
          variant="body2"
          color={last ? theme.palette.grey[500] : theme.palette.primary.main}
          className={`${classes.textBold} ${classes.smallText}`}
          sx={{ ml: theme.spacing(2) }}
        >
          {formatDateToTurkish(parseOrderTime(time))}
        </Typography>
        <Typography
          variant="body2"
          color={last ? theme.palette.grey[500] : theme.palette.primary.main}
          className={`${classes.textBold} ${classes.smallText}`}
          sx={{ ml: theme.spacing(2) }}
        >
          {note}
        </Typography>
      </Box>
    </Box>
  );
}
