import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid, Typography, TextField, Button, useTheme } from "@mui/material";
import { PaymentCard, OrderOption } from "../../components/Checkout";
import FlashMessage from "../../components/FlashMessage";
import { createOrder } from "../../api/catalog_api";
import useStyle from "./styles";
import NoteAdded from "../../components/Modals/NoteAdded/NoteAdded";
import dayjs from 'dayjs';
import GuestCartContext from "../../context/Guest";
import CodIcon from "../../assets/icons/CodIcon";
import GuestCartItemCard from "../../components/Checkout/GuestCartItemCard";
import LinkHeader from "../../components/Header/LoginHeader/LinkHeader";
import PersonalInfo from "../../components/Checkout/PersonalInfo";
import { useLocation } from 'react-router-dom';
import { createCampaignOrder } from "../../api/campaign_api";

const PAYMENT = {
  id: 2,
  payment: "COD",
  label: "Cash",
  icon: <CodIcon />,
};

function GuestCheckout() {
  const classes = useStyle();
  const navigate = useNavigate();
  const { cart, removeCartItem, clearCart } = useContext(GuestCartContext);
  const [mainError, setMainError] = useState({});
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT);
  const [selectedDate, setOrderDate] = useState(null);
  const [orderDateError, setOrderDateError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [emptyCartError, setEmptyCartError] = useState("");
  const [createdModal, setCreatedModal] = useState(false);
  const [businessNameError, setBusinessNameError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [personNameError, setPersonNameError] = useState("");
  const [orderNote, setOrderNote] = useState("");
  const location = useLocation();
  const state = location.state;

  const initialPersonalInfo = () => {
    const savedProfile = localStorage.getItem("profile");
    if (savedProfile) {
      const parsedProfile = JSON.parse(savedProfile);
      return {
        businessName: parsedProfile.businessName || '',
        phoneNumber: parsedProfile.phoneNumber || '',
        address: parsedProfile.address || '',
        personName: parsedProfile.personName || '',
      };
    }
    return { businessName: '', phoneNumber: '', address: '', personName: '' };
  };

  const [personalInfo, setPersonalInfo] = useState(initialPersonalInfo);

  const calculateTotal = () => {
    return cart.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
  };

  const validateInput = () => {
    if (!personalInfo.businessName) {
      setBusinessNameError("İşletme adı boş bırakılamaz.");
      return false
    }
    if (!personalInfo.phoneNumber) {
      setPhoneNumberError("Telefon numarası boş bırakılamaz.");
      return false
    }
    if (!personalInfo.personName) {
      setPersonNameError("Kişi adı boş bırakılamaz.");
      return false
    }
    return true;
  };

  const createNewOrder = async () => {
    setEmptyCartError("")
    setOrderDateError("")
    setAddressError("")
    setPersonNameError("")
    setPhoneNumberError("")
    setBusinessNameError("")
    if (!validateInput()) {
      return;
    }
    if (cart.length === 0) {
      setEmptyCartError("Sepetinizde ürün bulunmamaktadır");
      return;
    }

    const totalOrderValue = cart.reduce((total, item) => {
      const itemPrice = item.price || 0;
      return total + itemPrice * item.quantity;
    }, 0);

    if (state.type === "campaign") {
      const minOrder = state.minOrder;

      if (minOrder && totalOrderValue < minOrder) {
        setMainError({
          type: 'error',
          message: `Sipariş tutarınız minimum ${minOrder} TL olmalıdır.`,
        });
        return;
      }
    }

    if (selectedDate == null) {
      setOrderDateError("Lütfen sipariş günü ve saati giriniz!");
      return;
    }

    if (dayjs().isAfter(selectedDate)) {
      setOrderDateError("Geçmiş tarih seçilemez.");
      return;
    }

    if (personalInfo.address == null || personalInfo.address === '') {
      setAddressError("Lütfen işletme adresini giriniz!");
      return;
    }

    if (state.type === "campaign") {
      try {
        const data = {
          linkIdentifier: state.linkIdentifier,
          orderTime: selectedDate.format('YYYY-MM-DDTHH:mm:ss'),
          businessName: personalInfo.businessName,
          phone: personalInfo.phoneNumber,
          address: personalInfo.address,
          person: personalInfo.personName,
          note: orderNote,
          products: cart.map(item => ({
            productId: item.productId,
            amount: item.quantity,
            price: item.price,
          }))
        };
        const response = await createCampaignOrder(data);
        if (response && response.status === 200) {
          localStorage.setItem("profile", JSON.stringify(personalInfo));
          clearCart();
          setCreatedModal(true);
          setTimeout(() => {
            setCreatedModal(false);
            navigate(`/campaignOrder/${response.data}`, { replace: true });
          }, 3000);
        } else {
          setMainError({
            type: 'error',
            message: 'Kısa süreli bir sistem problemi yaşanmaktadır, Özür Dileriz!Lütfen En Kısa Zamanda Tekrar Deneyin!'
          });
        }
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      const data = {
        linkIdentifier: state.linkIdentifier,
        orderTime: selectedDate.format('YYYY-MM-DDTHH:mm:ss'),
        businessName: personalInfo.businessName,
        phone: personalInfo.phoneNumber,
        address: personalInfo.address,
        person: personalInfo.personName,
        note: orderNote,
        products: cart.map(item => ({
          productId: item.productId,
          catalogProductId: item.catalogProductId,
          amount: item.quantity,
          price: item.price,
        }))
      };
      try {
        const response = await createOrder(data);
        if (response && response.status === 200) {
          localStorage.setItem("profile", JSON.stringify(personalInfo));
          clearCart();
          setCreatedModal(true);
          setTimeout(() => {
            setCreatedModal(false);
            navigate(`/order/${response.data}`, { replace: true });
          }, 3000);
        } else {
          setMainError({
            type: 'error',
            message: 'Kısa süreli bir sistem problemi yaşanmaktadır, Özür Dileriz!Lütfen En Kısa Zamanda Tekrar Deneyin!'
          });
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const handlePersonalInfoChange = (e) => {
    const { name, value } = e.target;
    setPersonalInfo(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
  const theme = useTheme();
  return (
    <Grid container className={classes.root}>
      <FlashMessage
        open={Boolean(mainError.type)}
        severity={mainError.type}
        alertMessage={mainError.message}
        handleClose={() => setMainError({})}
      />
      <LinkHeader supplierName={state.supplier} />
      <Grid
        container
        item
        className={classes.mainContainer}
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <GuestCartItemCard
                cart={cart}
                removeCartItem={removeCartItem}
                calculateTotal={calculateTotal}
              />
              <TextField
                fullWidth
                variant="outlined"
                label="Sipariş Notu"
                multiline
                rows={4}
                value={orderNote}
                onChange={(e) => setOrderNote(e.target.value)}
                className={classes.textField}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <OrderOption
                selectedDate={selectedDate}
                handleDateChange={setOrderDate}
                error={orderDateError}
              />

              <PersonalInfo
                personalInfo={personalInfo}
                handlePersonalInfoChange={handlePersonalInfoChange}
                businessNameError={businessNameError}
                phoneNumberError={phoneNumberError}
                personNameError={personNameError}
              />
              {addressError && (
                <Typography variant="body2" color="error">
                  {addressError}
                </Typography>
              )}
              {/* <PaymentCard
                paymentMethod={paymentMethod}
                setPaymentMethod={setPaymentMethod}
                createOrder={createNewOrder}
              /> */}
              <Button
                style={{
                  maxWidth: "auto",
                  padding: `${theme.spacing(2)} 0`,
                  background: "#FF6B5E",
                  marginTop: theme.spacing(2),
                  width: "100%",
                  borderRadius: 0,
                }}
                onClick={(e) => {
                  e.preventDefault()
                  createNewOrder();
                }}
              >
                <Typography
                  style={{
                    ...theme.typography.body1,
                    color: theme.palette.common.white,
                    fontWeight: 700,
                  }}
                >
                  Sİparİş Ver
                </Typography>
              </Button>
              {emptyCartError && (
                <Typography variant="body2" color="error">
                  {emptyCartError}
                </Typography>
              )}
              {orderDateError && (
                <Typography variant="body2" color="error">
                  {orderDateError}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <NoteAdded isVisible={createdModal} toggleModal={() => setCreatedModal(!createdModal)} title="Siparişiniz Alınmıştır!"
        detail="TedaBir'i kullandığınız için teşekkür ederiz! Gelişmeler hakkında sizi bilgilendireceğiz!"
      />
    </Grid>
  );
}

export default GuestCheckout;
