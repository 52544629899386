import React, { createContext, useState, useEffect } from 'react';

const GuestCartContext = createContext();

export const GuestCartProvider = ({ children }) => {
  const [cart, setCart] = useState(() => JSON.parse(localStorage.getItem('guestCartItems')) || []);

  useEffect(() => {
    localStorage.setItem('guestCartItems', JSON.stringify(cart));
  }, [cart]);

  const clearCart = () => {
    setCart([]);
    localStorage.removeItem("guestCartItems");
  };

  const calculatePrice = (item, newQuantity) => {
    const { priceStrategy, fixedPrice, variablePrices } = item;

    let calculatedPrice = null;

    if (priceStrategy === 'fixed') {
      calculatedPrice = fixedPrice;
    } else if (priceStrategy === 'variable' && variablePrices && variablePrices.length > 0) {
      const priceTier = variablePrices.find(price => newQuantity >= price.minAmount && newQuantity <= price.maxAmount);
      calculatedPrice = priceTier ? priceTier.price : null;
    }

    return calculatedPrice;
  };

  const addCartItem = (item) => {
    const newItem = { ...item };
    const existingItemIndex = cart.findIndex(cartItem => cartItem.productId === item.productId);

    if (existingItemIndex !== -1) {
      const updatedCart = [...cart];
      updatedCart[existingItemIndex].quantity += (item.quantity || 1);
      updatedCart[existingItemIndex].price = calculatePrice(updatedCart[existingItemIndex], updatedCart[existingItemIndex].quantity); // Recalculate price
      setCart(updatedCart);
    } else {
      newItem.quantity = item.quantity || 1;
      newItem.price = calculatePrice(newItem, newItem.quantity); // Calculate price for new item
      setCart(currentCart => [...currentCart, newItem]);
    }
  };

  const removeCartItem = (itemKey) => {
    setCart(currentCart => currentCart.filter(item => item.key !== itemKey));
  };

  const addQuantity = (itemKey) => {
    const newCart = cart.map((item) => {
      if (item.key === itemKey) {
        const newQuantity = item.quantity + 1;
        const newPrice = calculatePrice(item, newQuantity); // Recalculate price
        return { ...item, quantity: newQuantity, price: newPrice };
      }
      return item;
    });
    setCart(newCart);
  };

  const removeQuantity = (itemKey) => {
    const newCart = cart.map((item) => {
      if (item.key === itemKey) {
        const newQuantity = item.quantity - 1;
        if (newQuantity > 0) {
          const newPrice = calculatePrice(item, newQuantity);
          return { ...item, quantity: newQuantity, price: newPrice };
        }
        return null;
      }
      return item;
    }).filter(item => item !== null);
    setCart(newCart);
  };

  const removeCampaignQuantity = (id) => {
    const newCart = cart.map((item) => {
      if (item.productId === id) {
        const newQuantity = item.quantity - 1;
        if (newQuantity > 0) {
          const newPrice = calculatePrice(item, newQuantity);
          return { ...item, quantity: newQuantity, price: newPrice };
        }
        return null;
      }
      return item;
    }).filter(item => item !== null);
    setCart(newCart);
  };

  const removeCampaignItem = (id) => {
    setCart(currentCart => currentCart.filter(item => item.productId !== id));
  };

  const contextValue = { cart, addCartItem, removeCartItem, addQuantity, removeQuantity, clearCart, removeCampaignQuantity, removeCampaignItem };

  return <GuestCartContext.Provider value={contextValue}>{children}</GuestCartContext.Provider>;
};

export default GuestCartContext;
