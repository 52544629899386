import React from "react";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";

function StarRating({ value, onChange, readOnly }) {
  const stars = [1, 2, 3, 4, 5];

  return (
    <div>
      {stars.map((star) => (
        <span key={star} onClick={readOnly ? null : () => onChange(star)} style={{ cursor: readOnly ? "default" : "pointer" }}>
          {star <= value ? (
            <StarIcon color="primary" />
          ) : (
            <StarOutlineIcon color="primary" />
          )}
        </span>
      ))}
    </div>
  );
}

export default StarRating;
