import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  Accordion, AccordionSummary, Box, AccordionDetails, Button, Grid, Typography, List, TextField, ListItem, IconButton, FormControl, InputLabel, Select, MenuItem,
  ListItemText, Dialog, DialogActions, DialogContent, DialogTitle
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import useStyles from './styles';
import { Header } from '../../components/Header';
import { mockOrders } from '../../components/Utils';
import RestaurantContext from '../../context/Restaurant';
import { getSuppliers, updateWeeklyPlan } from '../../api/restaurant_api';
import useMediaQuery from '@mui/material/useMediaQuery';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';
import OrderInfo from '../../components/Modals/OrderInfo/OrderInfo';
import Error from '../../components/Modals/Error/Error';
import { TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { trTR } from '@mui/x-date-pickers/locales';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import Snackbar from "@mui/material/Snackbar";
import { getISOWeek } from 'date-fns';

function PredictedOrders() {
  const [order, setOrder] = useState(null);
  const [newProduct, setNewProduct] = useState({ name: '', amount: '', unit: '' });
  const [editState, setEditState] = useState({ supplierIndex: -1, productIndex: -1, amount: '' });
  const { date } = useParams();
  const classes = useStyles();
  const { restaurantId } = useContext(RestaurantContext);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState({ id: '', name: '' });
  const [confirmOrderModalOpen, setConfirmOrderModalOpen] = useState(false);
  const [orderTime, setOrderTime] = useState(null);
  const [orderInfoModal, setOrderInfoModal] = useState(false);
  const [orderTimeError, setOrderTimeError] = useState("");
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [selectedSupplierIndexForOrder, setSelectedSupplierIndexForOrder] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const foundOrder = mockOrders.find(o => o.date === date);
    setOrder(foundOrder || { date, suppliers: [] });

    fetchSuppliers();
  }, [date]);

  const toggleOrderInfo = useCallback(() => {
    setOrderInfoModal((prev) => !prev);
    handleDeletePlan()
  }, []);

  const handleDeletePlan = async () => {

  };

  const handleOrderConfirm = async () => {
    setConfirmOrderModalOpen(false);
  };

  const closeErrorModal = () => {
    setErrorModalOpen(false);
  };

  const handleAddSupplier = () => {
    if (selectedSupplier.id) {
      setOrder(prevOrder => ({
        ...prevOrder,
        suppliers: [...prevOrder.suppliers, { id: selectedSupplier.id, name: selectedSupplier.name, products: [] }]
      }));
      setSelectedSupplier({ id: '', name: '' });
    }
  };

  const handleAddProduct = (supplierIndex) => {
    if (newProduct.name && !isNaN(newProduct.amount) && newProduct.unit) {
      const updatedOrder = { ...order };
      updatedOrder.suppliers[supplierIndex].products.push({
        ...newProduct,
        amount: Number(newProduct.amount),
        status: "Eklendi"
      });
      setOrder(updatedOrder);
      setNewProduct({ name: '', amount: '', unit: '' });
    }
  };

  const handleEdit = (supplierIndex, productIndex, product) => {
    setEditState({ supplierIndex, productIndex, amount: product.amount.toString() });
  };

  const handleSaveEdit = () => {
    const updatedOrder = { ...order };
    const { supplierIndex, productIndex, amount } = editState;
    updatedOrder.suppliers[supplierIndex].products[productIndex].amount = Number(amount);
    updatedOrder.suppliers[supplierIndex].products[productIndex].status = "Değiştirildi";
    setOrder(updatedOrder);
    setEditState({ supplierIndex: -1, productIndex: -1, amount: '' });
  };

  const handleCancelEdit = () => {
    setEditState({ supplierIndex: -1, productIndex: -1, amount: '' });
  };

  const handleDelete = (supplierIndex, productIndex) => {
    const updatedOrder = { ...order };
    updatedOrder.suppliers[supplierIndex].products.splice(productIndex, 1);
    setOrder(updatedOrder);
  };

  const handleConvertToOrder = (supplierIndex) => {
    if (supplierIndex !== null && order.suppliers[supplierIndex].products.length === 0) {
      setSuccessMessage("Listede ürün bulunmamaktadır.");
      return;
    }
    setSelectedSupplierIndexForOrder(supplierIndex);
    setOrderTimeError("");
    setConfirmOrderModalOpen(true);
  }

  const handleSaveList = async (supplierIndex) => {
    const selectedSupplier = order.suppliers[supplierIndex];
    const payload = {
      retailId: restaurantId,
      week: getISOWeek(new Date()),
      date,
      supplierId: selectedSupplier.id,
      supplierName: selectedSupplier.name,
      products: selectedSupplier.products.map(product => ({
        name: product.name,
        amount: product.amount,
        unit: product.unit,
        status: product.status,
      })),
    };

    try {
      console.log(payload)
      const response = await updateWeeklyPlan(payload);
      console.log(response)
      if (response && response.status === 200) {
        setSuccessMessage("Liste başarıyla kaydedildi.");
        setTimeout(() => {
          setSuccessMessage('');
        }, 3000);
      }
    } catch (error) {
      setErrorModalOpen(true);
    }
  };


  const fetchSuppliers = async () => {
    try {
      const response = await getSuppliers(restaurantId);
      if (response !== null && response.status === 200 && response.data !== undefined) {
        setSuppliers(response.data);
      } else {
        setSuppliers([])
      }
    } catch (error) {
      console.error('Error fetching suppliers:', error);
    }
  };

  const getStatusLabelStyle = (status) => {
    switch (status) {
      case 'Tahminlendi':
        return {
          backgroundColor: '#FF6B5E',
          color: 'white',
        };
      case 'Değiştirildi':
        return {
          backgroundColor: '#FF9800',
          color: 'white',
        };
      case 'Eklendi':
        return {
          backgroundColor: '#6FCF97',
          color: 'white',
        };
      default:
        return {
          backgroundColor: 'transparent',
          color: '#333',
        };
    }
  }

  const ProductItem = ({ supplierIndex, productIndex, product }) => {
    const isEditing = editState.supplierIndex === supplierIndex && editState.productIndex === productIndex;

    return (
      <ListItem className={classes.listItem}>
        <div className={classes.statusLabel}
          style={{
            ...getStatusLabelStyle(product.status),
            position: 'absolute',
            top: '0',
            right: '0',
            borderRadius: '0',
            padding: '2px',
          }}>
          {product.status}
        </div>
        <Typography variant="body1" className={classes.productName}>
          {product.name}
        </Typography>
        {isEditing ? (
          <TextField type="number" value={editState.amount} onChange={(e) => setEditState({ ...editState, amount: e.target.value })} size="small" className={classes.editInput} />
        ) : (
          <Typography variant="body1" className={classes.productAmount}>
            {`${product.amount} ${product.unit}`}
          </Typography>
        )}
        {isEditing ? (
          <>
            <IconButton onClick={handleSaveEdit} className={classes.iconButton}><CheckIcon /></IconButton>
            <IconButton onClick={handleCancelEdit} className={classes.iconButton}><CloseIcon /></IconButton>
          </>
        ) : (
          <>
            <IconButton onClick={() => handleEdit(supplierIndex, productIndex, product)} className={classes.iconButton}><EditIcon className={classes.editIcon} /></IconButton>
            <IconButton onClick={() => handleDelete(supplierIndex, productIndex)} className={classes.iconButton}><DeleteIcon className={classes.deleteIcon} /></IconButton>
          </>
        )}
      </ListItem>
    );
  };
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const formattedDate = format(new Date(date), 'd MMMM EEEE', { locale: tr });
  const titleText = `${formattedDate}`;

  return (
    <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDayjs} locale={trTR} localeText={trTR.components.MuiLocalizationProvider.defaultProps.localeText}>
      <Grid container className={classes.root}>
        <Header />

        <Grid container item className={classes.marginHeader}>
          <Box className={classes.breadcrumbContainer}>
            <Typography className={classes.breadcrumbText}>
              Haftalık Planlayıcı / {titleText}
            </Typography>
          </Box>
          <Grid container item xs={12} className={classes.mainContainer}>
            <Grid item xs={12} className={classes.title}>
              <Typography variant={isMobile ? 'p' : 'h6'} align="center" color="textSecondary" className={classes.textBold}>
                {`Şu anda `}
                <strong>{titleText}</strong>
                {` tarihinin sipariş planlamasını yapıyorsunuz`}
              </Typography>
            </Grid>

            <Grid item xs={12} md={8} lg={6} className={classes.profileContainer}>
              <Box className={classes.inputRow}>

                {order && order.suppliers.length > 0 && (
                  order.suppliers.map((supplierOrder, supplierIndex) => (
                    <Accordion key={supplierIndex} sx={{ marginBottom: 2 }}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.supplierName}>{supplierOrder.name}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <List>
                          {supplierOrder.products.map((product, productIndex) => (
                            <ProductItem key={`${supplierIndex}-${productIndex}`} supplierIndex={supplierIndex} productIndex={productIndex} product={product} />
                          ))}
                          <div className={classes.newProductFormRow}>
                            <TextField label="Ürün Adı" value={newProduct.name} onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })} size="small" style={{ flex: 1.8 }} className={classes.textField} />
                            <TextField label="Miktar" type="number" value={newProduct.amount} onChange={(e) => setNewProduct({ ...newProduct, amount: e.target.value })} size="small" style={{ flex: 1.2 }} className={classes.textField} />
                            <FormControl variant="outlined" size="small" className={`${classes.selectField} ${classes.textField}`}>
                              <InputLabel>Birim</InputLabel>
                              <Select value={newProduct.unit} onChange={(e) => setNewProduct({ ...newProduct, unit: e.target.value })}>
                                <MenuItem value="KG">KG</MenuItem>
                                <MenuItem value="Adet">Adet</MenuItem>
                              </Select>
                            </FormControl>
                            <IconButton size="small" className={classes.smallIconButton} onClick={() => handleAddProduct(supplierIndex)}><AddIcon /></IconButton>
                          </div>
                        </List>
                        <div className={classes.confirmButtonContainer}>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.saveButton}
                            onClick={() => handleSaveList(supplierIndex)}
                          >
                            Kaydet
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={() => handleConvertToOrder(supplierIndex)}
                          >
                            Sipariş Oluştur
                          </Button>
                          <Snackbar
                            open={Boolean(successMessage)}
                            autoHideDuration={6000}
                            onClose={() => {
                              setSuccessMessage("");
                            }}
                            message={successMessage}
                          />
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  ))
                )}

                <Box className={classes.supplierAddContainer}>
                  <FormControl variant="outlined" className={classes.supplierSelect}>
                    <InputLabel id="supplier-select-label">Tedarikçi Adı</InputLabel>
                    <Select
                      labelId="supplier-select-label"
                      id="supplier-select"
                      value={selectedSupplier.id}
                      onChange={(e) => {
                        const supplier = suppliers.find(supplier => supplier.id === e.target.value);
                        setSelectedSupplier({ id: supplier.id, name: supplier.businessName });
                      }}
                      label="Tedarikçi Adı"
                    >
                      {suppliers.map((supplier, index) => (
                        <MenuItem key={index} value={supplier.id}>{supplier.businessName}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button onClick={handleAddSupplier} className={classes.addSupplierButton}>Tedarikçi Ekle</Button>
                </Box>
              </Box>
            </Grid>

            <Dialog
              open={confirmOrderModalOpen}
              onClose={() => setConfirmOrderModalOpen(false)}
              PaperProps={{
                style: {
                  padding: '20px 40px 20px 40px',
                },
              }}>
              <DialogTitle>Siparişi Onayla</DialogTitle>
              <DialogContent>
                <InputLabel>Saat Seçin</InputLabel>
                <TimePicker
                  ampm={false}
                  value={orderTime}
                  onChange={setOrderTime}
                  renderInput={(params) => <TextField {...params} />}
                />
                {orderTimeError && (
                  <Typography variant="body2" color="error">
                    {orderTimeError}
                  </Typography>
                )}
                {selectedSupplierIndexForOrder !== null && (
                  <List>
                    {order.suppliers[selectedSupplierIndexForOrder].products.map((product, index) => (
                      <ListItem key={index}>
                        <ListItemText primary={`${product.name}: ${product.amount} ${product.unit}`} />
                      </ListItem>
                    ))}
                  </List>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  fullWidth
                  className={`${classes.btnBase} ${classes.cancelButton}`}
                  onClick={() => setConfirmOrderModalOpen(false)}
                >
                  <Typography variant="subtitle2" color="#FF6B5E">
                    İptal
                  </Typography>
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  className={classes.btnBase}
                  onClick={handleOrderConfirm}
                  style={{ backgroundColor: "#FF6B5E", textTransform: 'none' }}
                >
                  <Typography variant="subtitle2">
                    Onayla
                  </Typography>
                </Button>
              </DialogActions>
            </Dialog>

            <OrderInfo
              isVisible={orderInfoModal}
              toggleModal={toggleOrderInfo}
            />
            <Error
              isVisible={errorModalOpen}
              toggleModal={closeErrorModal}
            />
          </Grid>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}

export default PredictedOrders;
