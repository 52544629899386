import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      marginBottom: theme.spacing(2),
    },
    "& .MuiInputBase-input": {
      color: theme.palette.text.secondary,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.grey[200],
      },
      "&:hover fieldset": {
        borderColor: theme.palette.text.disabled,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },
    "& .MuiButton-contained": {
      borderRadius: "0px",
      height: "40px",
      fontSize: "0.875rem",
      "&:hover": {
        opacity: 0.8,
        backgroundColor: theme.palette.primary.main,
      },
    },
    marginBottom:'5%'
  },
  marginHeader: {
    marginTop: "100px",
  },
  MH3: {
    margin: theme.spacing(2, 0),
  },
  mainContainer: {
    justifyContent: "center",
  },
  title: {
    justifyContent: "center",
    padding: theme.spacing(1, 5),
  },
  profileContainer: {
    padding: theme.spacing(0, 2),
  },
  textBold: {
    fontWeight: 700,
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: '5%'
  },
}));

export default useStyles;
