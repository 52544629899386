import {
  Box,
  Button,
  ButtonBase,
  Container,
  Paper,
  Radio,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import CodIcon from "../../assets/icons/CodIcon";
import useStyles from "./styles";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const PAYMENT_OPTIONS = [
  {
    id: 2,
    payment: "COD",
    label: "Nakit (Ödeme Teslimatta)",
    icon: <CodIcon />,
  },
];

function PaymentCard({
  paymentMethod,
  setPaymentMethod,
  createOrder,
  loading,
}) {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Paper
      style={{
        background: theme.palette.common.white,
        borderRadius: "inherit",
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        marginTop: theme.spacing(4),
      }}
    >
      <Container>
        <Box display="flex" alignItems="center">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{
              background: "#FF6B5E",
              width: "32px",
              height: "32px",
            }}
          >
            <Typography style={theme.typography.body1}><ArrowForwardIcon style={{ paddingTop: 5 }} /></Typography>
          </Box>
          <Box ml={theme.spacing(1)} />
          <Typography variant="h5" color="textSecondary">
            Ödeme
          </Typography>
        </Box>
        {PAYMENT_OPTIONS.map((item, index) => (
          <ButtonBase
            key={`CARD_${index}`}
            className={classes.paymentInfoBtn}
            onClick={() => setPaymentMethod(item)}
          >
            <Box display="flex" alignItems="center">
              <Radio
                color="primary"
                checked={paymentMethod.id === item.id}
                onChange={() => setPaymentMethod(item)}
              />
              <Typography variant="body1" color="textSecondary">
                {item.label}
              </Typography>
            </Box>
            <Box display="flex">
              {item.icon}
            </Box>
          </ButtonBase>
        ))}
        <Button
          disabled={loading}
          style={{
            maxWidth: "auto",
            padding: `${theme.spacing(2)} 0`,
            background: "#FF6B5E",
            marginTop: theme.spacing(2),
            width: "100%",
            borderRadius: 0,
          }}
          onClick={(e) => {
            e.preventDefault()
            createOrder();
          }}
        >
          <Typography
            style={{
              ...theme.typography.body1,
              color: theme.palette.common.white,
              fontWeight: 700,
            }}
          >
            Sİparİş Ver
          </Typography>
        </Button>
        <Box mt={theme.spacing(2)} />
        <Typography
          variant="body2"
          style={{
            color: theme.palette.text.disabled,
          }}
        >
          Şu anda sadece nakit ödemeyle teslimatı destekliyoruz.
        </Typography>
      </Container>
    </Paper>
  );
}

export default PaymentCard;
