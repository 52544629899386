import makeStyles from '@mui/styles/makeStyles';

const useStyle = makeStyles((theme) => ({
  mainContainer: {
    padding: (extraSmall) => (extraSmall ? "0px  5vw" : "0px"),
    margin: "20px",
    flexDirection: "row",
    display: "flex",
    flexWrap: 'wrap',  // Added for responsiveness
    justifyContent: 'center',  // Center the items
    marginLeft: "auto",  // Center the tabs
    marginRight: "auto",  // Center the tabs
    maxWidth: 1400,  // Set a max-width to prevent the tabs from spreading too far on large screens
  },
}));

export default useStyle;
