import { AppBar, Box, Toolbar, Typography, Button } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import ProfileIcon from "../../../assets/icons/ProfileIcon";
import useStyles from "./styles";
import { ReactComponent as Logo } from "../../../assets/images/solo_logo.svg";
import { useTheme } from "@emotion/react";
import useMediaQuery from '@mui/material/useMediaQuery';

function LinkHeader({ supplierName, showCatalogName = true }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  return (
    <AppBar elevation={0} position="fixed">
      <Toolbar className={classes.toolbar}>
        <RouterLink to={"/"} className={classes.linkDecoration}>
          <Logo height={isMobile ? 40 : isTablet ? 60 : 80} width={isMobile ? 40 : isTablet ? 60 : 80} />
        </RouterLink>
        {showCatalogName && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: isMobile ? 'flex-start' : 'center',
              marginLeft: theme.spacing(isMobile ? 1 : 5),
            }}
          >
            <Typography
              variant={isMobile ? 'body2' : 'h6'}
              style={{
                fontWeight: 600,
                textAlign: isMobile ? 'left' : 'center',
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {supplierName}
            </Typography>
            <Typography
              variant={isMobile ? 'body2' : 'subtitle1'}
              color="inherit"
              style={{
                fontWeight: 500,
                textAlign: isMobile ? 'left' : 'center',
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              Ürün Kataloğu
            </Typography>
          </Box>
        )}
        <Box className={classes.flex} style={{ paddingRight: theme.spacing(isMobile ? 2 : 0) }}>
          <RouterLink to={"/"} className={classes.linkDecoration}>
            <Button aria-controls="simple-menu" aria-haspopup="true">
              <ProfileIcon />
              <Typography
                variant="button"
                color="white"
                className={`${classes.ml} ${classes.font700} ${classes.guestMobileText}`}
              >
                TEDABİR'E KAYIT OL
              </Typography>
            </Button>
          </RouterLink>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default React.memo(LinkHeader);
