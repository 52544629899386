/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Divider,
  Card as ICard,
  Typography,
  CardActionArea,
} from "@mui/material";
import StarSharpIcon from "@mui/icons-material/StarSharp";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import { categorisMap, Pictures } from "../../Utils";

function PricingDelivery({ grid, minimum, deliveryCharges, deliversToArea }) {
  const classes = useStyles();
  const containerStyle = grid
    ? { display: "flex", alignItems: "center" }
    : { display: "block" };
  return (
    <Box style={containerStyle}>
      {deliversToArea ? (
        <>
          <Box style={{ display: "flex" }}>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              className={classes.priceText}
            >
              {minimum} TL
            </Typography>
            <Typography
              style={{ marginLeft: "4px" }}
              className={classes.priceDescription}
            >
              minumum
            </Typography>
          </Box>
          <Divider
            light
            orientation="horizontal"
            style={{ width: "1px", height: "18px", margin: "auto 5px" }}
          />
          <Box style={{ display: "flex" }}>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              className={classes.priceText}
            >
              {deliveryCharges > 0 ? `${deliveryCharges} TL` : ``}
            </Typography>
            <Typography
              variant="caption"
              style={{ marginLeft: "4px" }}
              className={classes.priceDescription}
            >
              {deliveryCharges > 0 ? "teslimat" : ""}
            </Typography>
          </Box>
        </>
      ) : (
        <Typography variant="subtitle1" color="error" className={classes.priceText}>
          Bu bölgeye teslimat yapılmıyor
        </Typography>
      )}

    </Box>
  );
}

function Card(props) {
  const item = props.data ?? null;
  const navigateTo = useNavigate();
  const grid = props.grid ? props.grid : false;
  const cardImageHeight = props.cardImageHeight
    ? props.cardImageHeight
    : "144px";
  const classes = useStyles(props);
  const imageSource = item.imageData ? `data:image/jpeg;base64,${item.imageData}` : Pictures[item.mainCategory]?.source?.default;
  const navigate = useCallback(() => {
    navigateTo(`/market/${item.id}`, {
      state: {
        id: item.id,
        category: item.mainCategory,
      },
    });
  }
  );

  return (
    <ICard className={classes.card}>
      <CardActionArea
        onClick={(e) => {
          e.preventDefault();
          navigate();
        }}
      >
        <Box style={{ minWidth: "200px" }}>
          <Box
            style={{
              height: cardImageHeight,
            }}
            className={classes.imageContainer}
          >
            <Box
              style={{
                height: cardImageHeight,
                backgroundImage: `url(${imageSource})`,
              }}
              className={classes.imgContainer}
            />
            <Box className={classes.timeContainer}>
              <Typography
                color="textSecondary"
                className={classes.timeText}
                style={{ fontSize: ".75rem" }}
              >
                {item.deliveryTime}
              </Typography>
              <Typography color="textSecondary" className={classes.timeText}>
                DAKIKA
              </Typography>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography
              variant="subtitle1"
              color="textSecondary"
              className={classes.textBold}
            >
              {item.businessName}
            </Typography>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <StarSharpIcon style={{ fontSize: "14px", color: "#276fa5" }} />
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.textBold}
              >
                {item.rating ?? 0}
              </Typography>
              <Typography variant="caption" className={classes.totalRatingText}>
                /5
              </Typography>
              <Typography
                variant="caption"
                style={{ fontSize: "0.875rem", marginLeft: "3px" }}
                className={classes.totalRatingText}
              >
                ({item.reviewCount ?? 0})
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography
              display="inline"
              noWrap
              variant="caption"
              className={classes.subDescription}
            >
              {categorisMap.get(item.mainCategory)}
            </Typography>
          </Box>
          <PricingDelivery
            minimum={item.minimumOrder ?? 0}
            deliveryCharges={item.deliversToArea ? item.deliveryFee : 0}
            grid={grid}
            deliversToArea={item.deliversToArea}
          />
        </Box>
      </CardActionArea>
    </ICard>
  );
}

export default React.memo(Card);
