import makeStyles from '@mui/styles/makeStyles';

const useStyle = makeStyles((theme) => ({
  root: {
    "& .MuiInput-root": {
      marginLeft: "10px",
      caretColor: theme.palette.primary.main,
      color: theme.palette.text.secondary,
    },
  },
  mt: {
    marginTop: theme.spacing(2),
    flexDirection: "row",
    display: "flex",
    flexWrap: 'wrap',  // Added for responsiveness
    justifyContent: 'center',  // Center the items
    marginLeft: "auto",  // Center the tabs
    marginRight: "auto",  // Center the tabs
  },
  searchContainer: {
    padding: (extraSmall) => (extraSmall ? "0px  5vw" : "0px"),
    boxShadow: "0px 0px 20px 1px rgba(0,0,0,0.2)",
  },
}));

export default useStyle;
