import makeStyles from '@mui/styles/makeStyles';

const useStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: theme.spacing(10),
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
  },
  section: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    '&:target': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  icon: {
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  socialMediaContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  footer: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    paddingBottom: theme.spacing(2),
  },
  socialIcon: {
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  mainContainer: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(4),
    },
  },
}));

export default useStyle;
