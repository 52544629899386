import makeStyles from '@mui/styles/makeStyles';

const useStyle = makeStyles((theme) => ({
  imgContainer: {
    height: "200px",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  line: {
    margin: theme.spacing(2, 0),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1, 0),
      fontSize: "0.75rem",
    },
  },
  lightText: {
    fontWeight: theme.typography.fontWeightLight,
  },
  titleText: {
    ...theme.typography.h6,
    color: theme.palette.text.disabled,
  },
  xSmallText: {
    ...theme.typography.caption,
    color: theme.palette.text.disabled,
    [theme.breakpoints.down('sm')]: {
      fontSize: "0.75rem",
    },
  },
  smallText: {
    ...theme.typography.body2,
    color: theme.palette.text.disabled,
    fontSize: "0.875rem",
    [theme.breakpoints.down('sm')]: {
      fontSize: "0.75rem",
    },
  },
  closeContainer: {
    position: "absolute",
    background: "rgba(0,0,0,0.1)",
    top: theme.spacing(1),
    right: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.3)",
    },
  },
  reviewContainer: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  reviewItem: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    },
  },
  ratingSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',

    // Stack the rating details vertically on small screens
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  ratingItem: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2), // Add some space between items

    // Remove the margin on small screens
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: theme.spacing(1), // Add some space between stacked items
    },
  },
  ratingOverview: {
    textAlign: 'center',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    borderRadius: theme.shape.borderRadius,
    maxWidth: 360,
    margin: 'auto',
  },
  ratingTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
    paddingBottom: theme.spacing(2),
  },
  ratingBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
  },
  ratingText: {
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.text.secondary,
  },
  ratingValue: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.secondary.main,
  },
}));

export default useStyle;
