import makeStyles from '@mui/styles/makeStyles';

const useStyle = makeStyles((theme) => ({
  cardContainer: {
    margin: "20px 0px",
    backgroundColor: theme.palette.common.white,
    paddingBottom: '10px',
    paddingLeft: '16px',
    width: '100%',
  },
  titleText: {
    paddingTop: "20px",
    paddingBottom: "10px",
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  boldText: {
    fontWeight: 700,
  },
  categoryHeader: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
    color: "#FF6B5E",
  },
  footerContainer: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
  },
  contentGrow: {
    flexGrow: 1,
  },
  socialMediaContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
  },
  footer: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  addToCartButton: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    fontSize: '0.7rem',
    padding: theme.spacing(0.5, 1),
    minWidth: 'auto',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem',
    },
  },
  quantityInput: {
    width: '100%',
    maxWidth: '70px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '60px',
    },
  },
  quantityControlButton: {
    width: '30px',
    padding: '4px',
    [theme.breakpoints.down('sm')]: {
      width: '25px',
    },
  },
  itemDesc: {
    color: theme.palette.text.disabled,
    padding: '2px 6px',
    borderRadius: '4px',
    fontSize: '0.6rem',
    textAlign: 'justify',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
  brand: {
    backgroundColor: '#f0f0f0',
    padding: '2px 6px',
    borderRadius: '4px',
    marginBottom: '8px',
    fontSize: '0.7rem',
  },
  productCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(1),
    height: 240,
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 200,
    },
  },
  clickableCard: {
    cursor: 'pointer',
    '&:hover': {
      boxShadow: theme.shadows[6],
    },
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(1),
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'flex-start',
  },
  productImage: {
    width: '100%',
    height: '90px',
    objectFit: 'contain',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      height: '70px',
      maxWidth: '100%',
    },
  },
  cardActionsCentered: {
    justifyContent: 'center',
    padding: '8px',
  },
  cardContentGrow: {
    flexGrow: 1,
    padding: '8px',
    [theme.breakpoints.down('sm')]: {
      padding: '4px',
    },
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: theme.spacing(1),
  },
  productName: {
    fontSize: '0.8rem',
    fontWeight: 'bold',
    lineHeight: '1.2',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem',
    },
  },
  priceText: {
    fontSize: '0.7rem',
    fontWeight: 'normal',
    color: theme.palette.text.primary,
  },
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  categoryMainHeader: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
    color: "#FF6B5E",
    fontSize: '1.5rem',
  },
  categorySubHeader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '1.0rem',
  },
}));

export default useStyle;
