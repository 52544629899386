import { Box, Button, Container, Typography, useTheme, useMediaQuery } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import StarSharpIcon from "@mui/icons-material/StarSharp";
import clsx from "clsx";
import React, { useState } from "react";
import useStyles from "./styles";

function SupplierHeader({ toggleModal, headerData }) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`https://tedarikci.tedabir.com/#/auth/register?refer=${headerData?.id}`);
    setIsLinkCopied(true);
    setTimeout(() => setIsLinkCopied(false), 3000);
  };
  return (
    <Box>
      <Container style={{ marginLeft: "0px" }}>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "15px",
          }}
        >
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: theme.spacing(1)
            }}
          >
            <Typography className={classes.restaurantTitle} style={{ fontWeight: 'bold' }}>
              {headerData?.businessName ?? "..."}
            </Typography>
            <Box ml={theme.spacing(2)} />
            {headerData?.personName && (
              <>
                <Typography>
                  {headerData?.personName}
                </Typography>
              </>
            )}
          </Box>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Button onClick={toggleModal}>
              <InfoOutlinedIcon style={{ fill: "#FF6B5E" }} />
            </Button>
          </Box>
        </Box>
      </Container>
      <>
        {(mobile && headerData.type === "MARKET") ? (
          <Container style={{ marginLeft: "0px", paddingLeft: "0px", marginLeft: '-10px', display: "flex", flexDirection: "column" }}>
            <Box style={{ display: "flex", justifyContent: "space-around" }}>
              <Box className={clsx({ [classes.tagMobileContainer]: true, [classes.closeContainer]: false })}>
                <Typography className={clsx({ [classes.tagStyles]: true, [classes.closeTag]: false })}>
                  {true ? "Aktif" : "Closed"}
                </Typography>
              </Box>
              {headerData.minimumOrder &&
                <Box className={clsx({ [classes.tagMobileContainer]: true, [classes.closeContainer]: false })}>
                  <Typography className={clsx({ [classes.tagStyles]: true, [classes.closeTag]: false })}>
                    Minimum
                  </Typography>
                </Box>
              }
              {
                headerData?.delivery &&
                <Box className={clsx({ [classes.tagMobileContainer]: true, [classes.closeContainer]: false })}>
                  <Typography className={clsx({ [classes.tagStyles]: true, [classes.closeTag]: false })}>
                    Teslimat
                  </Typography>
                </Box>
              }
            </Box>
            <Box style={{ display: "flex", justifyContent: "space-around" }}>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography style={{ fontSize: "0.875rem" }} className={classes.totalRatingText}>
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <StarSharpIcon style={{ fontSize: "14px", color: "#276fa5" }} />
                    <Typography className={classes.currentRatingText}>{headerData?.rating ? headerData.rating : 0}</Typography>
                    <Typography className={classes.totalRatingText}>/5</Typography>
                    <Typography className={classes.totalRatingText} marginLeft='5px'>
                      ({headerData?.reviewCount || 0})
                    </Typography>
                  </Box>
                </Typography>
              </Box>
              {headerData.minimumOrder &&
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <Typography style={{ fontSize: "0.875rem" }} className={classes.totalRatingText}>
                    {headerData?.minimumOrder} TL
                  </Typography>
                </Box>
              }
              {
                headerData?.delivery &&
                <Box style={{ display: "flex", alignItems: "center", marginRight: "-15px" }}>
                  <Typography style={{ fontSize: "0.875rem" }} className={classes.totalRatingText}>
                    {`${headerData?.delivery?.deliveryTime} DK - ${headerData?.delivery?.deliveryFee} TL`}
                  </Typography>
                </Box>
              }
            </Box>
          </Container>
        ) : (
          <Container style={{ marginLeft: "0px", display: "flex" }}>
            <Box className={clsx({ [classes.tagContainer]: true, [classes.closeContainer]: false })}>
              <Typography className={clsx({ [classes.tagStyles]: true, [classes.closeTag]: false })}>
                {true ? "Aktif" : "Closed"}
              </Typography>
            </Box>
            <Box style={{ display: "flex", alignItems: "center", marginLeft: "5px" }}>
              <StarSharpIcon style={{ fontSize: "14px", color: "#276fa5" }} />
              <Typography className={classes.currentRatingText}>{headerData?.rating ? headerData.rating : 0}</Typography>
              <Typography className={classes.totalRatingText}>/5</Typography>
              <Typography className={classes.totalRatingText} marginLeft='5px'>
                ({headerData?.reviewCount || 0}{mobile ? '' : ' Geri Bildirim'})
              </Typography>
            </Box>
            {headerData.minimumOrder &&
              <>
                <Box ml={theme.spacing(3)} />
                <Box className={clsx({ [classes.tagContainer]: true, [classes.closeContainer]: false })}>
                  <Typography className={clsx({ [classes.tagStyles]: true, [classes.closeTag]: false })}>
                    Minimum
                  </Typography>
                </Box>
                <Box style={{ display: "flex", alignItems: "center", marginLeft: "5px" }}>
                  <Typography style={{ fontSize: "0.875rem", marginLeft: "3px" }} className={classes.totalRatingText}>
                    {headerData?.minimumOrder} TL
                  </Typography>
                </Box>
              </>
            }
            {
              headerData?.delivery &&
              <>
                <Box ml={theme.spacing(3)} />
                <Box className={clsx({ [classes.tagContainer]: true, [classes.closeContainer]: false })}>
                  <Typography className={clsx({ [classes.tagStyles]: true, [classes.closeTag]: false })}>
                    Teslimat
                  </Typography>
                </Box>
                <Box style={{ display: "flex", alignItems: "center", marginLeft: "5px" }}>
                  <Typography style={{ fontSize: "0.875rem", marginLeft: "3px" }} className={classes.totalRatingText}>
                    {`${headerData?.delivery?.deliveryTime} DK - ${headerData?.delivery?.deliveryFee} TL`}
                  </Typography>
                </Box>
              </>
            }
            {headerData.type === "SUPPLIER" && !headerData?.delivery && !headerData.isPartner &&
              <>
                <Typography
                  className={classes.inviteLink}
                  onClick={copyToClipboard}
                >
                  Uygulamaya Davet Linki!
                </Typography>
                {isLinkCopied && (
                  <span style={{ marginLeft: '10px', backgroundColor: '#FF6B5E', padding: '5px', borderRadius: '5px' }}>
                    Link kopyalandı
                  </span>
                )}
              </>
            }

            {headerData.type === "MARKET" && !headerData.isPartner && !headerData?.delivery &&
              <>
                <Typography variant="subtitle1" color="error" className={classes.priceText} style={{ marginLeft: '2%' }}>
                  Bu bölgeye teslimat yapılmıyor
                </Typography>
              </>
            }
          </Container>
        )
        }
      </>
    </Box>
  );
}

export default React.memo(SupplierHeader);
