/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Box
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Autocomplete from "@mui/material/Autocomplete";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import React, { useEffect, useState, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import LocationIcon from "../../../assets/icons/LocationIcon";
import { useLocationContext } from "../../../context/Location";
import { useLocation } from "../../../hooks";
import FlashMessage from "../../FlashMessage";
import useStyle from "./styles";
import { setAddress } from "../../../api/restaurant_api";
import RestaurantContext from "../../../context/Restaurant";

const autocompleteService = { current: null };

function SearchContainer() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const extraSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyle({ mobile, extraSmall });
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState(["Loading ..."]);
  const { getCurrentLocation } = useLocation();
  const [open, setOpen] = useState();
  const { location, setLocation } = useLocationContext();
  const [search, setSearch] = useState("");
  const [alertError, setAlertError] = useState(null);
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const [addressDetails, setAddressDetails] = useState({
    addressInfo: "",
    town: "",
    city: "",
    latitude: 0,
    longitude: 0,
  });
  const { setAddressInfo, profile } = useContext(RestaurantContext);
  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );
  const locationCallback = (error, data) => {
    setLoading(false)
    if (error) {
      setAlertError(error)
      setOpen(true)
      return
    }
    setSearch(data.deliveryAddress)
    setLocation(data)
  }
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];
        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const [addressError, setAddressError] = useState("");
  const [townError, setTownError] = useState("");
  const [cityError, setCityError] = useState("");

  const clearErrors = () => {
    setAddressError("");
    setTownError("")
    setCityError("")
  };

  const handleAction = async () => {
    clearErrors();
    if (addressDetails.addressInfo == "") {
      setAddressError("Adresi Girmelisiniz")
      return;
    }
    if (addressDetails.town == "") {
      setTownError("İlçe Girmelisiniz")
      return;
    }
    if (addressDetails.city == "") {
      setCityError("İl Girmelisiniz")
      return;
    }
    const data = {
      restaurantId: profile.id,
      address: addressDetails.addressInfo,
      district: addressDetails.town,
      city: addressDetails.city,
      latitude: addressDetails.latitude,
      longitude: addressDetails.longitude
    }
    try {
      const response = await setAddress(data);
      if (response === null || response.status === undefined || response.status !== 200) {
      }
      else if (response !== undefined && response !== null && response.status === 200) {
        await setAddressInfo({
          addressInfo: addressDetails.addressInfo,
          town: addressDetails.town,
          city: addressDetails.city,
        });
        navigate('/', { state: { fromAddressPage: true } });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <>
      <FlashMessage
        severity={loading ? "info" : "error"}
        alertMessage={alertError}
        open={open}
        handleClose={handleClose}
      />
      <Typography variant="h5" className={classes.font700}>
        Lütfen adresinizi seçiniz
      </Typography>
      <Autocomplete
        id="google-map-demo"
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.description
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={loading ? "..." : search ? search : location ? location.deliveryAddress : ""}
        onChange={(event, newValue) => {
          if (newValue) {
            const b = new window.google.maps.Geocoder();
            b.geocode({ placeId: newValue.place_id }, (res) => {
              const location = res[0].geometry.location;

              // Split address for Turkish standards
              const addressComponents = newValue.description.split(',');
              let combinedAddress = addressComponents.slice(0, -2).join(',').trim(); // Everything before the district and city

              const townAndCity = addressComponents[addressComponents.length - 2] || ""; // Typically "Mamak/Ankara"
              const [town, city] = townAndCity.split('/');

              setAddressDetails({
                addressInfo: combinedAddress,
                town: town.trim(),
                city: city.trim(),
                latitude: location.lat(),
                longitude: location.lng()
              });

              setLocation({
                label: "Home",
                deliveryAddress: newValue.description,
                latitude: location.lat(),
                longitude: location.lng()
              })
            });
          } else {
            setSearch("");
          }
          setOptions(newValue ? [...options] : options);
          setValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            style={{ color: "initial" }}
            variant="outlined"
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  <InputAdornment
                    position="end"
                    onClick={(e) => {
                      e.preventDefault();
                      setValue(search?.deliveryAddress ?? "");
                      setSearch(location?.deliveryAddress ?? "");
                    }}
                  >
                    {loading ? (
                      <SyncLoader
                        color={theme.palette.primary.main}
                        size={5}
                        speedMultiplier={0.7}
                        margin={1}
                      />
                    ) : (
                      <LocationIcon onClick={(e) => {
                        e.preventDefault()
                        setLoading(true)
                        getCurrentLocation(locationCallback)
                      }} />
                    )}
                  </InputAdornment>
                </>
              ),
            }}
          />
        )}
        renderOption={(props, option) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings;
          let parts = null;
          if (matches) {
            parts = parse(
              option.structured_formatting.main_text,
              matches.map((match) => [
                match.offset,
                match.offset + match.length,
              ])
            );
          }

          return (
            <Grid {...props} container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {parts &&
                  parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                        color: "black",
                      }}
                    >
                      {part.text}
                    </span>
                  ))}

                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />

      <Box mt={theme.spacing(1)} />

      <Grid item >
        <TextField
          label="Adres Bilgisi"
          value={addressDetails.addressInfo}
          error={Boolean(addressError)}
          helperText={addressError}
          variant="outlined"
          fullWidth
          onChange={(e) => setAddressDetails(prev => ({ ...prev, addressInfo: e.target.value }))}
        />
      </Grid>

      <Box mt={theme.spacing(1)} />

      <Grid item >
        <TextField
          label="İlçe"
          value={addressDetails.town}
          helperText={townError}
          error={Boolean(townError)}
          variant="outlined"
          fullWidth
          onChange={(e) => setAddressDetails(prev => ({ ...prev, town: e.target.value }))}
        />
      </Grid>

      <Box mt={theme.spacing(1)} />

      <Grid item >
        <TextField
          label="Şehir"
          value={addressDetails.city}
          helperText={cityError}
          error={Boolean(cityError)}
          variant="outlined"
          fullWidth
          onChange={(e) => setAddressDetails(prev => ({ ...prev, city: e.target.value }))}
        />
      </Grid>

      <Box mt={theme.spacing(1)} />

      <Grid item >
        <Button
          variant="contained"
          style={{
            borderRadius: 30,
            backgroundColor: "#FF6B5E",
            padding: "18px 36px",
            fontSize: "12px"
          }}
          fullWidth
          disableElevation
          className={classes.button}
          onClick={(e) => {
            e.preventDefault();
            if (location) {
              handleAction();
            }
          }}
        >
          Hemen Sİparİş Vermeye Başla!
        </Button>
      </Grid>
    </>
  );
}

export default React.memo(SearchContainer);
