import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import clsx from "clsx";
import React from "react";
import useStyles from "./styles";

function NoteAdded({ title, detail, isVisible, toggleModal }) {
  const theme = useTheme();
  const classes = useStyles();
  const extraSmall = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      onClose={toggleModal}
      open={isVisible}
      fullWidth={true}
      maxWidth="xs"
      className={classes.root}
    >
      <Box display="flex" justifyContent="flex-end">
        <IconButton size={extraSmall ? "medium" : "small"} onClick={toggleModal} className={classes.closeContainer}>
          <CloseIcon color="#FF6B5E" />
        </IconButton>
      </Box>
      <DialogTitle>
        <Box component="div">
          <Typography variant="h5" color="textSecondary" className={clsx(classes.boldText, classes.MB2)}>
            {title}
          </Typography>
          <Typography variant="subtitle2" className={`${classes.disabledText} ${classes.lightText}`}>
            {detail}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={classes.btnBase}
            onClick={async (e) => {
              e.preventDefault();
              toggleModal();
            }}
            style={{
              borderRadius: 30,
              backgroundColor: "#FF6B5E",
              padding: "18px 36px",
              fontSize: "18px"
            }}
          >
            <Typography variant="subtitle2" color="secondary" className={classes.boldText}>
              Kapat
            </Typography>
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default React.memo(NoteAdded);
