import {
  Box,
  Container,
  Dialog,
  Divider,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import StarSharpIcon from "@mui/icons-material/StarSharp";
import React, { useCallback, useState } from "react";
import useStyles from "./styles";
import TabContainer from "./TabContainer";
import { Pictures } from "../../Utils";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function MarketSupplierInfo({ isVisible, toggleModal, data }) {
  const theme = useTheme();
  const classes = useStyles();
  const extraSmall = useMediaQuery(theme.breakpoints.down('md'));
  const [tabValue, setTabValue] = useState(0);
  const image = Pictures[data?.category]?.source

  const handleChange = useCallback((event, newValue) => {
    setTabValue(newValue);
  }, []);

  return (
    <Dialog onClose={toggleModal} open={isVisible} fullWidth={true} maxWidth="md">
      <Box display="flex" justifyContent="flex-end">
        <IconButton size={extraSmall ? "medium" : "small"} onClick={toggleModal} className={classes.closeContainer}>
          <CloseIcon ccolor="#FF6B5E" />
        </IconButton>
      </Box>
      <Box className={classes.imgContainer} style={{ backgroundImage: `url('${image?.default ?? ""}')` }} />

      <Container
        style={{
          background: theme.palette.common.white,
          maxWidth: extraSmall ? "400px" : "700px",
          marginTop: "-50px",
          paddingLeft: "0px",
          paddingRight: "0px",
          boxShadow: theme.shadows[1],
        }}
      >
        <Box pt={theme.spacing(2)}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography className={classes.titleText} style={{ color: theme.palette.text.secondary, fontWeight: 700 }}>
              {data?.businessName}
            </Typography>
            <Box pl={theme.spacing(1)} pr={theme.spacing(1)} />
            <StarSharpIcon style={{ fontSize: "14px", color: "#276fa5" }} />
            <Typography className={classes.xSmallText} style={{ fontWeight: 700, color: theme.palette.text.secondary }}>
              {data?.rating ? data.rating : 0}
            </Typography>
            <Typography className={classes.xSmallText}>/5</Typography>
            <Typography style={{ fontSize: "0.875rem", marginLeft: "3px" }} className={classes.xSmallText}>
              ({data?.reviewCount ? data.reviewCount : 0} Geri Bildirim)
            </Typography>
            <Box pb={theme.spacing(2)} />
          </Box>
          <Box
            display="flex"
            paddingLeft={`${theme.spacing(1)}`}
            alignItems="center"
            justifyContent="center"
            pt={theme.spacing(1)}
          >
            <Typography className={classes.xSmallText} style={{ paddingRight: "5px" }}>
              <span style={{ fontWeight: 'bold' }}>Puanlama: </span>
              Kalite: <span style={{ fontWeight: 'bold' }}>{data?.qualityRating || 0}  </span>
              Fiyat: <span style={{ fontWeight: 'bold' }}>{data?.priceRating || 0}  </span>
              Teslimat: <span style={{ fontWeight: 'bold' }}>{data?.deliveryRating || 0}  </span>
            </Typography>
          </Box>
          <Divider light orientation="horizontal" />
          <Box display="flex" justifyContent="center">
            <Tabs value={tabValue} onChange={handleChange}>
              <Tab style={{ color: "black" }} label="Genel Bilgiler" {...a11yProps(0)} />
              <Tab style={{ color: "black" }} label="Yorumlar" {...a11yProps(1)} />
            </Tabs>
          </Box>
        </Box>
      </Container>

      <Container className={classes.reviewContainer}>
        <Container>
          <TabContainer value={tabValue} index={0}>
            <Typography className={classes.titleText} style={{ color: theme.palette.text.secondary }}>
              Adres
            </Typography>
            <Typography className={classes.smallText}>{data.address || ""}</Typography>
            <Box pt={theme.spacing(2)} />

            <Typography className={classes.titleText} style={{ color: theme.palette.text.secondary }}>
              Dağıtım Bölgeleri
            </Typography>
            {data?.deliveryDtos?.map((del, index) => (
              <Box display="flex" key={`${del}_${index}`}>
                <Typography className={classes.smallText} style={{ width: "150px" }}>
                  {" "}
                  {del.city}{" - "}{del.area}
                </Typography>
                <Typography key={`TIME_${index}`} className={classes.smallText}>
                  {" "}
                  {del.deliveryTime}
                  {" DK - "}
                  {del.deliveryFee}
                  {" TL"}
                </Typography>
              </Box>
            ))}
          </TabContainer>

        </Container>

        <Container>
          <TabContainer value={tabValue} index={1}>
            {data?.reviews?.map((review, index) => (
              <Box key={`REVIEW_${review.orderId}`} className={classes.reviewItem}>
                <Box display="flex" justifyContent="space-between" flexWrap="wrap">
                  <Box className={classes.ratingSection}>
                    <Box className={classes.ratingItem}>
                      <Typography className={classes.xSmallText}>Kalite Puanı</Typography>
                      <StarSharpIcon style={{ fontSize: "14px", color: "#276fa5" }} />
                      <Typography className={classes.xSmallText}>
                        {review.qualityRating}/5
                      </Typography>
                    </Box>
                    <Box className={classes.ratingItem}>
                      <Typography className={classes.xSmallText}>Fiyatlama Puanı</Typography>
                      <StarSharpIcon style={{ fontSize: "14px", color: "#276fa5" }} />
                      <Typography className={classes.xSmallText}>
                        {review.priceRating}/5
                      </Typography>
                    </Box>
                    <Box className={classes.ratingItem}>
                      <Typography className={classes.xSmallText}>Teslimat Puanı</Typography>
                      <StarSharpIcon style={{ fontSize: "14px", color: "#276fa5" }} />
                      <Typography className={classes.xSmallText}>
                        {review.deliveryRating}/5
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Typography
                  variant="body2"
                  className={`${classes.line} ${classes.lightText}`}
                  style={{ marginLeft: '8px' }}
                >
                  {review.comment}
                </Typography>
                <Divider />
              </Box>
            ))}
          </TabContainer>
        </Container>
      </Container>
    </Dialog>
  );
}

export default React.memo(MarketSupplierInfo);
