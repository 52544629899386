import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Container,
  Typography,
  Grid,
  Paper,
  Button,
  Card,
  CardContent,
  Divider,
  Avatar,
  IconButton,
  Tooltip,
} from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import useStyles from './styles';
import { Header } from '../../components/Header';
import { Chart } from 'react-google-charts';
import StackedBarChart from './StackedBarChart';
import { mockData } from '../../components/Utils';
import ProductAnalysis from './ProductAnalysis';
import { useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useMediaQuery, useTheme } from "@mui/material";
import RestaurantContext from '../../context/Restaurant';
import { getWeeklyReport } from '../../api/restaurant_api';

function WeeklyReport() {
  const reportRef = useRef(null);
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [report, setReport] = useState('');
  const { restaurantId } = useContext(RestaurantContext);

  const weeklyData = {
    totalOrders: 10,
    mostOrderedProducts: [
      { name: 'Domates', quantity: 10 },
      { name: 'Tavuk', quantity: 3 },
      { name: 'Maydonoz', quantity: 2 },
    ],
    supplierAnalysis: [
      { name: 'Kasap Ali', orders: 1, change: 1 },
      { name: 'Sebzeci Fatma', orders: 2, change: -1 },
      { name: 'Ahmet Kahve', orders: 4, change: 3 },
    ],
    newSuppliers: [
      { name: 'Deniz Balikci', orders: 1, amountOwed: 500 },
      { name: 'Erdem Kuruyemis', orders: 1, amountOwed: 300 },
      { name: 'Fasil Sut Urunleri', orders: 1, amountOwed: 450 },
    ],
  };

  const productData = [
    { name: 'Domates', quantity: 10, unit: 'KG', supplier: 'Sebzeci Fatma', change: 5 },
    { name: 'Maydonoz', quantity: 2, unit: 'Adet', supplier: 'Sebzeci Fatma', change: -2 },
  ];

  const fetchReport = async () => {
    try {
      const response = await getWeeklyReport(restaurantId);
      if (response && response.status === 200 && response.data) {
        setReport(response.data);
      } else {
        setReport([]);
      }
    } catch (error) {
      console.error('Error fetching report:', error);
    }
  };

  useEffect(() => {
    fetchReport();
  }, []);

  const navigateTo = useNavigate();
  const goToPlanPage = () => {
    navigateTo('/planner');
  };

  const downloadReport = () => {
    const input = reportRef.current;
  
    if (input) {
      const inputWidth = input.offsetWidth;
      const inputHeight = input.offsetHeight;
  
      // Set up pdf format (A4 in this case, you might need to adjust this)
      const pdfWidth = inputWidth + 40;
      const pdfHeight = inputHeight + 40;
      const pdfFormat = [pdfWidth, pdfHeight];
  
      html2canvas(input, {
        scale: 1,
        useCORS: true,
        scrollY: -window.scrollY,
        scrollX: 0,
        windowHeight: inputHeight,
        windowWidth: inputWidth,
      })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'pt', pdfFormat);
  
        // Add image to pdf
        pdf.addImage(imgData, 'PNG', 20, 20, inputWidth, inputHeight);
        pdf.save('haftalik-rapor.pdf');
      })
      .catch(error => {
        console.error("Error generating PDF", error);
      });
    } else {
      console.error("Report element not found");
    }
  };  

  return (
    <Grid container className={classes.root} ref={reportRef}>
      <Header />
      <Grid container item className={classes.marginHeader}>
        <Grid item xs={12} className={classes.mainContainer}>
          <Container maxWidth="lg">
            <Typography variant={mobile ? "h5" : "h4"} className={classes.pageTitle} gutterBottom>
              Haftalık Analiz Raporunuz
            </Typography>

            <Paper elevation={3} className={classes.enhancedPaper}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Typography variant={mobile ? "h6" : "h5"} className={classes.enhancedTitle}>
                    🚀 Bu Hafta TedaBir'le Harika Bir Performans!
                  </Typography>
                  <Typography variant="subtitle1">
                    Tam <strong>{weeklyData.totalOrders} sipariş</strong> verdiniz ve <strong>3 farklı tedarikçiden</strong> ürün aldınız. Ayrıca, <strong>2 yeni tedarikçi</strong> keşfettiniz ve toplamda <strong>50 ürün</strong> sipariş ettiniz!
                  </Typography>
                </Grid>
                <Grid item className={classes.buttonContainer}>
                  <Button variant="contained" color="secondary" className={classes.reportButton} onClick={downloadReport}>
                    Raporu İndİr 📥
                  </Button>
                  <Button variant="outlined" color="primary" className={classes.planButton} onClick={goToPlanPage}>
                    Gelecek Haftayı Planla 📅
                  </Button>
                </Grid>
              </Grid>
            </Paper>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                  Sipariş Analizi
                </Typography>
                <StackedBarChart ordersData={mockData.ordersData} />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                  En Çok Sipariş Edilen Ürünler
                </Typography>
                <Chart
                  chartType="BarChart"
                  width="100%"
                  height="400px"
                  data={[
                    ['name', 'Miktar'],
                    ...weeklyData.mostOrderedProducts.map(product => [product.name, product.quantity]),
                  ]}
                  options={{
                    legend: 'none',
                  }}
                />
              </Grid>
            </Grid>

            <Divider style={{ margin: '20px 0' }} />

            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Card elevation={3} className={classes.chartCard}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Tedarikçi Analizi
                    </Typography>
                    {weeklyData.supplierAnalysis.map((supplier) => (
                      <Paper key={supplier.name} className={classes.supplierCard}>
                        <Avatar className={classes.supplierAvatar}>
                          {supplier.name.charAt(0)}
                        </Avatar>
                        <div className={classes.supplierInfo}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="subtitle1" className={classes.supplierName}>
                              {supplier.name}
                            </Typography>
                            {supplier.change >= 0 ? (
                              <Tooltip title="Siparişlerde Artış">
                                <IconButton className={classes.supplierChangeIcon}>
                                  <TrendingUpIcon />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <Tooltip title="Siparişlerde Düşüş">
                                <IconButton className={`${classes.supplierChangeIcon} ${classes.supplierChangeIconNegative}`}>
                                  <TrendingDownIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                            <Typography
                              variant="body2"
                              className={supplier.change >= 0 ? classes.supplierChangeIcon : classes.supplierChangeIconNegative}
                            >
                              {supplier.change}%
                            </Typography>
                          </div>
                          <Typography variant="body2">
                            Toplam Siparişler: {supplier.orders}
                          </Typography>
                        </div>
                      </Paper>
                    ))}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card elevation={3} className={classes.chartCard}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Yeni Tedarikçiler
                    </Typography>
                    {weeklyData.newSuppliers.map((supplier) => (
                      <Paper key={supplier.name} className={classes.supplierCard}>
                        <Avatar className={classes.supplierAvatar}>
                          {supplier.name.charAt(0)}
                        </Avatar>
                        <div className={classes.supplierInfo}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="subtitle1" className={classes.supplierName}>
                              {supplier.name}
                            </Typography>
                            <Tooltip title="Borç Miktarı">
                              <Typography variant="body2" className={classes.amountOwed}>
                                ₺{supplier.amountOwed}
                              </Typography>
                            </Tooltip>
                          </div>
                          <Typography variant="body2">
                            Toplam Siparişler: {supplier.orders}
                          </Typography>
                        </div>
                      </Paper>
                    ))}
                  </CardContent>
                </Card>
              </Grid>

            </Grid>

            <Divider style={{ margin: '20px 0' }} />


            <Container maxWidth="lg" sx={{ marginBottom: 20 }}>
              <ProductAnalysis productData={productData} />
            </Container>

          </Container>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default WeeklyReport;
